// React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderWidth, borderColor } = borders;
const { transparent, info, grey, white } = colors;

const checkbox = {
  styleOverrides: {
    root: {
      "& .MuiSvgIcon-root": {
        border: `${borderWidth[1]} solid ${borderColor}`,
        borderRadius: pxToRem(5.6),
        backgroundColor: transparent.main
      },

      "&:hover": {
        backgroundColor: transparent.main
      },

      "&.Mui-focusVisible": {
        border: `${borderWidth[2]} solid ${info.main} !important`
      },

      "&.Mui-checked .MuiSvgIcon-root": {
        backgroundColor: info.main,
        color: white.main,
        borderColor: info.main
      },

      "&.Mui-disabled .MuiSvgIcon-root": {
        backgroundColor: grey[200],
        borderColor: grey[400]
      },

      "&.Mui-disabled.Mui-checked .MuiSvgIcon-root": {
        backgroundColor: grey[300],
        color: white.main
      }
    },

    colorPrimary: {
      color: borderColor
    },

    colorSecondary: {
      color: borderColor
    }
  }
};

export default checkbox;
