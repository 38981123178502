import React, { useState, useEffect } from "react";

// @mui components
import { FormGroup, TextField } from "@mui/material";

// @mui icons
import StraightenIcon from '@mui/icons-material/Straighten';

// Components
import { AdministrationHeaderWhereNoTabPanelIsUsed } from "components/AdministrationComponents/General";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createDescriptor, updateDescriptor, getDescriptor } from "apiservices/ResultServices/descriptorService";

const NumericDescriptorView = () => {
  const [descriptor, setDescriptor] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    if (descriptor) {
      setDescriptor(prevDescriptor => ({
        ...prevDescriptor,
        type: 2
      }));
    }
  }, [descriptor]);

  const handleSetDescriptor = (descriptor, e) => setDescriptor({...descriptor, ...{[descriptor]: e.target.checked}});

  return (
    <AdministrationObjectWrapper
      object={descriptor}
      setObject={setDescriptor}
        getObject={getDescriptor}
        createObject={createDescriptor}
        updateObject={updateDescriptor}
        namePlaceholder="Numeric Descriptor Name"
        descriptionPlaceholder="Description of Descriptor"
        iconComponent={<StraightenIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab="1"
        header={<AdministrationHeaderWhereNoTabPanelIsUsed/>}
        form={
          descriptor &&
            <FormGroup>
              {/* <FormControl error={Boolean(invalidFields.applicationAtlasTypes)}>
                <AdminFormLabel error={invalidFields.applicationAtlasTypes}>Application atlas types:</AdminFormLabel>
                <FormControlLabel
                  control={<Checkbox checked={descriptor.isPrimaryApplicable} onChange={(e) => { handleSetDescriptor("isPrimaryApplicable", e); if (e.target.checked) setInvalidFields({ ...invalidFields, applicationAtlasTypes: null }) }}/>}
                  name="primary"
                  label="Primary"/>
              </FormControl> */}
              <TextField
                fullWidth
                label="Default Value"
                value={descriptor.minValue || ""}
                onChange={(e) => setDescriptor({...descriptor, minValue: e.target.value})}
                error={Boolean(invalidFields.minValue)}
                helperText={invalidFields.minValue}/>
            </FormGroup>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
    />
  );
}

export default NumericDescriptorView;