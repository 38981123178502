import React, { useState } from "react";

// @mui components
import { Box, Typography, TextField, Tooltip, IconButton } from "@mui/material";
import { styled } from '@mui/material/styles';

// @mui icons
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';

const StyledBox = styled(Box)(({ theme }) => ({
  flex: 0,
  border: `1px solid ${theme.palette.grey[400]}`,
  height: 37,
  borderRadius: 6,
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center'
}));

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' }
];

const SearchOrderFilterPanel = ({ items, title, onFilterChange }) => {
  const [filteredItems, setFilteredItems] = useState(items);
  const [searchQuery, setSearchQuery] = useState("");

  const [constraints, setConstraints] = useState({
    search: "",
    orderBy: undefined,
    orderType: undefined,
    orderDirection: 0,
    // selectedCategories: initialSelectedCategories
  });

  const handleSearchChange = (event) => {
    const searchText = event.target.value.toLowerCase();
    setSearchQuery(searchText);
    const filteredData = items.filter((item) => item.name.toLowerCase().includes(searchText));
    setFilteredItems(filteredData);
    onFilterChange(filteredData);
  };

  return (
    <Box width={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
      <Typography variant="h1" fontSize={16}>
        {title}
      </Typography>
      <Box display='flex' flexDirection='row' gap={1}>
        <TextField
            variant="outlined"
            label="Search here"
            size="small"
            value={searchQuery}
            onChange={handleSearchChange}/>
        {/* Ordering Box */}
        <StyledBox>
          <Tooltip title="Ordering Attributes" placement="top" arrow={false}>
            <LowPriorityIcon sx={{ color: 'grey.500', fontSize: 27 }} />
          </Tooltip>
          {
            filterAttributes
              .filter((attr) => ['string', 'datetime', 'numeric'].includes(attr.type))
              .map((attr) => (
                <Tooltip title={attr.name + tooltipExtensions[constraints.orderDirection]} placement='top' key={attr.name}>
                  <IconButton
                    sx={{ pr: 0 }}
                    // onClick={() => handleSortClick(attr.property, attr.type)}
                    >
                    {renderIcon(attr.icon, constraints.orderBy === attr.property ? constraints.orderDirection : 0)}
                  </IconButton>
                </Tooltip>
              ))
          }
      </StyledBox>
      </Box>
    </Box>
  );
};

export default SearchOrderFilterPanel;


const renderIcon = (iconName, state=0) => {
  const colors = ['black', 'blue', 'red'];
  
  switch (iconName) {
    case 'alpha': return <SortByAlphaIcon sx={{ fontSize: 26, color: colors[state] }} fontSize=''/>;
    case 'datetime': return <DateRangeIcon sx={{ fontSize: 26, color: colors[state] }} fontSize=''/>;
    case 'description': return <DescriptionIcon sx={{ fontSize: 26, color: colors[state] }} fontSize=''/>;
    default: return null;
  }
}

const tooltipExtensions = {
  0: ' (Unsorted)',
  1: ' (Forward sorted)',
  2: ' (Reverse sorted)'
}