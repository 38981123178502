import React, { useState, useEffect } from "react";

// @mui components
import { Grid } from "@mui/material";
import { TextField } from "@mui/material";

// Components
import { WrapperModal } from "components/GlobalComponents/Modals";

// Db Context
import { createFunction, updateFunction, getFunction } from "apiservices/InventoryServices/inventoryItemTypeService";

function FunctionEditor({ closeEditor, functionId, setFunctions, inventoryItemTypeId }) {
    const [functionData, setFunctionData] = useState(null);
  
    useEffect(() => {
      const getFunctionData = async () => {
        const data = await getFunction(functionId);
        setFunctionData(data);
      };
      getFunctionData();
    }, [functionId]);
  
    const handlePrimaryEvent = async (e) => {
      e.preventDefault();
      if (!functionData) return;
      const updatedFunctionData = { ...functionData, inventoryItemTypeId };
      const result = functionData.id === 0
        ? await createFunction(updatedFunctionData)
        : await updateFunction(updatedFunctionData);
        
      setFunctions(result);
      closeEditor(e, -1);
    };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFunctionData((prevData) => ({ ...prevData, [name]: value }));
    };
  
    return (
      functionData &&
        <WrapperModal
          open={true}
          handleClose={(e) => closeEditor(e, -1)}
          title={functionData.id === 0 ? "Create New Function" : "Update Function"}
          primaryButtonText={functionData.id === 0 ? "Add Function" : "Update Function"}
          primaryEvent={handlePrimaryEvent}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                value={functionData.name}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={3}
                label="Description"
                name="description"
                value={functionData.description}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
        </WrapperModal>
    );
  }

export default FunctionEditor;