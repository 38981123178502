import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';

const ResizableBox = ({ children, styling, minHeight, defaultHeight }) => {
  const [height, setHeight] = useState(defaultHeight);
  const resizeRef = useRef(null);
  const initialMouseY = useRef(0);

  const handleMouseDown = (event) => {
    event.preventDefault();
    initialMouseY.current = event.clientY;
    resizeRef.current = true;

    // Add event listeners
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (event) => {
    if (resizeRef.current) {
      const delta = event.clientY - initialMouseY.current;
      setHeight(prevHeight => Math.max(prevHeight + delta, minHeight));
      initialMouseY.current = event.clientY; // Update the initial Y position
    }
  };

  const handleMouseUp = () => {
    resizeRef.current = false;

    // Remove event listeners
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <Box sx={{ display: "flex", height: height, position: 'relative', overflow: 'hidden', ...styling }}>
      {children}
      <Box
        sx={{
          height: '2px',
          backgroundColor: 'lightgrey',
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          cursor: 'ns-resize'
        }}
        onMouseDown={handleMouseDown}
      />
    </Box>
  );
};

export default ResizableBox;