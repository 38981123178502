import React, { useState } from "react";

// @mui components
import { Box, IconButton } from "@mui/material";
import { TextField, Typography, Icon, Tooltip, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// Local context
import { useAdminController, setIsDirty } from "context";

// Components
import { scrollbarStyles } from "./ScrollbarWrapper";

export const TextFieldTypographySwitchBox = ({ // this breaks if validation isnt included
  obj,
  setObj,
  attribute,
  viewKey,
  backgroundColour = "rgba(235, 235, 235, 0.9)",
  height = "40px",
  lines = undefined,
  validate,
  setInvalidFields
}) => {
  const [controller, dispatch] = useAdminController();
  const [editorVisible, setEditorVisible] = useState(obj[attribute] === "");
  const handleSetEditorVisible = () => setEditorVisible(!editorVisible);

  const handleValueChange = (e) => {
    setObj({ ...obj, [attribute]: e.target.value });
    setIsDirty(dispatch, viewKey);
    const invalidFields = validate(obj);
    setInvalidFields({ ...invalidFields });
  };

  const renderTextField = () => (
    <TextField
      id={attribute}
      value={obj[attribute]}
      onChange={handleValueChange}
      fullWidth
      autoFocus
      sx={{
        width: "500px",
        mt: -0.6,
        mx: -1.5,
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none",
          }
        } 
      }}
    />
  );

  const renderMultilineTextField = () => (
    <TextField
      id={attribute}
      value={obj[attribute]}
      onChange={handleValueChange}
      fullWidth
      autoFocus
      multiline
      maxRows={lines}
      sx={{
        height: "100%",
        lineHeight: "1.2",
        width: "500px",
        mx: -1.5,
        pt: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            border: "none"
          }
        },
        "& .MuiInputBase-inputMultiline": {
          overflow: "auto",
          maxHeight: `${lines}em`,
          paddingRight: "4px",
          paddingTop: "4px",
          ...scrollbarStyles
        }
      }}/>
  );

  const renderTypography = () => (
    <Typography 
      pt={0.5}
      variant="h6" 
      noWrap>
      {obj[attribute]}
    </Typography>
  );

  const renderMultilineTypography = () => (
    <Typography
      variant="h6"
      sx={{
        pt: 0.8,
        lineHeight: 1.2,
        display: "-webkit-box",
        WebkitLineClamp: lines,
        WebkitBoxOrient: "vertical",
        overflow: "hidden"
      }}
    >
      {obj[attribute]}
    </Typography>
  );


  return (
    <Box
      display="flex"
      alignItems="flex-start"
      width={1}
      border={editorVisible ? "1px solid blue" : "1px solid lightgray"}
      borderRadius="6px"
      position="relative"
      sx={{ 
        minHeight: "35px",
        px: 0.8,
        height: height }}
    >
      <Tooltip title={obj[attribute]}>
        {editorVisible && lines === undefined ? 
          renderTextField() 
        : editorVisible && lines !== undefined ? 
          renderMultilineTextField() 
        : !editorVisible && lines === undefined ? 
          renderTypography() 
        : renderMultilineTypography()}
      </Tooltip>
      <Box>
        <Box sx={{ position: "absolute", left: 7, top: -8, height: '0.4em' }} style={{ background: backgroundColour }}>
          <Typography
            fontWeight={100}
            fontSize={10}
            sx={{ textTransform: 'capitalize', mx: 0.2, color: "gray" }}>
            {attribute}
          </Typography>
        </Box>
        <IconButton onClick={handleSetEditorVisible} sx={{position: "absolute", right: -5, top: -5, p: 0, background: backgroundColour }}>
          <Icon style={{ color: editorVisible ? "blue" : "grey", fontSize: 14 }} fontSize="">create</Icon>
        </IconButton>
      </Box>
    </Box>
  );
};

export const TextFieldTypographySwitchBox2 = ({ obj, setObj, changeValue, attribute, backgroundColour = "grey.300", pl = 2 }) => {
  const [editorVisible, setEditorVisible] = useState(obj[attribute] === "");
  const handleSetEditorVisible = () => setEditorVisible(!editorVisible);
  
  // const handleValueChange = (e) => setObj({ ...obj, [attribute]: e.target.value });

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      width={1}
      pl={pl}
      backgroundColor={backgroundColour}
      borderRadius="6px">
      <Tooltip title={obj[attribute]}>
        {
          editorVisible ?
            <TextField
              id={attribute}
              value={obj[attribute]}
              inputProps={{ style: { height: "3.5px" } }}
              onChange={(e) => changeValue(obj.nodeId, obj.metricId, e.target.value)}
              fullWidth /> :
            <Typography variant="h6" noWrap>{obj[attribute]}</Typography>
        }
      </Tooltip>
      <IconButton onClick={handleSetEditorVisible}>
        <Icon>create</Icon>
      </IconButton>
    </Box>
  );
}

export const TextFieldSelectSwitchBox = ({value, setValue, options, backgroundColor = "transparent", pl=2}) => {
  const [editorVisible, setEditorVisible] = useState(value === undefined);

  // useEffect(() => {
  //   setEditorVisible(obj[attribute] === "");
  // }, [obj, attribute]);
  
  const handleSetEditorVisible = () => setEditorVisible(!editorVisible);

  const hasCategories = Object.keys(options).length > 0;
  options = hasCategories ? options : { 1: "No executors added" };
  let label = hasCategories ? "Select Category" : "";

  // const handleValueChange = (e) => setObj({...obj, ...{[e.target.id]: e.target.value}});
  return(
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      pl={pl}
      backgroundColor={backgroundColor}
      borderRadius="6px">
      {
        editorVisible === false ?
          <Typography variant="h6" noWrap>{value}</Typography> :
          <FormControl disabled={!hasCategories}>
            <InputLabel htmlFor="executor-select">{label}</InputLabel>
            <Select
              sx={{ height: "32px", width: "200px", background: "transparent", color: hasCategories ? "inherit" : "red"}}
              id="executor-select"
              value={hasCategories ? "" : 1}
              label={label}
              onChange={setValue}
              >
              {
                Object.entries(options).map(([id, value]) => {
                  return (
                    <MenuItem value={id} key={id}>{value}</MenuItem>
                  )
                })
              }
            </Select>
          </FormControl>
      }
      <IconButton onClick={handleSetEditorVisible}>
        <Icon>create</Icon>
      </IconButton>
    </Box>
  )
}