import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import newImage from './Diversity.webp';

const rolesIndividuals = [
  {
    name: "Roles & Individuals",
    paragraph1: "Merit is for everyone in your organisation, from those driving efficiency across the business to those proving their impact on the ground. For leadership and executives, Merit provides a vital framework to monitor performance, optimise processes, and ensure that the organisation runs based on merit, ensuring the right people and resources are where they are needed most.",
    paragraph2: "For managers and team leads, it delivers essential insights into resource allocation, team performance, and project progress, making it easier to streamline operations and meet objectives.",
    paragraph3: "At the delivery level, Merit empowers individuals to demonstrate their effectiveness and hone their skills with clear, highly granular, measurable data on their strengths and weaknesses. Whether you're managing a team or executing tasks, Merit ensures your work is visible and valued, making it an integral tool for improvement and recognition."
  }
];

export const RolesIndividuals = () => {
  return (
    <Box
      id="individuals"
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '0px', md: '20px' },
        py: { xs: '17px', md: '40px' }, 
        backgroundColor: '#f2e8e1',
        scrollMarginTop: { xs: '28px', sm: '10px', md: '-20px' }
      }}>
      <Container>
        <Grid container spacing={{ xs: 2, md: 4 }} alignItems="center">
          {/* Image on the left */}
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={newImage}
              alt="Diversity illustration"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }}
            />
          </Grid>

          {/* Text on the right */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: { xs: '11px', md: '20px' },
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }}
            >
              <Typography 
                fontSize={{ xs: 15, md: 22 }}
                gutterBottom
                sx={{ fontWeight: 600 }}>
                {rolesIndividuals[0].name}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                paragraph 
                sx={{ fontWeight: 400 }}>
                {rolesIndividuals[0].paragraph1}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                paragraph 
                sx={{ fontWeight: 400 }}>
                {rolesIndividuals[0].paragraph2}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                paragraph 
                sx={{ fontWeight: 400 }}>
                {rolesIndividuals[0].paragraph3}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
