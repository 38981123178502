import React, { useEffect, useState } from "react";

// @mui components
import { Box } from "@mui/material";
import { Typography, FormControlLabel, Checkbox, FormGroup } from "@mui/material";

//
import { getValidResultScopes } from "apiservices/ResultServices/scopeService";

const InstanceScopesEditor = ({ instance, setInstance, resultId }) => {
  const [scopes, setScopes] = useState([]);

  const toggleScope = (scopeId) => {
    let _scopes = instance.scopes;
    if (_scopes.includes(scopeId)) {
        _scopes = _scopes.filter((id) => id !== scopeId);
    } else {
        _scopes.push(scopeId);
    }
    setInstance({ ...instance, scopes: _scopes });
  };

  useEffect(() => {
    if (!resultId) return;
    const _getScopes = async () => {
      let _scopes = await getValidResultScopes(resultId);
      setScopes(_scopes);
    };
    _getScopes();
  }, [resultId]);

  return (
    <Box sx={{ backgroundColor: "#F5F5F5", borderRadius: "4px", width: 1, flexGrow: 1 }}>
      <Typography variant="h6" sx={{ p: 1 }}>Instance Scopes</Typography>
      <FormGroup sx={{ ml: 1 }}>
        {scopes.map((scope) => (
          <FormControlLabel
            key={scope.id}
            onClick={() => toggleScope(scope.id)}
            control={<Checkbox size='large' checked={instance.scopes.includes(scope.id)}/>}
            label={scope.name}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

export default InstanceScopesEditor;