// @mui components
import { Box, Grid, Card } from "@mui/material";
import { Typography } from "@mui/material";

// @mui icons
import UploadIcon from '@mui/icons-material/Upload';

// helpers
import { generateThumbnail } from "helpers/jsxpanelhelpers/helpers";

const HorizontalCardWrapper = ({ obj, setObj, icon, children, imgUploader = true, nameError = "", descriptionError = "", mt= 1.5 }) => {

  const handleImageUpload = async (e) => {
    let image = e.target.files[0];
    let base64Thumbnail = await generateThumbnail(image, [300, 300]);
    setObj({...obj, ...{media: base64Thumbnail}});
  }

  let colour = obj.media === "" ? "#b39ddb" : "transparent";

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <Card
        sx={{
          width: "275px",
          height: "126px",
          display: "flex",
          borderRadius: "6px",
          alignItems: "flex-start",
          flexDirection: "row",
          flexWrap: "nowrap",
          p: 1
        }}>
        <Box 
          style={{ width: "110px", height: "110px", background: colour }}
          display="flex"
          borderRadius="6px"
          justifyContent="center"
          alignItems="center"
          mr={1}>
          {
            obj.media === "" ?
              icon :
              <img src={obj.media} style={{ height: "100%", width: "100%", borderRadius: "6px" }}/>
          }
          {
            imgUploader &&
              <label
                onChange={handleImageUpload}
                htmlFor="image-input"
                style={{
                  zIndex: 2,
                  position: "absolute",
                  left: 9,
                  bottom: 4,
                  cursor: "pointer"
                }}>
                <input type="file" id="image-input" directory="C:\workspace\" hidden/>
                <UploadIcon sx={{ mb: 0, color: 'GrayText', fontSize: 21 }} fontSize=""/>
              </label>
          }
        </Box>
        {children}
      </Card>
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
        <Box sx={{ display: "flex", alignItems: "center", height: "50%" }}>
          <Typography variant="subtitle2" component="div" sx={{ ml: 1, mt: mt, color: "warning.main"}}>{nameError}</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", height: "50%" }}>
          <Typography variant="subtitle2" component="div" sx={{ ml: 1, mt: mt, color: "warning.main"}}>{descriptionError}</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default HorizontalCardWrapper;