import { postWrapper, getWrapper } from '../fetchWrapper';

export const createDescriptor = async (descriptor) => await postWrapper(`/api/descriptor/createdescriptor`, descriptor);
export const updateDescriptor = async (descriptor) => await postWrapper(`/api/descriptor/updatedescriptor`, descriptor);
export const deleteDescriptor = async (descriptorId) => await getWrapper(`/api/descriptor/deletedescriptor/` + descriptorId);
export const getDescriptors = async (organisationId, descriptorTypeId) => await getWrapper(`/api/descriptor/getdescriptors/` + organisationId + `/` + descriptorTypeId);
export const getDescriptor = async (descriptorId) => await getWrapper(`/api/descriptor/getdescriptor/` + descriptorId);

export const createDescriptorTag = async (descriptorTag) => await postWrapper(`/api/descriptor/createdescriptortag`, descriptorTag);
export const updateDescriptorTag = async (descriptorTag) => await postWrapper(`/api/descriptor/updatedescriptortag`, descriptorTag);
export const deleteDescriptorTag = async (descriptorTagId) => await getWrapper(`/api/descriptor/deletedescriptortag/` + descriptorTagId);
export const getDescriptorTags = async (descriptorId) => await getWrapper(`/api/descriptor/getdescriptortags/` + descriptorId);
export const getDescriptorTag = async (descriptorTagId) => await getWrapper(`/api/descriptor/getdescriptortag/` + descriptorTagId);