import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";

// Db Context
import { getUserUserAtlases } from "apiservices/AuthorisationServices/userAdminService";

// Local context
import { useAdminController } from "context";

const UserLandingPage = () => {
  const [controller, dispatch] = useAdminController();
  const { userId } = controller;

  const [atlases, setAtlases] = useState([]);
  useEffect(() => {
    const _getAtlases = async () => {
      let atlasReferences = await getUserUserAtlases(userId);
      setAtlases(atlasReferences);
    };
    _getAtlases();
  }, []);
  
  return (
    <AdministrationViewWrapper ml={0} mr={0} mt={1} mb={0}>
      <MediaCardArrayContainer items={atlases} path={"/usercanvas"} br={"0"}/>
    </AdministrationViewWrapper>
  );
}

export default UserLandingPage;