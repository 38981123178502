import React, { useState } from 'react';

// @ mui components
import { Box } from '@mui/material';
import { IconButton, Input } from '@mui/material';

// @ mui icons
import UploadIcon from '@mui/icons-material/Upload';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

// Components
import { generateThumbnail } from 'helpers/jsxpanelhelpers/helpers';

import theme from "assets/theme";

const UploadBox = () => {
  const [thumbnail, setThumbnail] = useState(null);

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    const thumbnailDataUrl = await generateThumbnail(file, {
      width: 200,
      height: 200,
    });
    setThumbnail(thumbnailDataUrl);
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: 220,
        mt: 1,
        borderRadius: 1,
        border: `1px solid ${theme.palette.grey[400]}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      {thumbnail ? (
        <img
          src={thumbnail}
          width="100%"
          height="100%"
          style={{ objectFit: 'contain' }}
        />
      ) : (
        <WallpaperIcon
          sx={{
            fontSize: 100,
            color: (theme) => theme.palette.grey[500]
          }}
          fontSize=''
        />
      )}
      <IconButton
        sx={{
          position: 'absolute',
          bottom: 8,
          left: 8,
        }}
        component="label"
      >
        <UploadIcon />
        <Input
          type="file"
          accept="image/*"
          sx={{ display: 'none' }}
          onChange={handleImageChange}
        />
      </IconButton>
    </Box>
  );
};

export default UploadBox;