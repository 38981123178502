export function getFontsize(strings, maxFontSize, boxWidth, boxHeight) {
  const padding = boxWidth * 0.1;
  const decrementFactor = 0.03;
  let fontSize = maxFontSize;

  // Pre-calculate max height based on boxHeight and padding
  const maxTextHeight = boxHeight - 2 * padding;

  // Function to estimate word width based on a single character's average width
  const estimateWordWidth = (word, fontSize) => {
    const averageCharWidth = fontSize * 0.6;
    return word.length * averageCharWidth;
  };

  const calculateTextHeight = (str, fontSize) => {
    const lineHeight = fontSize * 1.2; // Estimated line height
    const wordWrapWidth = boxWidth - 2 * padding;
    const words = str.split(' ');
    let lines = 1;
    let currentLineWidth = 0;

    words.forEach(word => {
      let wordWidth = estimateWordWidth(word, fontSize);

      if (wordWidth > wordWrapWidth) {
        // Estimate the number of lines for long words
        lines += Math.ceil(wordWidth / wordWrapWidth);
        wordWidth = wordWrapWidth; // Treat as a full line
      }

      if (currentLineWidth + wordWidth > wordWrapWidth) {
        lines++;
        currentLineWidth = wordWidth;
      } else {
        currentLineWidth += wordWidth;
      }
    });

    return lines * lineHeight;
  };

  const calculateLongestTextHeight = () => {
    return strings.reduce((longestHeight, str) => {
      return Math.max(longestHeight, calculateTextHeight(str, fontSize));
    }, 0);
  };

  let longestTextHeight = calculateLongestTextHeight();

  while (longestTextHeight > maxTextHeight) {
    fontSize *= (1 - decrementFactor);
    longestTextHeight = calculateLongestTextHeight();
  }

  return fontSize;
}
