
const cardContent = {
  styleOverrides: {
    root: {
      marginTop: 0,
      marginBottom: 0
    },
  },
};

export default cardContent;
