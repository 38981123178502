export const logger = (source, type, message) => {
  switch(type) {
    case 'info':
      console.info(`[${source}] ${message}`);
      break;
    case 'warn':
      console.warn(`[${source}] ${message}`);
      break;
    case 'error':
      console.error(`[${source}] ${message}`);
      break;
    default:
      console.log(`[${source}] ${message}`);
  }
}