import { postWrapper, getWrapper } from '../fetchWrapper';

export const createAtlasAccessPolicy = async (accessPolicy, atlasId) => await postWrapper('/api/accesspolicy/createatlasaccesspolicy/' + atlasId, accessPolicy);
export const updateAtlasAccessPolicy = async (accessPolicy, atlasId) => await postWrapper('/api/accesspolicy/updateatlasaccesspolicy/' + atlasId, accessPolicy);

export const deleteAtlasAccessPolicy = async (accessPolicyId, atlasId) => await getWrapper(`/api/accesspolicy/deleteatlasaccesspolicy/` + accessPolicyId + '/' + atlasId);
export const getAtlasAccessPolicies = async (organisationId, atlasId) => await getWrapper(`/api/accesspolicy/getatlasaccesspolicies/` + organisationId + '/' + atlasId);
export const getAtlasAccessPolicy = async (atlasId, organisationId, accessPolicyId) => await getWrapper(`/api/accesspolicy/getatlasaccesspolicy/` + atlasId + '/' + organisationId + '/' + accessPolicyId);

export const getAppliedAtlasAccessPolicy = async (organisationId, entityId, entityType, atlasId) => await getWrapper(`/api/accesspolicy/getappliedatlasaccesspolicy/` + organisationId + '/' + entityId + '/' + entityType + '/' + atlasId);
export const updateAppliedAtlasAccessPolicy = async (organisationId, entityId, entityType, atlasId, policyId) => await getWrapper(`/api/accesspolicy/updateappliedatlasaccesspolicy/` + organisationId + '/' + entityId + '/' + entityType + '/' + atlasId + '/' + policyId);