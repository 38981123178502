import React, { useState, useEffect } from 'react';

// @mui components
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, TextField } from "@mui/material";

// Components
import { EditorModal } from "components/GlobalComponents/Modals";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

import { submitDocumentReview } from 'apiservices/AtlasServices/knowledgeService';

const KnowledgeReviewWindow = ({ closeReviewWindow, id }) => {
  const [purposeOfVisit, setPurposeOfVisit] = useState('');
  const [documentAddressedIssue, setDocumentAddressedIssue] = useState();
  const [appropriatelyLabeled, setAppropriatelyLabeled] = useState();
  const [falseOrMisleading, setFalseOrMisleading] = useState();
  const [additionalComments, setAdditionalComments] = useState('');

  const [timeOpened, setTimeOpened] = useState(null);
  const [timeSpent, setTimeSpent] = useState(0);

  useEffect(() => {
    const startTime = Date.now();
    setTimeOpened(startTime);

    return () => {
      const endTime = Date.now();
      const duration = Math.round((endTime - startTime) / 1000);
      setTimeSpent(duration);
    };
  }, []);

  const handleSubmit = () => {
    const endTime = Date.now();
    const duration = Math.round((endTime - timeOpened) / 1000);
  
    const formData = {
      nodeKMReferenceId: id,
      purposeOfVisit,
      documentAddressedIssue,
      appropriatelyLabeled,
      falseOrMisleading,
      additionalComments,
      timeSpent: duration,
    };
    submitDocumentReview(formData);
    closeReviewWindow();
  };

  return (
    <EditorModal
      open={true}
      toggleEditorVisibility={closeReviewWindow}
      title="Review Document"
      createButtonText="Submit Review"
      submitEvent={handleSubmit}
      width="42%"
      height="56.5%"
    >
      <ScrollbarWrapper style={{ gap: 1, mt: 1 }}>
        
        {/* Purpose of Visit */}
        <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
          <FormLabel>What is the purpose of your visit?</FormLabel>
          <RadioGroup
            value={purposeOfVisit}
            onChange={(e) => setPurposeOfVisit(e.target.value)}
          >
            <FormControlLabel value="reviewing_information" control={<Radio />} label="Reviewing Information" />
            <FormControlLabel value="seeking_clarification" control={<Radio />} label="Seeking Clarification" />
            <FormControlLabel value="verifying_accuracy" control={<Radio />} label="Verifying Accuracy" />
            <FormControlLabel value="learning_researching" control={<Radio />} label="Learning / Researching" />
            <FormControlLabel value="providing_feedback" control={<Radio />} label="Providing Feedback" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>

        {/* Did this document address the issue true/false/null */}
        <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
          <FormLabel>Did this document address the issue?</FormLabel>
          <RadioGroup
            row
            value={documentAddressedIssue === undefined ? '' : documentAddressedIssue === null ? '' : documentAddressedIssue}
            onChange={(e) => setDocumentAddressedIssue(e.target.value === 'true' ? true : e.target.value === 'false' ? false : null)}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
            <FormControlLabel value={null} control={<Radio />} label="N/A" />
          </RadioGroup>
        </FormControl>

        {/* Was this document appropriately labeled true/false/null */}
        <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
          <FormLabel>Was this document appropriately labeled?</FormLabel>
          <RadioGroup
            row
            value={appropriatelyLabeled === undefined ? '' : appropriatelyLabeled === null ? '' : appropriatelyLabeled}
            onChange={(e) => setAppropriatelyLabeled(e.target.value === 'true' ? true : e.target.value === 'false' ? false : null)}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
            <FormControlLabel value={null} control={<Radio />} label="N/A" />
          </RadioGroup>
        </FormControl>

        {/* Was any information in this document false or misleading true/false/null */}
        <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
          <FormLabel>Was any information in this document false or misleading?</FormLabel>
          <RadioGroup
            row
            value={falseOrMisleading === undefined ? '' : falseOrMisleading === null ? '' : falseOrMisleading}
            onChange={(e) => setFalseOrMisleading(e.target.value === 'true' ? true : e.target.value === 'false' ? false : null)}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
            <FormControlLabel value={null} control={<Radio />} label="N/A" />
          </RadioGroup>
        </FormControl>

        {/* Additional Comments */}
        <TextField
          label="Additional Comments"
          multiline
          rows={3}
          sx={{ background: 'white', borderRadius: 1 }}
          value={additionalComments}
          onChange={(e) => setAdditionalComments(e.target.value)}
          variant="outlined"
        />
      </ScrollbarWrapper>
    </EditorModal>
  );
};

export default KnowledgeReviewWindow;
