import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import { Box } from "@mui/material";
import { List, Divider, IconButton, Typography } from "@mui/material";

// @mui icons
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

// Components
import SidenavCollapse from "components/Sidenav/SidenavCollapse";
import UnsavedChangesWarning from 'components/Warnings/UnsavedChangesWarning';

// Custom styles for the Sidenav
import SidenavRoot from "components/Sidenav/SidenavRoot";

// Local context
import {
  useAdminController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
  setNavigationPending,
  setGuideVisible
} from "context";

let sidenavHeaders = {"admin": "Administration", "inventoryadmin": "Inventory", "instanceadmin": "Results", "user": "" };

export default function Sidenav({ color="info", brand="", routes, activeUserRole, ...rest }) {
  const [controller, dispatch] = useAdminController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();

  let textColor = "white";
  if (transparentSidenav || (whiteSidenav && !darkMode))
    textColor = "dark";
  else if (whiteSidenav && darkMode)
    textColor = "inherit";

  const closeSidenav = () => { 
    setMiniSidenav(dispatch, true);
  }
  const openSidenav = () => { 
    setMiniSidenav(dispatch, false);
  }

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      // setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    // The event listener that's calling the handleMiniSidenav function when resizing the window.
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch]);

  const handleNavigation = (event, route) => {
    setNavigationPending(dispatch, route);
  };

  const handleShowGuide = () => {
    setGuideVisible(dispatch, true);
  }
  
  return (
      <SidenavRoot
        {...rest}
        variant="permanent"
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
        activerole={activeUserRole}>
        <Box display="flex" alignItems="end" height="45px" px={2} justifyContent="space-between" flexWrap={false}>
        {
          !miniSidenav ?
            <>
              <Box display='flex' flexDirection='row' alignItems='center'>
                <Typography component="h6" variant="button" fontWeight="medium" style={{ color: textColor }}>
                  {sidenavHeaders[activeUserRole]}
                </Typography>
                <IconButton
                  sx={{ backgroundColor: textColor, p: 0 }}
                  onClick={handleShowGuide}>
                  <InfoOutlinedIcon sx={{ ml: 0.75 }} style={{ color: textColor }}/>
                </IconButton>
              </Box>
              <IconButton
                sx={{ backgroundColor: textColor, p: 0 }}
                onClick={closeSidenav}>
                <VerticalAlignBottomIcon sx={{ transform: "rotate(90deg)" }} style={{ color: textColor }}/>
              </IconButton>
            </>
            :
            <IconButton
              sx={{ backgroundColor: textColor, pl: 1, pb: 0 }}
              onClick={openSidenav}>
              <VerticalAlignBottomIcon sx={{ transform: "rotate(-90deg)", alignSelf: "center" }} style={{ color: textColor }}/>
            </IconButton>
        }
        </Box>
        <Divider sx={{
          opacity: 0.9,
          width: '100%',
          my: 2,
          background: 'linear-gradient(to right, rgba(0, 0, 0, 0), grey, rgba(0, 0, 0, 0))',
          height: '1px',
          position: 'relative',
          border: 'none'
        }}/>
        <UnsavedChangesWarning>
          <List>
            {
              routes.map(({ name, icon, key, route }) => {
                return (
                  <div
                    key={key}
                    role="button"
                    onClick={(event) => handleNavigation(event, route)}
                    style={{ cursor: 'pointer' }}>
                    <SidenavCollapse name={name} icon={icon} active={key === location.pathname.split("/")[1]}/>
                  </div>
                );
              })
            }
          </List>
        </UnsavedChangesWarning>
      </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};