import React, { useState, useEffect, useMemo } from "react";

// @mui components
import { Box } from "@mui/material";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

// Components
import { SubmitButton } from "components/GlobalComponents/Buttons";

export const ProcessEditorWindowFooter = ({ action, buttonText, selectedProcessElementType, selectedProcessElement, setSelectedProcessElement, process, setProcess, selectedProduct, setSelectedProduct, products, level }) => {
  const handleSetProcessElementProperty = (e, type) => {
    const { name, value } = e.target;
    setProcess(prevProcess => {
      const updatedProcess = {
        ...prevProcess, [type]: prevProcess[type].map(element => 
          element.id === selectedProcessElement.id ? 
            { ...element, [name]: value } : 
            element 
        )
      };
      return updatedProcess;
    });

    // Update selectedProcessElement independently
    setSelectedProcessElement(prevSelected => ({ 
      ...prevSelected, [name]: value 
    }));
  };

  const [validProducts, setValidProducts] = useState([]);

  const isOutput = useMemo(() => {
    return selectedProcessElement && !process.nodes.map(x => x.id).includes(selectedProcessElement.targetNodeId);
  }, [selectedProcessElement, process.nodes]);

  useEffect(() => {
    if (level === 3 && isOutput) {
      setValidProducts(products.filter(x => x.isIncluded));
    } else {
      setValidProducts(products);
    }
  }, [selectedProcessElement, products, level, isOutput]);

  const handleSetSelectedProduct = (e) => {
    e.preventDefault();
    setSelectedProduct(e.target.value);
    handleSetProcessElementProperty({ target: { name: "authorisationId", value: e.target.value } }, "edges");
    handleSetProcessElementProperty({ target: { name: "name", value: products.find(x => x.id === e.target.value).name } }, "edges");
  }

  return (
    <Box
      sx={{
        width: 1,
        position: "absolute",
        display: "flex",
        justifyContent: selectedProcessElement ? "space-between" : "flex-end",
        px: 1,
        pointerEvents: "none"
      }}>
      {
        (selectedProcessElement && [3, 4].includes(selectedProcessElement.networkId)) && (
          <TextField
            name="name"
            sx={{
              width: "50%",
              height: "32px",
              pointerEvents: "auto",
              mb: 1,
              zIndex: 10,
              '& .MuiInputLabel-root': {
                transform: 'translate(10px, 10px)'
              },
              '& .MuiInputLabel-root.Mui-focused': {
                transform: 'translate(15px, -8px)', fontSize: '0.7rem'
              }
            }}
            InputProps={{ style: { backgroundColor: 'transparent', height: "32px", borderColor: 'black' } }}
            value={selectedProcessElement.name}
            label="Step Name"
            autoFocus
            onChange={(e) => handleSetProcessElementProperty(e, "nodes")}/>
        )
      }
      {
        (selectedProcessElement && selectedProcessElementType === 'edge') && (
          <FormControl fullWidth sx={{ width: "50%", pointerEvents: "auto", pb: 1 }}>
            <InputLabel id="required-product-label">Select Required Product</InputLabel>
            <Select
              labelId="required-product-label"
              id="required-product-select"
              name="authorisationId"
              value={selectedProduct || ""}
              onChange={(e) => handleSetSelectedProduct(e)}
              label="Select Required Product"
              sx={{
                height: "32px",
                zIndex: 10,
                '& .MuiSelect-select': { paddingTop: '8px' }
              }}>
              {validProducts.map((product) => (
                <MenuItem
                  value={product.id}
                  key={product.id}
                  style={{ color: product.objectTypeId === 1 ? 'green' : 'black' }}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )
      }
      <SubmitButton action={action} text={buttonText}/>
    </Box>
  );
};
