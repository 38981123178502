// @mui components
import { FormControl, InputLabel } from "@mui/material";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

export const BasicDatePicker = ({ obj, setObj, prop, label }) => {
  const handleSetDate = (newDate) => {
    if (obj && obj.hasOwnProperty(prop)) {
      setObj({ ...obj, [prop]: newDate });
    }
  };

  return (
    <FormControl fullWidth sx={{ mt: 0.6}}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          minDate={dayjs()}
          value={obj[prop] ? dayjs(obj[prop]) : null}
          onChange={(newDate) => handleSetDate(newDate)}
          id='startDate'
          label={label}
          sx={{ width: 1 }}
          slotProps={{
            textField: {
              helperText: '(MM/DD/YYYY)',
              FormHelperTextProps: {
                sx: {
                  position: 'absolute',
                  right: 20,
                  top: '50%',
                  transform: 'translateY(-70%)',
                  zIndex: 1
                }
              }
            }
          }}/>
      </LocalizationProvider>
    </FormControl>
  );
};

export const AssignmentDatePicker = ({ assignment, setObj }) => {
  const prop = "deadline";

  const handleSetDate = (newDate) => {
    setObj((prevObj) => ({
      ...prevObj,
      assignments: prevObj.assignments.map((_assignment) =>
        _assignment.id === assignment.id
          ? { ..._assignment, [prop]: newDate ? newDate.toISOString() : null }
          : _assignment
      )
    }));
  };

  return (
    <FormControl sx={{ ml: 1 }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          minDate={dayjs()}
          value={assignment[prop] ? dayjs(assignment[prop]) : null}
          onChange={(newDate) => handleSetDate(newDate)}
          id='targetDate'
          label="Target Date"
          slotProps={{
            textField: {
              sx: {
                width: '140px',
                height: '40px',
                '& .MuiInputBase-root': { width: '140px', height: '40px' },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: 'darkgray'
                  },
                  '&:hover fieldset': {
                    borderColor: 'darkgray'
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: 'darkgray'
                  }
                },
                '& .MuiInputLabel-root': {
                  top: '50%',
                  left: '12%',
                  transform: 'translateY(-50%)',
                  transition: 'top 0.3s, transform 0.3s, font-size 0.3s',
                  '&.MuiInputLabel-shrink': {
                    top: '-5px',
                    transform: 'translateY(0)',
                    fontSize: '0.75rem'
                  },
                  '&.Mui-focused': {
                    top: '30%',
                    left: '10%',
                    transform: 'translateY(-100%)'
                  }
                }
              }
            }
          }}
        />
      </LocalizationProvider>
    </FormControl>
  );
};