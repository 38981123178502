import React, { useState } from "react";

// @mui components
import { Tab, Tooltip, FormGroup, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import SellIcon from '@mui/icons-material/Sell';

// Components
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, AdminFormLabel, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createInventoryProperty, updateInventoryProperty, getInventoryProperty } from "apiservices/InventoryServices/inventoryItemPropertyService";

let createButton = [{icon: "add", name: "Add Tag to Group", id: 0}]

const InventoryTagGroup = () => {
  const [property, setProperty] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const handleSetDescriptor = (descriptor, e) => setProperty({...property, ...{[descriptor]: e.target.checked}});

  const handleDeleteTag = async (e, tagId, onDeleteComplete) => {
    // let _property = await deletePropertyAttribute(tagId);
    // setProperty(_property);
    // onDeleteComplete();
  }

  const [activeTag, setActiveTag] = useState(-1);
  const handleSetActiveTag = (e, id) => setActiveTag(id);

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(property.id !== 0)
      setActiveTab(tabNumber);
  };

  return (
    <>
      <AdministrationObjectWrapper
        object={property}
        setObject={setProperty}
        getObject={getInventoryProperty}
        createObject={createInventoryProperty}
        updateObject={updateInventoryProperty}
        namePlaceholder="Group Name"
        descriptionPlaceholder="Description of Group"
        iconComponent={<SellIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          property &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Tag Group Details" value="1"/>
              {
                property.id !== 0 ?
                  <Tab label="Tags" value="2"/> :
                  <Tooltip title="Save the tag group to begin adding tags">
                    <Tab label="Tags" value="2"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          property &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <FormGroup>
                <FormControl error={Boolean(invalidFields.applicationAtlasTypes)}>
                  <FormControlLabel
                    control={<Checkbox checked={property.isOrdinal} onChange={(e) => { handleSetDescriptor("isOrdinal", e) }}/>}
                    name="ordinal"
                    label="Ordinal"/>
                </FormControl>
              </FormGroup>
            </TabPanel>
        }
        panels={
          property?.id !== 0 &&
            <TabPanel 
              value="2"
              style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
              <CreateAndEditInPlaceArray
                items={createButton.concat(property?.propertyAttributes)}
                editor={handleSetActiveTag}
                deleteAction={handleDeleteTag}/>
            </TabPanel>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {/* {
        activeTag !== -1 &&
          <TagEditor
            closeEditor={handleSetActiveTag}
            property={property}
            setProperty={setProperty}
            tagId={activeTag}/>
      } */}
    </>
  );
}

export default InventoryTagGroup;