import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, TextField, Button, Input } from "@mui/material";

// Components
import { EditorModal } from "components/GlobalComponents/Modals";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

// Db context
import { createReference, updateReference, getDocumentCategories, getDocumentLocationTypes, getDocumentSourceTypes, 
         getDocumentReference } from "apiservices/AtlasServices/knowledgeService";

// Canvas context
import { useCanvasController } from "context/canvasContext";
import { useAdminController } from "context";

const KnowledgeEditor = ({referenceId, setReferences, setEditorVisible}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const [adminController, adminDispatch] = useAdminController();
  const { selectedObject, projectMode, activeProjectId } = canvasController;
  const { userId } = adminController;

  const [reference, setReference] = useState();
  const [categories, setCategories] = useState([]);
  const [locationTypes, setLocationTypes] = useState([]);
  const [sourceTypes, setSourceTypes] = useState([]);
  useEffect(() => {
    const _getKMReferences = async () => {
      let _reference = await getDocumentReference(referenceId);
      let _categories = await getDocumentCategories();
      let _locationTypes = await getDocumentLocationTypes();
      let _sourceTypes = await getDocumentSourceTypes();
      setReference(_reference);
      setCategories(_categories);
      setLocationTypes(_locationTypes);
      setSourceTypes(_sourceTypes);
    };
    _getKMReferences();
  }, []);

  const handleCreateReference = () => {
    reference.sentBy = userId;
    reference.projectId = projectMode ? activeProjectId : null;
    let _references = referenceId === 0 ? 
      createReference(selectedObject.id, reference) :
      updateReference(reference);
    setEditorVisible(false);
    setReferences(_references);
  }

  const handleSetSelectedCategoryId = (e) => setReference({...reference, ...{categoryId: parseInt(e.target.value)}});
  const handleSetSelectedLocationType = (e) => setReference({...reference, ...{locationTypeId: parseInt(e.target.value)}});
  const handleSetSelectedSourceTypeId = (e) => setReference({...reference, ...{sourceTypeId: parseInt(e.target.value)}});
  const handleSetPath = (path) => setReference({...reference, ...{path: path}});

  return(
    reference &&
      <EditorModal
        open={true}
        toggleEditorVisibility={() => setEditorVisible(false)}
        title="Add Document" 
        createButtonText="Submit Document"
        submitEvent={handleCreateReference}
        width='35%'
        height='45%'>
        <ScrollbarWrapper style={{ gap: 1, mt: 1 }}>
          <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
            <FormLabel id="source-type-radio-label">Source Type:</FormLabel>
            <RadioGroup
              sx={{ml: 1}}
              row
              aria-labelledby="source-type-radio-label"
              value={reference.sourceTypeId}
              onChange={handleSetSelectedSourceTypeId}>
              {
                sourceTypes.map((sourceType) => {
                  return (
                    <FormControlLabel value={sourceType.id} key={sourceType.id} control={<Radio />} label={sourceType.name}/>
                  )
              })
              }
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
            <FormLabel id="category-radio-label">Category:</FormLabel>
            <RadioGroup
              sx={{ml: 1}}
              row
              aria-labelledby="category-radio-label"
              value={reference.categoryId}
              onChange={handleSetSelectedCategoryId}>
              {
                categories.map((category) => {
                  return (
                    <FormControlLabel value={category.id} key={category.id} control={<Radio />} label={category.name}/>
                  )
              })
              }
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ background: 'white', p: 1, borderRadius: 1 }}>
            <FormLabel id="location-type-radio-label">Location Type:</FormLabel>
            <RadioGroup
              sx={{ml: 1}}
              row
              aria-labelledby="location-type-radio-label"
              value={reference.locationTypeId}
              onChange={handleSetSelectedLocationType}>
              {
                locationTypes.map((type) => {
                  return (
                    <FormControlLabel value={type.id} key={type.id} control={<Radio />} label={type.type}/>
                  )
                })
              }
            </RadioGroup>
            {
              reference.locationTypeId === 1 && (
                <Box>
                  <Button component="label" sx={{ pl: 1 }}>
                    Find File  
                    <Input hidden type="file" onChange={(e) => handleSetPath(e.target.files[0].name)} sx={{ ml: 1 }}/>
                    {/* <input hidden accept="image/*" multiple type="file" />  */}
                    {/* need to accept file of any type (the file itself is disposed of, we just need the address) */}
                  </Button>
                </Box>
              )
            }
            {
              reference.locationTypeId === 2 && (
                <TextField
                  label="Web URL"
                  placeholder="Enter or paste the internet address here"
                  type="text"
                  sx={{ mt: 1, width: 1 }}
                  onChange={(e) => handleSetPath(e.target.value)}
                  InputLabelProps={{ shrink: true }}/>
              )
            }
            {
              reference.locationTypeId === 3 && (
                <Box>
                  <Button component="label" sx={{ pl: 1 }}>
                    Find File  
                    <Input hidden type="file" onChange={(e) => handleSetPath(e.target.files[0].name)} sx={{ ml: 1 }}/>
                    {/* <input hidden accept="image/*" multiple type="file" />  */}
                    {/* need to accept file of any type (the file itself is disposed of, we just need the address) */}
                  </Button>
                </Box>
              )
            }
          </FormControl>
        </ScrollbarWrapper>
      </EditorModal>
  )
}

export default KnowledgeEditor;