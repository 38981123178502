import React, { useState } from "react";

// @mui components
import { Box, Card } from "@mui/material";
import { Typography } from "@mui/material";

// Components
import { CircularButton } from "components/GlobalComponents/Buttons";

import AdministratorProjectPanel from "./AdministratorProjectPanel";
import ExecutorProjectPanel from "./ExecutorProjectPanel";

// Canvas context
import { useAdminController } from "context";
import { useCanvasController, setActiveProjectPanelTab } from "context/canvasContext";

const AssignmentPanel = ({}) => {
  const [controller, dispatch] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { userRoles } = controller;
  const { activeProjectPanelTab } = canvasController;

  const accessibility = 
    userRoles.includes("coordinator") ? "coordinator" : 
    userRoles.includes("skilledcoordinator") ? "skilledcoordinator" : 
    null;

  const handleSetActivePanel = (e, tabNumber) => setActiveProjectPanelTab(canvasDispatch, tabNumber);
  const getUserRoleButtonColour = (tabNumber) => activeProjectPanelTab === tabNumber ? "#40c4ff" : "#787878";
  const getUserRoleButtonBorderColour = (tabNumber) => activeProjectPanelTab === tabNumber ? "black" : "white";
  return(
    <Card sx={{width: 1, borderRadius: "7px", mt: 1, p: 1 }}>
      <Box display="flex" justifyContent="space-around">
        <Typography variant="h1" fontSize={17}>Projects</Typography>
        {
          accessibility && (
            <Box sx={{ right: 7, position: "absolute", display: "flex", gap: "5px" }}>
              <CircularButton
                colour={getUserRoleButtonColour("1")}
                bordercolor={getUserRoleButtonBorderColour("1")}
                onClick={e => handleSetActivePanel(e, "1")}>
                PA
              </CircularButton>
              <CircularButton
                colour={getUserRoleButtonColour("2")}
                bordercolor={getUserRoleButtonBorderColour("2")}
                onClick={e => handleSetActivePanel(e, "2")}>
                U
              </CircularButton>
            </Box>
          )
        }
      </Box>
      {
        (accessibility && activeProjectPanelTab === "1") && (
          <AdministratorProjectPanel userRole={accessibility}/>
        )
      }
      {
        activeProjectPanelTab === "2" && (
          <ExecutorProjectPanel/>
        )
      }
    </Card>
  )
}

export default AssignmentPanel;