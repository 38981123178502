import React, { useState, useEffect } from "react";

import { MapBox, MapEdgeText, CanvasIconButton, CanvasEdge } from "components/CanvasComponents/CanvasObjects/canvascomponents";

import { addIcon, inputIcon, rubbishIcon } from "helpers/canvashelpers/base64icons";
import { mapLayoutDimensions } from "helpers/canvashelpers/constants";

import { clearStage, getMapCanvas } from "helpers/canvashelpers/canvasfunctions";

const SubProcessEditorCanvas = ({
  subProcess,
  addStep,
  deleteStep,
  addEdge,
  deleteEdge,
  selectedProcessElementType,
  setSelectedProcessElementType,
  selectedSubProcessElement,
  setSelectedSubProcessElement
}) => {

  const [app, setApp] = useState(undefined);
  const [resizeTriggered, setResizeTriggered] = useState(false);
  useEffect(() => {
    getMapCanvas(setApp, setSelectedProcessElementType, setSelectedSubProcessElement, 0.04, setResizeTriggered);
  }, [])

  useEffect(() => {
    if(app !== undefined && subProcess !== undefined){
      clearStage(app);
      let orderedProcess = subProcess.nodes.sort((a, b) => a.horizontalIndex - b.horizontalIndex);
      orderedProcess.forEach((node, i) => {
        app.stage.addChild(editorVertex(node, i, addStep, deleteStep, addEdge, selectedSubProcessElement));
        if(node.horizontalIndex > 1)
          app.stage.addChild(stepEdge(i));
        let edges = subProcess.edges.filter(x => x.targetNodeId === node.id);
        addProductEdges(app, selectedProcessElementType, setSelectedProcessElementType, selectedSubProcessElement, setSelectedSubProcessElement, deleteEdge, edges, i);
      });
    }
  }, [app, subProcess, selectedSubProcessElement, resizeTriggered])
}

function editorVertex(node, index, addStep, deleteStep, addEdge, selectedSubProcessElement) {
  const edgeColour = selectedSubProcessElement?.hasOwnProperty("networkId") && selectedSubProcessElement?.id === node.id ? 
    "#00b0ff" : "#000";
  const edgeThickness = selectedSubProcessElement?.hasOwnProperty("networkId") && selectedSubProcessElement?.id === node.id ?
    5 : 2;

  const vertex = MapBox({context: node, position: [index * 233, -100], edgeColour: edgeColour, edgeThickness: edgeThickness});

  vertex.addChild(CanvasIconButton(addIcon, [175, 2], 0x000000, 1, 0.5, addStep, 
    { networkId: 4, parentNodeId: node.parentNodeId, horizontalIndex: node.horizontalIndex + 1, name: "" }));
  vertex.addChild(CanvasIconButton(inputIcon, [2, 2], 0x000000, 1, 0.4, addEdge, { targetNodeId: node.id, type: 3 }));

  if (node.horizontalIndex > 1){
    vertex.addChild(CanvasIconButton(rubbishIcon, [3, 173], 0xFF0000, 1, 0.45, deleteStep, node.id));
  }

  return vertex;
}

function stepEdge(i){
  const startX = i * 202 + 31 * (i - 1);
  const line = { startX: startX, startY: 0, endX: startX + 32, endY: 0 };
  const _edge = {paths: [line]};
  return CanvasEdge(
    _edge,
    mapLayoutDimensions.width,
    1,
    mapLayoutDimensions.colour,
    mapLayoutDimensions.junctionRadius,
    mapLayoutDimensions.arrowheadLength
  );
}

function addProductEdges(app, selectedProcessElementType, setSelectedProcessElementType, selectedSubProcessElement, setSelectedSubProcessElement, deleteEdge, edges, stepIndex) {
  edges.forEach((edge, index) => {
    console.log(selectedSubProcessElement, edge);
    const colour = selectedProcessElementType === 'edge' && selectedSubProcessElement?.id === edge.id ? "#00b0ff" : "#000";
    const alpha = selectedProcessElementType === 'edge' && selectedSubProcessElement?.id === edge.id ? 1 : 0.25;

    const baseX = stepIndex * 233;
    const baseY = - 100;

    edge.paths = getProductPath([baseX, baseY], edge, index);
    app.stage.addChild(
      CanvasEdge(
        edge,
        mapLayoutDimensions.width,
        alpha,
        colour,
        mapLayoutDimensions.junctionRadius,
        mapLayoutDimensions.arrowheadLength,
        () => {
          setSelectedProcessElementType("edge");
          setSelectedSubProcessElement(edge);
        }
      )
    );
    
    const textX = baseX - 16;
    const textY = baseY - 27 - 42 * (index);
    app.stage.addChild(MapEdgeText(edge.name, 12, 300, [textX, textY], 1, 'left'));

    const deleteX = baseX + 40 * (index);
    const deleteY = baseY - 37 - 42 * (index);
    const deleteButton = CanvasIconButton(rubbishIcon, [deleteX, deleteY], 0xFF0000, 1, 0.32, deleteEdge, edge.id);
    app.stage.addChild(deleteButton);
  });
}

function getProductPath(position, _edge, index){
  const xLoc = position[0] + 20 + index * 40;
  // Define the starting points and lengths
  const horizontalStartX = xLoc - mapLayoutDimensions.horizontalWidth;
  const horizontalEndX = xLoc;
  const verticalStartY = position[1] - mapLayoutDimensions.verticalHeight * (index + 1);
  const verticalEndY = position[1];

  // Describe the lines as an array
  return [
    { startX: horizontalStartX, startY: verticalStartY, endX: horizontalEndX, endY: verticalStartY },
    { startX: horizontalEndX, startY: verticalStartY, endX: horizontalEndX, endY: verticalEndY }
  ];
}

export default SubProcessEditorCanvas;