import React, { useState } from "react";

// @mui components
import { TreeView, TreeItem } from '@mui/lab';

// @mui icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Components
import ResultOptionsTreeLabel from "./ResultOptionsTreeLabel";

// Contexts
import { useCanvasController } from "context/canvasContext";
  
const ResultOptionsTree = ({resultOptions, activeResultOption, setInteractionMode, interactionMode }) => {
  const [controller, dispatch] = useCanvasController();
  const { vertices } = controller;

  const [selectedResultOption, setSelectedResultOption] = useState(null);
  
  const renderTree = (option) => {
    let networkNodeCounts = getVariableNetworkNodeCounts(option, vertices);
    return(
      <TreeItem
        key={option.id}
        nodeId={option.id.toString()}
        sx={{ "& .MuiTreeItem-content": { padding: 0, backgroundColor: "#F5F5F5 !important", alignItems: 'flex-start' } }}
        label={
          <ResultOptionsTreeLabel
            option={option}
            networkNodeCounts={networkNodeCounts}
            activeResultOption={activeResultOption}
            setInteractionMode={setInteractionMode}
            selectedResultOption={selectedResultOption}
            setSelectedResultOption={setSelectedResultOption}
            interactionMode={interactionMode}
          />
        }>
        {
          resultOptions
            .filter(child => child.parentResultOptionId === option.id)
            .map(childOption => renderTree(childOption))
        }
      </TreeItem>
    )
  };

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMoreIcon sx={{ zIndex: 2, ml: 1, mt: 0.4 }}/>}
      defaultExpandIcon={<ChevronRightIcon sx={{ zIndex: 2, ml: 1, mt: 0.4 }}/>}>
      {
        resultOptions.filter(option => option.parentResultOptionId === null).map(option => renderTree(option))
      }
    </TreeView>
  )
}

function getVariableNetworkNodeCounts(option, vertices) {
  let networkNodeCounts = {};

  option.scopeNodes.forEach(scopeNode => {
    let uniqueNodeIds = new Set(scopeNode.nodeIds);

    uniqueNodeIds.forEach(nodeId => {
      let networkId = vertices.find(vertex => vertex.context.id === nodeId)?.context.networkId;
      if (networkId === undefined) return;
      
      if (networkNodeCounts[networkId] === undefined) {
        networkNodeCounts[networkId] = 1;
      } else {
        networkNodeCounts[networkId] += 1;
      }
    });
  });

  return networkNodeCounts;
}


export default ResultOptionsTree;