// @mui components
import { Box } from "@mui/material";
import { Card, CardActionArea, CardContent } from "@mui/material";
import { Typography, Tooltip, Icon } from "@mui/material";

// Components
import { DeleteButton } from "components/GlobalComponents/Buttons";

const ColourCard = ({ name, id, cardColour = "#F5F5F5", colour = "white", editor, icon, description = "", deleteAction }) => {
  let noWrap = icon === undefined ? true : false;
  
  return (
    <Card elevation={1} sx={{ height: 1, bgcolor: cardColour, borderRadius: "4px" }} id={id}>
      {
        (deleteAction !== undefined && icon === undefined) && (
          <DeleteButton deleteAction={deleteAction} id={id}/>   
        )
      }
      <CardActionArea onClick={(e) => editor(e, id)} sx={{height: 1, display: "flex", flexDirection: "column",  alignItems: "flex-start", justifyContent: "center" }} id={id}>
        <CardContent sx={{height: 1, width: 1, p: 0, textAlign: "center"}} id={id}>
          {
            icon !== undefined ?
              <Icon sx={{fontSize: 115, mb: -3.8}} fontSize="">{icon}</Icon> :
              <Box sx={{height: 0.5, backgroundColor: colour, borderTopRightRadius: "3px", borderTopLeftRadius: "3px" }}></Box>
          }
          <Tooltip title={name}>
            <Typography variant="h6" component="div" mt={1} p={1} textAlign="center" noWrap={noWrap} id={id}>
              {name}
            </Typography>
          </Tooltip>
          <Tooltip title={description}>
            <Typography variant="caption" component="div" mb={1} color="text.secondary" textAlign="center" noWrap id={id}>
              {description}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ColourCard;