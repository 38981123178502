import React, { useState, useEffect } from "react";

// @mui components
import { Card, Box } from "@mui/material";
import { Typography } from "@mui/material";

// Components
import ResizableBox from "components/GlobalComponents/ResizableBox";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";
import ResultOptionsTree from "./ResultOptionsTree";

// Contexts
import { useCanvasController, setInteractionMode, setSelectedObject } from "context/canvasContext";

// Services
import { getAtlasResultOptions, applyResultOptionToNode, removeResultOptionScopeFromNode } from "apiservices/AtlasServices/resultCorrelationService";

const ResultPanel = ({}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { interactionMode, selectedObject } = canvasController;

  const [activeResultOption, setActiveResultOption] = useState(undefined);
  const [atlasResultOptions, setAtlasResultOptions] = useState([]);

  const handleSetInteractionMode = (resultOptionId, scopeId, action) => {
    if (action === 'remove') {
      if (
        interactionMode === 'removeResultVariable' &&
        activeResultOption?.id === resultOptionId &&
        activeResultOption?.scopeId === scopeId
      ) {
        setInteractionMode(canvasDispatch, 'select');
        setActiveResultOption(undefined);
      } else {
        setInteractionMode(canvasDispatch, 'removeResultVariable');
        setActiveResultOption({ id: resultOptionId, scopeId: scopeId });
      }
    } else if (action === 'add') {
      if (
        interactionMode === 'resultVariable' &&
        activeResultOption?.id === resultOptionId &&
        activeResultOption?.scopeId === scopeId
      ) {
        setInteractionMode(canvasDispatch, 'select');
        setActiveResultOption(undefined);
      } else {
        setInteractionMode(canvasDispatch, 'resultVariable');
        setActiveResultOption({ id: resultOptionId, scopeId: scopeId });
      }
    } else if (action === 'select') {
      setInteractionMode(canvasDispatch, 'select');
      setActiveResultOption(undefined);
    }
  };
  
  useEffect(() => {
    if(selectedObject.id === 0) return;
    const _applyResultVariableToNode = async () => {
      if(interactionMode === "resultVariable" || interactionMode === "removeResultVariable" && activeResultOption !== undefined){
        let updatedOption = interactionMode === "resultVariable" ?
          await applyResultOptionToNode(selectedObject.id, activeResultOption.id, activeResultOption.scopeId) :
          await removeResultOptionScopeFromNode(selectedObject.id, activeResultOption.id, activeResultOption.scopeId);
        
        const updatedResultOptions = atlasResultOptions.map(option => 
          option.id === activeResultOption.id ? updatedOption : option
        );
        setAtlasResultOptions(updatedResultOptions);
        setSelectedObject(canvasDispatch, {id: 0, networkId: 0});
      }
    }
    _applyResultVariableToNode();
  }, [selectedObject]);

  useEffect(() => {
    const _getResultOptions = async () => {
      const _atlasResultOptions = await getAtlasResultOptions();
      setAtlasResultOptions(_atlasResultOptions);
    };
    _getResultOptions();
  }, []);

  return (
    <ResizableBox styling={{ mt: 1 }} minHeight={107} defaultHeight={242}>
      <Card sx={{width: 1, borderRadius: "7px", flexGrow: 1, textAlign: "center" }}>
        <Box display="flex" justifyContent="center" alignItems="center" height={32}>
          <Typography variant="h1" fontSize={17}>Result Options</Typography>
        </Box>
        <Card sx={{ bgcolor: "#F5F5F5", p: 1, borderRadius: "0 0 6px 6px", flexGrow: 1 }}>
          <ScrollbarWrapper mr={0}>
            <ResultOptionsTree
              resultOptions={atlasResultOptions}
              activeResultOption={activeResultOption}
              setInteractionMode={handleSetInteractionMode}
              interactionMode={interactionMode}
            />
          </ScrollbarWrapper>
        </Card>
      </Card>
    </ResizableBox>
  )
}

export default ResultPanel;