import React, { useState, useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui components
import { Box, Menu, AppBar, Toolbar, IconButton, Icon } from "@mui/material";

// Components
import { CircularButton } from "components/GlobalComponents/Buttons";
import Breadcrumbs from "components/Breadcrumbs";
import NotificationItem from "components/NotificationItem";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "components/Navbars/DashboardNavbar/styles";

// Local context
import {
  useAdminController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setRoleChangePending
} from "context";

const userRoleAbbreviations = {
  "admin": "A",
  "inventoryadmin": "IA",
  "instanceadmin": "RA",
  "skilledinstanceadmin": "ID",
  "user": "U"
}

export default function DashboardNavbar({ absolute=false, light=false, isMini=false }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useAdminController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode, userRoles, activeUserRole, logoutURL } = controller;

  const [openNotificationsMenu, setOpenNotificationsMenu] = useState(false);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar)
      setNavbarType("sticky");
    else
      setNavbarType("static");

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    // The event listener that's calling the handleTransparentNavbar function when scrolling the window.
    window.addEventListener("scroll", handleTransparentNavbar);
    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();
    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => { 
    setMiniSidenav(dispatch, !miniSidenav);
  }
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

  const handleOpenNotificationsMenu = (event) => setOpenNotificationsMenu(event.currentTarget);
  const handleCloseNotificationsMenu = () => setOpenNotificationsMenu(false);
  const handleOpenUserMenu = (event) => setOpenUserMenu(event.currentTarget);
  const handleCloseUserMenu = () => setOpenUserMenu(false);

  const handleSetActiveUserRole = (e, userRole) => {
    setRoleChangePending(dispatch, userRole);
  };

  const getUserRoleButtonColour = (userRole) => activeUserRole === userRole ? "#00b0ff" : "#424242";
  const getUserRoleButtonBorderColour = (userRole) => activeUserRole === userRole ? "black" : "white";

  // Render the notifications menu
  const renderNotificationsMenu = () => (
    <Menu
      anchorEl={openNotificationsMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openNotificationsMenu)}
      onClose={handleCloseNotificationsMenu}
      sx={{ mt: 2 }}>
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );

  const renderUserMenu = () => (
    <Menu
      anchorEl={openUserMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openUserMenu)}
      onClose={handleCloseUserMenu}
      sx={{ mt: 2 }}>
      <a href={logoutURL}>Logout</a>
      {/* <NotificationItem icon={<Icon>logout</Icon>} title="Logout" logoutUrl={logoutURL}/> */}
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light)
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;

      return colorValue;
    }
  });
  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <Box color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </Box>
        {isMini ? null : (
          <Box 
            sx={(theme) => navbarRow(theme, { isMini })}>
            <Box color={light ? "white" : "inherit"}>
              <IconButton
                size="small"
                disableRipple
                sx={navbarIconButton}
                onClick={handleOpenUserMenu}>
                <Icon sx={iconsStyle}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                // onClick={handleConfiguratorOpen}
                >
                <Icon sx={iconsStyle}>settings</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarIconButton}
                aria-controls="notification-menu"
                aria-haspopup="true"
                variant="contained"
                // onClick={handleOpenNotificationsMenu}
                >
                <Icon sx={iconsStyle}>notifications</Icon>
              </IconButton>
              {renderNotificationsMenu()}
              {renderUserMenu()}
            </Box>
            {
              userRoles.length > 1 &&
                <Box sx={{ display: "flex", gap: 1, p: 0.5, ml: 1 }}>
                  {
                    userRoles.filter(x => Object.keys(userRoleAbbreviations).includes(x)).map((userRole) => {
                      return ( 
                        <CircularButton 
                          key={userRole}
                          colour={getUserRoleButtonColour(userRole)}
                          bordercolor={getUserRoleButtonBorderColour(userRole)}
                          onClick={e => handleSetActiveUserRole(e, userRole)}>
                          {userRoleAbbreviations[userRole]}
                        </CircularButton>
                      )
                    })
                  }
                </Box>
            }
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};
