import React, { useState, useEffect } from "react";

// Compenents
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// DB context
import { getAssociates, deleteAssociate } from "apiservices/AuthorisationServices/associateService";

// Local context
import { useAdminController } from "context";

const createButton = [{media: ["add"], name: "Create New Associate", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Associates = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [associates, setAssociates] = useState([]);
  const [filteredAssociates, setFilteredAssociates] = useState([]);
  useEffect(() => {
    const _getAssociates = async () => {
      let _associates = await getAssociates(organisationId);
      setAssociates(_associates);
      setFilteredAssociates(_associates);
    };
    _getAssociates();
  }, []);

  const handleDelete = async (e, associateId, onDeleteComplete) => {
    let _associates = await deleteAssociate(associateId);
    setAssociates(_associates);
    onDeleteComplete();
  }
  
  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={createButton.concat(filteredAssociates)} path={"/associates/associate"} deleteAction={handleDelete}>
        <FinderBar
          objs={associates}
          setFilteredObjs={setFilteredAssociates}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default Associates;