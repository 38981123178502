import React, { useState, useEffect } from "react";

// @mui components
import { Box, Card, Grid } from "@mui/material";
import { Divider, Typography, Tab, IconButton, Tooltip } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";

// @mui Icons
import TripOriginIcon from '@mui/icons-material/TripOrigin';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import { networkNames } from "helpers/canvashelpers/constants";
import { networkIcons, propertiesIcons } from "helpers/canvashelpers/iconlibrary";

// Components
import { NetworkButton } from "components/CanvasComponents/OverlayButtons";
import TagGroupsPanel from "./TagGroupsPanel";
import MetricsPanel from "./MetricsPanel";
import EdgeColourPanel from "./EdgeColourPanel";
import EdgeMetricPanel from "./EdgeMetricPanel";

// Styles
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Local context
import { useCanvasController, setActiveKeyPanelTab, setOverrideLevelId, setInteractionMode, setActiveKey } from "context/canvasContext";

const KeyPanel = ({}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { activeLevel, overrideLevelId, activeKeyPanelTab, tagGroups, metrics, activeKey } = canvasController;
  
  const [levelTagData, setLevelTagData] = useState([]);
  const [levelMetricData, setLevelMetricData] = useState([]);
  useEffect(() => {
    tagGroups ? 
      setLevelTagData(tagGroups[activeLevel]): undefined;
    metrics ?
      setLevelMetricData(metrics[activeLevel]): undefined;
  }, [activeLevel, tagGroups]);

  const handleTabChange = (e, tabNumber) => {
    setInteractionMode(canvasDispatch, "select");
    setActiveKeyPanelTab(canvasDispatch, tabNumber);
  };

  const handleSetLevelOverrideId = (e, level) => {
    let override = level === overrideLevelId ? null : level;
    setOverrideLevelId(canvasDispatch, override);
  };

  const handleSetKey = (key) => {
    setActiveKey(canvasDispatch, key);
  };
  
  return (
    <Card sx={{ width: 1, borderRadius: "7px", mt: 1, p: 1 }}>
      <Typography align="center" variant="h1" fontSize={17}>Process level: {networkNames[activeLevel]}</Typography>
      <Grid container justifyContent="center" gap={1} sx={{ mt: 1 }}>
        {
          Object.keys(networkIcons).map((key) => {
            return (
              <NetworkButton
                key={key}
                item
                buttonIcon=
                  {React.cloneElement(networkIcons[key], {
                    sx: { ...networkIcons[key].props.sx, pt: 0.6 }
                  })}
                overrideLevel={overrideLevelId}
                buttonLevel={parseInt(key)}
                action={(e) => handleSetLevelOverrideId(e, parseInt(key))}
                activeLevel={parseInt(activeLevel)}/>
            );
          })
        }
      </Grid>
      {
        (levelTagData?.length > 0 || levelMetricData?.length > 0) && (
          <Divider sx={{
            opacity: 0.9,
            width: '100%',
            mt: 1,
            mb: 1.5,
            background: 'linear-gradient(to right, rgba(0, 0, 0, 0), lightgrey, rgba(0, 0, 0, 0))',
            height: '1px',
            position: 'relative',
            border: 'none'
          }}/>
        )
      }
      {
        (levelTagData?.length > 0 && levelMetricData?.length > 0) && (
          <TabContext value={activeKeyPanelTab}>
            <Box position='relative'>
              <Box
                sx={{
                  width: 55,
                  height: 32,
                  ml: -1,
                  mt: -1,
                  mr: 0,
                  background: "#faf7f2",
                  display: 'flex',
                  position: 'absolute',
                  justifyContent: 'space-evenly',
                  alignItems: 'center'}}>
                <Tooltip title="Node key" placement="top" arrow>
                  <IconButton
                    onClick={() => handleSetKey("1")}
                    sx={{ 
                      padding: 0, 
                      backgroundColor: activeKey === '1' ? '#00b0ff' : 'black',
                      height: 24,
                      width: 24,
                      ml: 0.76,
                      borderRadius: 2,
                      '&:hover': {
                        backgroundColor: activeKey === '1' ? '#0077b3' : '#333333',
                      }
                    }}>
                    <TripOriginIcon sx={{ fontSize: 20 }} fontSize=""/>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edge key" placement="top" arrow>
                  <IconButton
                    onClick={() => handleSetKey("2")}
                    sx={{ 
                      padding: 0, 
                      backgroundColor: activeKey === '2' ? '#00b0ff' : 'black',
                      height: 24,
                      borderRadius: 2,
                      '&:hover': {
                        backgroundColor: activeKey === '2' ? '#0077b3' : '#333333',
                      }
                    }}>
                    <TrendingFlatIcon 
                      sx={{ fontSize: 25, transform: 'rotate(-45deg)', fontWeight: 'bold' }} fontSize=""/>
                  </IconButton>
                </Tooltip>
              </Box>
              <AdminTabList onChange={handleTabChange} {...tabIndicator} sx={{ borderRadius: "0px", ml: 5.9, mr: -1, mt: -1, mb: 1 }}>
                <Tab value="1" sx={{ borderRadius: "0px" }}
                  icon={propertiesIcons["tag"]}/>
                <Tab value="2" sx={{ borderRadius: "0px" }}
                  icon={propertiesIcons["metric"]}/>
              </AdminTabList>
            </Box>
            <TabPanel value="1" style={{padding: 0}}>
              {
                activeKey === '1' ?
                  <TagGroupsPanel levelTagData={levelTagData}/> :
                  <EdgeColourPanel networkId={activeLevel}/>
              }
            </TabPanel>
            <TabPanel value="2" style={{padding: 0}}>
              {
                activeKey === '1' ?
                  <MetricsPanel levelMetricData={levelMetricData}/> :
                  <EdgeMetricPanel networkId={activeLevel}/>
              }
            </TabPanel>
          </TabContext>
        )
      }
      {
        (levelTagData?.length > 0 && !levelMetricData?.length > 0) && (
          <TagGroupsPanel levelTagData={levelTagData}/>
        )
      }
      {
        (!levelTagData?.length > 0 && levelMetricData?.length > 0) && (
          <MetricsPanel levelMetricData={levelMetricData}/>
        )
      }
    </Card>
  )
}

export default KeyPanel;