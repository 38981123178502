import {useNavigate} from "react-router-dom";

// @mui components
import { Box, Card } from "@mui/material";
import { Typography, IconButton, Tooltip } from "@mui/material";

// @mui icons
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ClearIcon from '@mui/icons-material/Clear';
import LanguageIcon from '@mui/icons-material/Language';
import GroupIcon from '@mui/icons-material/Group';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StraightenIcon from '@mui/icons-material/Straighten';

function getTooltipTitle(typeId) {
  switch (typeId) {
    case 1:
      return 'Atlas';
    case 2:
      return 'User Group';
    case 3:
        return 'Associate';
    case 4:
        return 'Tag Group';
    case 5:
        return 'Metric';
    default:
      return 'Default Tooltip';
  }
}
  
function getIconComponent(typeId) {
  switch (typeId) {
    case 1:
      return <LanguageIcon/>;
    case 2:
      return <GroupIcon/>;
    case 3:
      return <HandshakeIcon/>;
    case 4:
      return <LocalOfferIcon/>;
    case 5:
      return <StraightenIcon/>;
    default:
      return;
  }
}
  
function getPath(typeId) {
  switch (typeId) {
    case 1:
      return "/atlases/atlas";
    case 2:
      return "/userGroups/UserGroup";
    case 3:
      return "/associates/associate";
    case 4:
      return "/properties/tagGroup";
    case 5:
      return "/properties/metric";
    default:
      return;
  }
}

const UserRequestCard = ({request, responseAction}) => {
  const navigate = useNavigate();
  const onViewClickCallback = () => navigate(getPath(request.typeId), {state:{ id: "0", byRequest: true, request: request }});

  return(
    <Card 
      elevation={1} 
      sx={{ height: 1, bgcolor: "#F5F5F5", borderRadius: "4px", p: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Box>
        <Box width={1} display="flex" flexDirection="row" justifyContent="center" alignItems="center" position="relative" gap={1}>
          <Typography variant="h6">
            User Request
          </Typography>
          <Tooltip title={getTooltipTitle(request.typeId)}>
            {getIconComponent(request.typeId)}
          </Tooltip>
          {/* <Typography variant="h6">{request.statusId}</Typography> */}
        </Box>
        <Typography variant="h6" component="div">
          User Name:
        </Typography>
        <Typography variant="h6" fontWeight={100} component="div">
          {request.createdBy}
        </Typography>
        <Typography variant="h6" component="div">
          Testimony:
        </Typography>
        <Typography variant="h6" fontWeight={100} component="div">
          {request.testimony}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="row-reverse" justifyContent="space-between">
        <IconButton onClick={() => responseAction(request.id, false)} style={{ right: 0 }}>
          <ClearIcon style={{ color: 'red' }}/>
        </IconButton>
        {
          [1, 2, 3].includes(request.statusId) && (
            <IconButton onClick={onViewClickCallback}>
              <ExitToAppIcon style={{ color: 'green' }}/>
            </IconButton>
          )
        }
      </Box>
    </Card>
  )
}

export default UserRequestCard;