import React, { useState, useEffect } from "react";

// @mui components
import { Card, Box } from "@mui/material";
import { FormGroup, Button, Typography, Tab } from "@mui/material";
import { TabPanel, TabContext } from "@mui/lab";

// @mui icons
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';
import LinkIcon from '@mui/icons-material/Link';
import HardwareIcon from '@mui/icons-material/Hardware';
import StraightenIcon from '@mui/icons-material/Straighten';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

// Font Awesome icon import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints } from '@fortawesome/free-solid-svg-icons';

// Components
import { StatsWindowButton } from "components/CanvasComponents/OverlayButtons";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";
import NodeMetricsMetaVisual from "layouts/canvas/overlays/propertypanel/NodeMetricsMetaVisual";

import { isEmptyObject } from "helpers/jsxpanelhelpers/helpers";

// Canvas context
import { useAdminController } from "context";
import { useCanvasController, setActivePropertyPanelTab, setPropertiesManagerWindow, 
         setSelectedExecutorId, setSelectedProjectId, setStatsWindow } from "context/canvasContext";

// Db context
import { getAtlasBaseData } from "apiservices/AuthorisationServices/atlasAdminService";
import { getNodeBaseData } from "apiservices/AtlasServices/nodeService";
import { getEdgeBaseData } from "apiservices/AtlasServices/edgeService";
import { getProjectBaseData } from "apiservices/ResultServices/instanceService";
import { getNestedMetrics, getNestedKnowledgeCounts } from "apiservices/AtlasServices/propertiesDataService";

import { networkNames } from "helpers/canvashelpers/constants";

export const networkIcons = {
  1: <BusinessIcon />,
  2: <WorkIcon />,
  3: <FontAwesomeIcon icon={faShoePrints} style={{ fontSize: '14px', paddingBottom: '5px', marginLeft: 6, marginRight: 5 }}/>,
  4: <FingerprintIcon />,
  5: <CameraAltIcon />
}

const PropertiesPanel = ({atlasId}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const [adminController, adminDispatch] = useAdminController();
  const { activePropertyPanelTab, selectedObjectType, selectedObject, activeLevel, projectMode, activeMetric, activeProjectId, 
          vertices, selectedExecutorId, selectedProjectId, userAtlasPermissions } = canvasController;
  const { organisationId } = adminController;
  
  const handleTabChange = (e, tabNumber) => setActivePropertyPanelTab(canvasDispatch, tabNumber);

  const [selectionBaseData, setSelectionBaseData] = useState({});
  const [nestedNodeCounts, setNestedNodeCounts] = useState({});
  useEffect(() => {
    const nodes = vertices.map(x => x.context);
    const _nestedNodeCounts = getNestedNodeCounts(selectedObject, nodes);
    setNestedNodeCounts(_nestedNodeCounts);
  }, [selectedObject, vertices]);

  const [metricData, setMetricData] = useState({});
  const [knowledgeData, setKnowledgeData] = useState({});
  useEffect(() => {
    const _getSelectionData = async () => {
      if(selectedObjectType === "vertex"){
        if (selectedObject.id === 0){
          if (!projectMode){
            let _selectionBaseData = await getAtlasBaseData(atlasId);
            setSelectionBaseData(_selectionBaseData);
          }
          else {
            if(!activeProjectId) return;
            let _selectionBaseData = await getProjectBaseData(atlasId, activeProjectId);
            setSelectionBaseData(_selectionBaseData);
          }
        }
        else {
          if (!projectMode){
            let _selectionBaseData = await getNodeBaseData(selectedObject.id, organisationId);
            setSelectionBaseData(_selectionBaseData);
          }
          else {
            // let _selectionBaseData = await getProjectNodeBaseData(atlasId, selectedObject.id, activeProjectId);
            // setSelectionBaseData(_selectionBaseData);
          }
        }
        let projectId = projectMode ? activeProjectId : 0;
        if (activeMetric){
          let _metricData = await getNestedMetrics(selectedObject.id, activeMetric.metricId);
          setMetricData(_metricData);
        }
        let _knowledgeData = await getNestedKnowledgeCounts(selectedObject.id, projectId);
        setKnowledgeData(_knowledgeData);
      }
      else{
        if (!projectMode){
          let _selectionBaseData = await getEdgeBaseData(selectedObject.id, organisationId);
          setSelectionBaseData(_selectionBaseData);
        }
        else {
          // let _selectionBaseData = await getProjectEdgeBaseData(atlasId, selectedObject.id, activeProjectId);
          // setSelectionBaseData(_selectionBaseData);
        }
      }
    };
    _getSelectionData();
  }, [selectedObject, activeMetric, projectMode, activeProjectId]);

  const handleSetSelectedExecutor = (executorId) => setSelectedExecutorId(canvasDispatch, executorId);
  const handleSetSelectedProject = (projectId) => setSelectedProjectId(canvasDispatch, projectId);
  const handleSetPropertiesManagerWindow = (e) => setPropertiesManagerWindow(canvasDispatch, e.target.name);
  let overviewTitle = selectedObject.id === 0 ? 
    projectMode ? "Project name:" : "Atlas name:" : 
    typeTitles[activeLevel] + ":";

  return (
    <Card sx={{width: 1, height: "200px", borderRadius: "7px", mt: 1 }}>
      <TabContext value={activePropertyPanelTab} height={1}>
        <AdminTabList onChange={handleTabChange} {...tabIndicator}>
          <Tab 
            value="1" 
            sx={{ borderRadius: "0px", pl: 1.3 }}
            icon={<PermDeviceInformationIcon/>}/>
          <Tab 
            value="2" 
            sx={{ borderRadius: "0px", pl: 1.1 }}
            icon={<HardwareIcon/>}/>
          <Tab 
            value="3" 
            sx={{ borderRadius: "0px", pl: 1.3 }}
            icon={<StraightenIcon/>}/>
          <Tab 
            value="4" 
            sx={{ borderRadius: "0px", pl: 1.3 }}
            icon={<LinkIcon sx={{ fontSize: 20 }} fontSize=""/>}/>
        </AdminTabList>
        <PropertyPanelTab
          tabNumber="1"
          title="Overview"
          data={selectionBaseData}
          statsPanelName="usageStats">
          <OverviewFact intro={overviewTitle} text={selectionBaseData.name}/>
          <OverviewFact intro={"Created By:"} text={selectionBaseData.createdBy}/>
          <OverviewFact intro={"Creation Date:"} text={selectionBaseData.creationDate}/>
          <OverviewFact intro={"Last Updated By:"} text={selectionBaseData.lastUpdatedBy}/>
          <OverviewFact intro={"Last Updated Date:"} text={selectionBaseData.lastModifiedDate}/>
          {
            (selectedObject.id !== 0 && !projectMode) && (
              <>
                {
                  selectedObject.networkId == 2 && selectionBaseData.executors && (
                    <SelectorPanel
                      title="Executors"
                      data={selectionBaseData.executors}
                      setSelected={handleSetSelectedExecutor}
                      selectedId={selectedExecutorId}/>
                  )
                }
                {
                  selectedObject.networkId == 1 && selectionBaseData.instances && (
                    <SelectorPanel
                      title="Projects"
                      data={selectionBaseData.instances}
                      setSelected={handleSetSelectedProject}
                      selectedId={selectedProjectId}/>
                  )
                }
              </>
            )
          }
        </PropertyPanelTab>
        <PropertyPanelTab
          tabNumber="2"
          title={"Vertices: " + selectionBaseData.name}
          data={nestedNodeCounts}
          managerName={
            (!projectMode && userAtlasPermissions.nodeEditingPermissions.find(x => x.id === parseInt(activeLevel)).permissions.create) && 
              selectedObject.networkId + 1
          }
          managerButtonText="Node Manager"
          handleSetPropertiesManagerWindow={handleSetPropertiesManagerWindow}
          statsPanelName="nestedNodeStats">
          {
            Object.entries(nestedNodeCounts).map(([networkId, count]) => {
              let closer = count > 1 ? "s" : "";
              return (
                <Box display="flex" flexDirection="row" key={networkId} alignItems="center">
                  {React.cloneElement(networkIcons[networkId], {
                     sx: { ...networkIcons[networkId].props.sx, mb: 0.6, minWidth: "30px" }
                  })}
                  <Typography variant="h1" fontSize={15} noWrap>{count + " " + networkNames[networkId] + closer}</Typography>
                </Box>
              )
            })
          }
        </PropertyPanelTab>
        <PropertyPanelTab
          tabNumber="3"
          title={"Metrics: " + selectionBaseData.name}
          data={metricData}
          statsPanelName="metricStats">
          {/*consider including the ranking, and standard deviations from norm of the current selection*/}
          {
            Object.entries(metricData).map(([networkId, data]) => {
              return (
                <NodeMetricsMetaVisual key={networkId} data={data} icon={networkIcons[networkId]} metric={activeMetric}/>
              )
            })
          }
        </PropertyPanelTab>
        <PropertyPanelTab
          tabNumber="4"
          title={"Knowledge: " + selectionBaseData.name}
          data={knowledgeData}
          managerName={"kMEditor"}
          managerButtonText="Knowledge Manager"
          handleSetPropertiesManagerWindow={handleSetPropertiesManagerWindow}
          statsPanelName="knowledgeStats">
          {
            Object.entries(knowledgeData).map(([networkId, count]) => {
              let closer = count === 1 ? " Reference" : " References";
              return (
                <Box display="flex" flexDirection="row" key={networkId} alignItems="center">
                  {React.cloneElement(networkIcons[networkId], {
                    sx: { ...networkIcons[networkId].props.sx, minWidth: "30px" }
                  })}
                  <Typography variant="h1" fontSize={15} noWrap>{count + " " + networkNames[networkId] + closer}</Typography>
                </Box>
              )
            })
          }
        </PropertyPanelTab>
      </TabContext>
    </Card>
  )
}

function PropertyPanelTab({
  children, 
  tabNumber, 
  title, 
  data, 
  managerName, 
  managerButtonText, 
  handleSetPropertiesManagerWindow, 
  statsPanelName
}){
  const [canvasController, canvasDispatch] = useCanvasController();

  return (
    <TabPanel value={tabNumber} style={{ padding: 0,  height: "100%" }}>
      <Box display="flex" flexDirection="column" sx={{ height: 1, p: 0.8 }}>
        <ScrollbarWrapper style={{ justifyContent: 'space-between' }}>
          <Box display="flex" flexDirection="column" width={1}>
            <Card sx={{ zIndex: 2, position: 'sticky', top: 0, alignSelf: "center", maxWidth: 0.9, mb: 1 }}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor: "#FFFFFF", height: "24px", px: 1, borderRadius: "4px" }}>
                <Typography variant="h1" fontSize={17} sx={{ mb: -0.4 }} noWrap>{title}</Typography>
              </Box>
            </Card>
            <FormGroup>
              {
                !isEmptyObject(data) ?
                  children :
                  <Typography variant="h1" fontSize={15} alignSelf='center' mt={5}>Nothing to display</Typography>
              }
            </FormGroup>
          </Box>
          <Box
            display="flex"
            flexDirection="row-reverse"
            justifyContent="space-between"
            alignItems="center"
            sx={{ position: 'sticky', bottom: 0, height: "26px", pointerEvents: 'none' }}>
            <StatsWindowButton setWindow={setStatsWindow} icon={<EqualizerIcon id={statsPanelName}/>} dispatch={canvasDispatch}/>
            {
              managerName &&
                <Card sx={{ zIndex: 2, alignSelf: "center", pointerEvents: 'auto' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ backgroundColor: "#FFFFFF", height: "24px", px: 1, borderRadius: "4px" }}>
                    <Button sx={{ p: 0, color: 'green' }} name={managerName} onClick={handleSetPropertiesManagerWindow}>{managerButtonText}</Button>
                  </Box>
                </Card>
            }
          </Box>
        </ScrollbarWrapper>
      </Box>
    </TabPanel>
  )
}

function OverviewFact({intro, text}){
  return (
    <Box display="flex" flexDirection="row" mb={0.3}>
      <Typography
        fontSize={15}
        fontWeight={500}
        noWrap
        variant="h1"
        align="left">
        {intro}&nbsp;
      </Typography>
      <Typography
        fontSize={15}
        fontWeight={100}
        noWrap
        variant="h1"
        align="left">
        {text}
      </Typography>
    </Box>
  )
}

function SelectorPanel({ title, data, setSelected, selectedId }) {
  return (
    <Box display='flex' flexDirection='column' sx={{ background: '#F0F0F0', p: 0.7, alignItems: 'center', borderRadius: 1, width : 1, mt: 1 }} gap={0.7}>
      <Typography variant="h1" fontSize={15} fontWeight={500}>{title}</Typography>
      {
        data && Object.entries(data).map(([key, value], index) => {
          const isSelected = key === selectedId;
          return (
            <Button
              key={index}
              sx={{
                backgroundColor: isSelected ? "#2196f3" : "#FFF",
                color: isSelected ? "#FFF" : "#000",
                borderRadius: "4px",
                width: 1,
                height: '19px',
                padding: 0,
                minHeight: 0,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
              variant="contained"
              onClick={() => setSelected(key)}
            >
            {value}
            </Button>
          );
        })
      }
    </Box>
  );
}

const typeTitles = {
  "1": "Organisation Type",
  "2": "Job Type",
  "3": "Step Name",
  "4": "Substep Name",
  "5": "Action Name"
};

const getNestedNodeCounts = (node, nodes) => {
  const nodeCounts = {};

  if (node.id === 0) {
    // For the root node, count nodes in all networks and add only if count > 0
    for (let networkId = 1; networkId <= 5; networkId++) {
      const count = nodes.filter(x => x.networkId === networkId).length;
      if (count > 0) nodeCounts[networkId] = count;
    }
  } else {
    // Count job, step, substep, and action nodes, based on the hierarchy
    const jobNodes = node.networkId === 1 
      ? nodes.filter(x => x.parentNodeId === node.id) 
      : [];
    
    const stepNodes = jobNodes.length > 0 
      ? jobNodes.map(x => nodes.filter(y => y.parentNodeId === x.id)).flat() 
      : node.networkId === 2 
      ? nodes.filter(x => x.parentNodeId === node.id) 
      : [];

    const substepNodes = stepNodes.length > 0 
      ? stepNodes.map(x => nodes.filter(y => y.parentNodeId === x.id)).flat() 
      : node.networkId === 3 
      ? nodes.filter(x => x.parentNodeId === node.id) 
      : [];

    const actionNodes = substepNodes.length > 0 
      ? substepNodes.map(x => nodes.filter(y => y.parentNodeId === x.id)).flat() 
      : node.networkId === 4 
      ? nodes.filter(x => x.parentNodeId === node.id) 
      : [];

    // Only add counts to nodeCounts if greater than 0
    if (jobNodes.length > 0) nodeCounts[2] = jobNodes.length;
    if (stepNodes.length > 0) nodeCounts[3] = stepNodes.length;
    if (substepNodes.length > 0) nodeCounts[4] = substepNodes.length;
    if (actionNodes.length > 0) nodeCounts[5] = actionNodes.length;
  }

  return nodeCounts;
};


export default PropertiesPanel;