import React, { useEffect, useState } from "react";

// @mui components
import { FormGroup } from "@mui/material";

// @mui icons
import StraightenIcon from '@mui/icons-material/Straighten';

// Components
import { AdministrationHeaderWhereNoTabPanelIsUsed } from "components/AdministrationComponents/General";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createInventoryProperty, updateInventoryProperty, getInventoryProperty } from "apiservices/InventoryServices/inventoryItemPropertyService";

const InventoryMetric = () => {
  const [property, setProperty] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    if (property?.propertyTypeId !== 2) {
      setProperty(prevProperty => ({
        ...prevProperty,
        propertyTypeId: 2
      }));
    }
  }, [property]);
  
  return (
    <AdministrationObjectWrapper
      object={property}
      setObject={setProperty}
        getObject={getInventoryProperty}
        createObject={createInventoryProperty}
        updateObject={updateInventoryProperty}
        namePlaceholder="Metric Name"
        descriptionPlaceholder="Description of Metric"
        iconComponent={<StraightenIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab="1"
        header={<AdministrationHeaderWhereNoTabPanelIsUsed/>}
        form={
          property &&
            <FormGroup>
            </FormGroup>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
    />
  );
}

export default InventoryMetric;