import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { Typography, IconButton } from "@mui/material";

// @mui Icons
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';

import { useCanvasController, setNetworkStates } from "context/canvasContext";

export default function EdgeColourPanel({ networkId }) {
  const [controller, dispatch] = useCanvasController();
  const { networkStates } = controller;

  const [networkState, setNetworkState] = useState();
  useEffect(() => {
    const _networkState = networkStates?.find(x => x.networkId === parseInt(networkId));
    setNetworkState(_networkState);
  }, [networkStates, networkId]);

  const networkEdges = {
    1: {
      1: {
        'name': 'Products',
        'colour': '#a9a9a9'
      },
      2: {
        'name': 'Contracts',
        'colour': '#d7cbac'
      }
    },
    2: {
      1: {
        'name': 'Intra Organisational Products',
        'colour': '#a9a9a9'
      },
      2: {
        'name': 'Inter Organisational Products',
        'colour': '#aac3ab'
      }
    },
    3: {
      1: {
        'name': 'Products',
        'colour': '#a9a9a9'
      },
      3: {
        'name': 'Steps',
        'colour': '#ccb1b1'
      }
    }
  };

  const edges = Object.values(networkEdges[networkId] || {});

  const handleToggleVisibility = (edgeIndex) => {
    const visibilityKey = `edgeType${edgeIndex + 1}Visible`;

    // Update the specific network state based on networkId and toggle the visibility key
    const updatedNetworkStates = networkStates.map(state => 
      state.networkId === parseInt(networkId)
        ? { ...state, [visibilityKey]: !state[visibilityKey] }
        : state
    );

    setNetworkStates(dispatch, updatedNetworkStates);
  };

  return (
    <Box>
      <Typography align="left" variant="h1" fontSize={17} sx={{ mb: 1 }}>Edge Colours:</Typography>
      {edges.map(({ name, colour }, index) => {
        const edgeVisible = networkState?.[`edgeType${index + 1}Visible`];
        return (
          <Box key={index} display="flex" flexDirection="row" alignItems="center" mb={1}>
            <IconButton
              sx={{
                padding: 0,
                backgroundColor: edgeVisible ? colour : '#ffffff'
              }}
              onClick={() => handleToggleVisibility(index)}
            >
              <TrendingFlatIcon sx={{ fontSize: 25, transform: 'rotate(-45deg)', fontWeight: 'bold' }} />
            </IconButton>
            <Typography align="left" variant="h1" fontSize={17} sx={{ marginLeft: 1 }}>
              {name}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );
}