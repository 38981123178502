import { levelVisibilitySettings } from "helpers/canvashelpers/constants";

export function getScaleSettings(vertices, edges, scale, overrideLevelId, networkStates){
  const alphaSettings = getNetworkAlphaSettings(scale, overrideLevelId);
  const activeLevel = overrideLevelId || Object.keys(alphaSettings).reduce((a, b) => alphaSettings[a] > alphaSettings[b] ? a : b);
  const visibleVertices = vertices.filter(x => Object.keys(alphaSettings).map(Number).includes(x.context.networkId));
  const visibleEdges = edges.filter(x => Object.keys(alphaSettings).map(Number).includes(x.context.networkId)
                                      && checkEdgeTypeVisibility(x, networkStates));
  return {alphaSettings, visibleVertices, activeLevel, visibleEdges};
}

function getNetworkAlphaSettings(scale, overrideLevelId){
  let networkAlphaSettings = {};
  for (const [key, value] of Object.entries(levelVisibilitySettings.visibility_settings_ranges)) {
    if (overrideLevelId && parseInt(key) < overrideLevelId) {
      networkAlphaSettings[key] = levelVisibilitySettings.maximum_transparency;
    } else if (parseInt(key) === overrideLevelId) {
      networkAlphaSettings[key] = 1;
    } else if (scale <= value[0]) {
      continue;
    } else if (scale <= value[1]) {
      networkAlphaSettings[key] = (scale - value[0]) / (value[1] - value[0]);
    } else if (scale <= value[2]) {
      networkAlphaSettings[key] = 1;
    } else if (scale <= value[3]) {
      networkAlphaSettings[key] = 1 - (scale - value[2]) / (value[3] - value[2]) * (1 - levelVisibilitySettings.maximum_transparency);
    } else {
      networkAlphaSettings[key] = levelVisibilitySettings.maximum_transparency;
    }
  }
  return networkAlphaSettings;
}

export function getCurrentViewport(app) {
  return {
    x: -app.stage.x / app.stage.scale.x,
    y: -app.stage.y / app.stage.scale.y,
    width: app.renderer.view.width / app.stage.scale.x,
    height: app.renderer.view.height / app.stage.scale.y
  };
}

function checkEdgeTypeVisibility(edge, networkStates){
  switch(edge.context.networkId){
    case 1:
      if(edge.context.typeId === 1){
        return networkStates.find(x => x.networkId === edge.context.networkId).edgeType1Visible;
      }
      if(edge.context.typeId === 2){
        return networkStates.find(x => x.networkId === edge.context.networkId).edgeType2Visible;
      }
    case 2:
      if(edge.context.typeId === 1){
        return networkStates.find(x => x.networkId === edge.context.networkId).edgeType1Visible;
      }
      if(edge.context.typeId === 2){
        return networkStates.find(x => x.networkId === edge.context.networkId).edgeType2Visible;
      }
    case 3:
      if(edge.context.typeId === 1){
        return networkStates.find(x => x.networkId === edge.context.networkId).edgeType1Visible;
      }
      if(edge.context.typeId === 2){
        return networkStates.find(x => x.networkId === edge.context.networkId).edgeType2Visible;
      }
    default:
      return false;
  }
}