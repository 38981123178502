import { postWrapper, getWrapper } from '../fetchWrapper';

export const createProperty = async (property) => await postWrapper('/api/property/createproperty', property);
export const createPropertyAttribute = async (property) => await postWrapper('/api/property/createpropertyattribute', property);
export const updateProperty = async (property) => await postWrapper('/api/property/updateproperty', property);
export const updatePropertyAttribute = async (propertyAttribute) => await postWrapper('/api/property/updatepropertyattribute', propertyAttribute);

export const deleteProperty = async (organisationId, propertyId) => await getWrapper(`/api/property/deleteproperty/` + organisationId + '/' + propertyId);
export const deletePropertyAttribute = async (propertyAttributeId) => await getWrapper(`/api/property/deletepropertyattribute/` + propertyAttributeId);
export const getProperties = async (organisationId, propertyTypeId) => await getWrapper(`/api/property/getproperties/` + organisationId + '/' + propertyTypeId);
export const getProperty = async (organisationId, propertyId) => await getWrapper(`/api/property/getproperty/` + organisationId + '/' + propertyId);