import { postWrapper, getWrapper } from '../fetchWrapper';

export const createReference = async (nodeId, reference) => await postWrapper(`/api/knowledge/createreference/` + nodeId, reference);
export const updateReference = async (reference) => await postWrapper(`/api/knowledge/updatereference`, reference);

export const deleteReference = async (nodeId, referenceId, projectId) => await getWrapper(`/api/knowledge/deletereference/` + nodeId + `/` + referenceId + `/` + projectId);
export const getDocumentCategories = async () => await getWrapper(`/api/knowledge/getdocumentcategories`);
export const getDocumentLocationTypes = async () => await getWrapper(`/api/knowledge/getdocumentlocationtypes`);
export const getDocumentSourceTypes = async () => await getWrapper(`/api/knowledge/getdocumentsourcetypes`);
export const getNodeDocumentReferences = async (nodeId, projectId) => await getWrapper(`/api/knowledge/getnodedocumentreferences/` + nodeId + `/` + projectId);
export const getDocumentReference = async (referenceId) => await getWrapper(`/api/knowledge/getdocumentreference/` + referenceId);

export const submitDocumentReview = async (review) => await postWrapper(`/api/knowledge/submitdocumentreview`, review);