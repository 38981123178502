// @mui components
import { Box, } from '@mui/material';
import { TextField, Select, MenuItem, FormControl, InputLabel, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

// @mui icons
import LowPriorityIcon from '@mui/icons-material/LowPriority';
import { ArrowForward, ArrowBack } from '@mui/icons-material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import DateRangeIcon from '@mui/icons-material/DateRange';
import DescriptionIcon from '@mui/icons-material/Description';
import PublicIcon from '@mui/icons-material/Public';

const StyledBox = styled(Box)(({ theme }) => ({
  flex: 1,
  border: `1px solid ${theme.palette.grey[400]}`,
  height: 44,
  borderRadius: 6,
  padding: theme.spacing(1),
  display: 'flex',
  alignItems: 'center'
}));

const FinderBarLayout = ({ constraints, setConstraints, filterAttributes }) => {
  const handleSelectChange = (event, attributeName) => {
    const newSelectedValues = {
      ...constraints.selectedCategories,
      [attributeName]: event.target.value,
    };
  
    setConstraints(prevConstraints => ({
      ...prevConstraints,
      selectedCategories: newSelectedValues
    }));
  };

  const handleSearchChange = (event) => {
    setConstraints({...constraints, search: event.target.value });
  }

  const handleSortClick = (property, type) => {
    if (constraints.orderBy === property) {
      const nextState = constraints.orderDirection < 2 ? constraints.orderDirection + 1 : 0;
      setConstraints({...constraints, orderDirection: nextState});
    } else {
      setConstraints({...constraints, orderBy: property, orderType: type, orderDirection: 1});
    }
  };

  return (
    filterAttributes &&
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        height: 50,
        mt: -0.5,
        mb: 0.8
        }}>
        <TextField 
          variant="outlined" 
          fullWidth={false} 
          style={{ width: '18%' }} 
          placeholder="Search"
          value={constraints.search}
          onChange={handleSearchChange}
        />
        {/* Ordering Box */}
        <StyledBox sx={{ mx: 1 }}>
          <Tooltip title="Ordering Attributes" placement="top" arrow={false}>
            <LowPriorityIcon sx={{ color: 'grey.500', fontSize: 27 }} fontSize='' />
          </Tooltip>
          {
            filterAttributes
              .filter((attr) => ['string', 'datetime', 'numeric'].includes(attr.type))
              .map((attr) => (
                <Tooltip title={attr.name + tooltipExtensions[constraints.orderDirection]} placement='top' key={attr.name}>
                  <IconButton
                    sx={{ pr: 0 }}
                    onClick={() => handleSortClick(attr.property, attr.type)}>
                    {renderIcon(attr.icon, constraints.orderBy === attr.property ? constraints.orderDirection : 0)}
                  </IconButton>
                </Tooltip>
              ))
          }
        </StyledBox>
        {/* Filtering Box */}
        <StyledBox>
          <Tooltip title="Category Filters" placement="top" arrow={false}>
            <FilterAltIcon sx={{ color: 'grey.500', fontSize: 26 }} fontSize=''/>
          </Tooltip>
          {
            filterAttributes.filter(attr => attr.type === 'categorical').map(attr => (
              <Box 
                key={attr.name}
                sx={{
                  border: '1px solid darkgray',
                  height: 36,
                  borderRadius: 1.5,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '0px 8px',
                  ml: 1
                }}>
                {renderIcon(attr.icon)}
                <FormControl sx={{ minWidth: 120, ml: 1 }}>
                  <InputLabel
                    id="category-select-label"
                    sx={{
                      top: '-7px', left: '2px',
                      '&.Mui-focused': {
                        top: '-8px', left: '2px'
                      },
                      '&.MuiInputLabel-shrink': {
                        top: '3px', left: '0px'
                      }
                    }}
                  >
                    {attr.name}
                  </InputLabel>
                  <Select
                    labelId="category-select-label"
                    label={attr.name}
                    sx={{ height: 28 }}
                    multiple
                    value={constraints.selectedCategories[attr.property] || []}
                    onChange={(event) => handleSelectChange(event, attr.property)}>
                    {
                      attr.options.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Box>
            ))
          }
        </StyledBox>
      </Box>
  );
};

const renderIcon = (iconName, state=0) => {
  const colors = ['black', 'blue', 'red'];

  switch (iconName) {
    case 'alpha': return <SortByAlphaIcon sx={{ fontSize: 31, color: colors[state] }} fontSize=''/>;
    case 'datetime': return <DateRangeIcon sx={{ fontSize: 31, color: colors[state] }} fontSize=''/>;
    case 'description': return <DescriptionIcon sx={{ fontSize: 31, color: colors[state] }} fontSize=''/>;
    case 'country': return <PublicIcon sx={{ fontSize: 28, color: colors[state] }} fontSize='' />;
    default: return null;
  }
}

const tooltipExtensions = {
  0: ' (Unsorted)',
  1: ' (Forward sorted)',
  2: ' (Reverse sorted)'
}

export default FinderBarLayout;