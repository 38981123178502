import { Application } from "pixi.js";
import * as PIXI from "pixi.js";

export function clearStage(app){
  for (var i = app.stage.children.length - 1; i >= 0; i--)	
    app.stage.removeChild(app.stage.children[i]);
}

export function sortChildrenRecursively(container) {
  container.children.sort((a, b) => a.zIndex - b.zIndex);
  container.children.forEach(child => {
    if (child instanceof PIXI.Container) {
      sortChildrenRecursively(child);
    }
  });
}

function getAdjustedMouseCoordinates(e, app) {
  const scale = app.stage.scale;
  const invertedScale = new PIXI.Point(1 / scale.x, 1 / scale.y);

  const mouseX = (e.offsetX - app.stage.x) * invertedScale.x;
  const mouseY = (e.offsetY - app.stage.y) * invertedScale.y;

  // can the below be used instead?
  // const globalPoint = new PIXI.Point(e.offsetX, e.offsetY);
  // const localPoint = app.stage.toLocal(globalPoint);

  // // Now localPoint.x and localPoint.y will give you the mouse coordinates in the stage's local space
  // const mouseX = localPoint.x;
  // const mouseY = localPoint.y;

  return [mouseX, mouseY];
}

export function getVertexCollissions(e, app, objects, returnAll = false) {
  const mouseLoc = getAdjustedMouseCoordinates(e, app);

  const collidingObjects = [];

  // Sort the objects by zIndex in descending order
  if(!returnAll)
    objects.sort((a, b) => b.zIndex - a.zIndex);

  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    if (!(
      mouseLoc[0] < obj.x ||
      mouseLoc[0] > obj.x + obj.width ||
      mouseLoc[1] < obj.y ||
      mouseLoc[1] > obj.y + obj.height
    )) {
      collidingObjects.push(obj);
      if (!returnAll) {
        return obj.context; // Return the first object if not returning all
      }
    }
  }

  return returnAll ? collidingObjects : undefined;

  // can the below be used instead?
  // const globalPoint = new PIXI.Point(e.offsetX, e.offsetY);

  // // Iterate over the interactive objects on the stage
  // const hitObject = app.stage.children.find(child => {
  //   if (child.interactive) {
  //     // Get the local bounds of the child object
  //     const bounds = child.getBounds();
  //     return bounds.contains(globalPoint.x, globalPoint.y);
  //   }
  //   return false;
  // });

  // if (!hitObject) {
  //   console.log('Mouse is over the canvas, not on any object');
  // } else {
  //   console.log('Mouse is over an object:', hitObject);
  // }
}

export function applyWindowCenteredScaleChange(e, baseZoomAmount, stage, zoomOnMouse = false) {
  // Make zoomAmount relative to the current scale
  let zoomAmount = baseZoomAmount * stage.scale.x;

  // Calculate the new scale
  let newScale = stage.scale.x + zoomAmount;

  // Prevent zooming out too much or zooming in too far
  if (newScale < 0.25) return;
  if (newScale > 120) return;

  let pointX, pointY;

  if (zoomOnMouse && e) {
    // Zoom on mouse location
    pointX = e.clientX;
    pointY = e.clientY;
  } else {
    // Zoom on window center
    pointX = window.innerWidth / 2;
    pointY = window.innerHeight / 2;
  }

  // Calculate the difference after scaling
  const deltaX = (pointX - stage.x) * zoomAmount / stage.scale.x;
  const deltaY = (pointY - stage.y) * zoomAmount / stage.scale.y;

  // Apply the new scale
  stage.scale.set(newScale, newScale);

  // Adjust the position to keep the point in place
  stage.x -= deltaX;
  stage.y -= deltaY;
}

export function removeContextMenu(elementId){
  const canvas = document.getElementById(elementId);
  const handleContextMenu = (e) =>  e.preventDefault();
  canvas.addEventListener("contextmenu", handleContextMenu);
  return () => canvas.removeEventListener("contextmenu", handleContextMenu);
}

export function centerOnSpecifiedChildren(app, objects) {
  if (objects.length === 0) return; // No objects to center

  let xMin, yMin, xMax, yMax;

  // Calculate the bounding box in the unscaled coordinate system
  objects.forEach((object) => {
    let x = object.position.x;
    let y = object.position.y;
    let width = object.width;
    let height = object.height;

    if (xMin === undefined || x < xMin) xMin = x;
    if (yMin === undefined || y < yMin) yMin = y;
    if (xMax === undefined || x + width > xMax) xMax = x + width;
    if (yMax === undefined || y + height > yMax) yMax = y + height;
  });

  // Center of the bounding box in unscaled coordinates
  let centerX = xMin + (xMax - xMin) / 2;
  let centerY = yMin + (yMax - yMin) / 2;

  centerOnCoordinates(app, centerX, centerY);
}

export function centerOnCoordinates(app, x, y) {
  
  // Translate the point to scaled coordinates
  let scaledX = x * app.stage.scale.x;
  let scaledY = y * app.stage.scale.y;

  // Window's center
  let windowCenterX = window.innerWidth / 2;
  let windowCenterY = window.innerHeight / 2;

  // Set the stage's position, adjusting for scale
  app.stage.x = windowCenterX - scaledX;
  app.stage.y = windowCenterY - scaledY;
}

export function panAction(e, app, setPanCount){
  e.preventDefault();
  let ox = e.clientX, oy = e.clientY;
  app.view.onmousemove = (ev) => {
    let dx = ev.clientX - ox, dy = ev.clientY - oy;
    app.stage.x += dx, app.stage.y += dy;
    ox = ev.clientX, oy = ev.clientY;
    dx = 0, dy = 0;
    if(setPanCount) setPanCount(prevCount => prevCount + 1);
  };
}

export function mouseActions(
  app,
  handleSetSelectedObjectType,
  setSelectedObject,
  wheelScaleFactor,
  setSelectedEdgeAncestors,
  setScale = undefined,
  setPanCount = undefined
) {
  const handleMouseDown = (e) => {
    if (e.button === 0) {
      handleSetSelectedObjectType("vertex");
      if (typeof setSelectedEdgeAncestors === 'function') {
        setSelectedEdgeAncestors([]);
      }
      let collision = getVertexCollissions(e, app, app.stage.children.filter(x => x.zIndex > 1));
      if (!collision) {
        setSelectedObject({ id: 0, networkId: 0 });
      } else {
        setSelectedObject(collision);
      }

      e.stopImmediatePropagation();
    } else if (e.button === 2) {
      panAction(e, app, setPanCount);
    }
  };

  const handleMouseWheel = (e) => {
    let factor = e.deltaY > 0 ? -wheelScaleFactor : wheelScaleFactor;
    applyWindowCenteredScaleChange(e, factor, app.stage, true);
    if (setScale) setScale(app.stage.scale.x);
  };

  const handleMouseUp = () => {
    app.view.onmousemove = null;
  };

  if (!app || !app.view) return;  // Guard clause to ensure app and app.view exist before adding listeners

  app.view.addEventListener('mousedown', handleMouseDown);
  app.view.addEventListener('wheel', handleMouseWheel);
  app.view.addEventListener('mouseup', handleMouseUp);

  // Return a cleanup function with checks
  return () => {
    if (app.view) {
      app.view.removeEventListener('mousedown', handleMouseDown);
      app.view.removeEventListener('wheel', handleMouseWheel);
      app.view.removeEventListener('mouseup', handleMouseUp);
    }
  };
}

export function handleMouseMove(app, dispatch, vertices, setVertices, edges, setEdges, activeLevel, hoveredEdgesRef) {
  // Objects that are connected to the hovered object and their colours
  let ammendedVertices = [];

  // Attach the onmousemove handler
  app.view.onmousemove = (e) => {
    e.preventDefault();

    // Get objects from the current active level
    const objects = app.stage.children.filter(x => x.context?.networkId === parseInt(activeLevel));

    // Check for vertex collisions
    const vertexCollisions = getVertexCollissions(e, app, objects, true);

    if (vertexCollisions.length > 0) {
      // If vertex collisions are detected, handle hover state for connected vertices/edges
      let { connectedEdgeIds, connectedVertexIds } = getConnectedObjectIds(vertexCollisions, objects);

      setVertices(dispatch, 
        vertices.map(vertex =>
          connectedVertexIds.includes(vertex.context.id)
            ? { ...vertex, hovered: true }
            : { ...vertex, hovered: undefined }
        )
      );

      setEdges(dispatch,
        edges.map(edge =>
          connectedEdgeIds.includes(edge.context.id)
            ? { ...edge, hovered: true }
            : { ...edge, hovered: undefined }
        )
      );

      ammendedVertices = connectedVertexIds;
    } 
    else if (hoveredEdgesRef.current.length > 0) {
      // If no collisions, handle hover state for edges
      let connectedVertexIds = objects
        .filter(x => 
          x.canvasObjectType === 'vertex' && 
          hoveredEdgesRef.current.some(edge => edge.sourceNodeId === x.context.id || edge.targetNodeId === x.context.id)
        )
        .map(x => x.context.id);

      setVertices(dispatch,
        vertices.map(vertex =>
          connectedVertexIds.includes(vertex.context.id)
            ? { ...vertex, hovered: true }
            : { ...vertex, hovered: undefined }
        )
      );

      setEdges(dispatch,
        edges.map(edge =>
          hoveredEdgesRef.current.some(x => x.id === edge.context.id)
            ? { ...edge, hovered: true }
            : { ...edge, hovered: undefined }
        )
      );

      ammendedVertices = connectedVertexIds;
    } 
    else {
      // Reset any previously hovered objects if no collisions or hovered edges
      if (ammendedVertices.length > 0) {
        setVertices(dispatch,
          vertices.map(vertex =>
            ammendedVertices.includes(vertex.context.id)
              ? { ...vertex, hovered: undefined }
              : vertex
          )
        );

        setEdges(dispatch,
          edges.map(edge =>
            ammendedVertices.includes(edge.context.id)
              ? { ...edge, hovered: undefined }
              : edge
          )
        );

        ammendedVertices = [];
      }
    }
  };

  // Cleanup: make sure to clear event listeners on unmount
  return () => {
    app.view.onmousemove = null; // Clean up the event listener
  };
}

function getConnectedObjectIds(collisionObjects, allObjects){
  let connectedEdges = [];
  let connectedVertices = [];

  if(collisionObjects[0]?.canvasObjectType === "vertex"){
    const collisionId = collisionObjects[0].context.id;
    allObjects.filter(x => x.canvasObjectType === "edge").forEach((edge) => {
      if(edge.context.sourceNodeId === collisionId || edge.context.targetNodeId === collisionId){
        connectedEdges.push(edge);
      }
    });
    allObjects.filter(x => x.canvasObjectType === "vertex").forEach((vertex) => {
      if(connectedEdges.some(edge => edge.context.sourceNodeId === vertex.context.id || edge.context.targetNodeId === vertex.context.id)){
        connectedVertices.push(vertex);
      }
    });
  };

  if(collisionObjects[0]?.canvasObjectType === "edge"){
    connectedEdges.push(collisionObjects);
    allObjects.filter(x => x.canvasObjectType === "vertex").forEach((vertex) => {
      if(connectedEdges.some(edge => edge.context.sourceNodeId === vertex.context.id || edge.context.targetNodeId === vertex.context.id)){
        connectedVertices.push(vertex);
      }
    });
  }

  const connectedEdgeIds = connectedEdges.map(x => x.context.id);
  const connectedVertexIds = connectedVertices.map(x => x.context.id);

  return {connectedEdgeIds, connectedVertexIds};
}

let mouseUpWrapper;

export function setupMouseUpHandler(app, setCoordinates, dispatch) {
  mouseUpWrapper = (e) => onMouseUpHandler(e, app, setCoordinates, dispatch);
  window.addEventListener('mouseup', mouseUpWrapper);
}

export function cleanupMouseUpHandler() {
  window.removeEventListener('mouseup', mouseUpWrapper);
}

function onMouseUpHandler(e, app, setCoordinates, dispatch) {
  if (app && app.stage)
    setCoordinates(dispatch, { x: app.stage.x, y: app.stage.y });
  else
    console.error('App or stage is undefined');
}

export function getMapCanvas(setApp, setSelectedObjectType, setSelectedProcessElement, wheelScaleFactor, setResizeTriggered) {
  const rootElement = document.getElementById("mattsbox");

  // Check if canvas already exists, and remove it if so
  let existingCanvas = rootElement.querySelector("#processcanvas");
  if (existingCanvas) {
    rootElement.removeChild(existingCanvas);
  }

  const _app = new Application({
    antialias: true,
    backgroundAlpha: 0.15,
    resizeTo: rootElement
  });
  
  _app.stage.sortableChildren = true;
  _app.stage.eventMode = "dynamic";

  // Center stage relative to new rootElement size
  _app.stage.x = 50;
  _app.stage.y = rootElement.clientHeight / 2;

  // Initialize mouse actions
  mouseActions(_app, setSelectedObjectType, setSelectedProcessElement, wheelScaleFactor);
  _app.view.id = "processcanvas";
  rootElement.appendChild(_app.view);
  setApp(_app);

  // Remove context menu and return cleanup
  removeContextMenu("processcanvas");
  
  resizeMapCanvas(rootElement, _app, setResizeTriggered);
}

export function getAtlasBounds(app, vertices) {
  if (vertices.length === 0) {
    const windowWidth = app.view.width;
    const windowHeight = app.view.height;
    return {
      xMin: 0, xMax: windowWidth,
      yMin: 0, yMax: windowHeight,
    };
  }

  let xMin, yMin, xMax, yMax;
  vertices.forEach((vertex) => {
    if (xMin === undefined || vertex.tl[0] < xMin) xMin = vertex.tl[0];
    if (yMin === undefined || vertex.tl[1] < yMin) yMin = vertex.tl[1];
    if (xMax === undefined || vertex.tl[0] + vertex.sizes[0] > xMax) xMax = vertex.tl[0] + vertex.sizes[0];
    if (yMax === undefined || vertex.tl[1] + vertex.sizes[1] > yMax) yMax = vertex.tl[1] + vertex.sizes[1];
  });
  
  return { xMin, yMin, xMax, yMax };
}

export function centerVerticesAndScaleToFit(app, vertices, fillPercentageX = 0.85, fillPercentageY = 0.85) {
  const { xMin, xMax, yMin, yMax } = getAtlasBounds(app, vertices);
  const centerX = (xMin + xMax) / 2;
  const centerY = (yMin + yMax) / 2;
  const stage = app.stage;
  
  // Calculate the width and height of the vertices bounds
  const verticesWidth = xMax - xMin;
  const verticesHeight = yMax - yMin;
  
  // Get the dimensions of the window
  const windowWidth = app.view.width * fillPercentageX;
  const windowHeight = app.view.height * fillPercentageY;
  
  // Calculate the scaling factor
  const newScale = Math.min(windowWidth / verticesWidth, windowHeight / verticesHeight);
  
  // Adjust the scale of the stage
  stage.scale.set(newScale, newScale);

  // Center the vertices on the screen
  centerOnCoordinates(app, centerX, centerY);

  return newScale;
}

export function resizeMapCanvas(rootElement, _app, setResizeTriggered) {
  // Setup resize observer for mattsbox to adjust canvas accordingly
  const resizeObserver = new ResizeObserver(() => {
    _app.renderer.resize(rootElement.clientWidth, rootElement.clientHeight);
    _app.view.style.position = "absolute";
    _app.view.style.top = "0px";
    _app.view.style.left = "0px";
    setResizeTriggered(prev => !prev);
  });

  resizeObserver.observe(rootElement);

  return () => {
    resizeObserver.unobserve(rootElement);
    if (_app.view && rootElement.contains(_app.view)) {
      rootElement.removeChild(_app.view);
    }
    _app.destroy(true, { children: true });
  };
}

export function resizeCanvas(setResizeTriggered) {
  let timeoutId;

  const handleResize = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      setResizeTriggered(prev => !prev);
    }, 60);
  };

  window.addEventListener('resize', handleResize);

  return () => {
    clearTimeout(timeoutId);
    window.removeEventListener('resize', handleResize);
  };
}