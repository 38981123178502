import { postWrapper, getWrapper } from '../fetchWrapper';

export const createUser = async (user) => await postWrapper('/api/useradmin/createuser', user);
export const updateUser = async (user) => await postWrapper('/api/useradmin/updateuser', user);

export const deleteUser = async (userId) => await getWrapper(`/api/useradmin/deleteuser/` + userId);
export const getUsers = async (organisationId) => await getWrapper(`/api/useradmin/getusers/` + organisationId);
export const getUserById = async (userId) => await getWrapper(`/api/useradmin/getuserbyid/` + userId);
export const getUserRoles = async (userId) => await getWrapper(`/api/useradmin/getuserroles/` + userId);
export const getUserAtlases = async (userId, organisationId) => await getWrapper(`/api/useradmin/getuseratlases/` + userId + '/' + organisationId);
export const toggleUserAtlasAccess = async (userId, atlasId, organisationId) => await getWrapper(`/api/useradmin/toggleuseratlasaccess/` + userId + '/' + atlasId + '/' + organisationId);
export const getUserGroups = async (userId, organisationId) => await getWrapper(`/api/useradmin/getusergroups/` + userId + '/' + organisationId);
export const toggleUserInGroup = async (userId, groupId, organisationId) => await getWrapper(`/api/useradmin/toggleuseringroup/` + userId + '/' + groupId + '/' + organisationId);
export const getUserSkillSets = async (userId, organisationId) => await getWrapper(`/api/useradmin/getuserskillsets/` + userId + '/' + organisationId);
export const toggleUserSkillSet = async (userId, skillSetId, organisationId) => await getWrapper(`/api/useradmin/toggleuserskillset/` + userId + '/' + skillSetId + '/' + organisationId);
export const getAssignedUserSkillSets = async (userId) => await getWrapper(`/api/useradmin/getassigneduserskillsets/` + userId);
export const getUserProperties = async (userId, organisationId) => await getWrapper(`/api/useradmin/getuserproperties/` + userId + '/' + organisationId);
export const toggleUserProperty = async (userId, propertyId, organisationId) => await getWrapper(`/api/useradmin/toggleuserproperty/` + userId + '/' + propertyId + '/' + organisationId);

export const getUserUserAtlases = async (userId) => await getWrapper(`/api/useradmin/getuseruseratlases/` + userId);