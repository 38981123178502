//Atlas canvas constants
export const levelVisibilitySettings ={
  "maximum_transparency": 0,
  "visibility_settings_ranges": {
    //networkId: [minFadeIn, maxFadeIn, minFadeOut, maxFadeOut]
    1: [0, 0, 2.5, 3.8],
    2: [2.5, 3.8, 6, 10],
    3: [6, 10, 40, 100],
    4: [40, 100, 150, 200],
    5: [150, 200, 1000, 1000]
  }
}

export const networkColours = {
  1: "0xc39738",
  2: "0xb3b6af",
  3: "0x90ada4",
  4: "0xcd7f32",
  5: ""
}

export const networkNames = { 
  1: "Organisation", 
  2: "Job", 
  3: "Step", 
  4: "Substep", 
  5: "Action"
}

export const networkFontSizes = {
  'unconfigured': 12,
  1: 30,
  2: 10,
  3: 4,
  4: 1,
  5: 0.2,
}

export const mapLayoutDimensions = {
  colour: 0x000000, 
  width: 6,
  horizontalWidth: 34,
  verticalHeight: 42,
  junctionRadius: 10,
  arrowheadLength: 20
}