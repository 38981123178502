// @mui components
import { Card } from "@mui/material";
import { Typography, Tooltip, Icon, IconButton } from "@mui/material";

// @mui icons
import AccessibilityIcon from '@mui/icons-material/Accessibility';

const AddRemoveItemMediaCard = ({ name, id, action, hasAccess, permissionsPanelToggle }) => {
  return (
    <Card elevation={1} position="relative" sx={{height: 1, p: 1, borderRadius: "6px"}}>
      <Tooltip title={name}>
        <Typography align="center" variant="h1" fontSize={18} noWrap={true}>{name}</Typography>
      </Tooltip>
      <IconButton onClick={(e) => action(e, id)} sx={{p: 0, m: 0}}>
        <Icon sx={{fontSize: 84, m: 0}} fontSize="">{hasAccess ? "remove" : "add"}</Icon>
      </IconButton>
      {
        (permissionsPanelToggle !== undefined && hasAccess) && 
          <IconButton 
            sx={{
              position: "absolute",
              bottom: 0,
              right: 0,
              color: "green"
            }}
            onClick={(e) => permissionsPanelToggle(e, id)}>
            <AccessibilityIcon sx={{ fontSize: 27 }} fontSize=""/>
          </IconButton>
      }
    </Card>
  );
}

export default AddRemoveItemMediaCard;