import React, { useState, useEffect } from "react";

// Components
import { ManagerModal } from "components/GlobalComponents/Modals";
import SkillSetNodeEditor from "./SkillSetNodeEditor";

// Db context
import { deleteNode, getNestedNodes } from "apiservices/AtlasServices/nodeService";

// Canvas context
import { useCanvasController, setPropertiesManagerWindow, setVertices, setUnconfiguredJobs, setEdges } from "context/canvasContext";

const SkillSetNodeManager = ({}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { selectedObject } = canvasController;

  let networkId = selectedObject.networkId + 1
                   
  const [nodes, setNodes] = useState([]);
  useEffect(() => {
    const _getNodes = async () => {
      let _nodes = await getNestedNodes(selectedObject.id);
      setNodes(_nodes);
    };
    _getNodes();
  }, []);

  const handleDeleteNode = async (e, nodeId) => {
    let result = await deleteNode(nodeId);
    await setVertices(canvasDispatch, result.vertices.filter(x => !(x.context.networkId === 2 && x.isIntegrated === 4)));
    await setUnconfiguredJobs(canvasDispatch, result.vertices.filter(x => x.context.networkId === 2 && x.isIntegrated === 4));
    await setEdges(canvasDispatch, result.edges);
    let parentNodeId = selectedObject.id !== 0 ? selectedObject.id : null;
    setNodes(result.vertices
      .filter(x => x.context.networkId === networkId && x.context.parentNodeId == parentNodeId)
      .map(x => x.context));
  }
  
  const handleCloseWindow = (e) => setPropertiesManagerWindow(canvasDispatch, undefined);
  
  const [editorVisible, setEditorVisible] = useState(undefined);
  const handleSetEditorVisibility = (e, id) => setEditorVisible(id);

  const title = networkId === 1 ? "Organisation Node Manager" : "Job Node Manager";
  const addButtonTitle = networkId === 1 ? "Add new organisation" : "Add new job";

  return(
    <>
      <ManagerModal
        handleClose={handleCloseWindow}
        title={title}
        data={[{icon: "add", name: addButtonTitle, description: "", id: 0}].concat(nodes)}
        handleOpenEditor={handleSetEditorVisibility}
        deleteAction={handleDeleteNode}/>
      {
        editorVisible !== undefined &&
          <SkillSetNodeEditor
            networkId={networkId}
            nodeId={editorVisible}
            setEditorVisible={setEditorVisible}
            nodes={nodes}
            setNodes={setNodes}/>
      }
    </>
  )
}

export default SkillSetNodeManager;