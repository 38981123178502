import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getSkillSets, deleteSkillSet } from "apiservices/AuthorisationServices/skillSetService";

// Local context
import { useAdminController } from "context";

const createButton = [{media: ["add"], name: "Add New Skill Set", description: "", id: 0}]

const initialFilterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const SkillSets = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [filterAttributes, setFilterAttributes] = useState(initialFilterAttributes);
  const [skillSets, setSkillSets] = useState([]);
  const [filteredSkillSets, setFilteredSkillSets] = useState([]);
  
  useEffect(() => {
    const _getSkillSets = async () => {
      let _skillSets = await getSkillSets(organisationId, 0);
      setSkillSets(_skillSets.items);
      setFilteredSkillSets(_skillSets.items);
      
      // Create filter attributes dynamically
      const newFilterAttributes = [...initialFilterAttributes];
      Object.keys(_skillSets.categories).forEach(category => {
        newFilterAttributes.push({
          property: category,
          name: category,
          type: 'categorical',
          icon: 'country',
          options: _skillSets.categories[category].map(category => {
            return { id: category.id, name: category.name };
          })
        });
      });
      setFilterAttributes(newFilterAttributes);
    };

    _getSkillSets();
    
    // Add a cleanup function to dispose of the view
    return () => {
      setSkillSets([]);
      setFilteredSkillSets([]);
      setFilterAttributes(initialFilterAttributes);
    };
  }, [organisationId]);

  const handleDelete = async (e, skillSetId, onDeleteComplete) => {
    let _skillSets = await deleteSkillSet(skillSetId);
    setSkillSets(_skillSets.items);
    setFilteredSkillSets(_skillSets.items);
    onDeleteComplete();
  }

  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={createButton.concat(filteredSkillSets)} path={"/skillsets/skillSet"} deleteAction={handleDelete}>
      <FinderBar
          objs={skillSets}
          setFilteredObjs={setFilteredSkillSets}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default SkillSets;