import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormControl, FormControlLabel, Checkbox, RadioGroup, Radio, Typography } from "@mui/material";

// @mui icons
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

// Components
import { AdministrationHeaderWhereNoTabPanelIsUsed } from "components/AdministrationComponents/General";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import FormCollapse from "components/AdministrationComponents/FormCollapse";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createProduct, updateProduct, getProduct, getProductCategories } from "apiservices/AuthorisationServices/productService";

const ProductView = () => {
  const [product, setProduct] = useState();
  const [productCategories, setProductCategories] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    const _getProductCategories = async () => {
      let _productCategories = await getProductCategories();
      setProductCategories(_productCategories);
    };
    _getProductCategories();
  }, []);

  const handleSetNetworks = (e) => {
    setProduct(prevProduct => {return {...prevProduct, networks: { ...prevProduct.networks, [e.target.name]: e.target.checked}}});
    if (e.target.name === "1" && e.target.checked) {
      setProduct(prevProduct => {return {...prevProduct, networks: { ...prevProduct.networks, ["2"]: true}}});
    }
  }

  const handleSetSelectedProductCategory = (e) => {
    setProduct(prevProduct => {return {...prevProduct, productCategoryId: e.target.value}});
  }
  
  return (
    <AdministrationObjectWrapper
      object={product}
      setObject={setProduct}
      getObject={getProduct}
      createObject={createProduct}
      updateObject={updateProduct}
      namePlaceholder="Product Type"
      descriptionPlaceholder="Description of Product"
      iconComponent={<ShoppingCartIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab="1"
      header={<AdministrationHeaderWhereNoTabPanelIsUsed/>}
      form={
        product?.networks &&
          <Box display='flex' flexDirection='row'>
            <Box width={.5}>
              <FormCollapse title="Application Networks" open>
                <FormControl>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={product.networks["2"] || product.networks["1"]}
                        disabled={product.networks["1"]}
                        onChange={handleSetNetworks}/>
                    }
                    name="2"
                    label="Individual"
                    sx={{color: product.networks["1"] ? "text.disabled" : "inherit"}} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={product.networks["1"]}
                        onChange={handleSetNetworks}/>
                    }
                    name="1"
                    label="Group" />
                </FormControl>
              </FormCollapse>
              <FormCollapse title="Product Category" open={true}>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="product-category-radio-group"
                    value={product.productCategoryId || ""}
                    onChange={handleSetSelectedProductCategory}
                    >
                    {
                      productCategories?.map((category) => {
                        return (
                          <FormControlLabel value={category.id} control={<Radio />} label={category.name} key={category.id}/>
                        )
                      })
                    }
                  </RadioGroup>
                </FormControl>
              </FormCollapse>
            </Box>
            <Box>
              <Typography sx={{ pl: 5, color: '#d3d3d3' }} variant="h6">Associated SkillSets:</Typography>
              {
                Object.entries(product?.associatedSkillSets)?.map(([key, value]) => {
                  return (
                    <Typography sx={{ pl: 5, color: '#d3d3d3' }} key={key}>{value}</Typography>
                  )
                })
              }
            </Box>
          </Box>
      }
      validate={validate}
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}
      requiresOrgId/>
  );
}

export default ProductView;