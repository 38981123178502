import React, { useState, useEffect } from "react";

// @mui components
import { TextField } from "@mui/material"

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import { WrapperModal } from "components/GlobalComponents/Modals";
import FinderBar from "components/AdministrationComponents/FinderBar";
import UploadBox from "components/GlobalComponents/UploadBox";

// Db Context
import { createBrand, getBrands, deleteBrand } from "apiservices/InventoryServices/brandService";

// Local context
import { useAdminController } from "context";

let createButton = [{icon: "add", name: "Add New Brand", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Brands = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId, userEmail } = controller;

  const [brands, setBrands] = useState([]);
  const [filteredBrands, setFilteredBrands] = useState([]);
  useEffect(() => {
    const _getBrands = async () => {
      let response = await getBrands(organisationId);
      setBrands(response);
      setFilteredBrands(response);
    };
    _getBrands();
  }, []);

  const [brand, setBrand] = useState({
    name: "",
    createdBy: userEmail
  });
  
  const handleChangeName = (e) => setBrand({...brand, ...{name: e.target.value}});

  const addBrand = async () => {
    let brands = await createBrand(organisationId, brand);
    setBrands(brands);
    setEditorVisible(false);
  }

  const handleDelete = async (e, id, onDeleteComplete) => {
    let _brands = await deleteBrand(id);
    setBrands(_brands);
    onDeleteComplete();
  }

  const [editorVisible, setEditorVisible] = useState(false);
  const handleSetEditorVisibility = () => setEditorVisible(!editorVisible);

  return (
    <AdministrationViewWrapper>
      <CreateAndEditInPlaceArray
        items={createButton.concat(brands)}
        editor={handleSetEditorVisibility}
        colour="white"
        deleteAction={handleDelete}>
        <FinderBar
          objs={brands}
          setFilteredObjs={setFilteredBrands}
          filterAttributes={filterAttributes}/>
      </CreateAndEditInPlaceArray>
      <WrapperModal 
        open={editorVisible} 
        handleClose={handleSetEditorVisibility} 
        title="Create New Brand" 
        primaryButtonText="Add Brand" 
        primaryEvent={addBrand}>
        <UploadBox />
        <TextField label="Brand name" variant="outlined" onChange={handleChangeName}/>
      </WrapperModal>
    </AdministrationViewWrapper>
  );
}

export default Brands;