import React, { useState } from 'react';

// @mui components
import { Box, Typography, IconButton, Collapse } from '@mui/material';

// @mui icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const FormCollapse = ({ title, children, open = false, marginBottom='18px' }) => {
  const [isExpanded, setIsExpanded] = useState(open);

  const toggleCollapse = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        position: "relative",
        width: 1,
        border: "3px solid #d3d3d3",
        borderRadius: "6px",
        marginBottom: marginBottom,
        minHeight: "30px",
        px: 1.5, pt: 0.5
      }}>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          alignItems: "center",
          top: -8.5, left: 10,
          backgroundColor: "#fff",
          height: "0.8em"
        }}
        onClick={toggleCollapse}
      >
        <Typography variant="h6" sx={{ fontWeight: 600, color: "#000", ml: 0.7, lineHeight: "1.5em" }}>
          {title}
        </Typography>
        <IconButton sx={{ p: 0 }}>
          {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <Box sx={{ paddingTop: 2 }}>
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default FormCollapse;
