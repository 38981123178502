import React, { useState, useEffect } from "react";

// @mui components
import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import { AdminPanel, AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { getProperties, deleteProperty } from "apiservices/AuthorisationServices/propertyService";

// Local context
import { useAdminController } from "context";

const createTagGroupButton = [{media: ["add"], name: "Create New Tag Group", id: 0}]
const createMetricButton = [{media: ["add"], name: "Create New Metric", id: -1}]

const Properties = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [tagGroups, setTagGroups] = useState([]);
  const [metrics, setMetrics] = useState([]);
  useEffect(() => {
    const _getProperties = async () => {
      let _properties = await getProperties(organisationId, 0);
      setTagGroups(_properties.items.filter(x => x.typeId === 1));
      setMetrics(_properties.items.filter(x => x.typeId === 2));
    };
    _getProperties();
  }, []);

  const handleDeleteProperty = async (e, propertyId) => {
    let _properties = await deleteProperty(organisationId, propertyId);
    setTagGroups(_properties.items.filter(x => [1, 3].includes(x.typeId)));
    setMetrics(_properties.items.filter(x => x.typeId === 2));
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);
  
  return (
    <AdministrationViewWrapper>
      <AdminPanel>
        <TabContext value={activeTab}>
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Tag Groups" value="1"/>
            <Tab label="Metrics" value="2"/>
          </AdminTabList>
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <MediaCardArrayContainer items={createTagGroupButton.concat(tagGroups)} path={"/properties/tagGroup"} deleteAction={handleDeleteProperty}/>
          </TabPanel>
          <TabPanel 
            value="2"
            style={{ padding: 0, display: activeTab === "2" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <MediaCardArrayContainer items={createMetricButton.concat(metrics)} path={"/properties/metric"} deleteAction={handleDeleteProperty}/>
          </TabPanel>
        </TabContext>
      </AdminPanel>
    </AdministrationViewWrapper>
  );
}

export default Properties;