import React from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Bar,
  Line
} from 'recharts';

const HorizontalBoxAndWhiskerChart = ({data}) => {
  data.boxRange = [data.q25, data.q75];
  const maxTick = data.maxValue * 1.1;
  const ticks = Array.from({ length: 5 }, (_, i) => parseFloat(((i * maxTick) / 4).toFixed(1)));

  return (
    <ResponsiveContainer width="97%" height={80}>
      <ComposedChart
        layout="vertical"
        data={Array(data)}
        margin={{ top: 0, right: 0, left: -48, bottom: 0 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          type="number"
          domain={[0, maxTick]}
          ticks={ticks}
          tick={{ stroke: '#000', strokeWidth: 1, fontSize: 10, textAnchor: 'end' }}
          interval={0}
        />
        <YAxis
          type="category"
          dataKey={'Metric'}
          tick={{ stroke: '#000', strokeWidth: 1, fontSize: 10, angle: 90, textAnchor: 'middle' }}
        />
        {/* <Tooltip /> */}

        <Bar dataKey="boxRange" fill="#8884d8" barSize={40} />

        {/* Line to represent the min and max values */}
        <Line
          dataKey="minValue"
          type="monotone"
          stroke="#000"
        />
        <Line
          dataKey="q25"
          type="monotone"
          stroke="#000"
        />
        <Line
          dataKey="q75"
          type="monotone"
          stroke="#000"
        />
        <Line
          dataKey="maxValue"
          type="monotone"
          stroke="#000"
        />

        {/* Line to represent the median */}
        <Line
          dataKey="medianValue"
          type="monotone"
          stroke="#FF0000"
          strokeWidth={2}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default HorizontalBoxAndWhiskerChart;
