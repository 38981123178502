import React, { useState, useEffect } from "react";

// @mui components
import { Box, Card, FormControl, InputLabel, Select, MenuItem, Typography, TextField, Button, IconButton, Tooltip } from "@mui/material";

// @mui icons
import SaveIcon from '@mui/icons-material/Save';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

// Components
import OperationCard from "./OperationCard";

import { createCalculation, updateCalculation, getCalculation, getAtlasCalculations, deleteCalculation } from "apiservices/AuthorisationServices/calculationManagerService";
import { getSavedCalculation, getUnsavedCalculation } from "apiservices/AtlasServices/metricCalculationService";

const operationDictionary = { 1: '+', 2: '-', 3: '*', 4: '/' };

import { useAdminController } from "context";
import { useCanvasController, setVertexInnerSizes } from "context/canvasContext";

const MetricCalcs = ({ closeWindow, metrics, metric }) => {
  const [controller] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const [existingCalculations, setExistingCalculations] = useState([]);
  const [selectedCalculationId, setSelectedCalculationId] = useState('');
  const [selectedCalculation, setSelectedCalculation] = useState({});

  useEffect(() => {
    const _getCalculations = async () => {
      let _calculations = await getAtlasCalculations(controller.organisationId);
      setExistingCalculations(_calculations);
    };
    _getCalculations();
  }, []);

  useEffect(() => {
    const _getCalculation = async () => {
      let calculationId = selectedCalculationId || 0;
      let _calculation = await getCalculation(calculationId);
      if(_calculation.id === 0) {
        _calculation.operations[0].metricId = metric.metricId;
      }
      setSelectedCalculation(_calculation);
    };
    _getCalculation();
    
  }, [selectedCalculationId]);

  const handleBaseMetricChange = (event) => {
    setSelectedCalculation((prevCalc) => ({
      ...prevCalc,
      baseMetricId: event.target.value,
    }));
  };

  const handleAddOperation = () => {
    let nextOpId = selectedCalculation.operations.length * -1;
    setSelectedCalculation((prevCalc) => ({
      ...prevCalc,
      operations: [...prevCalc.operations,
        {
          id: nextOpId,
          calculationId: selectedCalculation.id,
          metricId: null,
          operationId: null
        }
      ]
    }));
  };

  const handleRetrieve = () => {
    let _calculation = selectedCalculation.id === 0 ?
      getUnsavedCalculation(selectedCalculation) :
      getSavedCalculation(selectedCalculation.id);

    setVertexInnerSizes(canvasDispatch, _calculation);
  };

  const handleSetCalculationName = (e) => {
    setSelectedCalculation((prevCalc) => ({
      ...prevCalc,
      name: e.target.value,
    }));
  };

  const [position, setPosition] = useState({ top: 0, left: 0 });
  const targetElementId = 'calcs-window';

  useEffect(() => {
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
        setPosition({
          top: rect.top - 210,
          left: rect.left - 10,
        });
    }
  }, []);

  const [saveButtonStatus, setSaveButtonStatus] = useState(0);
  const handleSave = () => {
    if(saveButtonStatus === 0)
      setSaveButtonStatus(1);
    else if(saveButtonStatus === 1)
      setSaveButtonStatus(2);
    else {
      let result = selectedCalculation.id === 0 ?
        createCalculation(selectedCalculation) :
        updateCalculation(selectedCalculation);
      selectedCalculation = result;
      setSaveButtonStatus(0);
    }
  };

  const handleDelete = () => {
    let result = deleteCalculation(selectedCalculation.id);
    setExistingCalculations(result);
    setSaveButtonStatus(0);
  };

  let saveIconColor = saveButtonStatus === 0 ? 'grey' : saveButtonStatus === 1 ? 'green' : 'red';
  return (
    <Card
      onMouseLeave={closeWindow}
      sx={{
        left: position.left,
        top: position.top,
        width: "300px",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        zIndex: 10,
        position: "fixed",
        backgroundColor: "rgba(189, 189, 189, 0.98)",
        justifyContent: "right",
        p: 1,
        borderRadius: "9px",
      }}
    >
      <Typography sx={{ textAlign: "center", mb: 1 }}>Metric Calculations</Typography>
      {/* Select existing calculation */}
      <FormControl sx={{ mb: 1 }}>
        <InputLabel id="existing-calc-select-label">Pre-defined Calculations</InputLabel>
        <Select
          labelId="existing-calc-select-label"
          id="existing-calc-select"
          value={selectedCalculationId}
          label="Pre-defined Calculations"
          onChange={(e) => setSelectedCalculationId(e.target.value)}
          sx={{ height: "40px" }}
        >
          <MenuItem value="">Select Calculation</MenuItem>
          {
            existingCalculations?.map((calc) => (
              <MenuItem key={calc.id} value={calc.id}>
                {calc.name}
              </MenuItem>
            ))
          }
        </Select>
        {
          selectedCalculation.id !== 0 && (
            <DeleteForeverIcon
              onClick={handleDelete}
              sx={{ color: "red", position: "absolute", right: "10px", top: "10px" }}/>
          )
        }
      </FormControl>
      {/* Calculation Display */}
      <Typography
        variant="h6"
        sx={{
          p: 1,
          border: "2px solid #A9A9A9",
          borderRadius: 1.5,
          mb: 1.5
        }}
      >
        {metric.metricName}
        {
          selectedCalculation?.operations?.map((operation, index) => {
            if (operation.metricId !== null && operation.operationId !== null) {
              return (
                <span key={index}>
                  {` ${operationDictionary[operation.operationId]} ${metrics.find((m) => m.metricId === operation.metricId)?.metricName}`}
                </span>
              );
            }
            return null;
          })
        }
      </Typography>
      {/* Select Menus and Operators */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="metric1-select-label" sx={{ top: '1px' }}>
            Base Metric
          </InputLabel>
          <Select
            labelId="metric1-select-label"
            id="metric1-select"
            value={metric.metricId}
            label="Base Metric"
            onChange={handleBaseMetricChange}
            sx={{ width: "180px", height: "40px" }}>
            {
              metrics?.map((item) => (
                <MenuItem key={item.metricId} value={item.metricId}>
                  {item.metricName}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {/* Dynamic operation inputs */}
        {
          selectedCalculation?.operations?.map((operation, index) => (
            index > 0 && (
              <OperationCard
                key={operation.id}
                id={operation.id}
                index={index}
                metrics={metrics}
                currentOperation={operation.operationId || ""}
                currentMetricId={operation.metricId || ""}
                setSelectedCalculation={setSelectedCalculation}/>
            )
          ))
        }
        {/* Rearranged layout for Retrieve and Save buttons */}
        <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button variant="outlined" sx={{ width: "100%", mr: 1 }} onClick={handleAddOperation}>
            Add Operation
          </Button>
          <Tooltip title="Retrieve Calculation" placement="top" arrow>
            <IconButton onClick={handleRetrieve} sx={{ color: saveIconColor }}>
              <PlayCircleOutlineIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Save Calculation" placement="top" arrow>
            <IconButton onClick={handleSave} sx={{ color: saveIconColor }}>
              <SaveIcon />
            </IconButton>
          </Tooltip>
        </Box>

        {
          saveButtonStatus !== 0 && (
            <TextField
              id="calculation-name"
              label="Calculation Name"
              value={selectedCalculation.name}
              onChange={(e) => handleSetCalculationName(e)}
              variant="standard"
              sx={{ width: "100%", mb: 1 }}
            />
          )
        }
      </Box>
    </Card>
  );
};

export default MetricCalcs;
