// @mui components
import { Box } from '@mui/material';
import { Typography, Checkbox, FormControl, FormControlLabel } from "@mui/material";

// Components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import HorizontalNewItemMediaCard from 'components/Cards/HorizontalNewItemMediaCard';
import HorizontalEditableMediaCard from 'components/Cards/HorizontalEditableMediaCard';
import { AdminFormLabel } from 'components/AdministrationComponents/StyledAdminComponents';

//
import { networkNames } from 'helpers/canvashelpers/constants';

export const AdministrationViewWrapper = ({children, ml=1, mr=1, mt=0, mb=0.9, gap=1}) => {
  return(
    <DashboardLayout>
      <Box display="flex" pt={0.5}>
        <DashboardNavbar/>
      </Box>
      <Box ml={ml} mr={mr} mt={mt} mb={mb} display="flex" flexDirection="column" flexGrow={1} gap={gap}>
        {children}
      </Box>
    </DashboardLayout>
  )
}

export const AdministrationHeaderWhereNoTabPanelIsUsed = ({title="Details"}) => {
  return(
    <Box 
      sx={{ 
        display: "flex",
        borderRadius: "6px 6px 0 0",
        justifyContent: "center", 
        alignItems: "center", 
        height: "43px", 
        backgroundColor: "#F5F5F5" }}>
      <Typography fontFamily='Arial, sans-serif'>{title}</Typography>
    </Box>
  )
}

export const NetworkPicker = ({networks, setNetworks, error, setError, disabled=false}) => {
  const handleSetNetworks = (e) => {
    setNetworks(prevProperty => {return {...prevProperty, networks: { ...prevProperty.networks, [e.target.name]: e.target.checked}}});
    if (Object.values(networks).some((value) => value) || e.target.checked) {
      setError(null);
    }
  }

  return(
    <FormControl error={Boolean(error)}>
      <AdminFormLabel error={error}>Application network types:</AdminFormLabel>
      {
        Object.keys(networks).map((network) => {
          return(
            <FormControlLabel
              key={network}
              control={<Checkbox
                checked={networks[network]}
                onChange={handleSetNetworks}
                disabled={disabled}/>}
              name={network.toString()}
              label={networkNames[network]}/>
          )
        })
      }
    </FormControl>
  )
}

export const CardTypeSwitch = ({ obj, setObj, icon, nameField, descriptionField, testFailIfNot = 0, invalidFields, validate, setInvalidFields, namePlaceholder, descriptionPlaceholder }) => {
  return (
    obj.id === testFailIfNot ?
      <HorizontalNewItemMediaCard 
        obj={obj}
        setObj={setObj}
        icon={icon}
        nameField={nameField}
        descriptionField={descriptionField}
        invalidFields={invalidFields}
        validate={validate}
        setInvalidFields={setInvalidFields}
        namePlaceholder={namePlaceholder}
        descriptionPlaceholder={descriptionPlaceholder}/> :
      <HorizontalEditableMediaCard 
        obj={obj}
        setObj={setObj}
        icon={icon}
        nameField={nameField}
        descriptionField={descriptionField}
        invalidFields={invalidFields}
        validate={validate}
        setInvalidFields={setInvalidFields}
        namePlaceholder={namePlaceholder}
        descriptionPlaceholder={descriptionPlaceholder}/>
  );
}