import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getProducts, deleteProduct } from "apiservices/AuthorisationServices/productService";

// Local context
import { useAdminController } from "context";

const createButton = [{media: ["add"], name: "Add New Product", description: "", id: 0}]

const initialFilterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Products = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [filterAttributes, setFilterAttributes] = useState(initialFilterAttributes); //if/when filterAttributes are passed from the backend, this wont be needed
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  
  useEffect(() => {
    const _getProducts = async () => {
      let _products = await getProducts(organisationId);
      setProducts(_products.items);
      setFilteredProducts(_products.items);
      
      // Create filter attributes dynamically
      const newFilterAttributes = [...initialFilterAttributes];
      Object.keys(_products.categories).forEach(category => {
        newFilterAttributes.push({
          property: category,
          name: category,
          type: 'categorical',
          icon: 'country',
          options: _products.categories[category].map(category => {
            return { id: category.id, name: category.name };
          })
        });
      });
      setFilterAttributes(newFilterAttributes);
    };

    _getProducts();
    
    // Add a cleanup function to dispose of the view
    return () => {
      setProducts([]);
      setFilteredProducts([]);
      setFilterAttributes(initialFilterAttributes);
    };
  }, [organisationId]);

  const handleDelete = async (e, productId, onDeleteComplete) => {
    let _products = await deleteProduct(organisationId, productId);
    setProducts(_products.items);
    onDeleteComplete();
  }

  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={createButton.concat(filteredProducts)} path={"/products/Product"} deleteAction={handleDelete}>
        <FinderBar
          objs={products}
          setFilteredObjs={setFilteredProducts}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default Products;