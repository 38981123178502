import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getInventoryItemTypes, deleteInventoryItemType } from "apiservices/InventoryServices/inventoryItemTypeService";

// Local context
import { useAdminController } from "context";

let createButton = [{media: ["add"], name: "Create New Generic Category", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const GenericInventory = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [inventoryItemTypes, setInventoryItemTypes] = useState([]);
  const [filteredInventoryItemTypes, setFilteredInventoryItemTypes] = useState([]);
  useEffect(() => {
    const _getInventoryItemTypes = async () => {
      let response = await getInventoryItemTypes(organisationId);
      setInventoryItemTypes(response);
      setFilteredInventoryItemTypes(response);
    };
    _getInventoryItemTypes();
  }, []);

  const handleDelete = async (e, id, onDeleteComplete) => {
    let _inventoryItemTypes = await deleteInventoryItemType(id);
    setInventoryItemTypes(_inventoryItemTypes);
    onDeleteComplete();
  }

  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={createButton.concat(filteredInventoryItemTypes)} path={"/inventoryItemTypes/inventoryItemType"} deleteAction={handleDelete}>
        <FinderBar
          objs={inventoryItemTypes}
          setFilteredObjs={setFilteredInventoryItemTypes}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default GenericInventory;