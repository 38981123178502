import React, { useState, useEffect } from "react";

// @mui components
import { Grid, Box } from "@mui/material";
import { FormGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import { Checkbox, Tab, TextField, Button, Typography, RadioGroup, Radio, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import HandshakeIcon from '@mui/icons-material/Handshake';

// Components
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import PermissionsAdjustmentPanel from "components/AdministrationComponents/PermissionsAdjustmentPanel";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// DB context
import { createAssociate, updateAssociate, getAssociate, createConnectionRequest, getAssociateSkillSets, toggleAssociateHasSkillSet,
         getAssociateAtlases, toggleAssociateAtlasAccess, getAssociateProperties, toggleAssociateProperty
} from "apiservices/AuthorisationServices/associateService";

// Local context
import { useAdminController } from "context";

const AssociateView = () => {
  const [controller] = useAdminController();
  const { organisationId } = controller;
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [association, setAssociation] = useState();
  const [atlases, setAtlases] = useState([]);
  const [skillSets, setSkillSets] = useState([]);
  const [associateProperties, setAssociateProperties] = useState([]);
  useEffect(() => {
    if(association?.id === 0 || !association) return;
    const _getAssociate = async () => {
      let _atlases = await getAssociateAtlases(organisationId, association.id);
      let _skillSets = await getAssociateSkillSets(organisationId, association.id);
      let _properties = await getAssociateProperties(organisationId, association.id);
      setAtlases(_atlases);
      setSkillSets(_skillSets);
      setAssociateProperties(_properties);
    }
    _getAssociate();
  }, [association])
  
  const handleSetIsCollaborator = (e) => setAssociation({...association, ...{isCollaborator: e.target.checked}});
  const handleSetRelativeType = (e) => setAssociation({...association, ...{relativeType: e.target.value}});

  const [isRelative, setIsRelative] = useState(false);
  const handleSetIsRelative = (e) => {
    setIsRelative(e.target.checked);
    setAssociation({...association, ...{relativeType: null}});
  }

  const [associateMeritId, setAssociateMeritId] = useState("");
  const [associationRequestPending, setAssociationRequestPending] = useState(undefined);
  const handleSetAssociateMeritId = (e) => setAssociateMeritId(e.target.value);
  const handleCreateConnectionRequest = async (e, associateMeritId) => {
    let response = await createConnectionRequest(organisationId, association.id, associateMeritId);
    setAssociationRequestPending(response);
  }

  const handleToggleAsociateAtlasAccess = async (e, id) => {
    let _atlases = await toggleAssociateAtlasAccess(organisationId, association.id, id);
    setAtlases(_atlases);
  }
  const handleToggleAssociateSkillSet = async (e, id) => {
    let _skillSets = await toggleAssociateHasSkillSet(organisationId, id, association.id);
    setSkillSets(_skillSets);
  }
  const handleToggleAssociateProperty = async (e, id) => {
    let _properties = await toggleAssociateProperty(organisationId, id, association.id);
    setAssociateProperties(_properties);
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(association.id !== 0)
      setActiveTab(tabNumber);
  }

  const [permissionsPanelAtlasId, setPermissionsPanelAtlasId] = useState(0);
  const handlePermissionsPanelAtlasId = (e, id) => {
    setPermissionsPanelAtlasId(id);
  }
  
  return (
    <>
      <AdministrationObjectWrapper
        object={association}
        setObject={setAssociation}
        getObject={getAssociate}
        createObject={createAssociate}
        updateObject={updateAssociate}
        namePlaceholder="Associate Name"
        descriptionPlaceholder="Description of Associate"
        iconComponent={<HandshakeIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          association &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Details" value="1"/>
              {
                association?.isConnected &&
                (
                  association.id !== 0 ?
                    <Tab label="Atlases" value="2"/> :
                    <Tooltip title="Save the associate to begin giving access to atlases">
                      <Tab label="Atlases" value="2"/>
                    </Tooltip>
                )
              }
              {
                association.id !== 0 ?
                  <Tab label="Skillsets" value="3"/> :
                  <Tooltip title="Save the associate to begin applying skillsets">
                    <Tab label="Skillsets" value="3"/>
                  </Tooltip>
              }
              {
                association.id > 0 ?
                  <Tab label="Tag Groups" value="4"/> :
                  <Tooltip title="Save the user group to begin setting access to tag groups">
                    <Tab label="Tag Groups" value="4"/>
                  </Tooltip>
              }
              {
                association.id > 0 ?
                  <Tab label="Metrics" value="5"/> :
                  <Tooltip title="Save the user group to begin setting access to metrics">
                    <Tab label="Metrics" value="5"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          association &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <FormGroup>
                {
                  !association.isConnected ?
                    <FormCollapse title="Connect" open>
                      {
                        associationRequestPending !== true &&
                        <>
                          <Typography variant="h6" component="div" mb={1} textAlign="left">
                            If you wish to connect with the organisation, start by asking them to provide their 
                            <Tooltip title="Found in the Organisation Profile" arrow>
                              <Button 
                                size="small" 
                                sx={{
                                  pb: 0.9,
                                  px: 0.5,
                                  color: 'grey',
                                  textTransform: 'none',
                                  minWidth: 'auto',
                                  fontFamily: 'inherit',
                                  fontSize: 'inherit',
                                  fontWeight: 'inherit' 
                                }}>
                                Merit ID
                              </Button>
                            </Tooltip>
                            and inputting it here:
                          </Typography>
                          <Grid sx={{display: "flex", flexDirection: "row", mb: 1 }}>
                            <TextField variant="outlined" size="small" label={"Merit Id"} onChange={handleSetAssociateMeritId} value={associateMeritId}/>
                            <Button size="small" onClick={(e) => handleCreateConnectionRequest(e, associateMeritId)}>Send Connection Request</Button>
                          </Grid>
                        </>
                      }
                      {
                        associationRequestPending &&
                          <>
                            <Typography variant="h6" component="div" textAlign="left" sx={{ color: "green" }}>
                              A connection request has been sent to the organisation and is pending.
                            </Typography>
                            <Typography variant="h6" fontWeight={300} component="div" mb={1} textAlign="left">
                              Once the request is accepted accept, you will be able to collaborate with them.
                            </Typography>
                          </>
                      }
                      {
                        associationRequestPending === false &&
                          <>
                            <Typography variant="h6" component="div" textAlign="left" sx={{ color: "red" }}>
                              Either an organisation with the Merit Id provided was not found, or you already have a request pending with the organisation.
                            </Typography>
                            <Typography variant="h6" fontWeight={300} component="div" textAlign="left">
                              Please ensure the Merit ID is correct and try again.
                            </Typography>
                          </>
                      }
                    </FormCollapse> :
                    <FormCollapse title="Disconnect" open>
                      <Typography variant="h6" component="div" textAlign="left">
                        Click the button below to disconnect:
                      </Typography>
                      <Button size="small" onClick={(e) => setAssociation({...association, isConnected: false})}>Disconnect</Button>
                    </FormCollapse>
                  }
                <FormCollapse title="Relationships" open>
                  <Box display="flex" flexDirection="column">
                    <FormControlLabel control={<Checkbox checked={association.isRelative}/>} onChange={handleSetIsRelative} label="Related Organisation" />
                      {
                        isRelative && (
                          <FormControl>
                            <FormLabel id="relative-type-radio-label">Relative Type:</FormLabel>
                            <RadioGroup
                              sx={{ml: 1}}
                              row
                              aria-labelledby="relative-type-radio-label"
                              value={association.relativeType}
                              onChange={handleSetRelativeType}>
                              <FormControlLabel value={1} control={<Radio />} label="Parent" />
                              <FormControlLabel value={2} control={<Radio />} label="Sibling" />
                              <FormControlLabel value={3} control={<Radio />} label="Child" />
                            </RadioGroup>
                          </FormControl>
                        )
                      }
                    <FormControlLabel control={<Checkbox checked={association.isCollaborator}/>} onChange={handleSetIsCollaborator} label="Project Contributor" />
                  </Box>
                </FormCollapse>
              </FormGroup>
            </TabPanel>
        }
        panels={
          skillSets &&
            <>
              {
                association?.isConnected && // atlases can only be changed if the associate is not the owner
                  <TabPanel
                    value="2"
                    style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
                    <SplitMediaCardArray
                      items={atlases}
                      titles={["Atlases which associate can access", "Atlases which associate cannot access"]}
                      action={handleToggleAsociateAtlasAccess}
                      permissionsPanelToggle={handlePermissionsPanelAtlasId}
                      searchboxLabel="Search atlases"/>
                  </TabPanel>
              }
              <TabPanel // skillsets can only be changed if the associate is not the owner
                value="3"
                style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  titles={["Associate skillsets", "Other skillsets"]}
                  items={skillSets}
                  action={handleToggleAssociateSkillSet}
                  searchboxLabel="Search skillsets"/>
              </TabPanel>
              <TabPanel
                value="4"
                style={{ padding: 8, display: activeTab === "4" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={associateProperties.filter(x => x.objectTypeId == 1)}
                  titles={["Permitted tag groups", "Other tag groups"]}
                  action={handleToggleAssociateProperty}
                  searchboxLabel="Search tag groups"/>
              </TabPanel>
              <TabPanel
                value="5"
                style={{ padding: 8, display: activeTab === "5" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={associateProperties.filter(x => x.objectTypeId == 2)}
                  titles={["Permitted metrics", "Other metrics"]}
                  action={handleToggleAssociateProperty}
                  searchboxLabel="Search metrics"/>
              </TabPanel>
            </>
        }
        requiresOrgId
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {
        permissionsPanelAtlasId !== 0 &&
          <PermissionsAdjustmentPanel
            organisationId={organisationId}
            atlasId={permissionsPanelAtlasId}
            entityId={association.id}
            setOpen={handlePermissionsPanelAtlasId}
            userType="associate"
          />
      }
    </>
  );
}

export default AssociateView;