// @mui components
import { Box } from "@mui/material";

// Components
import HorizontalCardWrapper from "./HorizontalCardWrapper";
import { TextFieldTypographySwitchBox } from "components/GlobalComponents/Composites";

const HorizontalEditableMediaCard = ({ obj, setObj, icon, namePlaceholder, descriptionPlaceholder, nameField = "name", descriptionField = "description", invalidFields = {}, validate, setInvalidFields }) => {
  return (
    <HorizontalCardWrapper obj={obj} setObj={setObj} colour="#b39ddb" icon={icon} nameError={invalidFields[nameField]} descriptionError={invalidFields[descriptionField]}>
      <Box display="flex" flexDirection="column" width="145px" gap={1} height={1}>
        <TextFieldTypographySwitchBox
          obj={obj}
          setObj={setObj}
          attribute={nameField}
          backgroundColour="#ffffff"
          pl={0}
          validate={validate}
          setInvalidFields={setInvalidFields}
          />
        <TextFieldTypographySwitchBox
          obj={obj}
          setObj={setObj}
          attribute={descriptionField}
          backgroundColour="#ffffff" 
          pl={0}
          height={"100%"}
          lines={3}
          validate={validate}
          setInvalidFields={setInvalidFields}
          />
      </Box>
    </HorizontalCardWrapper>
  );
}

export default HorizontalEditableMediaCard;