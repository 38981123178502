import { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { Box } from "@mui/material";

export const scrollbarStyles = {
  '::-webkit-scrollbar': {
    width: '4px',
  },
  '::-webkit-scrollbar-track': {
    background: '#F5F5F5',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb': {
    background: '#00b0ff',
    borderRadius: '4px',
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'lightBlue',
  },
};

export const Scrollbar = styled.div(({ direction, mr, vertical }) => ({
  width: '100%',
  display: 'flex',
  height: 1,
  marginRight: mr,
  flexDirection: vertical ? 'column' : 'row',
  flexGrow: 1,
  overflowX: vertical ? 'hidden' : 'auto',
  overflowY: vertical ? 'auto' : 'hidden',
  direction: direction,
  '::-webkit-scrollbar': {
    width: vertical ? '4px' : '0px',
    height: vertical ? '0px' : '6px'
  },
  '::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 0px grey',
    background: '#F5F5F5',
    borderRadius: '4px'
  },
  '::-webkit-scrollbar-thumb': {
    background: '#00b0ff',
    borderRadius: '4px',
    height: '2px'
  },
  '::-webkit-scrollbar-thumb:hover': {
    background: 'lightBlue',
    maxHeight: '10px'
  }
}));

const ScrollbarWrapper = ({ children, style = {}, vertical, marginRight = 0 }) => {
  const [scrollbarVisible, setScrollbarVisible] = useState(false);
  const scrollbarRef = useRef(null);

  const checkScrollbarVisibility = useCallback(() => {
    const scrollbarElement = scrollbarRef.current;
    if (scrollbarElement) {
      const scrollbarVisible = scrollbarElement.scrollHeight > scrollbarElement.clientHeight;
      setScrollbarVisible(scrollbarVisible);
    }
  }, []);

  useEffect(() => {
    checkScrollbarVisibility();
    window.addEventListener('resize', checkScrollbarVisibility);

    return () => {
      window.removeEventListener('resize', checkScrollbarVisibility);
    };
  }, [checkScrollbarVisibility]);

  useEffect(() => {
    // To ensure the check is done after the content is fully rendered
    setTimeout(checkScrollbarVisibility, 0);
  }, [children, checkScrollbarVisibility]);

  return (
    <Scrollbar
      direction="ltr"
      ref={scrollbarRef}
      mr={scrollbarVisible ? '4px' : '0px'}
      vertical={vertical}
    >
      <Box
        display="flex"
        flexDirection={vertical ? 'column' : 'row'}
        flexGrow={1}
        sx={{
          ...style,
          position: 'relative',
          mr: scrollbarVisible ? 0.5 : marginRight
        }}
      >
        {children}
      </Box>
    </Scrollbar>
  );
};

ScrollbarWrapper.defaultProps = {
  mr: 0,
  overflowX: "hidden",
  style: {},
  vertical: true,
};

export default ScrollbarWrapper;
