import React, { useState, useEffect } from "react";
import { Card, Box, Typography } from "@mui/material";

const ExperienceTable = ({ closeWindow, properties, attributes, executors, data }) => {
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const targetElementId = 'experience-window';
  const widthPerColumn = 110; // Define a constant width for columns

  useEffect(() => {
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();
      setPosition({
        top: rect.top - 200,
        left: rect.left - 530
      });
    }
  }, []);

  const getAttributesByPropertyId = (propertyId) => {
    return attributes.filter(attribute => attribute.propertyId === Number(propertyId));
  };

  const findCount = (executorId, propertyId, propertyAttributeId) => {
    const executorData = data.find(d => d.executorId === executorId);
    if (!executorData) return 0;

    const propertyData = executorData.properties.find(p => p.propertyId === Number(propertyId));
    if (!propertyData) return 0;

    const attributeData = propertyData.attributeCounts.find(ac => ac.propertyAttributeId === Number(propertyAttributeId));
    return attributeData ? attributeData.count : 0;
  };

  return (
    <Card
      onMouseLeave={closeWindow}
      sx={{
        left: position.left,
        top: position.top,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        zIndex: 10,
        position: "fixed",
        backgroundColor: "rgba(189, 189, 189, 0.98)",
        justifyContent: "right",
        p: 1,
        borderRadius: "9px",
        overflow: 'auto'
      }}
    >
      <Box sx={{ overflowX: 'auto', display: 'flex', flexDirection: 'column', backgroundColor: "rgba(189, 189, 189, 0.001)" }}>
        {/* Header Row */}
        <Box sx={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <Box sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, textAlign: 'center' }} />
          {Object.entries(properties).map(([propertyId, propertyName]) => {
            const attributesForProperty = getAttributesByPropertyId(propertyId);
            const numAttributes = attributesForProperty.length || 1;
            const totalWidth = numAttributes * widthPerColumn;
            return (
              <Box
                key={propertyId}
                sx={{
                  width: `${totalWidth}px`,
                  minWidth: `${totalWidth}px`,
                  maxWidth: `${totalWidth}px`,
                  textAlign: 'center',
                  borderLeft: '1px solid rgba(0, 0, 0, 0.1)' // Add left border for separation
                }}
              >
                <Typography
                  variant="body2" // Smaller text
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {propertyName}
                </Typography>
              </Box>
            );
          })}
        </Box>

        {/* Attribute Subheading Row */}
        <Box sx={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
          <Box sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, textAlign: 'center' }}>
            <Typography
              variant="body2" // Smaller text
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              Executor
            </Typography>
          </Box>
          {Object.entries(properties).map(([propertyId]) => {
            const attributesForProperty = getAttributesByPropertyId(propertyId);
            if (attributesForProperty.length === 0) {
              return (
                <Box key={propertyId} sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }} />
              );
            }
            return attributesForProperty.map(attribute => (
              <Box key={attribute.id} sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, textAlign: 'center', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
                <Typography
                  variant="body2" // Smaller text
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {attribute.name}
                </Typography>
              </Box>
            ));
          })}
        </Box>

        {/* Body Rows */}
        {executors.map(({ id, name }) => (
          <Box key={id} sx={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid rgba(0, 0, 0, 0.1)' }}>
            <Box sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, textAlign: 'center', overflow: 'hidden', whiteSpace: 'nowrap' }}>
              <Typography
                variant="body2" // Smaller text
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {name}
              </Typography>
            </Box>
            {Object.entries(properties).map(([propertyId]) => {
              const attributesForProperty = getAttributesByPropertyId(propertyId);
              if (attributesForProperty.length === 0) {
                return (
                  <Box key={propertyId} sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, textAlign: 'center', overflow: 'hidden', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }} />
                );
              }
              return attributesForProperty.map(attribute => (
                <Box key={attribute.id} sx={{ width: `${widthPerColumn}px`, minWidth: `${widthPerColumn}px`, maxWidth: `${widthPerColumn}px`, textAlign: 'center', overflow: 'hidden', borderLeft: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  <Typography
                    variant="body2" // Smaller text
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {findCount(id, propertyId, attribute.id)}
                  </Typography>
                </Box>
              ));
            })}
          </Box>
        ))}
      </Box>
    </Card>
  );
};

export default ExperienceTable;
