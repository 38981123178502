import React, { useState } from "react";

// @mui components
import { Box } from "@mui/material";
import { Tab } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";
import ResultOptionsTree from "./ResultOptionsTree";
import DescriptorManager from "./DescriptorManager";

// Local Context
import { useAdminController } from "context";

// Db Context
import { getResult, createResult, updateResult } from "apiservices/ResultServices/resultService";

const Result = () => {
  const [controller, dispatch] = useAdminController();

  const [result, setResult] = useState();
  const [invalidFields, setInvalidFields] = useState({});
  const [selectedResult, setSelectedResult] = useState();

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    setActiveTab(tabNumber);
  }

  return(
    <AdministrationObjectWrapper
      object={result}
      setObject={setResult}
      getObject={getResult}
      createObject={createResult}
      updateObject={updateResult}
      namePlaceholder="Result Name"
      descriptionPlaceholder="Description of Result"
      iconComponent={<ContentPasteSearchIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        result &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
          </AdminTabList>
      }
      form={
        result &&
          <TabPanel 
            value="1"
            style={{ padding: 8, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <Box sx={{ display: 'flex', gap: 1, height: 1 }}>
              <ResultOptionsTree 
                result={result} 
                setResult={setResult} 
                selectedResult={selectedResult} 
                setSelectedResult={setSelectedResult} 
                organisationId={controller.organisationId}/>  
              <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: '#F5F5F5', borderRadius: 2, p: 1, flex: 1 }}>
                {
                  selectedResult &&
                    <DescriptorManager
                      selectedResult={selectedResult}
                      setSelectedResult={setSelectedResult}
                      organisationId={controller.organisationId}/>
                }
              </Box>
            </Box>
          </TabPanel>
      }
      validate={validate}
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}
      requiresOrgId
      scrollable={false}/>
  )
}

export default Result;