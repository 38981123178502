import React, { useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
} from '@mui/material';
import CountryAddressFormats from './CountryAddressFormats';

const AddressForm = ({ occupant, setOccupant }) => {
  const { address } = occupant; // Destructure occupant address

  useEffect(() => {
    console.log('Address:', address);
  }, [address]);

  const handleSetCountry = (event) => {
    const newCountry = event.target.value;
    setOccupant((prevOccupant) => ({
      ...prevOccupant,
      address: {
        ...prevOccupant.address,
        country: newCountry,
        // Reset address country changes
        line1: "",
        line2: "",
        line3: "",
        line4: "",
      },
    }));
  };

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    setOccupant((prevOccupant) => ({
      ...prevOccupant,
      address: {
        ...prevOccupant.address,
        [id]: value,
      }
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const countryFields = CountryAddressFormats[address.country]?.fields || [];

  return (
    <form noValidate autoComplete="off" onSubmit={handleSubmit}>
      <Box display='flex' flexDirection="column" gap={1.5} mb={1.5}>
        <FormControl fullWidth>
          <InputLabel id="country-label">Country</InputLabel>
          <Select
            sx={{ height: "40px" }}
            labelId="country-label"
            id="country"
            label="Country"
            value={address.country}
            onChange={handleSetCountry}>
            <MenuItem value="">Select Country</MenuItem>
            {
              Object.keys(CountryAddressFormats).map((countryCode) => (
                <MenuItem key={countryCode} value={countryCode}>
                  {CountryAddressFormats[countryCode].countryname}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
        {
          countryFields.map((field, index) => (
            <TextField
              key={index}
              fullWidth
              label={field.label}
              id={`line${index + 1}`}  // Dynamically map to line1, line2, etc.
              type={field.type}
              required={field.required}
              value={address[`line${index + 1}`] || ''}
              onChange={handleInputChange}
              error={address[`line${index + 1}`] && field.required && !address[`line${index + 1}`]}
              helperText={address[`line${index + 1}`] && field.required && !address[`line${index + 1}`] ? 'Invalid input' : ''}
            />
          ))
        }
      </Box>
    </form>
  );
};

export default AddressForm;
