import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getInstances, deleteInstance } from "apiservices/ResultServices/instanceService";

// Local context
import { useAdminController } from "context";

let createButton = [{media: ["add"], name: "Create New Instance", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Instances = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [instances, setInstances] = useState([]);
  const [filteredInstances, setFilteredInstances] = useState([]);
  useEffect(() => {
    const _getInstances = async () => {
      let _instances = await getInstances(organisationId);
      setInstances(_instances);
    };
    _getInstances();
  }, []);

  const handleDeleteInstance = async (e, instanceId, onDeleteComplete) => {
    let _instances = await deleteInstance(instanceId);
    setInstances(_instances);
    onDeleteComplete();
  }
  
  return (
    instances &&
      <AdministrationViewWrapper>
        <MediaCardArrayContainer items={createButton.concat(filteredInstances)} path={"/instances/instance"} deleteAction={handleDeleteInstance}>
          <FinderBar
            objs={instances}
            setFilteredObjs={setFilteredInstances}
            filterAttributes={filterAttributes}/>
        </MediaCardArrayContainer>
      </AdministrationViewWrapper>
  );
}

export default Instances;