// For adding a new route you can follow the existing rules in the routes array.
// 1. The `name` key is used for the name of the route on the Sidenav.
// 2. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
// 3. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
// 4. The `route` key is used to store the route location which is used for the react router.
// 5. The `component` key is used to store the component of its route.
// 6. The `userRole` key is used to store the user role of its route.
// Note: New routes below will be visible on the Sidenav when 'icon' is not null.

// @mui icons
import Icon from "@mui/material/Icon";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ApprovalIcon from '@mui/icons-material/Approval';
import InventoryIcon from '@mui/icons-material/Inventory';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FolderIcon from '@mui/icons-material/Folder';
import DescriptionIcon from '@mui/icons-material/Description';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

// Dbcontext
import { UserSession } from "components/Authentification/UserSession";

// Front page layout
import FrontPage from "layouts/frontpage/FrontPage";

// Administrator layouts
import LandingPage from "layouts/administrator/landingpage";
import Atlases from "layouts/administrator/atlases";
import UserGroups from "layouts/administrator/usergroups";
import Users from "layouts/administrator/users";
import SkillSets from "layouts/administrator/skillsets";
import Properties from "layouts/administrator/properties";
import Associates from "layouts/administrator/associates";
import Products from "layouts/administrator/products";

import AtlasView from "layouts/administrator/atlases/views/AtlasView";
import UserGroupView from "layouts/administrator/usergroups/views/UserGroupView";
import UserView from "layouts/administrator/users/views/UserView";
import SkillSetView from "layouts/administrator/skillsets/views/SkillSetView";
import TagGroupView from "layouts/administrator/properties/views/TagGroupView";
import MetricView from "layouts/administrator/properties/views/MetricView";
import AssociateView from "layouts/administrator/associates/views/AssociateView";
import ProductView from "layouts/administrator/products/views/ProductView";

import OrganisationProfile from "layouts/administrator/organisationprofile";

// User layouts
import UserLandingPage from "layouts/user/landingpage";
import RequestsPage from "layouts/user/requests";

// Inventory administrator layouts
import InventoryAdminLandingPage from "layouts/inventoryadministrator/landingpage";
import GenericInventory from "layouts/inventoryadministrator/inventoryitemtypes";
import InventoryItemType from "layouts/inventoryadministrator/inventoryitemtypes/views/InventoryItemType";
import InventoryProperties from "layouts/inventoryadministrator/properties";
import InventoryTagGroup from "layouts/inventoryadministrator/properties/views/InventoryTagGroup";
import InventoryMetric from "layouts/inventoryadministrator/properties/views/InventoryMetric";
import Brands from "layouts/inventoryadministrator/brands";
import Inventory from "layouts/inventoryadministrator/inventoryitems";
import InventoryItem from "layouts/inventoryadministrator/inventoryitems/views/InventoryItem";
import UserInventories from "layouts/inventoryadministrator/userinventory";
import UserInventory from "layouts/inventoryadministrator/userinventory/views/UserInventory";

// Instance administrator layouts
import InstanceAdminLandingPage from "layouts/resultadministrator/landingpage";
import Clients from "layouts/resultadministrator/clients";
import Client from "layouts/resultadministrator/clients/views/Client";
import Scopes from "layouts/resultadministrator/scopes";
import Scope from "layouts/resultadministrator/scopes/views/Scope";
import Descriptors from "layouts/resultadministrator/descriptors";
import DescriptorTagGroupView from "layouts/resultadministrator/descriptors/views/DescriptorTagGroupView";
import NumericDescriptorView from "layouts/resultadministrator/descriptors/views/NumericDescriptorView";
import Results from "layouts/resultadministrator/results";
import Result from "layouts/resultadministrator/results/views/Result";
import Instances from "layouts/resultadministrator/instances";
import Instance from "layouts/resultadministrator/instances/views/Instance";

// Canvas
import Workplace from "layouts/canvas";

// Loading page
import LoadingView from "layouts/LoadingScreen";

export const routes = [
  {
    name: "",
    key: "userSession",
    icon: null,
    route: "/usersession",
    element: <UserSession/>,
    userRoles: ["user"]
  },

  {
    name: "",
    key: "frontPage",
    icon: null,
    route: "/",
    element: <FrontPage/>,
    userRoles: ["frontpage"]
  },

  {
    name: "Dashboard",
    key: "adminDashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/adminDashboard",
    element: <LandingPage/>,
    userRoles: ["admin"]
  },
  {
    name: "Atlases",
    key: "atlases",
    icon: <Icon fontSize="small">language</Icon>,
    route: "/atlases",
    element: <Atlases />,
    userRoles: ["admin"]
  },
  {
    name: "User Groups",
    key: "userGroups",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/userGroups",
    element: <UserGroups/>,
    userRoles: ["admin"]
  },
  {
    name: "Users",
    key: "users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/users",
    element: <Users/>,
    userRoles: ["admin"]
  },
  {
    name: "Products",
    key: "products",
    icon: <ShoppingCartIcon fontSize="small"/>,
    route: "/products",
    element: <Products/>,
    userRoles: ["admin"]
  },
  {
    name: "Skill Sets",
    key: "skillsets",
    icon: <Icon fontSize="small">work</Icon>,
    route: "/skillsets",
    element: <SkillSets/>,
    userRoles: ["admin"]
  },
  {
    name: "Properties",
    key: "properties",
    icon: <Icon fontSize="small">information</Icon>,
    route: "/properties",
    element: <Properties />,
    userRoles: ["admin"]
  },
  {
    name: "Associates",
    key: "associates",
    icon: <Icon fontSize="small">handshake</Icon>,
    route: "/associates",
    element: <Associates />,
    userRoles: ["admin"]
  },
  {
    name: "Organisation Profile",
    key: "organisationProfile",
    icon: <Icon fontSize="small">settings</Icon>,
    route: "/organisationProfile",
    element: <OrganisationProfile />,
    userRoles: ["admin"]
  },
  {
    name: "Atlas",
    key: "atlas",
    icon: null,
    route: "/atlases/atlas",
    element: <AtlasView/>,
    userRoles: ["admin"]
  },
  {
    name: "User Group",
    key: "UserGroup",
    icon: null,
    route: "/userGroups/UserGroup",
    element: <UserGroupView />,
    userRoles: ["admin"]
  },
  {
    name: "User",
    key: "user",
    icon: null,
    route: "/users/user",
    element: <UserView />,
    userRoles: ["admin"]
  },
  {
    name: "Product",
    key: "Product",
    icon: null,
    route: "/products/Product",
    element: <ProductView />,
    userRoles: ["admin"]
  },
  {
    name: "Skill Set",
    key: "skillSet",
    icon: null,
    route: "/skillSets/skillSet",
    element: <SkillSetView/>,
    userRoles: ["admin"]
  },
  {
    name: "Tag Group",
    key: "tagGroup",
    icon: null,
    route: "/properties/tagGroup",
    element: <TagGroupView/>,
    userRoles: ["admin"]
  },
  {
    name: "Metric",
    key: "metric",
    icon: null,
    route: "/properties/metric",
    element: <MetricView />,
    userRoles: ["admin"]
  },
  {
    name: "Associate",
    key: "associate",
    icon: null,
    route: "/associates/associate",
    element: <AssociateView />,
    userRoles: ["admin"]
  },

  {
    name: "Atlases",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    element: <UserLandingPage />,
    userRoles: ["user"]
  },
  {
    name: "Requests",
    key: "requests",
    icon: <Icon fontSize="small">RequestQuoteSharpIcon</Icon>,
    route: "/requests",
    element: <RequestsPage />,
    userRoles: ["user"]
  },
  {
    name: "",
    key: "usercanvas",
    icon: null,
    route: "/usercanvas",
    element: <Workplace/>,
    userRoles: ["user"]
  },
  
  {
    name: "Dashboard",
    key: "inventoryDashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/inventoryDashboard",
    element: <InventoryAdminLandingPage />,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Brands",
    key: "brands",
    icon: <ApprovalIcon fontSize="small"/>,
    route: "/brands",
    element: <Brands />,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Inventory Item Types",
    key: "inventoryItemTypes",
    icon: <InventoryIcon fontSize="small"/>,
    route: "/inventoryItemTypes",
    element: <GenericInventory/>,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Inventory Item Type",
    key: "inventoryitemtypes",
    icon: null,
    route: "/inventoryItemTypes/inventoryItemType",
    element: <InventoryItemType/>,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Inventory Properties",
    key: "inventoryProperties",
    icon: <Icon fontSize="small">information</Icon>,
    route: "/inventoryProperties",
    element: <InventoryProperties/>,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Inventory Tag Group",
    key: "inventoryTagGroup",
    icon: null,
    route: "/inventoryProperties/inventoryTagGroup",
    element: <InventoryTagGroup/>,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Inventory Metric",
    key: "inventoryMetric",
    icon: null,
    route: "/inventoryProperties/inventoryMetric",
    element: <InventoryMetric/>,
    userRoles: ["inventoryadmin"]
  },
  {
    name: "Inventory Items",
    key: "inventoryItems",
    icon: <ContentPasteSearchIcon fontSize="small"/>,
    route: "/inventoryItems",
    element: <Inventory/>,
    userRoles: ["inventoryadmin", "skilledinventoryadmin"]
  },
  {
    name: "Inventory Item",
    key: "inventoryItem",
    icon: null,
    route: "/inventoryItems/inventoryItem",
    element: <InventoryItem/>,
    userRoles: ["inventoryadmin", "skilledinventoryadmin"]
  },
  {
    name: "User Inventories",
    key: "userInventories",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/userInventories",
    element: <UserInventories/>,
    userRoles: ["inventoryadmin", "skilledinventoryadmin"]
  },
  {
    name: "User Inventory",
    key: "userInventory",
    icon: null,
    route: "/userInventories/userInventory",
    element: <UserInventory/>,
    userRoles: ["inventoryadmin", "skilledinventoryadmin"]
  },

  {
    name: "Dashboard",
    key: "instanceDashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/instanceDashboard",
    element: <InstanceAdminLandingPage />,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Clients",
    key: "clients",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/clients",
    element: <Clients/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Client",
    key: "client",
    icon: null,
    route: "/clients/client",
    element: <Client/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Scopes",
    key: "scopes",
    icon: <AssignmentTurnedInIcon fontSize="small"/>,
    route: "/scopes",
    element: <Scopes/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Scope",
    key: "scope",
    icon: null,
    route: "/scopes/Scope",
    element: <Scope/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Descriptors",
    key: "descriptors",
    icon: <DescriptionIcon fontSize="small"/>,
    route: "/descriptors",
    element: <Descriptors/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Descriptor Tag Group",
    key: "descriptorTagGroup",
    icon: null,
    route: "/descriptors/descriptorTagGroup",
    element: <DescriptorTagGroupView/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Numeric Descriptor",
    key: "numericDescriptor",
    icon: null,
    route: "/descriptors/numericDescriptor",
    element: <NumericDescriptorView/>,
    userRoles: ["instanceadmin"]
  },
  {
    name: "Results",
    key: "results",
    icon: <ApprovalIcon fontSize="small"/>,
    route: "/results",
    element: <Results/>,
    userRoles: ["instanceadmin", "skilledinstanceadmin"]
  },
  {
    name: "Result",
    key: "result",
    icon: null,
    route: "/results/Result",
    element: <Result/>,
    userRoles: ["instanceadmin", "skilledinstanceadmin"]
  },
  {
    name: "Instances",
    key: "instances",
    icon: <FolderIcon fontSize="small"></FolderIcon>,
    route: "/instances",
    element: <Instances/>,
    userRoles: ["instanceadmin", "skilledinstanceadmin"]
  },
  {
    name: "Instance",
    key: "instance",
    icon: null,
    route: "/instances/instance",
    element: <Instance/>,
    userRoles: ["instanceadmin", "skilledinstanceadmin"]
  },

  {
    name: "",
    key: "loadingpage",
    icon: null,
    route: "/loadingpage",
    element: <LoadingView/>,
    userRoles: ["frontpage"]
  }
];

export const defaultRoutes = (role) => {
  if (role === "admin") {
    return "/adminDashboard";
  } else if (role === "inventoryadmin") {
    return "/inventoryDashboard";
  } else if (role === "instanceadmin") {
    return "/instanceDashboard";
  } else if (role === "user") {
    return "/dashboard";
  } else if (role === "coordinator") {
    return "/dashboard";
  } else if (role === "skilledinstanceadmin") {
    return "/instances";
  } else if (role === "skilledinventoryadmin") {
    return "/instances";
  } else if (role === "skilledknowledgeadmin") {
    return "/dashboard";
  } else if (role === "skilledcoordinator") {
    return "/dashboard";
  } else {
    return "/";
  }
}
