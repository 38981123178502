import { postWrapper, getWrapper } from '../fetchWrapper';

export const createAtlas = async (atlas) => await postWrapper('/api/atlasadmin/createatlas', atlas);
export const updateAtlasReference = async (atlas) => await postWrapper('/api/atlasadmin/updateatlasreference', atlas);

export const getAtlasReferences = async (organisationId) => await getWrapper(`/api/atlasadmin/getatlasreferences/` + organisationId);
export const getAtlasReference = async (organisationId, atlasId) => await getWrapper(`/api/atlasadmin/getatlasreference/` + organisationId + `/` + atlasId);
export const deleteAtlas = async (atlasId) => await getWrapper(`/api/atlasadmin/deleteatlas/` + atlasId);

export const getAtlasUserGroups = async (atlasId, organisationId) => await getWrapper(`/api/atlasadmin/getatlasusergroups/` + atlasId + '/' + organisationId);
export const toggleUserGroupAtlasAccess = async (userGroupId, atlasId, organisationId) => await getWrapper(`/api/atlasadmin/toggleusergroupatlasaccess/` + userGroupId + '/' + atlasId + '/' + organisationId);
export const getAtlasUsers = async (atlasId, organisationId) => await getWrapper(`/api/atlasadmin/getatlasusers/` + atlasId + '/' + organisationId);
export const toggleUserAtlasAccess = async (userId, atlasId, organisationId) => await getWrapper(`/api/atlasadmin/toggleuseratlasaccess/` + userId + '/' + atlasId + '/' + organisationId);
export const getAtlasAssociates = async (atlasId, organisationId) => await getWrapper(`/api/atlasadmin/getatlasassociates/` + atlasId + '/' + organisationId);
export const toggleAssociateAtlasAccess = async (associationId, atlasId) => await getWrapper(`/api/atlasadmin/toggleassociateatlasaccess/` + associationId + '/' + atlasId);

export const getAtlasProperties = async (atlasId, organisationId) => await getWrapper(`/api/atlasadmin/getatlasproperties/` + atlasId + '/' + organisationId);
export const toggleAtlasHasProperty = async (propertyId, organisationId, atlasId) => await getWrapper(`/api/atlasadmin/toggleatlashasproperty/` + propertyId + `/` + organisationId + '/' + atlasId);
export const getAtlasBaseData = async (atlasId) => await getWrapper(`/api/atlasadmin/getatlasbasedata/` + atlasId);
