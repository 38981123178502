import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { Tab, Tooltip, Typography, TextField } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import MediaCardArrayContainerSelector from "components/CardArrayContainers/MediaCardArrayContainerSelector";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db Context
import { createInventoryItem, updateInventoryItem, getInventoryItem } from "apiservices/InventoryServices/inventoryItemService";
import { getInventoryItemTypes, getAppliedItemTypeBrands } from "apiservices/InventoryServices/inventoryItemTypeService";
import { getUserAllocations, toggleUserAllocation, getGroupAllocations, toggleGroupAllocation } from "apiservices/InventoryServices/allocationService";

// Local context
import { useAdminController } from "context";

const InventoryItem = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [inventoryItem, setInventoryItem] = useState();
  const [itemTypes, setItemTypes] = useState([]);
  const [typeBrands, setTypeBrands] = useState([]);
  const [selectedItemTypeId, setSelectedItemTypeId] = useState(0);
  const [userAllocations, setUserAllocations] = useState();
  const [groupAllocations, setGroupAllocations] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };


  useEffect(() => {
    const _getInventoryItem = async () => {
      let _itemTypes = await getInventoryItemTypes(organisationId);
      setItemTypes(_itemTypes);
    };
    _getInventoryItem();
  }, [inventoryItem]);

  useEffect(() => {
    if(inventoryItem?.id === 0 || !inventoryItem) return;
    const _getAllocations = async () => {
      let _userAllocations = await getUserAllocations(organisationId, inventoryItem.id);
      let _groupAllocations = await getGroupAllocations(organisationId, inventoryItem.id);
      setUserAllocations(_userAllocations);
      setGroupAllocations(_groupAllocations);
      setSelectedItemTypeId(inventoryItem.inventoryItemTypeId);
      
    };
    _getAllocations();
  }, [inventoryItem]);

  useEffect(() => {
    if (selectedItemTypeId === 0) return;
    const _getTypeBrands = async () => {
      let _typeBrands = await getAppliedItemTypeBrands(selectedItemTypeId);
      setTypeBrands(_typeBrands);
    };
    _getTypeBrands();
  }, [selectedItemTypeId]);

  const handleSetInventoryItemTypeId = (id) => setSelectedItemTypeId(id);
  const handleSetInventoryItemBrandId = (id) => setInventoryItem({ ...inventoryItem, typeBrandId: id });
  
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if (inventoryItem.id !== 0)
      setActiveTab(tabNumber);
  };

  const handleToggleUserAllocation = async (e, userId) => {
    let _userAllocations = await toggleUserAllocation(organisationId, inventoryItem.id, userId);
    setUserAllocations(_userAllocations);
  };
  const handleToggleGroupAllocation = async (e, groupId) => {
    let _groupAllocations = await toggleGroupAllocation(organisationId, inventoryItem.id, groupId);
    setGroupAllocations(_groupAllocations);
  }

  return(
    <AdministrationObjectWrapper
      object={inventoryItem}
      setObject={setInventoryItem}
      getObject={getInventoryItem}
      createObject={createInventoryItem}
      updateObject={updateInventoryItem}
      namePlaceholder="Inventory Item Name"
      descriptionPlaceholder="Description of Inventory Item"
      iconComponent={<ContentPasteSearchIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        inventoryItem &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
            {
              inventoryItem.id !== 0 ?
                <Tab label="Group Allocations" value="2"/> :
                <Tooltip title="Save the inventory item to begin allocating user groups">
                  <Tab label="Group Allocations" value="2"/>
                </Tooltip>
            }
            {
              inventoryItem.id !== 0 ?
                <Tab label="User Allocations" value="3"/> :
                <Tooltip title="Save the inventory item to begin allocating users">
                  <Tab label="User Allocations" value="3"/>
                </Tooltip>
            }
          </AdminTabList>
      }
      form={
        inventoryItem &&
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            {/* <Typography variant="h6" component="div" noWrap>
              User Allocations should only include users who do jobs which the inventory item is required for
            </Typography> */}
            <FormCollapse title="Type & Brand" open>
              <MediaCardArrayContainerSelector
                items={itemTypes} 
                setSelectedItemId={handleSetInventoryItemTypeId} 
                selectedItemId={selectedItemTypeId}
                label="Product Type"
                style={{ mb: 1.5, mt: 1 }}/>
              {
                typeBrands?.length > 0 &&
                  <MediaCardArrayContainerSelector
                    items={typeBrands} 
                    setSelectedItemId={handleSetInventoryItemBrandId} 
                    selectedItemId={inventoryItem.typeBrandId}
                    label="Brand"
                    style={{ mb: 1.5 }}/>
              }
            </FormCollapse>
            <FormCollapse title="Quantity"/>
            <FormCollapse title="Metrics">     
                  <Box display="flex" flexDirection="column">
                  {
                    inventoryItem.propertyMetrics?.map((metric, index) => {
                      return (
                        <Box key={index} display="flex" flexDirection="row" alignItems="center">
                          <Typography>{metric.metricName}: </Typography>
                          <TextField
                            value={metric.value}
                            type='number'
                            name={metric.metricId.toString()}
                            onChange={handleSetMetricValue}
                          />
                        </Box>
                      );
                    })
                  }
                  </Box>
            </FormCollapse>
          </TabPanel>
      }
      panels={
        (userAllocations)  &&
          <>
            <TabPanel 
              value="2"
              style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
              <SplitMediaCardArray
                items={groupAllocations}
                titles={["User groups who can use this item", "User groups who can't use this item"]}
                action={handleToggleGroupAllocation}
                searchboxLabel="Search user groups"/>
            </TabPanel>
            <TabPanel 
              value="3"
              style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
              <SplitMediaCardArray
                items={userAllocations}
                titles={["Users who can use this item", "Users who can't use this item"]}
                action={handleToggleUserAllocation}
                searchboxLabel="Search users"/>
            </TabPanel>
          </>
      }
      validate={validate}
      requiresOrgId
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}
    />
  )
}

export default InventoryItem;