// @mui components
import { Box } from "@mui/material";
import { Card, CardContent, CardActionArea } from "@mui/material";
import { Typography, Icon, Tooltip, IconButton } from "@mui/material";

// @mui icons
import BuildIcon from '@mui/icons-material/Build';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

// Components
import { DeleteButton } from "components/GlobalComponents/Buttons";

export const FixedItemMediaCard = ({ id, name, description, action, deleteAction, media, br = "8px", style }) => {
  let image = new Image();
  image.src = media;

  return (
    <Card elevation={1} sx={{ height: 1, bgcolor: "#F5F5F5", borderRadius: br, ...style }}>
      {
        ((id > 0 || id > "0") && deleteAction) && 
          <DeleteButton deleteAction={deleteAction} id={id}/>
      }
      <CardActionArea sx={{height: 1, justifyContent: "center"}} onClick={action}>
        <Box sx={{textAlign: "center", maxHeight: "85px"}}>
          {
            id > 0 ?
              <img src={media} style={{height: "78px", width: "78px", paddingTop: "10px"}}/> :
              <Icon sx={{fontSize: 115, pb: -1}} fontSize="">{media}</Icon>
          }
        </Box>
        <CardContent sx={{px: 1}}>  
          <Tooltip title={name}>
            <Typography variant="h6" component="div" mt={0.5} textAlign="center" noWrap>
              {name}
            </Typography>
          </Tooltip>
          <Tooltip title={description}>
            <Typography variant="caption" component="div" mb={1} color="text.secondary" textAlign="center" noWrap>
              {description}
            </Typography>
          </Tooltip>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export const FixedItemMiniMediaCard = ({ id, name, action, selected, media, makeEditorVisible, borderColour, editable, deleteAction}) => {
  return (
    <Card
      elevation={1}
      sx={{
        height: 1,
        bgcolor: selected && "#00b0ff",
        borderRadius: "3px",
        width: '100px',
        border: borderColour && `2px solid ${borderColour}`,
        }}>
      <CardActionArea
        sx={{height: 1, justifyContent: "center"}}
        onClick={action}>
        <Box sx={{textAlign: "center", maxHeight: "85px"}}>
          {
            id > 0 ?
              <img src={media} style={{height: "39px", width: "39px", paddingTop: "5px"}}/> :
              <Icon sx={{fontSize: 45, pb: -1}} fontSize="">{media}</Icon>
          }
          <Tooltip title={name}>
            <Typography 
              variant="h6" 
              component="div" 
              textAlign="center" 
              sx={{
                mx: 0.5,
                pt: 0.5,
                lineHeight: 1.2,
                display: "-webkit-box",
                WebkitLineClamp: 2,
                WebkitBoxOrient: "vertical",
                overflow: "hidden" 
              }}>
              {name}
            </Typography>
          </Tooltip>
        </Box>
      </CardActionArea>
      {
        (makeEditorVisible && editable) &&
          <IconButton sx={{ position: "absolute", p: 0 }} onClick={(e) => makeEditorVisible(id, false)}>
            <BuildIcon sx={{fontSize: 20, color: "#000000", p: 0.5}} fontSize=""/>
          </IconButton>
      }
      {
        (id > 0 && !editable) &&
          <IconButton sx={{ position: "absolute", p: 0 }} onClick={(e) => makeEditorVisible(id, true)}>
            <RemoveRedEyeIcon sx={{fontSize: 20, color: "#000000", p: 0.5}} fontSize=""/>
          </IconButton>
      }
      {
        editable && 
          <DeleteButton deleteAction={deleteAction} id={id} scale={0.4} margin={-0.5}/>
      }
    </Card>
  );
}