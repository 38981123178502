import React, { useState, useEffect } from 'react';

// @mui components
import { Card, IconButton, Button, Tooltip, Typography } from '@mui/material';

// @mui icons
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DeleteIcon from '@mui/icons-material/Delete';

// Components
import { WrapperModal } from 'components/GlobalComponents/Modals';

// Db context
import { deleteProcess } from "apiservices/AtlasServices/nodeService";

class ProcessModel {
  constructor() {
    this.parentNodeId = null;
    this.nodes = [];
    this.edges = [];
  }
}

const ProcessAlternator = ({ processes, setProcesses, process, setProcess, parentNodeId, networkId }) => {
  const [selectedProcessId, setSelectedProcessId] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setProcess(processes[selectedProcessId]);
  }, [selectedProcessId]);
  
  const handleAddButtonClick = () => {
    const newProcessModel = new ProcessModel();
    newProcessModel.parentNodeId = parentNodeId;
    newProcessModel.profileNo = Math.max(0, ...processes.map(p => p.profileNo)) + 1;
    newProcessModel.nodes = [
      {
        id: 0,
        name: "",
        networkId: parseInt(networkId),
        parentNodeId: parentNodeId,
        horizontalIndex: 1,
        authorisationId: 0
      }
    ];
    setProcess(newProcessModel);
    setProcesses([...processes, newProcessModel]);
    setSelectedProcessId(processes.length);
  };

  const handleSelectProcess = (i) => setSelectedProcessId(i);

  const handleSelectNextProcess = () => {
    const currentIndex = processes.findIndex((p) => p.id === selectedProcessId);
    const nextIndex = (currentIndex + 1) % processes.length;
    const nextProcess = processes[nextIndex];
    handleSelectProcess(nextProcess);
  };

  const getVisibleProcesses = () => {
    const currentIndex = processes.findIndex((p) => p.id === selectedProcessId);
    const processCount = processes.length;

    // Show all processes, if there are 3 or fewer
    if (processCount <= 3) {
      return processes;
    }

    const start = Math.max(0, currentIndex - 1);
    const end = Math.min(start + 3, processCount);

    // Adjust start and end if the selected process is at the edge of the array
    if (end - start < 3) {
      return processes.slice(processCount - 3, processCount);
    }

    return processes.slice(start, end);
  };

  const handleDelete = () => {
    setOpen(true);
  };

  const onDeleteComplete = () => {
    // deleteProcess(parentNodeId, selectedProcessId); //debug this carefully
    setOpen(false);
  };

  return (
    <Card
      sx={{
        backgroundColor: '#ffffff',
        elevation: 1,
        height: '25px',
        display: 'flex',
        borderRadius: '4px',
        zIndex: 200,
        flexDirection: 'row',
        alignItems: 'center',
        width: 'fit-content'
      }}>
      <Tooltip title='Create New Alternative Process'>
        <IconButton 
          aria-label="add"
          sx={{ p: 0, pl: 1, pr: 0.4 }}
          onClick={() => handleAddButtonClick()}>
          <AddIcon sx={{ fontSize: 16, pb: 0.2 }} fontSize=''/>
        </IconButton>
      </Tooltip>
      <Tooltip title="Delete Process">
        <IconButton
          aria-label="delete"
          sx={{ p: 0, color: 'red' }}
          onClick={handleDelete}>
          <DeleteIcon sx={{ fontSize: 16, pb: 0.3 }} fontSize=''/>
        </IconButton>
      </Tooltip>
      {
        getVisibleProcesses().map((process, i) => (
          <Button
            key={i}
            aria-label={i}
            sx={{
              color: selectedProcessId === i ? '#00b0ff' : 'inherit',
              p: 0,
              minWidth: '18px',
              variant: "h1",
              fontSize: 18
            }}
            onClick={() => handleSelectProcess(i)}>
            {i}
          </Button>
        ))
      }
      {
        processes.length > 3 &&
          <IconButton
            aria-label="next"
            sx={{ p: 0 }}
            onClick={handleSelectNextProcess}>
            <ArrowRightIcon sx={{ pb: 0.2 }}/>
          </IconButton>
      }
      <WrapperModal
        open={open} 
        handleClose={() => setOpen(false)}
        title={"Delete Item"}
        primaryButtonText={"Permanently Delete Item"}
        primaryEvent={onDeleteComplete} 
        id={selectedProcessId}>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to delete the selected process? This action cannot be undone.
        </Typography>
      </WrapperModal>
    </Card>
  );
};

export default ProcessAlternator;