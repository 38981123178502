import { postWrapper, getWrapper } from '../fetchWrapper';

export const createNode = async (node) => await postWrapper('/api/node/createnode', node);
export const updateNode = async (node) => await postWrapper('/api/node/updatenode', node);
export const createProcess = async (process) => await postWrapper('/api/node/createprocess', process);
export const createSubProcess = async (process) => await postWrapper('/api/node/createsubprocess', process);
export const deleteProcess = async (parentNodeId, profileNo) => await getWrapper(`/api/node/deleteprocess/` + parentNodeId + '/' + profileNo);

export const getNestedNodes = async (nodeId) => await getWrapper(`/api/node/getnestednodes/` + nodeId);
export const deleteNode = async (nodeId) => await getWrapper(`/api/node/deletenode/` + nodeId);
export const getNode = async (nodeId) => await getWrapper(`/api/node/getnode/` + nodeId);
export const getAtlasNodes = async () => await getWrapper(`/api/node/getatlasnodes`);
export const getVertexColours = async (tagGroupIds) => await getWrapper(`/api/node/getvertexcolours/` + tagGroupIds);
export const getProcesses = async (parentNodeId) => await getWrapper(`/api/node/getprocesses/` + parentNodeId);
export const getNodeBaseData = async (nodeId, organisationId) => await getWrapper(`/api/node/getnodebasedata/` + nodeId + '/' + organisationId);

export const getAtlasProducts = async (organisationId, skillSetId) => await getWrapper(`/api/node/getatlasproducts/` + organisationId + '/' + skillSetId);