// @mui components
import { Box } from "@mui/material";
import { Typography, Tooltip } from "@mui/material";

// Components
import HorizontalCardWrapper from "./HorizontalCardWrapper";

const HorizontalMediaCard = ({ obj, color, icon }) => {
  return (
    <HorizontalCardWrapper obj={obj} setObj={undefined} colour={color} icon={icon} imgUploader={false}>
      <Box display="flex" flexDirection="column" width="145px" mt={0.4}>
        <Tooltip title={obj.name}>
          <Typography variant="h6" noWrap>{obj.name}</Typography>
        </Tooltip>
        <Tooltip title={obj.description}>
          <Typography 
            variant="caption" 
            color="text.secondary"
            mt={1.5}
            sx={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2}}>
            {obj.description}
          </Typography>
        </Tooltip>
      </Box>
    </HorizontalCardWrapper>
  );
}

export default HorizontalMediaCard;