import React, { useEffect, useState, useRef } from "react";

// Canvas service functions
import { getScaleSettings, getCurrentViewport } from "./visibilityService";
import { getVertex, getEdge } from "./SceneObjects";

import { clearStage, removeContextMenu, mouseActions, resizeCanvas, setupMouseUpHandler, cleanupMouseUpHandler, 
         sortChildrenRecursively, handleMouseMove } from "helpers/canvashelpers/canvasfunctions";
import { networkFontSizes } from "helpers/canvashelpers/constants";
import { getFontsize } from "helpers/canvashelpers/textoperations";

// Local context
import { useCanvasController, getApp, setScale, setCoordinates, setSelectedObjectType, setSelectedObject, setSelectedExecutorId, 
         setSelectedProjectId, setActiveLevel, setOpenDrawers, setVertices, setEdges } from "context/canvasContext";

const Canvas = () => {
  const [controller, dispatch] = useCanvasController();
  const { vertices, unconfiguredJobs, vertexTagColours, vertexHeatColours, vertexInnerSizes, edges, scale, selectedObjectType, 
          selectedObject, coordinates, stateLoaded, interactionMode, overrideLevelId, highlightedVertexIds, openDrawers, metricsVisible, 
          tagsVisible, heatMapVisible, projectMode, projectToggled, hoverActive , activeLevel, networkStates} = controller;

  const handleSetLastClickedItem = (vertex) => {
    setSelectedObject(dispatch, vertex);
    setSelectedExecutorId(dispatch, undefined);
    setSelectedProjectId(dispatch, undefined);
  };
  const handleSetSelectedObjectType = (type) => setSelectedObjectType(dispatch, type);
  const handleSetScale = (scale) => setScale(dispatch, scale);
  const handleSetOpenDrawers = (openDrawers) => setOpenDrawers(dispatch, openDrawers);
  
  useEffect(() => {
    setSelectedObject(dispatch, {id: 0, networkId: 0});
    setSelectedExecutorId(dispatch, undefined);
    setSelectedProjectId(dispatch, undefined);
  }, [interactionMode]);

  const [panCount, setPanCount] = useState(0);

  const [selectedEdgeAncestors, setSelectedEdgeAncestors] = useState([]);
  useEffect(() => {
    const app = getApp();
    
    if (!app || !app.view) return;  // Guard clause to ensure app and app.view exist

    app.stage.x = coordinates.x;
    app.stage.y = coordinates.y;
    app.stage.scale.x = scale;
    app.stage.scale.y = scale;
    
    // Set up mouse actions and store the cleanup function
    const cleanupMouseActions = mouseActions(
      app,
      handleSetSelectedObjectType,
      handleSetLastClickedItem,
      0.04,
      setSelectedEdgeAncestors,
      handleSetScale,
      setPanCount
    );
    
    removeContextMenu("atlascanvas");
    setupMouseUpHandler(app, setCoordinates, dispatch);

    // Cleanup function to remove event listeners
    return () => {
      if (cleanupMouseActions) {
        cleanupMouseActions();
      }
      cleanupMouseUpHandler();
    };
  }, [stateLoaded]);
  
  const [hoveredEdges, setHoveredEdges] = useState([]);
  const hoveredEdgesRef = useRef(hoveredEdges);

  // Update the ref value whenever hoveredEdges changes
  useEffect(() => {
    hoveredEdgesRef.current = hoveredEdges;
  }, [hoveredEdges]);
  const handleSetHoveredEdges = (edge) => setHoveredEdges(edge);
  const handleEdgeSelection = (edge) => {
    const ancestors = getAncestorEdges(edge, vertices, edges);
    if(ancestors){
      setSelectedEdgeAncestors(ancestors);
    }
    else{
      setSelectedEdgeAncestors([]);
    }
    setSelectedObject(dispatch, edge);
    setSelectedObjectType(dispatch, 'edge');
  };

  useEffect(() => {
    if(hoverActive){
      const cleanup = handleMouseMove(getApp(), dispatch, vertices, setVertices, edges, setEdges, activeLevel, hoveredEdgesRef);
      return cleanup;
    }
  }, [stateLoaded, hoverActive]);

  const [adjustedFontSizes, setAdjustedFontSizes] = useState(undefined);
  useEffect(() => {
    if(vertices?.length > 0){
      setAdjustedFontSizes(adjustFontSizes(vertices));
    }
  }, [vertices, metricsVisible]);

  const [resizeTriggered, setResizeTriggered] = useState(false);
  useEffect(() => {
    resizeCanvas(setResizeTriggered);
  }, []);
  
  useEffect(() => {
    if(vertices && adjustedFontSizes !== undefined && !projectToggled){
      let {alphaSettings, visibleVertices, activeLevel, visibleEdges} = getScaleSettings(vertices, edges, scale, overrideLevelId, networkStates);
      setActiveLevel(dispatch, activeLevel);

      let cursor = interactionMode === "select" ? "pointer" : "cell";
      let selectedVertexId = (interactionMode === "select" && selectedObjectType === 'vertex')  ? selectedObject.id : 0;
      let viewport = getCurrentViewport(getApp());
      clearStage(getApp());
      visibleVertices.forEach((vertex) => {
        let unconfiguredChildren = getUnconfiguredChildren(vertex, unconfiguredJobs);
        let parentVertex = getApp().stage.children.find(x => x.canvasObjectType === "vertex" && x.context.id === vertex.context.parentNodeId); // this is used for culling
        if(vertex.context.networkId === 1 || (vertex.context.networkId > 1 && parentVertex)){
          let colour = tagsVisible ? vertexTagColours[vertex.context?.networkId]?.[vertex.context?.id] : undefined;
          if(!projectMode && heatMapVisible && vertexHeatColours){
            colour = vertexHeatColours[vertex.context?.networkId]?.[vertex.context?.id] ?? null;
          }
          let innerSize = (metricsVisible && vertexInnerSizes) ? 
            vertexInnerSizes[vertex.context?.networkId]?.[vertex.context?.id]?.relativeSize ?? null : 
            undefined;
          let highlighted = highlightedVertexIds.includes(vertex.context.id);
          getVertex(getApp(), vertex, colour, innerSize, selectedVertexId, cursor, alphaSettings[vertex.context.networkId], activeLevel, adjustedFontSizes, viewport, highlighted, unconfiguredChildren, openDrawers, metricsVisible, handleSetOpenDrawers);
        }
      });
      const selectedEdgeId = selectedObjectType === 'edge' ? selectedObject.id : undefined;
      visibleEdges.forEach((edge) => {
        getEdge(
          getApp(),
          edge,
          selectedEdgeId,
          hoveredEdges,
          handleSetHoveredEdges,
          handleEdgeSelection,
          selectedEdgeAncestors
        ); 
      });

      sortChildrenRecursively(getApp().stage);
      getApp().renderer.render(getApp().stage);
    }
  }, [vertices, vertexTagColours, coordinates, edges, interactionMode, adjustedFontSizes, selectedObject, scale, overrideLevelId, resizeTriggered, panCount, highlightedVertexIds, openDrawers, tagsVisible, heatMapVisible, vertexHeatColours, vertexInnerSizes, projectToggled]);
}

function adjustFontSizes(vertices) {
  const fontSizes = new Map(Object.entries(networkFontSizes));
  const vertexMap = new Map(vertices.map(x => [x.context.networkId, x]));

  for (const [key, value] of fontSizes) {
    const networkId = key === 'unconfigured' ? 2 : parseInt(key);
    const vertex = key === 'unconfigured' ? { sizes: [33, 33] } : vertexMap.get(networkId);
    if (vertex) {
      const names = [];
      for (const v of vertices) {
        if (v.context.networkId === networkId) {
          names.push(v.context.name);
        }
      }

      // Find the 3 strings with the longest character count
      const longestStrings = names.sort((a, b) => b.length - a.length).slice(0, 1); //may need to change this to 3

      // Find the 3 strings with the longest words, by character count
      const longestWordStrings = names.sort((a, b) => {
        const maxWordA = Math.max(...a.split(/\s+/).map(word => word.length));
        const maxWordB = Math.max(...b.split(/\s+/).map(word => word.length));
        return maxWordB - maxWordA;
      }).slice(0, 1); //may need to change this to 3

      // Combine both sets of strings to form a sample set
      const sampleStrings = Array.from(new Set([...longestStrings, ...longestWordStrings]));

      // Calculate font size using only the sample set
      fontSizes.set(key, getFontsize(sampleStrings, value, vertex.sizes[0], vertex.sizes[1]));
    }
  }
  
  return Object.fromEntries(fontSizes);
}

function getUnconfiguredChildren(vertex, unconfiguredJobs){
  if(vertex.context.networkId === 1){
    return unconfiguredJobs.filter(x => x.context.parentNodeId === vertex.context.id);
  }
}

function getAncestorEdges(edge, vertices, edges){
  if(edge.networkId === 2){
    return getOrganisationEdges(edge, vertices, edges);
  }
  if(edge.networkId === 3){
    const sourceVertex = vertices.find(x => x.context.id === edge.sourceNodeId);
    const targetVertices = vertices.filter(x => x.context.id === edge.targetNodeId);
    let parentJobs = [];
    targetVertices.forEach(targetVertex => {
      parentJobs.push(edges.filter(x => x.context.sourceNodeId === sourceVertex.context.parentNodeId
                                        && x.context.targetNodeId === targetVertex.context.parentNodeId));
    });
    let parentOrganisations = [];
    parentJobs.forEach(parentJob => {
      // parentJob.forEach(job => {
      //   parentOrganisations.push(getOrganisationEdges(job, vertices, edges));
      // });
    });
    return parentJobs.flat().map(x => x.context.id);
  }
}

function getOrganisationEdges(edge, vertices, edges){
  const sourceVertex = vertices.find(x => x.context.id === edge.sourceNodeId);
  const targetVertex = vertices.find(x => x.context.id === edge.targetNodeId);
  if(sourceVertex.context.parentNodeId !== targetVertex.context.parentNodeId){
    return edges.filter(x => x.context.sourceNodeId === sourceVertex.context.parentNodeId && x.context.targetNodeId === targetVertex.context.parentNodeId).map(x => x.context.id);
  }
}

export default Canvas;