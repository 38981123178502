import { postWrapper, getWrapper } from '../fetchWrapper';

export const createUserGroup = async (userGroup) => await postWrapper('/api/usergroup/createusergroup', userGroup);
export const updateUserGroup = async (userGroup) => await postWrapper('/api/usergroup/updateusergroup', userGroup);

export const deleteUserGroup = async (organisationId, userGroupId) => await getWrapper(`/api/usergroup/deleteusergroup/` + organisationId + '/' + userGroupId);
export const getUserGroups = async (organisationId) => await getWrapper(`/api/usergroup/getusergroups/` + organisationId);
export const getUserGroup = async (userGroupId) => await getWrapper(`/api/usergroup/getusergroup/` + userGroupId);

export const getUserGroupAtlases = async (userGroupId, organisationId) => await getWrapper(`/api/usergroup/getusergroupatlases/` + userGroupId + '/' + organisationId);
export const toggleUserGroupAtlasAccess = async (userGroupId, atlasId, organisationId) => await getWrapper(`/api/usergroup/toggleusergroupatlasaccess/` + userGroupId + '/' + atlasId + '/' + organisationId);

export const getGroupUsers = async (groupId) => await getWrapper(`/api/usergroup/getgroupusers/` + groupId);
export const toggleUserInGroup = async (userId, groupId) => await getWrapper(`/api/usergroup/toggleuseringroup/` + userId + '/' + groupId);

export const getUserGroupSkillSets = async (userGroupId, organisationId) => await getWrapper(`/api/usergroup/getusergroupskillsets/` + userGroupId + '/' + organisationId);
export const toggleUserGroupSkillSet = async (userId, skillSetId, organisationId) => await getWrapper(`/api/usergroup/toggleusergroupskillset/` + userId + '/' + skillSetId + '/' + organisationId);

export const getUserGroupProperties = async (userGroupId, organisationId) => await getWrapper(`/api/usergroup/getusergroupproperties/` + userGroupId + '/' + organisationId);
export const toggleUserGroupProperty = async (userId, propertyId, organisationId) => await getWrapper(`/api/usergroup/toggleusergroupproperty/` + userId + '/' + propertyId + '/' + organisationId);