
// @mui components
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

export const PlatformRoles = () => {
  return(
    <Box>
      <Typography variant="h6">Understanding Platform Roles</Typography>
      <Typography variant="body1">Platform Administrator:</Typography>
        <Typography variant="body2">
          The platform administrator is responsible for managing the users and user access rights of those users. They are also responsible 
          for defining the skillsets and products of your organisation and employees as well as those organisation types which you interact 
          with as well of those which you employ. Organisation and job level skillsets should be defined before creating an atlas.
        </Typography>
      <Typography variant="body1">User:</Typography>
        <Typography variant="body2">
          Users will work almost exclusively within an atlas. The functionality and information available to them will depend on the 
          permissions which have been granted to them by the platform administrator.
        </Typography>
      <Typography variant="body1">Instance Administrator:</Typography>
        <Typography variant="body2">
          Context:
          The instance administrator will initially define the atlas result and map it to the nodes in the atlas. Then They will create
          instances of the result, which will be used to generate projects.
        </Typography>
      <Typography variant="body1">Inventory Administrator:</Typography>
        <Typography variant="body2">
          Context:
          The inventory administrator is in charge of managing the tools, materials and comsumables which the organisation uses to deliver
          the product.
        </Typography>
      <Typography variant="body1">Coordinatoror:</Typography>
        <Typography variant="body2">
          Context:
          Coordinators are responsible for assigning and scheduling the work to be done, as well as managing the progress of the work.
        </Typography>
    </Box>
  )
}

export const PlatformTerminology = () => {
  return(
    <Box>
      <Typography variant="h6">Platform Terminology</Typography>
        <Typography variant="body1">Atlas:</Typography>
          <Typography variant="body2">
            An 'atlas' is a collection of process maps, each of which belongs to a job skillset, which in turn belongs to organisational 
            skillset. The entire atlas should define the process of delivering a product or 'Result'.
          </Typography>
        <Typography variant="body1">Result:</Typography>
          <Typography variant="body2">
            A 'result' is the outcome of an atlas, in generic terms. When the atlas is complete, the 'Result' is the product which is 
            delivered.
            Note: the 'Result' is not necessarily something you would ever expect to deliver, as it will include every possible variation of 
            the product.
          </Typography>
        <Typography variant="body1">Instance:</Typography>
          <Typography variant="body2">
            An 'Instance' is a subset of the 'Result', as per the requirements of the client.
          </Typography>
    </Box>
  )
}

export const AtlasCreation = () => {
  return(
    <Box>
      <Typography variant="h6">Atlas Creation Instructions</Typography>
      <Typography variant="body1">Platform Administrator:</Typography>
        <Typography variant="body2">
          1. Navigate to the "Atlases" tab in the sidenav.
          2. Click the "Create Atlas" button.
          3. Give the atlas a name and description, within the card in the top right.
          4. If the atlas result is available, then select it (you will not be able to access projects, until a result is selected).
          5. Set the atlas access policy.
          6. Click the "SAVE" button.
          7. Add the users, user groups and associates who should have access to the atlas.
        </Typography>
      <Typography variant="body1">User:</Typography>
        <Typography variant="body2">
          Now any user who has been given permission to create vertices in the atlas can do so, from within the atlas.
          Note: A vertex is always added within the current selection. When nothing is selected, an organisational vertex is added.
        </Typography>
    </Box>
  )
}

export const ProcessMapCreation = () => {
  return(
    <Box>
      <Typography variant="body1">Process Map Creation:</Typography>
      <Typography variant="body2">
        1. Go to the second tab in the property panel.
        2. Click the "NODE MANAGER" button (only visible to users with the required permissions).
        3. When no vertex or an organisational vertex is selected a panel will open which has an array of cards, of which the first will
           contain a + icon. Click this icon to open an editor which will allow you to add a skillset which has been defined by the 
           platform administrator.
        4. When a job vertex or process step is selected, the panel will instead contain a canvas, which will enable you to specify a 
           process, as well as its inputs and outputs.
        5. Click the "UPDATE PROCESS" button to add the process to and reconfigure the atlas.
      </Typography>
    </Box>
  )
}

export const ResultVariableMapping = () => {
  return(
    <Box>
      <Typography variant="h6">Result Variable Mapping Instructions</Typography>
      <Typography variant="body1">
        Context:
        Result variable mapping is the process of associating result variables with nodes, in the atlas. This enables Merit to understand
        which parts of the process are involved with delivering which components of the product, for each scope which is relevant to that
        component. This is critical for enabling the platform to generate a project atlas, based on a given instance.
      </Typography>
    </Box>
  )
}

export const ProjectCreation = () => {
  return(
    <Box>
      <Typography variant="h6">Project Creation Instructions</Typography>
      <Typography variant="body1">
        Context:
        Projects are not manually created. Provided that the result has been mapped to the atlas project atlases will be automatically 
        generated, based on the instance of the result which is required.
      </Typography>
    </Box>
  )
}

export const UIGuidelines = () => {
  return(
    <Box>
      <Typography variant="h6">UI Guidelines</Typography>
      <Typography variant="body1">Navigation:</Typography>
        <Typography variant="body2">
          The sidenav is the primary means of navigation. It is divided into two sections: the top section contains the main navigation 
          items, which are always visible, while the bottom section contains the secondary navigation items, which are only visible when 
          the primary navigation item is selected. Blue UI elements give information on the current location.
        </Typography>
      <Typography variant="body1">Property Panel:</Typography>
        <Typography variant="body2">
          The property panel is the primary means of interacting with the platform. It is divided into two sections: the top section 
          contains the primary property controls, while the bottom section contains the secondary property controls.
        </Typography>
    </Box>
  )
}