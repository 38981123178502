import React, { useState } from "react";

// @mui components
import { Tab } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import PersonIcon from '@mui/icons-material/Person';

// Components
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";
import InventoryAdministratorDashboard from "./inventoryadmindashboard";

// Local context
import { useAdminController } from "context";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";

const InventoryAdminLandingPage = () => {
  const [controller, dispatch] = useAdminController();
  const { userEmail } = controller;

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);
  
  return (
    <AdministrationObjectWrapper
      object={{name: userEmail, description: "", media: "", organisationId: null}}
      namePlaceholder="User Name"
      descriptionPlaceholder="Email Address"
      iconComponent={<PersonIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        <AdminTabList onChange={handleTabChange} {...tabIndicator}>
          <Tab label="Overview" value="1"/>
          <Tab label="Requests" value="2"/>
          <Tab label="Suggestions" value="3"/>
        </AdminTabList>
      }
      form={
        <TabPanel 
          value="1"
          style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", height: '100%', flex: 1 }}>
          <InventoryAdministratorDashboard/>
        </TabPanel>
      }
      panels={
        <>
          <TabPanel 
            value="2"
            style={{ padding: 0, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
            <MediaCardArrayContainer items={[]}/>
          </TabPanel>
          <TabPanel 
            value="3"
            style={{ padding: 0, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
            <MediaCardArrayContainer items={[]}/>
          </TabPanel>
        </>
      }
      getByUserId
      restrictable={false}
      scrollable={false}
    />
  );
}

export default InventoryAdminLandingPage;