import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db context
import { getUsers, deleteUser } from "apiservices/AuthorisationServices/userAdminService";

// Local context
import { useAdminController } from "context";

const createButton = []; //[{media: ["add"], name: "Create Expected User", description: "", id: 0}]

const initialFilterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Users = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [filterAttributes, setFilterAttributes] = useState(initialFilterAttributes);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  
  useEffect(() => {
    const _getUsers = async () => {
      let _users = await getUsers(organisationId);
      setUsers(_users.items);
      setFilteredUsers(_users.items);
      
      // Create filter attributes dynamically
      const newFilterAttributes = [...initialFilterAttributes];
      Object.keys(_users.categories).forEach(category => {
        newFilterAttributes.push({
          property: category,
          name: category,
          type: 'categorical',
          icon: 'country',
          options: _users.categories[category].map(category => {
            return { id: category.id, name: category.name };
          })
        });
      });
      setFilterAttributes(newFilterAttributes);
    };

    _getUsers();
    
    // Add a cleanup function to dispose of the view
    return () => {
      setUsers([]);
      setFilteredUsers([]);
      setFilterAttributes(initialFilterAttributes);
    };
  }, [organisationId]);

  const handleDelete = async (e, userId, onDeleteComplete) => {
    let _users = await deleteUser(userId);
    setUsers(_users.items);
    setFilteredUsers(_users.items);
    onDeleteComplete();
  }

  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={createButton.concat(filteredUsers)} path={"/users/user"} deleteAction={handleDelete}>
        <FinderBar
          objs={users}
          setFilteredObjs={setFilteredUsers}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default Users;