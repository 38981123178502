import React, { useState, useEffect } from "react";

// @mui components
import { Tab, Checkbox, FormControlLabel, FormGroup, Typography } from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";

// @mui icons
import BusinessIcon from '@mui/icons-material/Business';

// Components
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import AddressForm from "components/AdministrationComponents/AddressForm";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { updateOrganisation, getOrganisation, getOrganisationSkillSets, toggleOrganisationHasSkillSet } from "apiservices/AuthorisationServices/organisationService";

import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";


const OrganisationProfile = () => {
  const [organisation, setOrganisation] = useState({});
  const [organisationSkillSets, setOrganisationSkillSets] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    if(!organisation?.id) return;
    const getOrg = async () => {
      let products = await getOrganisationSkillSets(organisation.id);
      setOrganisationSkillSets(products);
    };
    getOrg();
  }, [organisation]);
  
  const handleToggleOrganisationHasSkillSet = async (e, id) => {
    let _skillSets = await toggleOrganisationHasSkillSet(id, organisation.id);
    setOrganisationSkillSets(_skillSets);
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);

  return (
    <AdministrationObjectWrapper
      object={organisation} 
      setObject={setOrganisation}
      getObject={getOrganisation}
      updateObject={updateOrganisation}
      namePlaceholder="Organisation Name"
      descriptionPlaceholder="Organisation Description"
      iconComponent={<BusinessIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        organisation &&
          <TabContext value={activeTab}>
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Details" value="1"/>
              {/* <Tab label="Accounts" value="2"/> */}
              <Tab label="Skill Sets" value="3"/>
            </AdminTabList>
          </TabContext>
      }
      form={
        organisation &&
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <FormGroup>
                <Typography variant="h6" component="div" mb={2} textAlign="left">
                  Merit ID: {generateMeritID(organisation)}
                </Typography>
                <FormCollapse title="Address">
                  {
                    organisation &&
                      <AddressForm occupant={organisation} setOccupant={setOrganisation}/>
                  }
                </FormCollapse>
                <FormCollapse title="Industry Classifications">
                  {/* <FormControlLabel control={<Checkbox/>} label="SIC Codes" /> */}
                </FormCollapse>
              </FormGroup>
          </TabPanel>
      }
      panels={
        <>
          {/* <TabPanel 
            value="2"
            style={{ padding: 0, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox/>} label="Total number of accounts" />
              <FormControlLabel control={<Checkbox/>} label="Number of unused accounts" />
              <FormControlLabel control={<Checkbox/>} label="Purchase more accounts" />
            </FormGroup>
          </TabPanel> */}
          <TabPanel 
            value="3"
            style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
            <SplitMediaCardArray
              items={organisationSkillSets} 
              titles={["Skill Sets Your Organisation Offers", "Skill Sets not Offered by Your Organisation"]}
              action={handleToggleOrganisationHasSkillSet}
              searchboxLabel="Search skill sets"/>
          </TabPanel>
        </>
      }
      validate={validate}
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}
      getByUserId
    />
  );
}

function generateMeritID(organisation) {
  if (organisation && organisation.id !== undefined) {
      const idString = String(organisation.id);
      const zerosNeeded = 10 - ('MID'.length + idString.length);
      const zeros = '0'.repeat(Math.max(0, zerosNeeded));
      const meritID = `MID${zeros}${idString}`;
      return meritID;
  }
  return "";
}

export default OrganisationProfile;