import { postWrapper, getWrapper } from '../fetchWrapper';

export const getUserAllocations = async (organisationId, inventoryItemId) => await getWrapper(`/api/allocation/getuserallocations/` + organisationId + '/' + inventoryItemId);
export const toggleUserAllocation = async (organisationId, inventoryItemId, userId) => await getWrapper(`/api/allocation/toggleuserallocation/` + organisationId + '/' + inventoryItemId + '/' + userId);
export const getGroupAllocations = async (organisationId, inventoryItemId) => await getWrapper(`/api/allocation/getgroupallocations/` + organisationId + '/' + inventoryItemId);
export const toggleGroupAllocation = async (organisationId, inventoryItemId, groupId) => await getWrapper(`/api/allocation/togglegroupallocation/` + organisationId + '/' + inventoryItemId + '/' + groupId);

export const getSkillSetToolkit = async (skillSetId) => await getWrapper(`/api/allocation/getskillsettoolkit/` + skillSetId);
export const getUserToolkit = async (userId) => await getWrapper(`/api/allocation/getusertoolkit/` + userId);

export const getToolInventory = async (toolTypeId, organisationId) => await getWrapper(`/api/allocation/gettoolinventory/` + toolTypeId + '/' + organisationId);