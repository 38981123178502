import React from 'react';
import { AppBar, Box, Toolbar, Button, IconButton, useMediaQuery, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) section.scrollIntoView({ behavior: 'smooth' });
};

const signingStyle = {
  fontSize: '1.4rem',
  whiteSpace: 'nowrap',
  color: "white !important",
  background: 'linear-gradient(30deg, #2196F3 30%, #21CBF3 90%)',
  px: "1px",
  py: "1px",
  fontWeight: '500'
};

const navStyles = {
  fontSize: '1.4rem',
  color: '#888888 !important',
  fontWeight: '400'
};

export const Navigation = () => {
  const isMobile = useMediaQuery('(max-width:880px)');
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (sectionId) => {
    scrollToSection(sectionId);
    requestAnimationFrame(handleMenuClose);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ backgroundColor: 'white !important' }}>
        <Toolbar
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            minHeight: 64
          }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              ml: isMobile ? 0.5 : 3
            }}>
            <Button
              sx={{
                fontSize: '1.8rem',
                color: '#000000 !important',
                fontWeight: '600',
                alignItems: 'center'
              }}
              onClick={() => scrollToSection('head')}>
              Merit
            </Button>
            {isMobile && (
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={handleMenuClick}
                sx={{ alignItems: 'center', mb: 0.3 }}>
                <MenuIcon />
              </IconButton>
            )}
            {!isMobile && (
              <Box sx={{ display: 'flex', alignItems: 'center', ml: 3 }}>
                <Button sx={{ ...navStyles }} onClick={() => scrollToSection('concepts')}>Concepts</Button>
                <Button sx={{ ...navStyles }} onClick={() => scrollToSection('services')}>Capabilities</Button>
                <Button sx={{ ...navStyles }} onClick={() => scrollToSection('industries')}>Organisations & Industries</Button>
                <Button sx={{ ...navStyles }} onClick={() => scrollToSection('individuals')}>Roles & Individuals</Button>
                <Button sx={{ ...navStyles }} onClick={() => scrollToSection('contact')}>Contact</Button>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              mr: isMobile ? 1 : -1
            }}>
            <Button sx={{ ...signingStyle, mb: 0.3 }} href="/bff/login">Sign In</Button>
            {/* <Button sx={{ ...signingStyle }} href="https://localhost:5443/account/register/register?returnurl=https://localhost:44463">Sign Up</Button> */}
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            sx={{ mt: 4 }}>
            <MenuItem 
              onClick={() => handleMenuItemClick('concepts')}
              sx={{ fontSize: '1.55rem' }}>
                Concepts
            </MenuItem>
            <MenuItem 
              onClick={() => handleMenuItemClick('services')}
              sx={{ fontSize: '1.55rem' }}>
                Capabilities
            </MenuItem>
            <MenuItem 
              onClick={() => handleMenuItemClick('industries')}
              sx={{ fontSize: '1.55rem' }}>
                Organisations & Industries
            </MenuItem>
            <MenuItem 
              onClick={() => handleMenuItemClick('individuals')}
              sx={{ fontSize: '1.55rem' }}>
                Roles & Individuals
            </MenuItem>
            <MenuItem 
              onClick={() => handleMenuItemClick('contact')}
              sx={{ fontSize: '1.55rem' }}>
                Contact
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar/>
    </Box>
  );
};
