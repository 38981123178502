import React, { useState, useEffect } from "react";

// @mui components
import { TextField, Tab, Tooltip, FormGroup, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import SellIcon from '@mui/icons-material/Sell';

// Components
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import { WrapperModal } from "components/GlobalComponents/Modals";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, AdminFormLabel, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createDescriptor, updateDescriptor, getDescriptor, createDescriptorTag, updateDescriptorTag, deleteDescriptorTag } from "apiservices/ResultServices/descriptorService";

let createButton = [{icon: "add", name: "Add Tag to Group", id: 0}]

const DescriptorTagGroupView = () => {
  const [descriptor, setDescriptor] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };
  
  const [tags, setTags] = useState([]);
  useEffect(() => {
    if (!descriptor) return;
    const getTagGroup = async () => {
      setTags(descriptor.attributes);
    };
    getTagGroup();
  }, [descriptor]);

  const handleSetDescriptor = (key, e) => setDescriptor({...descriptor, ...{[key]: e.target.checked}});

  const handleDeleteTag = async (e, tagId, onDeleteComplete) => {
    let _tags = await deleteDescriptorTag(tagId);
    setTags(_tags);
    onDeleteComplete();
  }

  const [activeTag, setActiveTag] = useState(-1);
  const handleSetActiveTag = (e, id) => setActiveTag(id);

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(descriptor.id !== 0)
      setActiveTab(tabNumber);
  };

  return (
    <>
      <AdministrationObjectWrapper
        object={descriptor}
        setObject={setDescriptor}
        getObject={getDescriptor}
        createObject={createDescriptor}
        updateObject={updateDescriptor}
        namePlaceholder="Group Name"
        descriptionPlaceholder="Description of Group"
        iconComponent={<SellIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          descriptor &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Tag Group Details" value="1"/>
              {
                descriptor.id !== 0 ?
                  <Tab label="Tags" value="2"/> :
                  <Tooltip title="Save the tag group to begin adding tags">
                    <Tab label="Tags" value="2"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          descriptor &&
            <TabPanel
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <FormGroup>
                <FormControl error={Boolean(invalidFields.applicationAtlasTypes)}>
                  <FormControlLabel
                    control={<Checkbox
                      checked={descriptor.allowManyTags}
                      onChange={(e) => { handleSetDescriptor("allowManyTags", e) }}/>}
                    name="project"
                    label="Allow many tags"/>
                </FormControl>
              </FormGroup>
            </TabPanel>
        }
        panels={
          descriptor?.id !== 0 &&
            <TabPanel 
              value="2"
              style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
              <CreateAndEditInPlaceArray
                items={createButton.concat(tags)}
                editor={handleSetActiveTag}
                deleteAction={handleDeleteTag}/>
            </TabPanel>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {
        activeTag !== -1 &&
          <TagEditor 
            closeEditor={handleSetActiveTag} 
            tags={tags}
            setTags={setTags}
            tagId={activeTag}
            descriptorId={descriptor.id}/>
      }
    </>
  );
}

function TagEditor({closeEditor, tags, setTags, tagId, descriptorId}) {
  const [tag, setTag] = useState({ id: 0, name: '' });

  // Effect to initialize the tag state when editing
  useEffect(() => {
    if (tagId >= 0) {
      const existingTag = tags.find(t => t.id === tagId);
      if (existingTag) {
        setTag({ ...existingTag });
      }
    } else {
      // Resetting tag for adding new tag
      setTag({ id: 0, name: '' }); //better to simply load the tag, from the back end, when the editor is opened
    }
  }, [tagId, tags]);

  const handleChangeName = (e) => setTag({ ...tag, name: e.target.value });
  
  const saveTag = async (e, tag) => {
    tag.descriptorId = descriptorId;
    let result = tag.id === 0 ? 
      await createDescriptorTag(tag) :
      await updateDescriptorTag(tag);
    setTags(result);
    closeEditor(e, -1);
  }

  // Submit event handler
  const handlePrimaryEvent = (e) => {
    if (tagId >= 0) {
      // Edit existing tag
      const updatedTags = tags.map(t => t.id === tagId ? { ...tag } : t);
      setTags(updatedTags);
    } else {
      // Add new tag
      setTags([...tags, { ...tag }]);
    }
    saveTag(e, tag);
    closeEditor(e, -1);
  };

  return(
    <WrapperModal
      open={true}
      handleClose={(e) => closeEditor(e, -1)}
      title="Create New Tag"
      primaryButtonText={tag.id === 0 ? "Add Tag" : "Update Tag"}
      primaryEvent={handlePrimaryEvent}>
      <TextField label="Tag name" value={tag.name} variant="outlined" sx={{ mt: 2 }} onChange={handleChangeName}/>
    </WrapperModal>
  )
}

export default DescriptorTagGroupView;