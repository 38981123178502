import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getClients, deleteClient } from "apiservices/ResultServices/clientService";

// Local context
import { useAdminController } from "context";

let createButton = [{media: ["add"], name: "Create New Client", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Clients = () => {
  const [controller, dispatch] = useAdminController();
  const { userEmail, organisationId } = controller;

  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  useEffect(() => {
    const _getClients = async () => {
      let _clients = await getClients(organisationId);
      setClients(_clients);
    };
    _getClients();
  }, []);

  const handleDeleteClient = async (e, clientId, onDeleteComplete) => {
    let _projects = await deleteClient(clientId);
    setClients(_projects);
    onDeleteComplete();
  }
  
  return (
    clients &&
      <AdministrationViewWrapper>
        <MediaCardArrayContainer items={createButton.concat(filteredClients)} path={"/clients/client"} deleteAction={handleDeleteClient}>
          <FinderBar
            objs={clients}
            setFilteredObjs={setFilteredClients}
            filterAttributes={filterAttributes}/>
        </MediaCardArrayContainer>
      </AdministrationViewWrapper>
  );
}

export default Clients;