import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip, Typography } from "@mui/material";

// @mui icons
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

// Canvas context
import { useCanvasController, setActiveProjectId, setActiveAssignmentId, setUserAssignmentNodes, setScale,
  getApp } from "context/canvasContext";
import { useAdminController } from "context/index";

// Helpers
import { centerVerticesAndScaleToFit } from "helpers/canvashelpers/canvasfunctions";

// DB context
import { getUserProjects, getActiveProjectAssignments, getUserAssignment } from "apiservices/AtlasServices/executionService";

const ExecutorProjectPanel = ({}) => {
  const [controller, dispatch] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { userId } = controller;
  const { activeProjectId, activeAssignmentId, userAssignmentNodes, vertices } = canvasController;

  const [userProjects, setUserProjects] = useState(undefined);
  useEffect(() => {
    const _getUserProjects = async () => {
      let _userProjects = await getUserProjects(userId);
      setUserProjects(_userProjects);
    }
    _getUserProjects();
  }, []);

  const [activeProjectAssignments, setActiveProjectAssignments] = useState();
  useEffect(() => {
    if (!activeProjectId) return;
    const _getActiveProjectAssignments = async () => {
      let _activeProjectAssignments = await getActiveProjectAssignments(userId, activeProjectId);
      setActiveAssignmentId(canvasDispatch, Object.keys(_activeProjectAssignments)[0]);
      setActiveProjectAssignments(_activeProjectAssignments);
    }
    _getActiveProjectAssignments();
  }, [activeProjectId]);

  useEffect(() => {
    if (!activeAssignmentId) return;
    const _getCurrentExecutable = async () => {
      let _userAssignment = await getUserAssignment(activeAssignmentId);
      setUserAssignmentNodes(canvasDispatch, _userAssignment.executables);
    }
    _getCurrentExecutable();
  }, [activeAssignmentId]);

  useEffect(() => {
    handleCenterAtlasOnAssignment()
  }, [userAssignmentNodes]);

  const handleSetActiveProject = (e) => setActiveProjectId(canvasDispatch, e.target.value);
  const handleSetActiveAssignment = (e) => setActiveAssignmentId(canvasDispatch, e.target.value);

  const handleCenterAtlasOnAssignment = () => {
    if(!userAssignmentNodes) return;
    let jobNodes = userAssignmentNodes.filter(x => x.networkId === 2);
    let jobVertices = vertices.filter(v => jobNodes.map(x => x.nodeId).includes(v.context.id));
    centerVerticesAndScaleToFit(getApp(), jobVertices, 1.5, 1.5);
    setScale(canvasDispatch, getApp().stage.scale.x);
  }

  return(
    userProjects &&
      <Box mt={2} sx={{ minHeight: 80 }}>
        <FormControl fullWidth>
          <InputLabel htmlFor="project-select">Active Project</InputLabel>
          <Select
            sx={{ height: "40px"}}
            id="project-select"
            value={Object.keys(userProjects).includes(String(activeProjectId)) ? activeProjectId : ""}
            label="Active Project"
            onChange={e => handleSetActiveProject(e)}>
            {
              Object.entries(userProjects).map(([id, projectName]) => {
                return (
                  <MenuItem value={id} key={id}>{projectName}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        {
          activeProjectAssignments && Object.keys(activeProjectAssignments).length > 1 ?
          <Box display="flex" width="100%" sx={{ mt: 1 }}>
          <IconButton 
            size="small" 
            onClick={handleCenterAtlasOnAssignment} 
            sx={{ flexShrink: 0 }}>
            <CenterFocusStrongIcon />
          </IconButton>
          <FormControl fullWidth sx={{ flexGrow: 1 }}>
            <InputLabel htmlFor="assignment-select">Active Assignment</InputLabel>
            <Select
              sx={{ height: "40px" }}
              id="assignment-select"
              value={activeAssignmentId}
              label="Active Assignment"
              onChange={e => {
                handleSetActiveAssignment(e);
                handleCenterAtlasOnAssignment();
              }}
            >
              {
                Object.entries(activeProjectAssignments).map(([id, name]) => (
                  <MenuItem value={id} key={id}>{name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
        </Box> :
            <Box textAlign='center' py={1}>
            {
              activeProjectAssignments &&
                <>
                  <IconButton onClick={handleCenterAtlasOnAssignment} sx={{ p: 0, position: 'absolute', left: 6 }}>
                    <Tooltip title="Center atlas on assignment" placement="bottom" arrow={false}>
                      <CenterFocusStrongIcon/>
                    </Tooltip>
                  </IconButton>
                  <Typography variant="body2" noWrap>{activeProjectAssignments[activeAssignmentId]}</Typography>
                </>
            }
            </Box>
        }
      </Box>
  )
}

export default ExecutorProjectPanel;