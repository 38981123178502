import React, { useState } from 'react';
import seedrandom from 'seedrandom';

// @mui components
import { Card, Box } from '@mui/material';

// recharts components
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

// Components
import { StatCard, ObjectStatusCardArray, commonCardStyle, outerBoxStyle, chartMargins, DashboardFilter } from 'components/AdministrationComponents/DashboardComponents';
import { AtlasCreationInstructions } from 'components/AdministrationComponents/Instructions';

const categories = [
  { name: 'Complete', color: 'green' },
  { name: 'Late', color: 'red' },
  { name: 'On Target', color: 'black' },
];

const PlatformAdministratorDashboard = () => {
  const generateMockData = () => {
    const rng = seedrandom('fixed-seed');
    const dataLength = 7;
    const data = Array.from({ length: dataLength }, (_, index) => {
      const date = new Date();
      date.setDate(date.getDate() - index);
      return {
        name: date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }),
        logins: Math.floor(rng() * 150) + 50,
        timeSpent: Math.floor(rng() * 60) + 15,
      };
    });
    return data;
  };

  const mockData = generateMockData();

  const userCount = 1211;
  const otherCount = 902;
  
  const [selectedStatCard, setSelectedStatCard] = useState('');

  const handleStatCardClick = (title) => {
      setSelectedStatCard(title === selectedStatCard ? '' : title);
  };

  // Generate more mock data for the new component
  const generateMockDataForNewComponent = () => {
    const data = Array.from({ length: 10 }, (_, index) => {
      const randomNum = Math.random();
      let category;
      if (randomNum < 0.6) {
        category = 'On Target';
      } else if (randomNum < 0.9) {
        category = 'Complete';
      } else {
        category = 'Late';
      }
  
      return {
        id: index,
        value: Math.floor(Math.random() * 100) + 20,
        label: `Label ${index + 1}`,
        category,
      };
    });
  
    // Sort the data using the index of the category in the categories array
    return data.sort((a, b) => {
      const categoryAIndex = categories.findIndex(cat => cat.name === a.category);
      const categoryBIndex = categories.findIndex(cat => cat.name === b.category);
      return categoryAIndex - categoryBIndex;
    });
  };

  const mockDataForNewComponent = generateMockDataForNewComponent();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <DashboardFilter />
      <Box sx={outerBoxStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <StatCard
              title="User Count"
              count={userCount}
              diff={-4}
              selectedStatCard={selectedStatCard}
              setSelectedStatCard={setSelectedStatCard}
              onClick={() => handleStatCardClick('User Count')}/>
            <StatCard
              title="Associate Count"
              count={otherCount}
              diff={2}
              selectedStatCard={selectedStatCard}
              setSelectedStatCard={setSelectedStatCard}
              onClick={() => handleStatCardClick('Associate Count')}/>
          </Box>

          {/* Chart Card */}
          <Card elevation={2} sx={commonCardStyle}>
            <ResponsiveContainer height="99%">
              <LineChart data={mockData} margin={chartMargins}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={30} dy={30} dx={46} style={{ fontSize: '10px' }} textAnchor="end"/>
                <YAxis
                  yAxisId="left"
                  label={{
                    value: 'Number of Logins',
                    angle: -90,
                    dx: -2,
                    dy: 10,
                    style: { fontSize: 17, fontWeight: 'bold' }
                  }}
                  tick={{ fontSize: 8 }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  label={{
                    value: 'Time Spent (minutes)',
                    angle: 90,
                    dx: 2,
                    dy: 10,
                    style: { fontSize: 17, fontWeight: 'bold' }
                  }}
                  tick={{ fontSize: 8 }}
                />
                <Tooltip />
                <Legend wrapperStyle={{ bottom: 51, left: 58 }} align="left"/>
                <Line type="monotone" dataKey="logins" name="Logins" yAxisId="left" stroke="#8884d8" />
                <Line type="monotone" dataKey="timeSpent" name="Time Spent" yAxisId="right" stroke="#82ca9d" />
                <ReferenceLine y={0} stroke="#000" yAxisId="left" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Box>
        <ObjectStatusCardArray data={mockDataForNewComponent} keyColours={categories}></ObjectStatusCardArray>
      </Box>
    </Box>
  );
};

export default PlatformAdministratorDashboard;