import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getScopes, deleteScope } from "apiservices/ResultServices/scopeService";

// Local context
import { useAdminController } from "context";

let createButton = [{media: ["add"], name: "Create New Scope", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Scopes = () => {
  const [controller, dispatch] = useAdminController();
  const { userEmail, organisationId } = controller;

  const [scopes, setScopes] = useState([]);
  const [filteredScopes, setFilteredScopes] = useState([]);
  useEffect(() => {
    const _getScopes = async () => {
      let _results = await getScopes(organisationId);
      setScopes(_results);
    };
    _getScopes();
  }, []);

  console.log(scopes);

  const handleDeleteResult = async (e, scopeId, onDeleteComplete) => {
    let _scopes = await deleteScope(scopeId);
    setScopes(_scopes);
    onDeleteComplete();
  }
  
  return (
    scopes &&
      <AdministrationViewWrapper>
        <MediaCardArrayContainer items={createButton.concat(filteredScopes)} path={"/scopes/scope"}  deleteAction={handleDeleteResult}>
          <FinderBar
            objs={scopes}
            setFilteredObjs={setFilteredScopes}
            filterAttributes={filterAttributes}/>
        </MediaCardArrayContainer>
      </AdministrationViewWrapper>
  );
}

export default Scopes;