import React, { useEffect, useState } from "react";

// @mui components
import { FormGroup, FormControl, FormControlLabel, Checkbox, RadioGroup, Radio } from "@mui/material";

// @mui icons
import StraightenIcon from '@mui/icons-material/Straighten';

// Components
import { NetworkPicker, AdministrationHeaderWhereNoTabPanelIsUsed } from "components/AdministrationComponents/General";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminFormLabel } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createProperty, updateProperty, getProperty } from "apiservices/AuthorisationServices/propertyService";

const MetricView = () => {
  const [property, setProperty] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    // Add more validation rules specific to MetricView
    if (!object.networks || !Object.values(object.networks).some((value) => value)) {
      invalidFields.networks = 'At least one network must be selected';
    }
    
    // add more validation rules as needed
    return invalidFields;
  };
  
  const [filteredNetworks, setFilteredNetworks] = useState([]);
  useEffect(() => {
    if (property) {
      if (property.isUserApplicable) {
        setFilteredNetworks(Object.fromEntries(
          Object.entries(property.networks).filter(([key]) => key === '1' || key === '2')
        ));
      } else {
        setFilteredNetworks(property.networks);
      }
    }
  }, [property]);

  const handleSetAtlasScope = (descriptor, e) => {
    setProperty({...property, ...{
      [descriptor]: e.target.checked,
      'isUserApplicable': false
    }});
  };

  const handleSetAsAdminMetric = (e) => {
    if (e.target.value === 'admin') {
      setProperty(prevProperty => ({
        ...prevProperty,
        isPrimaryApplicable: false,
        isProjectApplicable: false,
        isUserApplicable: true
      }));
    } else {
      setProperty(prevProperty => ({
        ...prevProperty,
        isUserApplicable: false
      }));
    }
  };

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if(property?.organisationId === null)
      setDisabled(true);
  }, [property]);

  return (
    <AdministrationObjectWrapper
      object={property}
      setObject={setProperty}
        getObject={getProperty}
        createObject={createProperty}
        updateObject={updateProperty}
        namePlaceholder="Metric Name"
        descriptionPlaceholder="Description of Metric"
        iconComponent={<StraightenIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab="1"
        header={<AdministrationHeaderWhereNoTabPanelIsUsed/>}
        form={
          property &&
            <>
              <FormGroup>
                <FormControl error={Boolean(invalidFields.applicationAtlasTypes)}>
                  <AdminFormLabel error={invalidFields.applicationAtlasTypes}>Application scopes:</AdminFormLabel>
                  <RadioGroup
                    value={property.isUserApplicable ? 'admin' : 'atlas'}
                    onChange={(e) => handleSetAsAdminMetric(e)}>
                    <FormControlLabel
                      value="admin"
                      control={<Radio
                        disabled={disabled}/>}
                      label="Administration"/>
                    <FormControlLabel
                      value="atlas"
                      control={<Radio
                        disabled={disabled}/>}
                      label="Atlas"/>
                  </RadioGroup>
                  {
                    (!property.isUserApplicable) &&
                      <>
                        <FormControlLabel
                          sx={{ ml: 1 }}
                          control={<Checkbox 
                            checked={property.isPrimaryApplicable} 
                            onChange={(e) => { handleSetAtlasScope("isPrimaryApplicable", e); 
                                              if (e.target.checked) setInvalidFields({ ...invalidFields, applicationAtlasTypes: null }) 
                                              }
                                      }
                            disabled={disabled}/>
                          }
                          name="primary"
                          label="Primary Atlas"/>
                        <FormControlLabel
                          sx={{ ml: 1 }}
                          control={<Checkbox 
                            checked={property.isProjectApplicable} 
                            onChange={(e) => { handleSetAtlasScope("isProjectApplicable", e); 
                                              if (e.target.checked) setInvalidFields({ ...invalidFields, applicationAtlasTypes: null }) 
                                              }
                                      }
                            disabled={disabled}/>
                          }
                          name="project"
                          label="Project Atlas (The metric may only be APPLIED within projects. The metric will still be available to view within the main atlas)"/>
                      </>
                  }
                </FormControl>
              </FormGroup>
              <NetworkPicker
                networks={filteredNetworks}
                setNetworks={setProperty}
                error={invalidFields.networks}
                setError={(error) => setInvalidFields({ ...invalidFields, networks: error })}
                disabled={disabled}/>
            </>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
        requiresOrgId/>
  );
}

export default MetricView;