// @mui components
import { Box } from "@mui/material";
import { TextField } from '@mui/material';

// Components
import HorizontalCardWrapper from "./HorizontalCardWrapper";

const HorizontalNewItemMediaCard = ({  obj, setObj, color, icon, invalidFields, setInvalidFields, validate, nameField="name", descriptionField="description", namePlaceholder, descriptionPlaceholder }) => { 
  const handleInputChange = (e) => {
    setObj({ ...obj, [e.target.id]: e.target.value });

    if (invalidFields[e.target.id] !== undefined) {
      const invalidFields = validate(obj);
      setInvalidFields({ ...invalidFields });
    }
  };

  return ( 
    <HorizontalCardWrapper obj={obj} setObj={setObj} colour={color} icon={icon} nameError={invalidFields[nameField]} descriptionError={invalidFields[descriptionField]}>
      <Box display="flex" flexDirection="column" sx={{ height: 1 }}>
        <TextField
          id={nameField}
          placeholder={namePlaceholder}
          variant="outlined"
          error={invalidFields[nameField] !== undefined}
          size="small"
          fullWidth
          label={namePlaceholder}
          onChange={handleInputChange}
          required
          sx={{ mb: 1 }}
        />
        <TextField
          id={descriptionField}
          placeholder={descriptionPlaceholder}
          variant="outlined"
          error={invalidFields[descriptionField] !== undefined}
          size="small"
          fullWidth
          label={descriptionPlaceholder}
          onChange={handleInputChange}
          multiline
          minRows={3}
          sx={{
            flexGrow: 1,
            maxHeight: '64px',
            '& .MuiInputBase-root': {
              height: '100%',
            }
          }}
        /> 
      </Box> 
    </HorizontalCardWrapper> 
  ); 
}

export default HorizontalNewItemMediaCard;