import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { Select, MenuItem } from "@mui/material";

// Components
import { WrapperModal } from "components/GlobalComponents/Modals";

// Db Context
import { getAppliedAtlasAccessPolicy, updateAppliedAtlasAccessPolicy } from "apiservices/AuthorisationServices/accessPolicyService";

const PermissionsAdjustmentPanel = ({ organisationId, atlasId, entityId, setOpen, userType = "user" }) => {
  const [policies, setPolicies] = useState([]);
  const [appliedPolicies, setAppliedPolicies] = useState(userType === "user" ? [] : '');

  useEffect(() => {
    if (atlasId === 0) return;
    const _getPolicies = async () => {
      const _policies = await getAppliedAtlasAccessPolicy(organisationId, entityId, userType, atlasId);
      setPolicies(_policies);

      const _appliedPolicies = _policies.filter(policy => policy.isIncluded).map(policy => policy.id);
      setAppliedPolicies(userType === "user" ? _appliedPolicies : _appliedPolicies[0] || '');
    };
    _getPolicies();
  }, [atlasId, organisationId, entityId, userType]);

  const handlePolicyChange = async (event) => {
    const _policies = await updateAppliedAtlasAccessPolicy(organisationId, entityId, userType, atlasId, event.target.value);
    setPolicies(_policies);

    const _appliedPolicies = _policies.filter(policy => policy.isIncluded).map(policy => policy.id);
    setAppliedPolicies(userType === "user" ? _appliedPolicies : _appliedPolicies[0] || '');
  };

  return (
    <WrapperModal
      open={atlasId !== 0}
      title="Permissions Templates"
      handleClose={(e) => setOpen(e, 0)}>
      <Box display="flex" flexDirection="column" flexGrow={1} sx={{ mt: 1 }}>
        <Select
          value={appliedPolicies}
          onChange={handlePolicyChange}
          multiple={userType === "user"}
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#000',
            },
            height: '40px'
          }}
        >
          {
            policies?.map(policy => (
              <MenuItem key={policy.id} value={policy.id}>
                {policy.name}
              </MenuItem>
            ))
          }
        </Select>
      </Box>
    </WrapperModal>
  );
};

export default PermissionsAdjustmentPanel;
