import React, { useState, useEffect } from "react";

// @mui components
import { Tab, Tooltip, TextField } from "@mui/material";
import { TabPanel } from "@mui/lab";

// Icons
import FolderIcon from '@mui/icons-material/Folder';

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import { WrapperModal } from "components/GlobalComponents/Modals";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db Context
import { createClient, updateClient, getClient } from "apiservices/ResultServices/clientService";
import { createContract, updateContract, deleteContract, getContracts, getContract } from "apiservices/ResultServices/contractService";

let createButton = [{icon: "add", name: "Add Contract", id: 0}]

const Project = () => {
  const [client, setClient] = useState();
  const [invalidFields, setInvalidFields] = useState({});
  const [contracts, setContracts] = useState([]);

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    if (!client) return;
    const _getContracts = async () => {
      let _contracts = await getContracts(client.id);
      setContracts(_contracts);
    };
    _getContracts();
  }, [client]);

  const handleDeleteContract = async (e, contractId) => {
    let _contracts = await deleteContract(contractId);
    setContracts(_contracts);
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(client.id === 0) return;
    setActiveTab(tabNumber);
  };

  const [activeContract, setActiveContract] = useState(-1);
  const handleSetActiveContract = (e, id) => setActiveContract(id);
  
  return(
    <>
      <AdministrationObjectWrapper
        object={client}
        setObject={setClient}
        getObject={getClient}
        createObject={createClient}
        updateObject={updateClient}
        namePlaceholder="Client Name"
        descriptionPlaceholder="Description of Client"
        iconComponent={<FolderIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          client &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Details" value="1"/>
              {
                client?.id !== 0 ?
                  <Tab label="Contracts" value="2"/> :
                  <Tooltip title="Save the client to begin adding contracts">
                    <Tab label="Contracts" value="2"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          client &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            </TabPanel>
        }
        panels={
          client?.id !== 0 &&
            <TabPanel 
              value="2"
              style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
              <CreateAndEditInPlaceArray
                items={createButton.concat(contracts)}
                editor={handleSetActiveContract}
                deleteAction={handleDeleteContract}/>
            </TabPanel>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {
        activeContract !== -1 &&
          <ContractEditor
            closeEditor={handleSetActiveContract}
            setContracts={setContracts}
            contractId={activeContract}
            clientId={client.id}
          />
      }
    </>
  )
}

function ContractEditor({closeEditor, setContracts, contractId, clientId}) {
  const [contract, setContract] = useState();

  useEffect(() => {
    const _getContract = async () => {
      let _contract = await getContract(contractId);
      setContract(_contract);
    };
    _getContract();
  }, [contractId]);

  const handleChangeName = (e) => setContract({ ...contract, name: e.target.value });

  // Submit event handler
  const saveContract = (e) => {
    contract.clientId = clientId;
    let contracts = contract.id === 0 ? 
      createContract(contract) : 
      updateContract(contract);
    setContracts(contracts);
    closeEditor(e, -1);
  };

  return(
    contract &&
      <WrapperModal
        open={true}
        handleClose={(e) => closeEditor(e, -1)}
        title="Create New Contract"
        primaryButtonText={contract.id === 0 ? "Add Contract" : "Update Contract"}
        primaryEvent={saveContract}>
        <TextField label="Contract name" value={contract.name} variant="outlined" sx={{ mt: 2 }} onChange={handleChangeName}/>
      </WrapperModal>
  )
}

export default Project;