import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { TextField, Typography } from "@mui/material";

// Components
import { EditorModal } from "components/GlobalComponents/Modals";

// Local context
import { useCanvasController, setMetricTables } from "context/canvasContext";

// DB context
import { getAssignmentNodeMetrics, applyAssignmentMetricToNode } from "apiservices/AtlasServices/metricService";

const ExecutionMetricSheet = ({closeWindow, metric}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { selectedObject, activeAssignmentId } = canvasController;

  const [existingMetrics, setExistingMetrics] = useState([]);
  const [editingMetric, setEditingMetric] = useState({ id: 0, value: 0, date: '0' });

  useEffect(() => {
    const _getExistingMetrics = async () => {
      let _existingMetrics = await getAssignmentNodeMetrics(activeAssignmentId, metric.metricId, selectedObject.id);
      setExistingMetrics(_existingMetrics);
    }
    if (activeAssignmentId) _getExistingMetrics();
  }, [selectedObject]);

  const handleSaveAssignments = async () => {
    let metrics = await applyAssignmentMetricToNode({
      assignmentId: parseInt(activeAssignmentId),
      metricId: metric.metricId,
      nodeId: selectedObject.id,
      values: [editingMetric]
    });
    setExistingMetrics(metrics);
    setMetricTables(canvasDispatch, metrics.metricTables);
    closeWindow();
  }

  return(
    <EditorModal
      toggleEditorVisibility={closeWindow}
      title={"Metric Editor" + (selectedObject ? `: ${selectedObject.name}` : "")}
      createButtonText="Submit Metric"
      submitEvent={handleSaveAssignments}
      style="managerModalStyle"
      width="30%"
      maxHeight="45%">
      {
        [{ id: 0, value: 0, date: '0' }].concat(existingMetrics?.values).map(metric => (
          <MetricEntryForm
            key={metric.id}
            metric={metric}
            editingMetric={editingMetric}
            setEditingMetric={setEditingMetric}
          />
        ))
      }
    </EditorModal>
  )
}

export default ExecutionMetricSheet;

const MetricEntryForm = ({metric, editingMetric, setEditingMetric}) => {
  useEffect(() => {
    if (metric.id === 0) {
      setEditingMetric(metric);
    }
  }, []);

  const handleValueChange = (e) => {
    let updatedMetric = { ...metric, value: parseInt(e.target.value) };
    setEditingMetric(updatedMetric);
  }

  return(
    <Box sx={{ backgroundColor: "#fff", p: 1.5, borderRadius: 1 }}>
      {
        editingMetric?.id === metric.id ?
          <TextField
            type="number"
            value={editingMetric.value}
            name="value"
            onChange={handleValueChange}
            placeholder="Enter value"
            sx={{ height: 43, width: 120, mr: 1 }}
          /> :
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">{metric.value} minutes</Typography>
            <Typography variant="body2">on: {metric.date}</Typography>
          </Box>
      }
    </Box>
  )
}