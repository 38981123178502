import React, { useState, useEffect } from "react";

// @mui components
import { TextField, Typography } from "@mui/material";

// Components
import { WrapperModal } from "components/GlobalComponents/Modals";

// Db Context
import { createRequest, getUserRequest } from "apiservices/AuthorisationServices/requestService";

const RequestEditor = ({closeEditor, setRequests, requestId, tabId}) => {
  const [request, setRequest] = useState();
  useEffect(() => {
    const _getUserRequest = async () => {
      let _request = await getUserRequest(tabId === "2" ? requestId : 0);
      setRequest(tabId === "1" ? 
        {..._request, ...{typeId: requestId}} : 
        _request);
    }
    _getUserRequest();
  }, [requestId]);
  let descriptors = request?.id === 0 ? requestEditorVariables[requestId] : {title: "View request", name: "Name"};
  
  const handleCreateRequest = async (e, request) => {
    let _requests = await createRequest(request);
    setRequests(_requests);
    setEditor(0);
  }
  
  const handleChangeName = (e) => setRequest({...request, ...{name: e.target.value}});
  const handleChangeTestimony = (e) => setRequest({...request, ...{testimony: e.target.value}});
  return(
    request &&
      <WrapperModal
        open={true}
        handleClose={closeEditor}
        title={descriptors.title}
        primaryEvent={(e) => handleCreateRequest(e, request)}
        primaryButtonText="Submit Request">
        <TextField label={descriptors.name} variant="outlined" sx={{ mt: 1}} onChange={handleChangeName}/>
        <TextField label="Reason for request" multiline rows={4} sx={{ mt: 1}} onChange={handleChangeTestimony}/>
        {
          request?.id !== 0 &&
            <>
              <Typography label="Request status" variant="outlined" sx={{ mt: 1}} value={request.status}/>
              <Typography label="Request feedback" multiline rows={4} sx={{ mt: 1}} value={request.response}/>
            </>
        }
      </WrapperModal>
  )
}
  
const requestEditorVariables = {
  1: {
    title: "Request Atlas",
    name: "Proposed atlas name"
  },
  2: {
    title: "Request User Group",
    name: "Proposed group name"
  },
  3: {
    title: "Request Associate",
    name: "Associate name"
  },
  4: {
    title: "Request Tag Group",
    name: "Tag group name"
  },
  5: {
    title: "Request Metric",
    name: "Metric name"
  }
}

export default RequestEditor;