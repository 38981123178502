import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormControl, InputLabel, Select, MenuItem, Typography, Tooltip, IconButton } from "@mui/material";

// @mui icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import SettingsIcon from '@mui/icons-material/Settings';
import FunctionsIcon from '@mui/icons-material/Functions';
import Add from "@mui/icons-material/Add";

// Components
import { StatsWindowButton } from "components/CanvasComponents/OverlayButtons";
import { HatchedBox } from "components/AdministrationComponents/StyledAdminComponents";
import ExecutionMetricSheet from "../assignmentPanel/ExecutionMetricSheet";
import MetricSettings from "./MetricSettings";
import MetricCalcs from "./MetricCalcs";

// Local context
import { useCanvasController, setNetworkStates, setTagsVisible, setSumMetricValues, 
         setMetricsVisible, setHeatMapVisible, setMetricTables
} from "context/canvasContext";

// Db context
import { getMetricTable, getProjectMetricTable } from "apiservices/AtlasServices/atlasService";

const MetricPanel = ({levelMetricData}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { networkStates, activeLevel, projectMode, activeProjectId, vertexInnerSizes, sumMetricValues, userAssignmentNodes,
          selectedObject, metricsVisible, heatMapVisible, relateSizesToZero, activeMetric, metricTables } = canvasController;

  const handleSetMetric = async (e, networkId) => {
    const activeMetricId = activeMetric?.metricId || activeMetric?.projectMetricId;

    const updatedState = networkStates.map(item => {
      if (item.networkId === networkId) {
        return !projectMode
          ? { ...item, metricId: parseInt(e.target.value) }
          : { ...item, projectMetricId: parseInt(e.target.value) };
      }
      return item;
    });
  
    await setNetworkStates(canvasDispatch, updatedState);
    setHeatMapVisible(canvasDispatch, false);
  
    // Create a copy of metricTables to ensure sequential updates
    let updatedMetricTables = [...metricTables];
  
    // Check if the new metric table needs to be fetched
    if (!updatedMetricTables.find(x => x.metricId === parseInt(e.target.value))) {
      let metricTable = !projectMode 
        ? await getMetricTable(parseInt(e.target.value)) 
        : await getProjectMetricTable(parseInt(e.target.value), activeProjectId);
      updatedMetricTables = [...updatedMetricTables, metricTable];
      await setMetricTables(canvasDispatch, updatedMetricTables);
    }
  
    // Use updatedState to check if the previous metric is still in use
    let previousStillInUse = !projectMode
      ? !!updatedState.find(x => x.metricId === activeMetricId)
      : !!updatedState.find(x => x.projectMetricId === activeMetricId);
  
    // If previous metric is no longer in use, filter it out and update the state again
    if (!previousStillInUse) {
      updatedMetricTables = updatedMetricTables.filter(x => x.metricId !== activeMetricId);
      await setMetricTables(canvasDispatch, updatedMetricTables);
    }
  };

  const [metricSheetVisible, setMetricSheetVisibility] = useState(false);
  const handleSetMetricSheetVisible = () => setMetricSheetVisibility(!metricSheetVisible);

  const [metricCalcsVisible, setMetricCalcsVisible] = useState(false);
  const [metricSettingsVisible, setMetricSettingsVisible] = useState(false);

  const handleSetMetricsVisible = (event) => setMetricsVisible(canvasDispatch, !metricsVisible);
  const handleSetHeatMapVisible = (event) => {
    if (!heatMapVisible) {
      setTagsVisible(canvasDispatch, false);
    }
    setHeatMapVisible(canvasDispatch, !heatMapVisible);
  };

  const handleAggregationTypeChange = (sum) => {
    setSumMetricValues(canvasDispatch, sum);
  };

  const { minSize, maxSize } = getSizeRange(vertexInnerSizes, activeLevel, sumMetricValues, relateSizesToZero);

  return(
    levelMetricData?.length > 0 &&
      <FormControl fullWidth>
        <InputLabel htmlFor="metric-select">Active Metric</InputLabel>
        <Select
          sx={{ height: "40px" }}
          id="metric-select"
          value={activeMetric?.metricId || ''}
          label="Active Metric"
          onChange={e => handleSetMetric(e, parseInt(activeLevel, 10))}>
          {
            levelMetricData.map((_metric) => {
              return (
                <MenuItem value={_metric.metricId} key={_metric.metricId}>{_metric.metricName}</MenuItem>
              )
            })
          }
        </Select>
        {
          activeMetric &&
            <Box sx={{ background: 'lightgrey', display: 'flex', gap: 0.4, px: 0.8, height: '20px', borderRadius: 1, mt: 0.5, justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ mt: -0.3 }}>
                <IconButton
                  sx={{ p: 0 }}
                  onClick={handleSetMetricsVisible}
                >
                  {
                    metricsVisible ? 
                      <VisibilityIcon fontSize="small"/> : 
                      <VisibilityOffIcon fontSize="small"/>
                  }
                </IconButton>
                {
                  !projectMode &&
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={handleSetHeatMapVisible}
                    >
                      <LocalFireDepartmentIcon fontSize="small" style={{ color: heatMapVisible ? 'red' : 'inherit' }} />
                    </IconButton>
                }
              </Box>
              <Box>
                <StatsWindowButton setWindow={setMetricCalcsVisible} icon={<FunctionsIcon/>} id='calcs-window' delay={1200}/>
                <StatsWindowButton setWindow={setMetricSettingsVisible} icon={<SettingsIcon/>} id='settings-window' delay={1200}/>
                {
                  (projectMode && userAssignmentNodes?.filter(x => x.networkId === parseInt(activeLevel) 
                                                                && x.propertyIds.includes(activeMetric?.metricId)
                                                                && x.nodeId === selectedObject?.id).length > 0) &&
                    <StatsWindowButton setWindow={handleSetMetricSheetVisible} icon={<Add/>} id='add-window' delay={1200}/>
                }
              </Box>
            </Box>
        }
        {
          !projectMode &&
            <AggregationToggle 
              sumValues={sumMetricValues} 
              handleAggregationTypeChange={handleAggregationTypeChange} />
        }
        <Box width={1} height='75px' display='flex' flexDirection='row' justifyContent='space-evenly' gap={0.5} mt={1}>
          <HatchedBox size="75px" borderRadius="4px" marginpercentage="8%">
            <Typography fontSize={16} textAlign="center" fontWeight={700}>No data</Typography>
          </HatchedBox>
          <Box width='75px' height='75px' sx={{ borderWidth: '2px', borderRadius: "4px", borderColor: 'black', border: 'solid', alignContent: 'center' }}>
            <Typography fontSize={16} textAlign='center' fontWeight={700}>{minSize}</Typography>
          </Box>
          <Box width="75px" height="75px" sx={{ background: 'black', borderRadius: "4px", alignContent: 'center' }}>
            <Typography fontSize={16} textAlign="center" fontWeight={700} sx={{ color: '#FFFFFF' }}>
              {maxSize}
            </Typography>
          </Box>
        </Box>
        {
          (metricSheetVisible) &&
            <ExecutionMetricSheet 
              closeWindow={() => handleSetMetricSheetVisible()}
              metric={activeMetric}
              />
        }
        {
          metricSettingsVisible &&
            <MetricSettings closeWindow={() => setMetricSettingsVisible(false)} metric={activeMetric} />
        }
        {
          metricCalcsVisible &&
            <MetricCalcs closeWindow={() => setMetricCalcsVisible(false)} metrics={levelMetricData} metric={activeMetric} />
        }
      </FormControl>
  )
}

export default MetricPanel;

const AggregationToggle = ({ sumValues, handleAggregationTypeChange }) => {
  return (
    <Box display="flex" flexDirection="row" justifyContent="center" mb={-1}>
      <Typography component="span" 
        onClick={() => handleAggregationTypeChange(false)} 
        style={{ 
          cursor: 'pointer', 
          color: sumValues === false ? "#008dcf" : 'black',
          marginRight: '8px' 
        }}>
        Average
      </Typography>
      /
      <Typography component="span" 
        onClick={() => handleAggregationTypeChange(true)} 
        style={{ 
          cursor: 'pointer', 
          color: sumValues === true ? "#008dcf" : 'black',
          marginLeft: '8px' 
        }}>
        Sum
      </Typography>
    </Box>
  );
};

function getSizeRange(vertexInnerSizes, activeLevel, sumMetricValues, relateSizesToZero) {
  let property = sumMetricValues ? 'value' : 'meanValue';

  const sizes = vertexInnerSizes?.[activeLevel];
  const hasItems = sizes && Object.values(sizes).length > 0;

  let minSize = relateSizesToZero 
    ? 'Min: 0'
    : hasItems 
      ? 'Min: ' + Math.min(...Object.values(sizes).map(item => item[property])).toFixed(2)
      : 'Min: N/A';

  let maxSize = hasItems 
    ? 'Max: ' + Math.max(...Object.values(sizes).map(item => item[property])).toFixed(2)
    : 'Max: N/A';

  return { minSize, maxSize };
}
