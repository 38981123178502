// @mui components
import { Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";

// Components
import { FixedItemMiniMediaCard } from "components/Cards/FixedItemMediaCard";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

const MediaCardArrayContainerSelector = ({
  items,
  children,
  selectedItemId,
  setSelectedItemId,
  setEditorVisible,
  label,
  style,
  deleteAction
}) => {

  return(
    <Box sx={{ ...style, display: "flex", flexGrow: 1, flexDirection: "row", minHeight: "137px" }}>
      {
        label && (
          <Box sx={{ display: "flex", alignItems: "center", background: "#F5F5F5", justifyContent: "center", width: "30px", borderRight: "1px solid #ccc" }}>
            <Typography variant="h6" component="div" sx={{ transform: "rotate(-90deg)", whiteSpace: "nowrap", pt: 0.8 }}>{label}</Typography>
          </Box>
        )
      }
      <Box
        borderRadius="6px"
        p={1.5}
        sx={{display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden", background: "#F5F5F5"}}
      >
        {children}
        <ScrollbarWrapper style={{ flexGrow: 1, overflowX: "auto" }} vertical={false}>
          <Grid container direction="row" wrap="nowrap" alignItems="flex-start" justifyContent="flex-start" gap={1} overflow={'hidden'}>
            {items && items.map((item) => (
              <Grid item style={{ height: "6.9rem", alignSelf: "flex-start", justifySelf: "flex-start", flex: '0 0 auto' }} key={item.id}>
                <FixedItemMiniMediaCard
                  id={item.id}
                  name={item.name}
                  action={item.id > 0 ? 
                    () => setSelectedItemId(item.id) :
                    () => setEditorVisible(0, false)
                  }
                  selected={selectedItemId === item.id}
                  media={item.media}
                  makeEditorVisible={setEditorVisible}
                  editable={item.editable}
                  deleteAction={() => deleteAction(item.id)}
                />
              </Grid>
            ))}
          </Grid>
        </ScrollbarWrapper>
      </Box>
    </Box>
  )
}

export default MediaCardArrayContainerSelector;