import { useEffect, useState } from "react";

import { updateState } from "apiservices/AtlasServices/stateService";
import { getAtlas } from "apiservices/AtlasServices/atlasService";
import { useCanvasController, getApp,
         setStateLoaded, setProjectToggled,
         setVertices, setUnconfiguredJobs, setVertexTagColours, setMetricTables,
         setEdges, setTagGroups, setMetrics, setOrganisationNodeId, setUserAtlasPermissions,
         setScale, setActiveLevel, setCoordinates, setOverrideLevelId, setSelectedObjectType, setSelectedObject,
         setTagsVisible, setMetricsVisible, setHeatMapVisible, setHoverActive,
         setActiveProjectId, setProjectMode,
         setPropertyPanelVisible, setKeyPanelVisible, setProjectPanelVisible, setQualityPanelVisible,
         setActivePropertyPanelTab, setActiveKeyPanelTab, setActiveProjectPanelTab, setActiveKey,
         setNetworkStates } from "context/canvasContext";

import { centerVerticesAndScaleToFit } from "helpers/canvashelpers/canvasfunctions";

const StateManager = ({ userId, atlasId }) => {
  const [controller, dispatch] = useCanvasController();
  const [error, setError] = useState(null);
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    const fetchAtlas = async () => {
      try {
        const atlas = await getAtlas(userId, atlasId, controller.projectMode, controller.activeProjectId);
        dispatchAtlasData(atlas);
        setTimeout(() => {
          setHasInitialized(true);
        }, 200);
      } catch (error) {
        setError(error);
      }
    };
    fetchAtlas();
  }, []);

  useEffect(() => {
    if (!hasInitialized) return;
    const fetchAtlas = async () => {
      try {
        const atlas = await getAtlas(userId, atlasId, controller.projectMode, controller.activeProjectId);
        dispatchAtlasData(atlas, false);
        if(controller.activeProjectPanelTab === "1"){
          const newScale = centerVerticesAndScaleToFit(getApp(), atlas.vertices.filter(vertex => vertex.context.networkId === 1));
          setScale(dispatch, newScale);
        }
        setTimeout(() => {
          setProjectToggled(dispatch, false);
        }, 200);
      } catch (error) {
        setError(error);
      }
    };

    fetchAtlas();
  }, [controller.activeProjectId, controller.projectMode]);

  useEffect(() => {
    const updateStateInBackend = async () => {
      if(controller.stateLoaded === true){
        try {
          await updateState({
            createdBy: userId,
            scale: controller.scale,
            activeLevel: controller.activeLevel,
            xCoordinate: controller.coordinates.x,
            yCoordinate: controller.coordinates.y,
            selectedObjectType: controller.selectedObjectType,
            selectedObjectId: controller.selectedObject.id,
            overrideLevelId: controller.overrideLevelId,
            tagsVisible: controller.tagsVisible,
            metricsVisible: controller.metricsVisible,
            heatMapVisible: controller.heatMapVisible,
            hoverActive: controller.hoverActive,
            activeProjectId: controller.activeProjectId,
            projectMode: controller.projectMode,
            propertyPanelVisible: controller.propertyPanelVisible,
            keyPanelVisible: controller.keyPanelVisible,
            projectPanelVisible: controller.projectPanelVisible,
            qualityPanelVisible: controller.qualityPanelVisible,
            activePropertyPanelTab: controller.activePropertyPanelTab,
            activeKeyPanelTab: controller.activeKeyPanelTab,
            activeProjectPanelTab: controller.activeProjectPanelTab,
            activeKey: controller.activeKey,
            networkStates: controller.networkStates,
          });
        } catch (error) {
          setError(error);
        }
      }
    };
    updateStateInBackend();
  }, [controller]);

  const dispatchAtlasData = (atlas, useStateScale = true) => {
    if(!atlas.state.projectMode){
      setVertices(dispatch, atlas.vertices.filter((x) => !(x.context.networkId === 2 && x.isIntegrated === 4)));
      setUnconfiguredJobs(dispatch, atlas.vertices.filter((x) => x.context.networkId === 2 && x.isIntegrated === 4));
    }
    else{
      setVertices(dispatch, atlas.vertices);
      setUnconfiguredJobs(dispatch, []);
    }
    setVertexTagColours(dispatch, atlas.vertexTagColours);
    setMetricTables(dispatch, atlas.metricTables);
    setEdges(dispatch, atlas.edges);
    setTagGroups(dispatch, atlas.tagGroups);
    setMetrics(dispatch, atlas.metrics);
    setOrganisationNodeId(dispatch, atlas.organisationNodeId);
    setUserAtlasPermissions(dispatch, atlas.userAtlasPermissions);
    if(useStateScale){
      setScale(dispatch, atlas.state.scale);
    }
    setActiveLevel(dispatch, atlas.state.activeLevel);
    setCoordinates(dispatch, { x: atlas.state.xCoordinate, y: atlas.state.yCoordinate });
    setSelectedObjectType(dispatch, atlas.state.selectedObjectType);
    const selectedObject = atlas.vertices.find((x) => x.context.id === atlas.state.selectedObjectId)?.context;
    setSelectedObject(dispatch, selectedObject ?? { id: 0, networkId: 0 });
    setOverrideLevelId(dispatch, atlas.state.overrideLevelId);
    setTagsVisible(dispatch, atlas.state.tagsVisible);
    setMetricsVisible(dispatch, atlas.state.metricsVisible);
    setHeatMapVisible(dispatch, atlas.state.heatMapVisible);
    setHoverActive(dispatch, atlas.state.hoverActive);
    setActiveProjectId(dispatch, atlas.state.activeProjectId);
    setProjectMode(dispatch, atlas.state.projectMode);
    setPropertyPanelVisible(dispatch, atlas.state.propertyPanelVisible);
    setKeyPanelVisible(dispatch, atlas.state.keyPanelVisible);
    setProjectPanelVisible(dispatch, atlas.state.projectPanelVisible);
    setQualityPanelVisible(dispatch, atlas.state.qualityPanelVisible);
    setActivePropertyPanelTab(dispatch, atlas.state.activePropertyPanelTab);
    setActiveKeyPanelTab(dispatch, atlas.state.activeKeyPanelTab);
    setActiveProjectPanelTab(dispatch, atlas.state.activeProjectPanelTab);
    setActiveKey(dispatch, atlas.state.activeKey);
    setNetworkStates(dispatch, atlas.state.networkStates);
    setStateLoaded(dispatch, true);
  };

  return (
    <div>
      {error && <div>Error: {error.message}</div>}
    </div>
  );
};

export default StateManager;
