// @mui icons
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import StraightenIcon from '@mui/icons-material/Straighten';
import SellIcon from '@mui/icons-material/Sell';

// Font Awesome icon import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints } from '@fortawesome/free-solid-svg-icons';

export const networkIcons = {
  1: <BusinessIcon />,
  2: <WorkIcon />,
  3: <FontAwesomeIcon icon={faShoePrints} style={{ fontSize: '20px', paddingBottom: '6px' }}/>,
  4: <FingerprintIcon />,
  5: <CameraAltIcon />
}

export const propertiesIcons = {
  "tag": <SellIcon />,
  "metric": <StraightenIcon />
}
