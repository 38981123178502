import React, { useState, useEffect } from "react";

// @mui components
import { Tab, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import LanguageIcon from '@mui/icons-material/Language';

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import MediaCardArrayContainerSelector from "components/CardArrayContainers/MediaCardArrayContainerSelector";
import AccessPolicyEditor from "./AccessPolicyEditor";
import PermissionsAdjustmentPanel from "components/AdministrationComponents/PermissionsAdjustmentPanel";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db context
import { createAtlas, updateAtlasReference, getAtlasReference, getAtlasUserGroups, getAtlasUsers, getAtlasProperties, 
         toggleUserGroupAtlasAccess, getAtlasAssociates, toggleAssociateAtlasAccess, toggleUserAtlasAccess, toggleAtlasHasProperty
} from "apiservices/AuthorisationServices/atlasAdminService";

import { getAtlasAccessPolicies, deleteAtlasAccessPolicy } from "apiservices/AuthorisationServices/accessPolicyService";
import { getResults } from "apiservices/ResultServices/resultService";

import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Local context
import { useAdminController } from "context";

let createButton = [{media: "add", name: "Create Policy", id: 0}]

const AtlasView = () => {
  const [controller, dispatch] = useAdminController();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [atlas, setAtlas] = useState();
  const [userGroups, setUserGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [associates, setAssociates] = useState([]);
  const [properties, setProperties] = useState([]);
  const [accessPolicies, setAccessPolicies] = useState([]);
  const [results, setResults] = useState([]);
  
  useEffect(() => {
    if (atlas?.id === "0" || !atlas) return;
    const _getAccessors = async () => {
      let _userGroups = await getAtlasUserGroups(atlas.id, controller.organisationId);
      let _users = await getAtlasUsers(atlas.id, controller.organisationId);
      let _associates = await getAtlasAssociates(atlas.id, controller.organisationId);
      let _properties = await getAtlasProperties(atlas.id, controller.organisationId);
      setUserGroups(_userGroups);
      setUsers(_users);
      setAssociates(_associates);
      setProperties(_properties);
    };
    _getAccessors();
  }, [atlas])

  useEffect(() => {
    if (!atlas) return;
    const _getAccessPolicies = async () => {
      let _accessPolicies = await getAtlasAccessPolicies(controller.organisationId, atlas.id);
      let _results = await getResults(controller.organisationId);
      setAccessPolicies(_accessPolicies);
      setResults(_results);
    };
    _getAccessPolicies();
  }, [atlas]);

  const handleToggleUserGroupAccess = async (e, id) => {
    let _userGroups = await toggleUserGroupAtlasAccess(id, atlas.id, controller.organisationId);
    let _users = await getAtlasUsers(atlas.id, controller.organisationId);
    setUserGroups(_userGroups);
    setUsers(_users);
  }
  const handleToggleUserAccess = async (e, id) => {
    let _users = await toggleUserAtlasAccess(id, atlas.id, controller.organisationId);
    setUsers(_users);
  }
  const handleToggleAssociateAccess = async (e, id) => {
    let _associates = await toggleAssociateAtlasAccess(id, atlas.id);
    setAssociates(_associates);
  }
  const handleTogglePropertyApplication = async (e, id) => {
    let _properties = await toggleAtlasHasProperty(id, controller.organisationId, atlas.id);
    setProperties(_properties);
  }
  const handleSetAppliedAccessPolicy = (id) => {
    setAtlas(prevState => ({ ...prevState, defaultAtlasAccessPolicyId: id }));
  }
  const handleSetAtlasResult = (id) => {
    setAtlas(prevState => ({ ...prevState, resultId: id }));
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(atlas.id !== "0")
      setActiveTab(tabNumber);
  }

  const [accessPolicyEditorVisible, setAccessPolicyEditorVisible] = useState(undefined);
  const [accessPolicyEditorReadonly, setAccessPolicyEditorReadonly] = useState(false);
  const handleUpdateAccessPolicyEditorVisibility = (id, readonly) => {
    setAccessPolicyEditorVisible(id);
    setAccessPolicyEditorReadonly(readonly);
  }

  const [permissionsPanelId, setPermissionsPanelId] = useState(0);
  const handlePermissionsPanelId = (e, id) => {
    setPermissionsPanelId(id);
  }

  const handleDeleteAccessPolicy = async (id) => {
    let _accessPolicies = await deleteAtlasAccessPolicy(id, atlas.id);
    setAccessPolicies(_accessPolicies);
  }

  return (
    <>
      <AdministrationObjectWrapper
        object={atlas}
        setObject={setAtlas}
        getObject={getAtlasReference}
        createObject={createAtlas}
        updateObject={updateAtlasReference}
        namePlaceholder="Atlas Name"
        descriptionPlaceholder="Description of Atlas"
        iconComponent={<LanguageIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          atlas &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Settings" value="1"/>
              {
                atlas.id !== "0" ?
                  <Tab label="User Groups" value="2"/> :
                  <Tooltip title="Save the atlas to begin giving access to user groups">
                    <Tab label="User Groups" value="2"/>
                  </Tooltip>
              }
              {
                atlas.id !== "0" ?
                  <Tab label="Users" value="3"/> :
                  <Tooltip title="Save the atlas to begin setting user access">
                    <Tab label="Users" value="3"/>
                  </Tooltip>
              }
              {
                atlas.id !== "0" ?
                  <Tab label="Associates" value="4"/> :
                  <Tooltip title="Save the atlas to begin setting associate access">
                    <Tab label="Associates" value="4"/>
                  </Tooltip>
              }
              {
                atlas.id !== "0" ?
                  <Tab label="Tag Groups" value="5"/> :
                  <Tooltip title="Save the atlas to begin adding tag groups">
                    <Tab label="Tag Groups" value="5"/>
                  </Tooltip>
              }
              {
                atlas.id !== "0" ?
                  <Tab label="Metrics" value="6"/> :
                  <Tooltip title="Save the atlas to begin specifying metrics">
                    <Tab label="Metrics" value="6"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          atlas &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              {
                atlas.isIncluded && // better if the product was greyed out, rather than hidden (possibly not within a MediaCardArrayContainer)
                    <FormCollapse title="Atlas Result" open={true}>
                      {
                        (accessPolicies && accessPolicies.length > 0) &&
                          <MediaCardArrayContainerSelector
                            items={results}
                            selectedItemId={atlas?.resultId}
                            setSelectedItemId={handleSetAtlasResult}
                            style={{ mt: 1, mb: 1.5 }}/>
                      }
                    </FormCollapse>
              }
              <FormCollapse title="Access Policies" open={true}>
                {
                  accessPolicies &&
                    <MediaCardArrayContainerSelector
                      items={createButton.concat(accessPolicies)}
                      selectedItemId={atlas?.defaultAtlasAccessPolicyId ?? 1}
                      setSelectedItemId={handleSetAppliedAccessPolicy}
                      setEditorVisible={handleUpdateAccessPolicyEditorVisibility}
                      style={{ mt: 1, mb: 1.5 }}
                      deleteAction={handleDeleteAccessPolicy}/>
                }
              </FormCollapse>
            </TabPanel>
        }
        panels={
          atlas?.id !== "0" &&
            <>
              <TabPanel 
                value="2"
                style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userGroups}
                  titles={["Groups with access", "Groups without access"]}
                  action={handleToggleUserGroupAccess}
                  permissionsPanelToggle={handlePermissionsPanelId}
                  searchboxLabel="Search user groups"/>
              </TabPanel>
              <TabPanel 
                value="3"
                style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={users} 
                  titles={["Users with access", "Users without access"]}
                  action={handleToggleUserAccess}
                  permissionsPanelToggle={handlePermissionsPanelId}
                  searchboxLabel="Search users"/>
              </TabPanel>
              <TabPanel 
                value="4"
                style={{ padding: 8, display: activeTab === "4" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={associates} 
                  titles={["Associates with access", "Associates without access"]}
                  action={handleToggleAssociateAccess}
                  permissionsPanelToggle={handlePermissionsPanelId}
                  searchboxLabel="Search associates"/>
              </TabPanel>
              <TabPanel 
                value="5"
                style={{ padding: 8, display: activeTab === "5" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={properties.filter(x => x.objectTypeId === 1 || x.objectTypeId === 3)}
                  titles={["Tag groups relevant to atlas", "Tag groups not relevant to atlas"]}
                  action={handleTogglePropertyApplication}
                  searchboxLabel="Search tag groups"/>
              </TabPanel>
              <TabPanel 
                value="6"
                style={{ padding: 8, display: activeTab === "6" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={properties.filter(x => x.objectTypeId === 2)} 
                  titles={["Metrics relevant to atlas", "Metrics not relevant to atlas"]}
                  action={handleTogglePropertyApplication} 
                  searchboxLabel="Search metrics"/>
              </TabPanel>
            </>
        }
        requiresOrgId
        defaultId="0"
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {
        accessPolicyEditorVisible !== undefined &&
          <AccessPolicyEditor 
            setOpen={handleUpdateAccessPolicyEditorVisibility}
            policyId={accessPolicyEditorVisible}
            setAccessPolicies={setAccessPolicies}
            atlasId={atlas.id}
            readonly={accessPolicyEditorReadonly}
          />
      }
      {
         (permissionsPanelId && permissionsPanelId !== 0) &&
          <PermissionsAdjustmentPanel 
            atlasId={atlas.id} 
            entityId={permissionsPanelId} 
            organisationId={controller.organisationId}
            setOpen={handlePermissionsPanelId}
            userType={(function() {
              switch(activeTab) {
                case "2":
                  return "group";
                case "4":
                  return "associate";
                default:
                  return "user";
              }
            })()}/>
      }
    </>
  );
}

export default AtlasView;