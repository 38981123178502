import { postWrapper, getWrapper } from '../fetchWrapper';

export const createSkillSet = async (skillSet) => await postWrapper('/api/skillset/createskillset', skillSet);
export const updateSkillSet = async (skillSet) => await postWrapper('/api/skillset/updateskillset', skillSet);

export const deleteSkillSet = async (skillSetId) => await getWrapper(`/api/skillset/deleteskillset/` + skillSetId);
export const getSkillSets = async (organisationId, networkId) => await getWrapper(`/api/skillset/getskillsets/` + organisationId + '/' + networkId);
export const getNodeSkillSets = async (organisationId, networkId) => await getWrapper(`/api/skillset/getnodeskillsets/` + organisationId + '/' + networkId);
export const getSkillSet = async (organisationId, skillSetId) => await getWrapper(`/api/skillset/getskillset/`+ organisationId + `/` + skillSetId);
export const getSkillSetProducts = async (skillSetId) => await getWrapper(`/api/skillset/getskillsetproducts/` + skillSetId);
export const toggleSkillSetProducesProduct = async (productId, skillSetId) => await getWrapper(`/api/skillset/toggleskillsetproducesproduct/` + productId + '/' + skillSetId);

export const toggleSkillSetRole = async (roleId, skillSetId) => await getWrapper(`/api/skillset/toggleskillsetrole/` + roleId + '/' + skillSetId);