import {useNavigate} from "react-router-dom";

// @mui components
import { Grid, Box } from "@mui/material";

// Components
import { FixedItemMediaCard } from "components/Cards/FixedItemMediaCard";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

const MediaCardArrayContainer = ({items, path, children, br = "6px", deleteAction = undefined}) => {
  const navigate = useNavigate();
  const handleNavigate = (id) => navigate(`${path}?id=${id}`);

  return(
    <Box
      borderRadius={br}
      p={1.5}
      bgcolor="primary.main"
      style={{ backgroundColor: "white" }}
      sx={{display: "flex", flexDirection: "column", flexGrow: 1}}>
      {children}
      <ScrollbarWrapper>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
          {
            items.map((element) => {
              return (
                <Grid item xl={2} lg={2} md={3} sm={4} xs={6} style={{height: "12.65rem", alignSelf: "flex-start", justifySelf: "flex-start"}} key={element.id}>
                  {
                    <FixedItemMediaCard
                      id={element.id}
                      name={element.name}
                      description={element.description}
                      action={() => handleNavigate(element.id)}
                      deleteAction={element.editable && deleteAction}
                      media={element.media}
                      br={"4px"}/>
                  }
                </Grid>
              )
            })
          }
        </Grid>
      </ScrollbarWrapper>
    </Box>
  )
}

export default MediaCardArrayContainer;