import { postWrapper, getWrapper } from '../fetchWrapper';

export const createResult = async (result) => await postWrapper(`/api/result/createresult`, result);
export const updateResult = async (result) => await postWrapper(`/api/result/updateresult`, result);
export const deleteResult = async (resultId) => await getWrapper(`/api/result/deleteresult/` + resultId);
export const getResults = async (organisationId) => await getWrapper(`/api/result/getresults/` + organisationId);
export const getResult = async (organisationId, resultId) => await getWrapper(`/api/result/getresult/` + organisationId + `/` + resultId);

export const getResultOption = async (resultOptionId) => await getWrapper(`/api/result/getresultoption/` + resultOptionId);

export const toggleApplyScopeToResultOption = async (resultOptionId, scopeId) => await getWrapper(`/api/result/toggleapplyscopetoresultoption/` + resultOptionId + `/` + scopeId);
export const toggleApplyDescriptorToResultOption = async (resultOptionId, descriptorId) => await getWrapper(`/api/result/toggleapplydescriptortoresultoption/` + resultOptionId + `/` + descriptorId);
export const toggleDescriptorRequired = async (resultOptionId, descriptorId) => await getWrapper(`/api/result/toggledescriptorrequired/` + resultOptionId + `/` + descriptorId);