import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormGroup, FormControl, FormControlLabel, Typography, TextField } from "@mui/material";
import { Checkbox, Tab, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import PersonIcon from '@mui/icons-material/Person';

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import PermissionsAdjustmentPanel from "components/AdministrationComponents/PermissionsAdjustmentPanel";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { userValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db Context
import { createUser, updateUser, getUserById, getUserAtlases, getUserGroups, getUserSkillSets, toggleUserSkillSet, 
         getUserProperties, toggleUserProperty, toggleUserAtlasAccess, toggleUserInGroup } from "apiservices/AuthorisationServices/userAdminService";

const UserView = () => {
  const [user, setUser] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, userValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [userAtlases, setUserAtlases] = useState([]);
  const [userGroups, setUserUserGroups] = useState([]);
  const [userSkillSets, setUserSkillSets] = useState([]);
  const [userProperties, setUserProperties] = useState([]);
  useEffect(() => {
    if (user?.id === 0 || !user) return;
    const _getUser = async () => {
      let _atlases = await getUserAtlases(user.id, user.organisationId);
      let _groups = await getUserGroups(user.id, user.organisationId);
      let _skillSets = await getUserSkillSets(user.id, user.organisationId);
      let _properties = await getUserProperties(user.id, user.organisationId);
      setUserAtlases(_atlases);
      setUserUserGroups(_groups);
      setUserSkillSets(_skillSets);
      setUserProperties(_properties);
    };
    _getUser();
  }, [user, userGroups]);
  
  const handleToggleRole = (e, roleId) => {
    const hasRole = e.target.checked;

    setUser(prevUser => ({
      ...prevUser,
      roles: prevUser.roles.map(role => 
        role.roleId === roleId 
          ? { ...role, isSelected: hasRole }
          : role
      )
    }));
  };

  const handleSetGrantedItems = (e) => {
    setUser({...user, grantedItems: {...user.grantedItems, [e.target.name]: e.target.checked}});
  }
  const handleSetMetricValue = (event) => {
    const { name, value } = event.target;
  
    setUser(prevUser => ({
      ...prevUser,
      userMetrics: prevUser.userMetrics.map(metric =>
        metric.metricId.toString() === name
          ? { ...metric, value: value }
          : metric
      )
    }));
  };

  const handleToggleUserAtlasAccess = async (e, id) => {
    let _atlases = await toggleUserAtlasAccess(user.id, id, user.organisationId);
    setUserAtlases(_atlases);
  }
  const handleToggleUserInGroup = async (e, id) => {
    let _userGroups = await toggleUserInGroup(user.id, id, user.organisationId);
    setUserUserGroups(_userGroups);
  }
  const handleToggleUserSkillSet = async (e, id) => {
    let _skillSets = await toggleUserSkillSet(user.id, id, user.organisationId);
    setUserSkillSets(_skillSets);
  }
  const handleToggleUserProperty = async (e, id) => {
    let _properties = await toggleUserProperty(user.id, id, user.organisationId);
    setUserProperties(_properties);
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(user.id > 0)
      setActiveTab(tabNumber);
  };

  const [permissionsPanelAtlasId, setPermissionsPanelAtlasId] = useState(0);
  const handlePermissionsPanelAtlasId = (e, id) => {
    setPermissionsPanelAtlasId(id);
  }

  //we need to ensure that new users are added to valid domains. We can achieve by adding the demain by default and having a select menu, if there are more than 1

  return (
    <>
      <AdministrationObjectWrapper           
        object={user}
        setObject={setUser}
        getObject={getUserById}
        createObject={createUser}
        updateObject={updateUser}
        nameField="name"
        descriptionField="email"
        namePlaceholder="Name"
        descriptionPlaceholder="Email"
        iconComponent={<PersonIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          user &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Details" value="1"/>
              {
                user.id > 0 ?
                  <Tab label="User Groups" value="2"/> :
                  <Tooltip title="Save the user to begin setting access to user groups">
                    <Tab label="User Groups" value="2"/>
                  </Tooltip>
              }
              {
                user.id > 0 ?
                  <Tab label="Atlases" value="3"/> :
                  <Tooltip title="Save the user to begin setting access to atlases">
                    <Tab label="Atlases" value="3"/>
                  </Tooltip>
              }
              {
                user.id > 0 ?
                  <Tab label="Skillsets" value="4"/> :
                  <Tooltip title="Save the user to begin adding skillsets">
                    <Tab label="Skillsets" value="4"/>
                  </Tooltip>
              }
              {
                user.id > 0 ?
                  <Tab label="Tag Groups" value="5"/> :
                  <Tooltip title="Save the user to begin setting access to tag groups">
                    <Tab label="Tag Groups" value="5"/>
                  </Tooltip>
              }
              {
                user.id > 0 ?
                  <Tab label="Metrics" value="6"/> :
                  <Tooltip title="Save the user to begin setting access to metrics">
                    <Tab label="Metrics" value="6"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          user &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
                <FormGroup>
                  <FormCollapse title="Roles" open>
                    <FormControl component="fieldset">
                      {
                        user.roles.filter(x => x.roleId !== 1).map((role, index) => (
                          <FormControlLabel
                            key={index}
                            control={<Checkbox
                              checked={role.isSelected}
                              onChange={(e) => handleToggleRole(e, role.roleId)}/>
                            }
                            name={role.name}
                            label={role.name}/>
                        ))
                      }
                    </FormControl>
                  </FormCollapse>
                  {
                    !user.roles.includes("admin") &&
                      <FormCollapse title="Requests" open>
                        <Box display="flex" flexDirection="row">
                          <FormControl sx={{ minWidth: "150px" }}>
                            <FormControlLabel name="1" control={<Checkbox checked={user.grantedItems[1]}/>} onChange={handleSetGrantedItems} label="Atlas"/>
                            <FormControlLabel name="2" control={<Checkbox checked={user.grantedItems[2]}/>} onChange={handleSetGrantedItems} label="User Group"/>
                            <FormControlLabel name="3" control={<Checkbox checked={user.grantedItems[3]}/>} onChange={handleSetGrantedItems} label="Skill Set"/>
                            <FormControlLabel name="4" control={<Checkbox checked={user.grantedItems[4]}/>} onChange={handleSetGrantedItems} label="Tag Group"/>
                            <FormControlLabel name="5" control={<Checkbox checked={user.grantedItems[5]}/>} onChange={handleSetGrantedItems} label="Metric"/>
                          </FormControl>
                          <Typography>
                            Checking an option here means that the user has permission to create these objects, without a request being required. You will still be notified of the object creation.
                          </Typography>
                        </Box>
                      </FormCollapse>
                  }
                </FormGroup>
                <FormCollapse title="Metrics" open>             
                  <Box display="flex" flexDirection="column" gap={1} mb={1} width={0.5}>
                  {
                    user.userMetrics?.map((metric, index) => {
                      return (
                        <Box key={index} display="flex" flexDirection="row" alignItems="center" justifyContent='space-between'>
                          <Typography>{metric.metricName}: </Typography>
                          <TextField
                            value={metric.value}
                            type='number'
                            name={metric.metricId.toString()}
                            onChange={handleSetMetricValue}
                          />
                        </Box>
                      );
                    })
                  }
                  </Box>
                </FormCollapse>
            </TabPanel>
        }
        panels={
          user?.id > 0 &&
            <>
              <TabPanel
                value="2"
                style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userGroups}
                  titles={["Groups which user is a member of", "Groups which user is not a member of"]}
                  action={handleToggleUserInGroup}
                  searchboxLabel="Search user groups"/>
              </TabPanel>
              <TabPanel
                value="3"
                style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userAtlases}
                  titles={["Atlases which user has access to", "Atlases which user does not have access to"]}
                  action={handleToggleUserAtlasAccess}
                  permissionsPanelToggle={handlePermissionsPanelAtlasId}
                  searchboxLabel="Search atlases"/>
              </TabPanel>
              <TabPanel
                value="4"
                style={{ padding: 8, display: activeTab === "4" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userSkillSets}
                  titles={["User skillsets", "Other skillsets"]}
                  action={handleToggleUserSkillSet}
                  searchboxLabel="Search skillsets"/>
              </TabPanel>
              <TabPanel
                value="5"
                style={{ padding: 8, display: activeTab === "5" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userProperties.filter(x => x.objectTypeId == 1)}
                  titles={["Permitted tag groups", "Other tag groups"]}
                  action={handleToggleUserProperty}
                  searchboxLabel="Search tag groups"/>
              </TabPanel>
              <TabPanel
                value="6"
                style={{ padding: 8, display: activeTab === "6" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userProperties.filter(x => x.objectTypeId == 2)}
                  titles={["Permitted metrics", "Other metrics"]}
                  action={handleToggleUserProperty}
                  searchboxLabel="Search metrics"/>
              </TabPanel>
            </>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {
         permissionsPanelAtlasId !== 0 &&
          <PermissionsAdjustmentPanel
            organisationId={user.organisationId}
            atlasId={permissionsPanelAtlasId}
            entityId={user.id}
            setOpen={handlePermissionsPanelAtlasId}
          />
      }
    </>
  );
}

export default UserView;