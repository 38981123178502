import React, { useEffect, useState } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormControl, InputLabel, Select, Typography, MenuItem, IconButton } from "@mui/material";

// @mui icons
import BrushIcon from '@mui/icons-material/Brush';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Services
import { applyCoreTag, applyExecutionTag, removeCoreTag, removeExecutionTag } from "apiservices/AtlasServices/tagService";
import { getNetworkVertexColours } from "apiservices/AtlasServices/atlasService";

// Local context
import { useAdminController } from "context/index";
import { useCanvasController, setInteractionMode, setNetworkStates, setVertexTagColours, setTagsVisible, setSelectedObject,
         setHeatMapVisible } from "context/canvasContext";

const TagGroupsPanel = ({levelTagData}) => {
  const [controller, dispatch] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { networkStates, interactionMode, selectedObject, vertexTagColours, activeLevel, vertices, userAtlasPermissions,
          projectMode, activeProjectId, activeAssignmentId, userAssignmentNodes, tagsVisible, selectedObjectType } = canvasController;
  const { userId } = controller;

  const [tagGroup, setTagGroup] = useState();
  useEffect(() => {
    if(!projectMode){
      let tagGroupId = networkStates.find(x => x.networkId === parseInt(activeLevel))?.tagGroupId;
      setTagGroup(tagGroupId ? levelTagData.find(x => x.tagGroupId === tagGroupId) : undefined);
    }
    else{
      let tagGroupId = networkStates.find(x => x.networkId === parseInt(activeLevel))?.projectTagGroupId;
      setTagGroup(tagGroupId ? levelTagData.find(x => x.tagGroupId === tagGroupId) : undefined);
    }
  }, [networkStates, activeLevel, levelTagData, projectMode]);

  const UpdateTagColours = async (tagColours) => {
    setVertexTagColours(canvasDispatch, {
      ...vertexTagColours,
      [activeLevel]: tagColours
    });
  };

  const handleSetTagGroup = async (e) => {
    const updatedState = await networkStates.map(item => {
      if (item.networkId === parseInt(activeLevel))
        if(!projectMode)
          return { ...item, tagGroupId: parseInt(e.target.value) };
        else
          return { ...item, projectTagGroupId: parseInt(e.target.value) };
      return item;
    });
    await setNetworkStates(canvasDispatch, updatedState);
    let levelVertices = vertices.filter(x => x.context.networkId === parseInt(activeLevel));
    let projectId = projectMode ? activeProjectId : 0;
    let tagColours = await getNetworkVertexColours(parseInt(e.target.value), levelVertices.map(x => x.context.id), projectId);
    UpdateTagColours(tagColours);
  };

  const [activeTagId, setActiveTagId] = useState(undefined);
  const handleSetActiveTag = (e, id) => {
    let _tag = activeTagId === id ? undefined : id;
    let mode = _tag !== undefined ? "tag" : "select";
    setActiveTagId(_tag);
    setInteractionMode(canvasDispatch, mode);
  };

  const handleSetRemoveTag = async () => {
    let mode = interactionMode !== "removetag" ? "removetag" : "select";
    setActiveTagId(undefined);
    setInteractionMode(canvasDispatch, mode);
  };

  useEffect(() => {
    if(selectedObject.id === 0 || selectedObjectType !== 'vertex') return;
    const _applyTagToNode = async () => {
      let tagColours;
      
      if (projectMode) {
        let assignmentVertex = userAssignmentNodes?.find(x => x.nodeId === selectedObject.id && x.propertyIds.includes(tagGroup.tagGroupId));
        if (!assignmentVertex) return;
        if (interactionMode === "tag" && activeTagId !== undefined)
          tagColours = await applyExecutionTag(activeAssignmentId, selectedObject.id, activeTagId, userId);
        else if (interactionMode === "removetag")
          tagColours = await removeExecutionTag(activeAssignmentId, selectedObject.id);
      }
      if (!projectMode){
        if (interactionMode === "tag" && activeTagId !== undefined)
          tagColours = await applyCoreTag(selectedObject.id, activeTagId);
        else if (interactionMode === "removetag")
          tagColours = await removeCoreTag(selectedObject.id, tagGroup.tagGroupId);
      }
    
      if (tagColours)
        UpdateTagColours(tagColours);
      setSelectedObject(canvasDispatch, {id: 0, networkId: 0});
    };
    _applyTagToNode();
  }, [selectedObject]);

  const handleSetTagsVisible = () => {
    if(!tagsVisible)
      setHeatMapVisible(canvasDispatch, false);
    setTagsVisible(canvasDispatch, !tagsVisible);
  }
  
  let userPermissions = userAtlasPermissions.tagGroupPermissions.find(x => x.id === tagGroup?.tagGroupId);

  return(
    levelTagData?.length > 0 &&
      <FormControl fullWidth>
        <InputLabel htmlFor="tag-group-select">Active Tag Group</InputLabel>
        <Select
          sx={{ height: "40px"}}
          id="tag-group-select"
          value={tagGroup?.tagGroupId || ''}
          label="Active Tag Group"
          onChange={e => handleSetTagGroup(e)}>
          {
            levelTagData.map((group) => {
              return (
                <MenuItem value={group.tagGroupId} key={group.tagGroupId}>{group.tagGroupName}</MenuItem>
              )
            })
          }
        </Select>
        {
          tagGroup &&
            <Box sx={{ background: 'lightgrey', display: 'flex', gap: 0.4, px: 0.8, py: 0.2, borderRadius: 1, mt: 0.5 }}>
              <IconButton
                sx={{ p: 0 }}
                onClick={handleSetTagsVisible}
              >
                {
                  tagsVisible ? 
                    <VisibilityIcon fontSize="small"/> : 
                    <VisibilityOffIcon fontSize="small"/>
                }
              </IconButton>
              {
                userPermissions?.permissions.delete &&
                  <IconButton
                    sx={{ p: 0 }}
                    style={{ color: interactionMode === "removetag" ? 'red' : 'inherit' }}
                    onClick={handleSetRemoveTag}
                  >
                    <CancelIcon fontSize="small"/>
                  </IconButton>
              }
            </Box>
        }
        <Box display="flex" flexDirection="column" sx={{mb: 1}}>
          {
            Object.entries(tagGroup?.tags ?? {}).map(([id, attrs]) => {
              return (
                <Box display="flex" flexDirection="row" alignItems="center" sx={{ mt: 0.5, mx: 0.5 }} key={id}>
                  <Box sx={{
                    p: 0,
                    height: "20px",
                    width: "20px",
                    minHeight: "20px",
                    minWidth: "20px",
                    backgroundColor: attrs.value,
                    borderRadius: "10px",
                    marginRight: "6px" }}/>
                  <Typography variant="h1" fontSize={15} noWrap>{attrs.name}</Typography>
                  {
                    userPermissions?.permissions.create &&
                      <IconButton
                        style={{ marginLeft: "auto" }}
                        sx={{ p: 0.3 }}
                        onClick={(e) => handleSetActiveTag(e, id)}
                        >
                        <BrushIcon
                          sx={{
                            fontSize: 20, 
                            backgroundColor: (activeTagId === id && interactionMode == 'tag') ? "red" : "black",
                            borderRadius: "10px", }} 
                          fontSize=""/>
                      </IconButton>
                  }
                </Box>
              )
            })
          }
        </Box>
      </FormControl>
  )
}

export default TagGroupsPanel;