import React, { useState, useEffect } from "react";

// @mui components
import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

// @mui icons
import PersonIcon from '@mui/icons-material/Person';

// components
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import RequestCardContainer from "components/CardArrayContainers/RequestCardContainer";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";
import PlatformAdministratorDashboard from "./platformadmindashboard";

// Local context
import { useAdminController } from "context";

// DB context
import { getAssociateRequests, actionAssociateRequest } from "apiservices/AuthorisationServices/associateService";
import { getAllRequests } from "apiservices/AuthorisationServices/requestService";

const LandingPage = () => {
  const [controller, dispatch] = useAdminController();
  const { userEmail, organisationId } = controller;

  const [associateRequests, setAssociateRequests] = useState([]);
  const [userRequests, setUserRequests] = useState([]);
  useEffect(() => {
    const getRequests = async () => {
      let _associateRequests = await getAssociateRequests(organisationId);
      setAssociateRequests(_associateRequests);
      let _userRequests = await getAllRequests(organisationId);
      setUserRequests(_userRequests);
    }
    getRequests();
  }, [])

  const handleRequestResponse = async (requestId, response) => {
    let _associateRequests = await actionAssociateRequest(requestId, response);
    setAssociateRequests(_associateRequests);
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);
  
  return (
    <AdministrationObjectWrapper
      object={{name: userEmail, description: userEmail, media: "", organisationId: null}}
      namePlaceholder="User Name"
      descriptionPlaceholder="Email Address"
      iconComponent={<PersonIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        <TabContext value={activeTab}>
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Overview" value="1" style={{ color: '#000000' }}/>
            <Tab 
              label={
                <span>
                  Requests
                  {
                    (associateRequests.length > 0 || userRequests.length > 0) && (
                      <span>
                        <span style={{ color: 'black' }}> (</span>
                        <span style={{ color: 'red' }}>{associateRequests.length + userRequests.length}</span>
                        <span style={{ color: 'black' }}>)</span>
                      </span>
                    )
                  }
                </span>
              }
              value="2" 
              sx={{ color: '#ffffff' }}/>
            <Tab label="Suggestions" value="3" sx={{ color: '#ffffff' }}/>
          </AdminTabList>
        </TabContext>
      }
      form={
        <TabPanel 
          value="1"
          style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", height: '100%', flex: 1 }}>
          <PlatformAdministratorDashboard/>
          {/* usage trend */}
          {/* user activity */}
          {/* user update/user dropoff */}
        </TabPanel>
      }
      panels={
        <>
          <TabPanel 
            value="2"
            style={{ padding: 0, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
            <RequestCardContainer requests={associateRequests.concat(userRequests)} responseAction={handleRequestResponse}/>
          </TabPanel>
          <TabPanel 
            value="3"
            style={{ padding: 0, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
            <MediaCardArrayContainer items={[]}/>
          </TabPanel>
        </>
      }
      getByUserId
      restrictable={false}
      scrollable={false}
    />
  );
}

export default LandingPage;