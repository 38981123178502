import React, { useState, useEffect } from "react";

import { MapBox, MapEdgeText, CanvasIconButton, CanvasEdge } from "components/CanvasComponents/CanvasObjects/canvascomponents";

import { addIcon, inputIcon, outputIcon, rubbishIcon } from "helpers/canvashelpers/base64icons";
import { mapLayoutDimensions } from "helpers/canvashelpers/constants";

import { clearStage, getMapCanvas } from "helpers/canvashelpers/canvasfunctions";

const ProcessEditorCanvas = ({process,
  addStep,
  deleteStep,
  addEdge,
  deleteEdge,
  selectedProcessElementType,
  setSelectedProcessElementType,
  selectedProcessElement,
  setSelectedProcessElement
}) => {

  const [app, setApp] = useState(undefined);
  const [resizeTriggered, setResizeTriggered] = useState(false);
  useEffect(() => {
    getMapCanvas(setApp, setSelectedProcessElementType, setSelectedProcessElement, 0.04, setResizeTriggered);
  }, [])
  
  useEffect(() => {
    if(app !== undefined && process !== undefined){
      clearStage(app);
      let orderedProcess = process.nodes.sort((a, b) => a.horizontalIndex - b.horizontalIndex);
      orderedProcess.forEach((node, i) => {
        app.stage.addChild(editorVertex(node, i, addStep, deleteStep, addEdge, selectedProcessElement, process.nodes.length, process.edges.length));
        if(i > 0)
          app.stage.addChild(stepEdge(i));
        let inputs = process.edges.filter(x => x.hasOwnProperty("targetNodeId") && x.targetNodeId === node.id);
        let outputs = process.edges.filter(x => x.hasOwnProperty("sourceNodeId") && x.sourceNodeId === node.id);
        addProductEdges("input", app, selectedProcessElementType, setSelectedProcessElementType, selectedProcessElement, setSelectedProcessElement, deleteEdge, inputs, i);
        addProductEdges("output", app, selectedProcessElementType, setSelectedProcessElementType, selectedProcessElement, setSelectedProcessElement, deleteEdge, outputs, i);
      });
    }
  }, [app, process, selectedProcessElementType, selectedProcessElement, resizeTriggered])
}

function editorVertex(node, index, addStep, deleteStep, addEdge, selectedProcessElement) {
  const edgeColour = selectedProcessElement?.hasOwnProperty("networkId") && selectedProcessElement?.id === node.id ? 
    "#00b0ff" : "#000";
  const edgeThickness = selectedProcessElement?.hasOwnProperty("networkId") && selectedProcessElement?.id === node.id ?
    5 : 2;

  const vertex = MapBox({context: node, position: [index * 233, -100], edgeColour: edgeColour, edgeThickness: edgeThickness});

  vertex.addChild(CanvasIconButton(addIcon, [175, 2], 0x000000, 1, 0.5, addStep, 
    { networkId: 3, parentNodeId: node.parentNodeId, horizontalIndex: node.horizontalIndex + 1, name: "" }));

  vertex.addChild(CanvasIconButton(inputIcon, [2, 2], 0x000000, 1, 0.4, addEdge, { targetNodeId: node.id }));
  vertex.addChild(CanvasIconButton(inputIcon, [180, 178], 0x000000, 1, 0.4, addEdge, { sourceNodeId: node.id }));
  
  if (node.horizontalIndex > 1){
    vertex.addChild(CanvasIconButton(rubbishIcon, [3, 173], 0xFF0000, 1, 0.45, deleteStep, node.id));
  }

  return vertex;
}

function stepEdge(i){
  const startX = i * 202 + 31 * (i - 1);
  const line = { startX: startX, startY: 0, endX: startX + 32, endY: 0 };
  const _edge = {paths: [line]};
  return CanvasEdge(
    _edge,
    mapLayoutDimensions.width,
    1,
    mapLayoutDimensions.colour,
    mapLayoutDimensions.junctionRadius,
    mapLayoutDimensions.arrowheadLength
  );
}

function addProductEdges(type, app, selectedProcessElementType, setSelectedProcessElementType, selectedProcessElement, setSelectedProcessElement, deleteEdge, edges, stepIndex) {
  edges.forEach((edge, index) => {
    const colour = selectedProcessElementType === 'edge' && selectedProcessElement?.id === edge.id ? "#00b0ff" : "#000";
    const alpha = selectedProcessElementType === 'edge' && selectedProcessElement?.id === edge.id ? 1 : 0.25;

    const baseX = stepIndex * 233;
    const baseY = type === "input" ? - 100 : 102;
    
    edge.paths = getProductPath(type, [baseX, baseY], edge, index);
    app.stage.addChild(
      CanvasEdge(
        edge,
        mapLayoutDimensions.width,
        alpha,
        colour,
        mapLayoutDimensions.junctionRadius,
        mapLayoutDimensions.arrowheadLength,
        () => {
          setSelectedProcessElementType("edge");
          setSelectedProcessElement(edge);
        }
      )
    );

    const textX = type === "input" ? 
      baseX - 16 :
      baseX + 224;
    const textY = type === "input" ? 
      baseY - 27 - 42 * (index) :
      baseY + 72 + 42 * (index);
    const justification = type === "input" ? "left" : "right";
    app.stage.addChild(MapEdgeText(edge.name, 12, 300, [textX, textY], 1, justification));

    const deleteX = type === "input" ? 
      baseX + 30 * (index) :
      baseX + 186 - 30 * (index);
    const deleteY = type === "input" ? 
      baseY - 37 - 42 * (index) :
      baseY + 11 + 42 * (index);
    const deleteButton = CanvasIconButton(rubbishIcon, [deleteX, deleteY], 0xFF0000, 1, 0.32, deleteEdge, edge.id);
    app.stage.addChild(deleteButton);
  });
}

function getProductPath(type, position, _edge, index){
  if (type === "input") {  
    const xLoc = position[0] + 20 + index * 30;
    // Define the starting points and lengths
    const horizontalStartX = xLoc - mapLayoutDimensions.horizontalWidth;
    const horizontalEndX = xLoc;
    const verticalStartY = position[1] - mapLayoutDimensions.verticalHeight * (index + 1);
    const verticalEndY = position[1];

    // Describe the lines as an array
    return [
      { startX: horizontalStartX, startY: verticalStartY, endX: horizontalEndX, endY: verticalStartY },
      { startX: horizontalEndX, startY: verticalStartY, endX: horizontalEndX, endY: verticalEndY }
    ];
  } 
  else if (type === "output") {
    const xLoc = position[0] + 180 - index * 30;
    const verticalStartX = xLoc;
    const verticalEndX = xLoc + mapLayoutDimensions.horizontalWidth + 10;
    const horizontalStartY = position[1];
    const horizontalEndY = position[1] -10 + mapLayoutDimensions.verticalHeight * (index + 1);
    
    return [
      { startX: verticalStartX, startY: horizontalStartY, endX: verticalStartX, endY: horizontalEndY },
      { startX: verticalStartX, startY: horizontalEndY, endX: verticalEndX, endY: horizontalEndY }
    ];
  }
}

export default ProcessEditorCanvas;