// @mui components
import { Grid, Box } from "@mui/material";

// Components
import KMCard from "components/Cards/KMCard";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

const KMCardContainer = ({
  items,
  editor,
  deleteAction,
  cardColour,
  colour,
  padding = 1.5,
  margins = 2,
  borderRadius = "6px",
  title="name",
  size="large"
  }) => {
  const gridSizes = size === "large" ? { xl: 2, lg: 2, md: 3, sm: 6, xs: 8 } : { xl: 4, lg: 6, md: 6, sm: 6, xs: 12 };
  const gridHeight = size === "large" ? "13.1rem" : "11.25rem";
    
  return(
    <Box
      p={padding}
      borderRadius={borderRadius}
      style={{backgroundColor: colour}}
      sx={{display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden", mt: 1 }}>
      <ScrollbarWrapper>
        <Grid container spacing={margins} alignItems="flex-start" justifyContent="flex-start">
          {
            items && items.map((element) => {
              return (
                <Grid item {...gridSizes} style={{height: gridHeight, alignSelf: "flex-start", justifySelf: "flex-start"}} key={element.id}>
                  <KMCard
                    name={element[title]}
                    id={element.id}
                    icon={element.icon}
                    cardColour={cardColour}
                    colour={element.value}
                    editor={editor}
                    deleteAction={deleteAction}/>
                </Grid>
              )
            })
          }
        </Grid>
      </ScrollbarWrapper>
    </Box>
  )
}

export default KMCardContainer;