import React, { useState, useEffect } from "react";

// Components
import { KnowledgeManagerModal } from "components/GlobalComponents/Modals";
import KnowledgeEditor from "./KnowledgeEditor";

// Db context
import { getNodeDocumentReferences, deleteReference } from "apiservices/AtlasServices/knowledgeService";

// Canvas context
import { useCanvasController, setPropertiesManagerWindow } from "context/canvasContext";

const KnowledgeManager = ({}) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { selectedObject, projectMode, activeProjectId } = canvasController;

  const [references, setReferences] = useState([]);
  useEffect(() => {
    const _getKMReferences = async () => {
      let projectId = projectMode ? activeProjectId : 0;
      let _references = await getNodeDocumentReferences(selectedObject.id, projectId);
      setReferences(_references);
    };
    _getKMReferences();
  }, []);

  const handleDeleteDocument = async (e, documentId) => {
    let projectId = projectMode ? activeProjectId : 0;
    let _references = await deleteReference(selectedObject.id, documentId, projectId);
    setReferences(_references);
  }
  
  const handleCloseWindow = (e) => setPropertiesManagerWindow(canvasDispatch, undefined);
  
  const [editorVisible, setEditorVisible] = useState(false);
  const handleSetEditorVisibility = (e, id) => setEditorVisible(id);

  return(
    <>
      <KnowledgeManagerModal
        handleClose={handleCloseWindow}
        title={"Knowledge Manager: " + selectedObject.name}
        data={[{id: 0, name: "Add new document", description: "", icon: "add"}].concat(references)}
        handleOpenEditor={handleSetEditorVisibility}
        deleteAction={handleDeleteDocument}/>
      {
        editorVisible !== false && (
          <KnowledgeEditor
            setReferences={setReferences}
            referenceId={editorVisible}
            setEditorVisible={setEditorVisible}/>
        )
      }
    </>
  )
}

export default KnowledgeManager;