import React, { useEffect } from 'react';

// @mui components
import { Card, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

// @mui icons
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/en';

dayjs.locale('en');

// Components
import ScrollbarWrapper from 'components/GlobalComponents/ScrollbarWrapper';

export const commonCardStyle = { backgroundColor: '#faf7f2', p: '2px', display: 'flex', flex: 1, borderRadius: 1, height: '100%' };
export const outerBoxStyle = {
  display: 'flex',
  flexDirection: 'row',
  gap: 2,
  height: '100%',
  m: 1,
  mt: 0
};
export const chartMargins = { top: 30, right: -10, left: -10, bottom: -10 };

export const StatCard = ({ title, count, diff, selectedStatCard, setSelectedStatCard, onClick }) => {
  const iconColor = diff >= 0 ? 'green' : 'red';
  const IconComponent = diff >= 0 ? ArrowDropUpIcon : ArrowDropDownIcon;
  const cardBackgroundColor = selectedStatCard === title ? '#00b0ff' : '#faf7f2';

  // Deselect logic when clicking outside the card
  useEffect(() => {
    // Event listener to detect clicks outside the card
    const handleClickOutside = (event) => {
      // Deselect the current card, if it is not the click target
      if (event.target.closest('.stat-card') === null) {
        setSelectedStatCard(''); // Deselect the card by passing an empty title
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setSelectedStatCard]);

  return (
    <Card
      className="stat-card"
      elevation={1}
      sx={{ ...commonCardStyle, backgroundColor: cardBackgroundColor, cursor: 'pointer' }}
      onClick={(e) => {
        e.stopPropagation();
        onClick(title);
      }}>
      <Typography variant="h3" sx={{ textAlign: 'center' }}>
        {title}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '25px' }}>
        <Typography variant="h5">{count}</Typography>
        <IconComponent style={{ color: iconColor, fontSize: 50 }} />
        <Typography variant="h5">{`(${diff})`}</Typography>
      </Box>
    </Card>
  );
};

export const ObjectStatusCardArray = ({ data, keyColours }) => {
  // Create a map of category names to colors for quick lookup
  const categoryColorMap = keyColours.reduce((acc, { name, color }) => {
    acc[name] = color;
    return acc;
  }, {});

  return (
    <Card
      elevation={2}
      sx={{
        ...commonCardStyle,
        p: 1,
        pr: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 1,
        flex: 1,
        flexGrow: 1
      }}>
      <ScrollbarWrapper>
        {
          keyColours.map((category) => {
            const filteredData = data.filter(entry => entry.category === category.name);

            return (
              <Box key={category.name}>
                {/* Render category name before the first matching card */}
                {
                  filteredData.length > 0 && (
                    <Typography
                      variant="h6"
                      sx={{
                        alignSelf: 'flex-start',
                        color: category.color,
                        marginBottom: 0.5,
                        marginTop: 1,
                      }}>
                      {category.name}:
                    </Typography>
                  )
                }
                {
                  filteredData.map((entry) => (
                    <Box
                      key={entry.id}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 10,
                        background: 'white',
                        width: '100%',
                        borderRadius: 1,
                        mb: 0.5,
                        p: 0.5,
                        color: categoryColorMap[entry.category],
                      }}>
                      <Typography variant="body1">{entry.label}</Typography>
                      <Typography variant="body1">{entry.value}</Typography>
                      <Typography variant="body1">{entry.category}</Typography>
                    </Box>
                  ))
                }
              </Box>
            );
          })
        }
      </ScrollbarWrapper>
    </Card>
  );
};

export const DashboardFilter = () => {
  const StyledBox = styled(Box)(({ theme }) => ({
    flex: 1,
    border: `1px solid ${theme.palette.grey[400]}`,
    height: 44,
    borderRadius: 6,
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  }));

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1, backgroundColor: '#fff', gap: 1 }}>
        <DatePicker
          label="Start Date"
          value={null}
          onChange={(newValue) => {}}/>
        <DatePicker
          label="End Date"
          value={null}
          onChange={(newValue) => {}}/>
        <StyledBox>
          <FilterAltIcon sx={{ marginRight: 1 }} />
          {/* Filter */}
        </StyledBox>
      </Box>
    </LocalizationProvider>
  );
};