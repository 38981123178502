import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import bgImage from './bgImage.jpeg';

export const Header = () => {
  return (
    <Box
      id="head"
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: { xs: '70vh', md: '93vh' },
        minHeight: '93vh', // Ensures height is never less than 93vh
      }}
    >
      <Box
        sx={{
          height: 1,
          width: 1,
          background: 'rgba(128, 128, 128, 0.65)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          px: { xs: 2, md: 0 },
        }}
      >
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={10} lg={8}>
              <Box textAlign="center">
                <Typography
                  fontSize={{ xs: '2.5rem', md: '4rem', lg: '6rem' }}
                  fontWeight="600"
                  sx={{ color: '#F0F0F0' }}
                >
                  Welcome to Merit
                </Typography>
                <Typography
                  fontSize={{ xs: '1.5rem', md: '2.5rem', lg: '3rem' }}
                  fontWeight="400"
                  sx={{ color: '#F0F0F0', mt: 2 }}
                >
                  Merit is still pre-launch, but you must have found us because we are targeting a challenge on your agenda! 
                  Take a few minutes to explore our upcoming offerings and drop us a note with any enquiries.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
