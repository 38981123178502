import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { FormControl, InputLabel, MenuItem, Select, Button } from "@mui/material";

// import AssignmentEditor from "./AssignmentEditor";
import AssignmentEditor from "./AssignmentEditor";

// Canvas context
import { useAdminController } from "context";
import { useCanvasController, setActiveProjectId } from "context/canvasContext";

import { getInstances } from "apiservices/ResultServices/instanceService";
import { getUserProjects } from "apiservices/AtlasServices/executionService";

const AdministratorProjectPanel = ({userRole}) => {
  const [adminController, adminDispatch] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { organisationId, userId } = adminController;
  const { activeProjectId } = canvasController;
  
  const [instances, setInstances] = useState(undefined);
  useEffect(() => {
    const _getInstances = async () => {
      let _instances = userRole === "skilledcoordinator"
        ? Object.entries(await getUserProjects(userId)).map(([key, value]) => ({ id: key, name: value }))
        : await getInstances(organisationId);
  
      setInstances(_instances);
    };
    _getInstances();
  }, []);

  const handleSetActiveProject = (e) => setActiveProjectId(canvasDispatch, e.target.value);

  const [assignmentEditorVisible, setAssignmentEditorVisible] = useState(false);
  const handleSetAssignmentEditorVisibility = () => setAssignmentEditorVisible(!assignmentEditorVisible);

  return(
    instances &&
      <Box mt={2}>
        <FormControl fullWidth>
          <InputLabel htmlFor="project-select">Active Project</InputLabel>
          <Select
            sx={{ height: "40px"}}
            id="project-select"
            value={activeProjectId || ""}
            label="Active Project"
            onChange={e => handleSetActiveProject(e)}>
            {
              instances.map((project) => {
                return (
                  <MenuItem value={project.id} key={project.id}>{project.name}</MenuItem>
                )
              })
            }
          </Select>
        </FormControl>
        <Button sx={{ width: 1, px: 0 }}
          onClick={handleSetAssignmentEditorVisibility}>
          Open Assignment Editor
        </Button>
        {
          assignmentEditorVisible &&
            <AssignmentEditor
              projectId={activeProjectId}
              setEditorVisibility={handleSetAssignmentEditorVisibility}
              userRole={userRole}/>
        }
      </Box>
  )
}

export default AdministratorProjectPanel;