import { postWrapper, getWrapper } from '../fetchWrapper';

export const createInventoryItemType = async (inventoryItemType) => await postWrapper(`/api/inventoryitemtype/createinventoryitemtype`, inventoryItemType);
export const updateInventoryItemType = async (inventoryItemType) => await postWrapper(`/api/inventoryitemtype/updateinventoryitemtype`, inventoryItemType);

export const deleteInventoryItemType = async (inventoryItemTypeId) => await getWrapper(`/api/inventoryitemtype/deleteinventoryitemtype/` + inventoryItemTypeId);
export const getInventoryItemTypes = async (organisationId) => await getWrapper(`/api/inventoryitemtype/getinventoryitemtypes/` + organisationId);
export const getInventoryItemType = async (inventoryItemTypeId) => await getWrapper(`/api/inventoryitemtype/getinventoryitemtype/` + inventoryItemTypeId);

export const getInventoryItemTypeCategories = async () => await getWrapper(`/api/inventoryitemtype/getinventoryitemtypecategories`);
export const getInventoryItemQuantificationCategories = async () => await getWrapper(`/api/inventoryitemtype/getinventoryitemquantificationcategories`);
export const getInventoryItemAcquisitionCategories = async () => await getWrapper(`/api/inventoryitemtype/getinventoryitemacquisitioncategories`);

export const getItemTypeBrands = async (organisationId, inventoryItemTypeId) => await getWrapper(`/api/inventoryitemtype/getitemtypebrands/` + organisationId + '/' + inventoryItemTypeId);
export const toggleItemTypeHasBrand = async (organisationId, inventoryItemTypeId, brandId) => await getWrapper(`/api/inventoryitemtype/toggleitemtypehasbrand/` + organisationId + '/' + inventoryItemTypeId + '/' + brandId);
export const getAppliedItemTypeBrands = async (itemTypeId) => await getWrapper(`/api/inventoryitemtype/getapplieditemtypebrands/` + itemTypeId);
export const getItemTypeSkillSets = async (itemTypeId) => await getWrapper(`/api/inventoryitemtype/getitemtypeskillsets/` + itemTypeId);
export const toggleSkillSetNeedsItemType = async (itemTypeId, skillSetId) => await getWrapper(`/api/inventoryitemtype/toggleskillsetneedsitemtype/` + itemTypeId + '/' + skillSetId);
export const getSkillSetItemTypes = async (skillSetId) => await getWrapper(`/api/inventoryitemtype/getskillsetitemtypes/` + skillSetId);

export const createFunction = async (_function) => await postWrapper(`/api/inventoryitemtype/createfunction`, _function);
export const updateFunction = async (_function) => await postWrapper(`/api/inventoryitemtype/updatefunction`, _function);
export const deleteFunction = async (functionId) => await getWrapper(`/api/inventoryitemtype/deletefunction/` + functionId);
export const getFunctions = async (inventoryItemTypeId) => await getWrapper(`/api/inventoryitemtype/getfunctions/` + inventoryItemTypeId);
export const getFunction = async (functionId) => await getWrapper(`/api/inventoryitemtype/getfunction/` + functionId);