import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import keyImage from './Key.webp';

const services = [
  {
    "icon": "fa fa-diagram-project",
    "name": "3D Process Mapping",
    "text": "Merit’s innovative approach transforms process mapping into an inviting and accessible tool for all. We simplify the creation and interpretation of process maps while greatly increasing their utility."
  },
  {
    "icon": "fa fa-square-kanban",
    "name": "Project Planning",
    "text": "Merit integrates project planning by enabling you to link standardised specifications directly to the process, ensuring that each individual project is always a defined portion of the overall atlas."
  },
  {
    "icon": "fa fa-folder-open",
    "name": "Knowledge Management",
    "text": "Knowledge management is central to Merit’s approach, providing a clear and intuitive way to link documents and resources directly to processes, enabling seamless navigation of your organisation’s knowledge."
  },
  {
    "icon": "fa fa-solid fa-people-arrows",
    "name": "Business Intelligence",
    "text": "Our process maps incorporate a holistic data perspective, enabling you to extract valuable business intelligence from your processes and make data-driven decisions with confidence."
  }
];

export const Services = () => {
  return (
    <Box
      id="services"
      sx={{
        minHeight: '100vh',
        width: '100%',
        background: 'rgba(211, 211, 211, 0.97)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        py: { xs: 4, md: 6 },
        scrollMarginTop: { xs: '7px', sm: '-15px', md: '-20px' }
      }}
    >
      <Container>
        {/* Section Title */}
        <Box sx={{ mb: { xs: 3, md: 4 }, textAlign: 'center' }}>
          <Typography
            fontSize={{ xs: 22, md: 32 }}
            gutterBottom
            sx={{ fontWeight: '600 !important' }}>
            Capabilities
          </Typography>
          <Typography
            fontSize={{ xs: 15, md: 18 }}
            sx={{ fontWeight: '400 !important' }}
            lineHeight={1.6}
            maxWidth="800px"
            margin="0 auto"
          >
            Familiar services, to which Merit brings an entirely new and joined-up approach, all centered around our intuitive visual perspective.
          </Typography>
        </Box>

        <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" alignItems='center'>
          {/* Services Grid */}
          <Grid item xs={12} md={9}>
            <Grid container spacing={2} alignItems='stretch'>
              {services.map((service, index) => (
                <Grid key={index} item xs={12} sm={6} display='flex'>
                  <Box
                    sx={{
                      textAlign: 'left',
                      px: { xs: 2, md: 3 },
                      py: { xs: 2, md: 2 },
                      background: '#fff',
                      boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
                      borderRadius: '8px',
                      mb: { xs: 0, md: 0 },
                      display: 'flex',
                      flexDirection: 'column',
                      flexGrow: 1,
                    }}>
                    <Typography
                      fontSize={{ xs: 13, md: 19 }}
                      gutterBottom
                      sx={{ fontWeight: '600 !important' }}>
                      {service.name}
                    </Typography>
                    <Typography
                      fontSize={{ xs: 12, md: 16 }}
                      sx={{ fontWeight: '400 !important' }}
                      lineHeight={1.6}>
                      {service.text}
                    </Typography>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>

          {/* Key Image on the Right */}
          <Grid item xs={12} md={3}>
            <Box
              component="img"
              src={keyImage}
              alt="Key"
              sx={{
                maxWidth: { xs: '100%', md: '95%' },
                height: 'auto',
                borderRadius: '8px',
                ml: { xs: 'auto', md: 3 },
                mr: { xs: 'auto' },
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
