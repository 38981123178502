import { postWrapper, getWrapper } from '../fetchWrapper';

export const createAssociate = async (associate) => await postWrapper('/api/associate/createassociate', associate);
export const updateAssociate = async (associate) => await postWrapper('/api/associate/updateassociate', associate);

export const actionAssociateRequest = async (requestId, isApproved) => await getWrapper(`/api/associate/actionassociaterequest/` + requestId + `/` + isApproved);
export const deleteAssociate = async (associateId) => await getWrapper(`/api/associate/deleteassociate/` + associateId);
export const getAssociates = async (organisationId) => await getWrapper(`/api/associate/getassociates/` + organisationId);
export const getAssociate = async (organisationId, associateId) => await getWrapper(`/api/associate/getassociate/` + organisationId + `/` + associateId);
export const getAssociateRequests = async (organisationId) => await getWrapper(`/api/associate/getassociaterequests/` + organisationId);
export const createConnectionRequest = async (organisationId, associateId, associateMeritId) => await getWrapper(`/api/associate/createconnectionrequest/` + organisationId + `/` + associateId + `/` + associateMeritId);

export const getAssociateAtlases = async (organisationId, associationId) => await getWrapper(`/api/associate/getassociateatlases/` + organisationId + `/` + associationId);
export const toggleAssociateAtlasAccess = async (organisationId, associateId, atlasId) => await getWrapper(`/api/associate/toggleassociateatlasaccess/` + organisationId + `/` + associateId + `/` + atlasId);

export const getAssociateSkillSets = async (organisationId, associationId) => await getWrapper(`/api/associate/getassociateskillsets/` + organisationId + `/` + associationId);
export const toggleAssociateHasSkillSet = async (organisationId, skillSetId, associationId) => await getWrapper(`/api/associate/toggleassociatehasskillset/` + organisationId + `/` + skillSetId + `/` + associationId);

export const getAssociateProperties = async (organisationId, associationId) => await getWrapper(`/api/associate/getassociateproperties/` + organisationId + `/` + associationId);
export const toggleAssociateProperty = async (organisationId, propertyId, associationId) => await getWrapper(`/api/associate/toggleassociateproperty/` + organisationId + `/` + propertyId + `/` + associationId);