import React, { useState } from 'react';
import seedrandom from 'seedrandom';

// @mui components
import { Card, Box } from '@mui/material';

// recharts components
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

// Components
import { StatCard, ObjectStatusCardArray, commonCardStyle, outerBoxStyle, chartMargins, DashboardFilter } from 'components/AdministrationComponents/DashboardComponents';

const categories = [
  { name: 'Complete', color: 'green' },
  { name: 'Late', color: 'red' },
  { name: 'On Target', color: 'black' },
];

const ResultAdministratorDashboard = () => {
  const generateMockData = () => {
    const rng = seedrandom('fixed-seed');
    const dataLength = 30;
    const data = Array.from({ length: dataLength }, (_, index) => {
      const date = new Date(Date.now() - (86400000 * index));
      return {
        name: date.toLocaleDateString('en-GB', { day: 'numeric', month: 'numeric', year: 'numeric' }),
        instancesInProgress: Math.floor(rng() * 100) + 50,
        instancesPending: Math.floor(rng() * 100) + 30,
        date,
      };
    });
    return data;
  };

  const mockResultsData = generateMockData();
  const [selectedStatCard, setSelectedStatCard] = useState('');

  const handleStatCardClick = (title) => {
    setSelectedStatCard(title === selectedStatCard ? '' : title);
  };

  // Generate more mock data for the new component
  const generateMockDataForNewComponent = () => {
    const rng = seedrandom('fixed-seed-for-nent'); // Use a fixed seed here

    const data = Array.from({ length: 10 }, (_, index) => {
      const randomNum = rng(); // Use the seeded random number generator
      let category;
      if (randomNum < 0.6) {
        category = 'On Target';
      } else if (randomNum < 0.9) {
        category = 'Complete';
      } else {
        category = 'Late';
      }

      return {
        id: index,
        value: Math.floor(rng() * 100) + 20, // Use seeded random for value
        label: `Label ${index + 1}`,
        category,
      };
    });

    // Sort the data using the index of the category in the categories array
    return data.sort((a, b) => {
      const categoryAIndex = categories.findIndex((cat) => cat.name === a.category);
      const categoryBIndex = categories.findIndex((cat) => cat.name === b.category);
      return categoryAIndex - categoryBIndex;
    });
  };

  const mockDataForNewComponent = generateMockDataForNewComponent();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <DashboardFilter />
      <Box sx={outerBoxStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <StatCard
              title="Instances in Progress"
              count={mockResultsData[mockResultsData.length - 1].instancesInProgress}
              diff={2}
              selectedStatCard={selectedStatCard}
              setSelectedStatCard={setSelectedStatCard}
              onClick={() => handleStatCardClick('Instances in Progress')}
            />
            <StatCard
              title="Instances Pending"
              count={mockResultsData[mockResultsData.length - 1].instancesPending}
              diff={-1}
              selectedStatCard={selectedStatCard}
              setSelectedStatCard={setSelectedStatCard}
              onClick={() => handleStatCardClick('Instances Pending')}
            />
          </Box>

          {/* Chart Card for Results */}
          <Card elevation={2} sx={commonCardStyle}>
            <ResponsiveContainer height="99%">
              <LineChart data={mockResultsData} margin={{ ...chartMargins, right: 30 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" angle={30} dy={27} dx={46} style={{ fontSize: '10px' }} textAnchor="end" />
                <YAxis
                  label={{
                    value: 'Number of Instances',
                    angle: -90,
                    dx: -2,
                    dy: 10,
                    style: { fontSize: 12, fontWeight: 'bold' },
                  }}
                  tick={{ fontSize: 8 }}
                />
                <Tooltip />
                <Legend wrapperStyle={{ bottom: 51, left: 58 }} align="left"/>
                <Line type="monotone" dataKey="instancesInProgress" name="Instances in Progress" stroke="#8884d8" />
                <Line type="monotone" dataKey="instancesPending" name="Instances Pending" stroke="#82ca9d" />
                <ReferenceLine y={0} stroke="#000" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Box>
        <ObjectStatusCardArray data={mockDataForNewComponent} keyColours={categories}></ObjectStatusCardArray>
      </Box>
    </Box>
  );
};

export default ResultAdministratorDashboard;