import React, { useEffect, useState, useCallback } from "react";

import { debounce } from 'lodash';

// @mui components
import { Box, TextField, Typography, Select, MenuItem } from "@mui/material";

// Components
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

// Db Context
import { getInstanceVariable, updateDescriptorTag, toggleDescriptorTag, updateDescriptorValue } from "apiservices/ResultServices/instanceService";

const InstanceDescriptorEditor = ({ selectedInstanceVariableId }) => {
  const [editingInstanceVariable, setEditingInstanceVariable] = useState();
  const [inputValues, setInputValues] = useState({});

  useEffect(() => {
    const _getInstanceVariable = async () => {
      if (selectedInstanceVariableId > 0) {
        let _instanceVariable = await getInstanceVariable(selectedInstanceVariableId);
        setEditingInstanceVariable(_instanceVariable);

        // Initialize inputValues based on fetched data
        const initialValues = _instanceVariable.values.reduce((acc, value) => {
          acc[value.descriptorId] = value.value || '';
          return acc;
        }, {});
        setInputValues(initialValues);
      } else {
        setEditingInstanceVariable(undefined);
        setInputValues({});
      }
    };
    _getInstanceVariable();
  }, [selectedInstanceVariableId]);

  const handleChangeDescriptorTag = async (e, id) => {
    let tagId = await updateDescriptorTag(selectedInstanceVariableId, id, e.target.value);
    setEditingInstanceVariable((prevProjectVariable) => ({
      ...prevProjectVariable,
      values: prevProjectVariable.values.map((value) => {
        if (value.descriptorId === id) {
          return {
            ...value,
            assignedTagIds: [
              tagId,
              ...value.assignedTagIds.slice(1)
            ]
          };
        }
        return value;
      })
    }));
  };

  const handleToggleDescriptorTag = async (e, value) => {
    const selectedValue = parseInt(e.target.value[e.target.value.length - 1], 10);
    const updatedTagIds = value.assignedTagIds.includes(selectedValue)
      ? value.assignedTagIds.filter((tagId) => tagId !== selectedValue)
      : [...value.assignedTagIds, selectedValue];

    let tagIds = await toggleDescriptorTag({ projectVariableId: selectedInstanceVariableId, propertyId: value.descriptorId, tagIds: updatedTagIds });

    setEditingInstanceVariable((prevProjectVariable) => ({
      ...prevProjectVariable,
      values: prevProjectVariable.values.map((_value) => {
        if (_value.descriptorId === value.descriptorId) {
          return {
            ..._value,
            assignedTagIds: tagIds
          };
        }
        return _value;
      })
    }));
  };

  const handleUpdateDescriptorValue = async (e, id) => {
    try {
      const newValue = await updateDescriptorValue(selectedInstanceVariableId, id, e.target.value);

      setEditingInstanceVariable((prevProjectVariable) => ({
        ...prevProjectVariable,
        values: prevProjectVariable.values.map((v) =>
          v.descriptorId === id ? { ...v, value: newValue } : v
        ),
      }));

      setInputValues((prevInputValues) => ({
        ...prevInputValues,
        [id]: newValue,
      }));
    } catch (error) {
      console.error("Error updating descriptor value:", error);
    }
  };

  const debouncedUpdateDescriptorValue = useCallback(
    debounce((e, id) => handleUpdateDescriptorValue(e, id), 1000),
    [selectedInstanceVariableId]
  );

  return (
    <Box sx={{ backgroundColor: "#F5F5F5", borderRadius: "4px", height: 1, width: 0.5, display: 'flex', flexDirection: 'column', gap: 1, p: 1 }}>
      <ScrollbarWrapper>
        {editingInstanceVariable?.values.map((value) => (
          <Box key={value.descriptorId} sx={{ backgroundColor: '#fff', borderRadius: 1, display: 'flex', flexDirection: 'column', p: 1, pb: 1.5, gap: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', height: '44px' }}>
              <Typography>{value.name}</Typography>
              {value.descriptorTypeId === 1 ? (
                !value.allowMany ? (
                  <Select
                    variant="outlined"
                    value={value?.assignedTagIds[0] || ''}
                    sx={{ height: '40px', width: 0.5 }}
                    onChange={(e) => handleChangeDescriptorTag(e, value.descriptorId)}
                  >
                    {Object.entries(value.descriptorTags).map(([k, v]) => (
                      <MenuItem key={k} value={k}>{v}</MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Select
                    variant="outlined"
                    value={value?.assignedTagIds || ''}
                    sx={{ height: '40px', width: 0.5 }}
                    multiple
                    onChange={(e) => handleToggleDescriptorTag(e, value)}
                  >
                    {Object.entries(value.descriptorTags).map(([k, v]) => (
                      <MenuItem key={k} value={k}>{v}</MenuItem>
                    ))}
                  </Select>
                )
              ) : (
                <TextField
                  type="number"
                  variant="outlined"
                  label={value.name}
                  value={inputValues[value.descriptorId] || ''}
                  sx={{ height: '40px', width: 0.5 }}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    if (/^\d*$/.test(newValue)) {
                      setInputValues((prevInputValues) => ({
                        ...prevInputValues,
                        [value.descriptorId]: newValue,
                      }));
                      debouncedUpdateDescriptorValue(e, value.descriptorId);
                    }
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
      </ScrollbarWrapper>
    </Box>
  );
};

export default InstanceDescriptorEditor;