export function getMetricTableNodeIds(organisationNodeId, vertices, metricTable) {
  const nodes = vertices.filter(v => v.context.parentNodeId === organisationNodeId);
  
  metricTable.forEach(metric => {
    const node = nodes.find(n => n.context.authorisationId === metric.authorisationId);
    if (node) {
      metric.nodeId = node.context.id;
    }
  });
  return metricTable;
}

export function getAggregatedMetricTable(vertices, metricTable) {
  let aggregatedMetricTable = [...metricTable];

  for (let i = 5; i > 1; i--) {
    let parentNodeAggregatedValues = [];
    const parentGroupings = getGroupings(aggregatedMetricTable?.filter(m => m.networkId === i), 'parentNodeId');

    Object.entries(parentGroupings).forEach(([parentNodeId, nodes]) => {
      const projectGroupings = getGroupings(nodes, 'projectId');
      const parentNode = vertices.find(v => v.context.id === parseInt(parentNodeId));

      Object.entries(projectGroupings).forEach(([projectId, projectNodes]) => {
        const userGroupings = getGroupings(projectNodes, 'userId');

        Object.entries(userGroupings).forEach(([userId, userNodes]) => {
          parentNodeAggregatedValues.push({
            networkId: i - 1,
            nodeId: parseInt(parentNodeId),
            organisationId: userNodes[0].organisationId,
            parentNodeId: parentNode?.context.parentNodeId,
            projectId: parseInt(projectId),
            userId: parseInt(userId),
            value: userNodes.map(x => x.value).reduce((a, b) => a + b, 0)
          });
        });
      });
    });
    aggregatedMetricTable.push(...Object.values(parentNodeAggregatedValues));
  }

  return aggregatedMetricTable;
}

export function getMetaProfile(metricTable) {
  let metaProfile = {};
  for (let i = 5; i > 0; i--) {
    const networkMetrics = metricTable?.filter(metric => metric.networkId === i);

    const nodeValues = getGroupings(networkMetrics.filter(m => m.networkId === i), 'nodeId');
    const nodeValueSums = Object.values(nodeValues).map(x => x.map(y => y.value).reduce((a, b) => a + b, 0));
    const mean = calculateMean(nodeValueSums);

    const networkCharacteristics = {
      mean: mean,
      min: Math.min(...nodeValueSums),
      max: Math.max(...nodeValueSums),
      standardDeviation: calculateStandardDeviation(Object.values(networkMetrics).map(x => x.value), mean)
    };
    metaProfile[i] = networkCharacteristics;
  }
  return metaProfile;
}

export function getMetricSizes(metricTable, metaProfile, relateToZero=false) {
  let vertexSizes = {};
  for (let i = 5; i > 0; i--) {
    const networkMetaProfile = metaProfile[i];
    const nodeValueGroupings = getGroupings(metricTable.filter(m => m.networkId === i), 'nodeId');

    let networkValues = {};
    Object.entries(nodeValueGroupings).forEach(([nodeId, instances]) => {
      const sumValue = instances.map(v => v.value).reduce((a, b) => a + b, 0);
      const mean = sumValue / instances.length;

      let vals = {
        vertexId: parseInt(nodeId),
        value: sumValue,
        valueCount: instances.length,
        meanValue: instances.length > 0 ? mean : null,
        stdDev: instances.length > 0 ? calculateStandardDeviation(instances.map(x => x.value), mean) : null,
        relativeSize: instances.length > 0 && networkMetaProfile.max !== networkMetaProfile.min 
          ? relateToZero ? 
              Math.max((sumValue - networkMetaProfile.min) / (networkMetaProfile.max - networkMetaProfile.min), 0.0001) :
              Math.max((sumValue) / (networkMetaProfile.max), 0.0001)
          : 1,
      };
      networkValues[nodeId] = vals;
    });
    vertexSizes[i] = networkValues;
  }
  return vertexSizes;
}

function getGroupings(nodes, key) {
  return nodes.reduce((acc, current) => {
    const keyValue = current[key];
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(current);
    return acc;
  }, {});
}

//below can remain

export function getHeatMap(metricTable, orgMetricTable) {
  const orgHeatMap = {};

  Object.entries(orgMetricTable).forEach(([networkId, orgNodeMetricSet]) => {
    let heats = {};
    Object.entries(orgNodeMetricSet).forEach(([nodeId, orgNodeMetric]) => {
      const metric = metricTable[networkId][nodeId];

      if (metric && metric.valueCount !== orgNodeMetric.valueCount) {
        const deviation = (metric.meanValue - orgNodeMetric.meanValue) / metric.stdDev;
        const safeDeviation = isNaN(deviation) ? 0 : deviation;
        heats[orgNodeMetric.vertexId] = getColor(safeDeviation);
      }
    });
    orgHeatMap[networkId] = heats;
  });
  return orgHeatMap;
}

function calculateMean(values) {
  const total = values.reduce((sum, value) => sum + value, 0);
  return total / values.length;
}

function calculateStandardDeviation(values, mean) {
  const squareDiffs = values.map(value => Math.pow(value - mean, 2));
  const avgSquareDiff = calculateMean(squareDiffs);
  return Math.sqrt(avgSquareDiff);
}

function blendColors(color1, color2, factor) {
  let result = color1.slice(1).match(/.{2}/g).map(function(hex) {
    return parseInt(hex, 16);
  });
  color2.slice(1).match(/.{2}/g).map(function(hex, i) {
    result[i] = Math.round(result[i] * (1 - factor) + parseInt(hex, 16) * factor);
  });
  return '#' + result.map(function(val) {
    return ('0' + val.toString(16)).slice(-2);
  }).join('');
}

function getColor(deviation) {
  const green = '#00ff00';
  const red = '#ff0000';
  const neutral = '#e0e0e0';

  if (deviation <= -1) {
    return green;
  } else if (deviation >= 1) {
    return red;
  } else if (deviation < 0) {
    const factor = Math.abs(deviation);
    return blendColors(green, neutral, factor);
  } else {
    const factor = deviation;
    return blendColors(red, neutral, factor);
  }
}
