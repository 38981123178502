import React, { useState, useEffect } from 'react';

//@mui components
import { Box, Grid } from '@mui/material';
import { Typography, TextField, Divider } from '@mui/material';

// Components
import ScrollbarWrapper from 'components/GlobalComponents/ScrollbarWrapper';
import { FixedItemMiniMediaCard } from 'components/Cards/FixedItemMediaCard';

// Local context
import { useAdminController } from "context";

// Db Context
import { getToolInventory } from 'apiservices/InventoryServices/allocationService';

const ToolkitBuilder = ({toolkit, userToolkit, searchboxLabel="Search here"}) => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [selectedToolId, setSelectedToolId] = useState();
  const [toolInventory, setToolInventory] = useState([]);

  useEffect(() => {
    if (!selectedToolId) return;
    const _getToolInventory = async () => {
      let _toolInventory = await getToolInventory(selectedToolId, organisationId);
      setToolInventory(_toolInventory);
    }
    _getToolInventory();
  }, [selectedToolId]);

  const handleSetSelectedInstanceId = async (id) => {
    //let _userAllocations = await toggleUserAllocation(organisationId, inventoryItem.id, userId);
    //setUserAllocations(_userAllocations);
  }
  
  return(
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box sx={{ height: 1, display: "flex" }} gap={1}>
        {
          [toolkit.requiredItems, toolInventory].map((items, i) => {
            return(
              <Box
                borderRadius="6px"
                display="flex"
                flexDirection="column"
                flexGrow={1}
                justifyContent="flex-start"
                alignItems="flex-start"
                p={1}
                style={{ backgroundColor: "#faf7f2" }}
                width={0.5}
                overflow={"hidden"}
                key={i}>
                <Box width={1} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                  {/* <Typography variant="h1" fontSize={16}>{titles[i]}</Typography> */}
                  <TextField variant="outlined" label={searchboxLabel} size="small"/>
                </Box>
                <Divider sx={{width: "100%", my: 1}}/>
                <ScrollbarWrapper>
                  <Grid container spacing={1} justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start" height={1}>
                    {
                      i === 0 ?
                        items.map((item) => {
                          return (
                            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} sx={{ height: "8.75rem" }} key={item.id}>
                              <FixedItemMiniMediaCard
                                id={item.id}
                                name={item.name}
                                action={() => setSelectedToolId(item.id)}
                                selected={selectedToolId === item.id}
                                borderColour={userToolkit.hasOwnProperty(item.id) ? 'green' : undefined}
                                media={item.media}
                              />
                            </Grid>
                          )
                        })
                      :
                        items.map((item) => {
                          return (
                            <Grid item xl={3} lg={3} md={4} sm={6} xs={12} sx={{ height: "8.75rem" }} key={item.id}>
                              <FixedItemMiniMediaCard
                                id={item.id}
                                name={item.name}
                                action={() => handleSetSelectedInstanceId(item.id)}
                                selected={userToolkit.hasOwnProperty(selectedToolId) &&
                                          userToolkit[selectedToolId] === item.id}
                                media={item.media}
                              />
                            </Grid>
                          )
                        })
                    }
                  </Grid>
                </ScrollbarWrapper>
              </Box>
            )
          })
        }
      </Box>
    </Box>
  )
}

export default ToolkitBuilder;