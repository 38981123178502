import React, { useState, useEffect } from "react";

// react-router-dom components
import { useSearchParams } from "react-router-dom";

// object comparer
import { isEqual } from "lodash";

// @mui components
import { Box, Typography, IconButton } from "@mui/material";
import { TabContext } from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Components
import { AdministrationViewWrapper, CardTypeSwitch } from "components/AdministrationComponents/General";
import ScrollbarWrapper from 'components/GlobalComponents/ScrollbarWrapper';
import { FormSaveButton } from "components/GlobalComponents/Buttons";
import { AdminPanel } from "components/AdministrationComponents/StyledAdminComponents";

// Local context
import { useAdminController, setIsDirty, setMiniViewHeader } from "context";

const AdministrationObjectWrapper = ({
  object,
  setObject,
  getObject,
  createObject,
  updateObject,
  nameField = "name",
  descriptionField = "description",
  namePlaceholder,
  descriptionPlaceholder,
  iconComponent,
  activeTab,
  header,
  form,
  panels,
  getByUserId,
  requiresOrgId=false,
  restrictable=true,
  modal=false,
  defaultId=0,
  validate,
  setInvalidFields,
  invalidFields,
  scrollable=true
}) => {
  const [controller, dispatch] = useAdminController();
  const { organisationId, userId, miniViewHeader, isDirty } = controller;
  const [searchParams, setSearchParams] = useSearchParams();
  const objectId = searchParams.get('id');
  
  const [initialObject, setInitialObject] = useState();

  useEffect(() => {
    if(!getObject) return;
    const _getObject = async () => {
      let _object = requiresOrgId ?
       await getObject(organisationId, objectId) :
       getByUserId ?
         await getObject(userId) :
         await getObject(objectId);
      setObject(_object);
      setInitialObject(_object);
      setIsDirty(dispatch, false);
    };
    _getObject();
  }, []);

  useEffect(() => {
    if(restrictable){
      if (isEqual(object, initialObject)) {
        setIsDirty(dispatch, false);
      } else {
        setIsDirty(dispatch, true);
      }
    }
  }, [object, initialObject, dispatch]);

  const handleSave = async () => {
    const invalidFields = validate(object);
    setInvalidFields({ ...invalidFields });
    if (Object.keys(invalidFields).length > 0) return;

    object.organisationId = organisationId;
    object.sentBy = userId;
    let result = object.id === defaultId ?
      await createObject(object):
      await updateObject(object);
    setSearchParams({ id: result.id.toString() });
    setObject(result);
    setInitialObject(result);
    setIsDirty(dispatch, false);
  };

  const handleMiniViewHeader = () => {
    setMiniViewHeader(dispatch, !miniViewHeader);
  };

  const content = (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        {
          (miniViewHeader && object && object?.id !== defaultId) ? 
            <Box sx={{ background: 'white', minHeight: 38, borderRadius: 2, display: 'flex', flexWrap: 'wrap', padding: '0 8px', alignItems: 'center' }}>
              <Typography sx={{ marginRight: 3 }}>
                Name: {object[nameField]}
              </Typography>
              {
                object[descriptionField] !== "" &&
                  <Typography>
                    Description: {object[descriptionField]}
                  </Typography>
              }
            </Box>
           : 
            <CardTypeSwitch 
              obj={object}
              setObj={setObject}
              icon={iconComponent}
              nameField={nameField}
              descriptionField={descriptionField}
              invalidFields={invalidFields}
              validate={validate}
              setInvalidFields={setInvalidFields}
              namePlaceholder={namePlaceholder}
              descriptionPlaceholder={descriptionPlaceholder}/>
        }
        <IconButton onClick={handleMiniViewHeader} sx={{ transition: 'transform 400ms' }}>
          <ExpandMoreIcon sx={{
            transform: miniViewHeader ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 400ms'
          }} />
        </IconButton>
      </Box>
      <AdminPanel>
        <TabContext value={activeTab}>
          {header}
          {
            activeTab === "1" &&
              <>
                {
                  scrollable ? (
                    <ScrollbarWrapper style={{ mx: 1, mt: 2 }} marginRight={1}>
                      {form}
                    </ScrollbarWrapper>
                  ) : (
                    form
                  )
                }
                {
                  (object?.organisationId !== null || object.id === 0) &&
                    <FormSaveButton handleSave={handleSave} isDirty={isDirty}/>
                }
              </>
          }
          {panels}
        </TabContext>
      </AdminPanel>
    </>
  );

  return object && (
    modal ? content : <AdministrationViewWrapper>{content}</AdministrationViewWrapper>
  );
}

export default AdministrationObjectWrapper;