import React, { useState, useEffect } from "react";

// @mui components
import { FormControl, FormGroup, FormControlLabel, Tab, RadioGroup, Radio, Tooltip, TextField, Checkbox } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import InventoryIcon from '@mui/icons-material/Inventory';

// Components
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminFormLabel, AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";
import FunctionEditor from "./FunctionEditor";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db Context
import { createInventoryItemType, updateInventoryItemType, getInventoryItemType, getItemTypeBrands,
         getInventoryItemTypeCategories, getInventoryItemQuantificationCategories, getInventoryItemAcquisitionCategories,
         getItemTypeSkillSets, toggleSkillSetNeedsItemType, toggleItemTypeHasBrand, 
         deleteFunction, getFunctions } from "apiservices/InventoryServices/inventoryItemTypeService";



// Local context
import { useAdminController } from "context";

let createButton = [{icon: "add", name: "Add Function to Item Type", id: 0}]

const InventoryItemType = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [inventoryItemTypeCategories, setInventoryItemTypeCategories] = useState({});
  const [inventoryItemQuantificationCategories, setInventoryItemQuantificationCategories] = useState({});
  const [inventoryItemAcquisitionCategories, setInventoryItemAcquisitionCategories] = useState({});

  useEffect(() => {
    const _getFieldCategories = async () => {
      let _inventoryItemTypeCategories = await getInventoryItemTypeCategories();
      let _inventoryItemQuantificationCategories = await getInventoryItemQuantificationCategories();
      let _inventoryItemAcquisitionCategories = await getInventoryItemAcquisitionCategories();
      setInventoryItemTypeCategories(_inventoryItemTypeCategories);
      setInventoryItemQuantificationCategories(_inventoryItemQuantificationCategories);
      setInventoryItemAcquisitionCategories(_inventoryItemAcquisitionCategories);
    };
    _getFieldCategories();
  }, []);

  const [inventoryItemType, setInventoryItemType] = useState();
  const [brands, setBrands] = useState([]);
  const [skillSets, setSkillSets] = useState([]);
  const [functions, setFunctions] = useState([]);
  useEffect(() => {
    if (inventoryItemType?.id === 0 || !inventoryItemType) return;
    const _getBrands = async () => {
      let _brands = await getItemTypeBrands(organisationId, inventoryItemType.id);
      let _skillSets = await getItemTypeSkillSets(inventoryItemType.id);
      let _functions = await getFunctions(inventoryItemType.id);
      setBrands(_brands)
      setSkillSets(_skillSets);
      setFunctions(_functions);
    };
    _getBrands();
  }, [inventoryItemType]);
  
  const handleSetSetting =(e) => setInventoryItemType({...inventoryItemType, ...{[e.target.name]: e.target.value}});

  const handleToggleItemTypeHasBrand = async (e, id) => {
    let _brands = await toggleItemTypeHasBrand(organisationId, inventoryItemType.id, id);
    setBrands(_brands);
  }

  const handleToggleSkillSetNeedsItemType = async (e, id) => {
    let _skillSets = await toggleSkillSetNeedsItemType(inventoryItemType.id, id);
    setSkillSets(_skillSets);
  }

  const handleDeleteFunction = async (e, functionId, onDeleteComplete) => {
    let _functions = await deleteFunction(functionId);
    setFunctions(_functions);
    onDeleteComplete();
  }

  const handleSetMaximumUsers = (value) => {
    setInventoryItemType((prev) => ({ ...prev, maximumUsers: value }));
  };

  const [activeFunction, setActiveFunction] = useState(-1);
  const handleSetActiveFunction = (e, id) => setActiveFunction(id);
  
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(inventoryItemType.id !== 0)
      setActiveTab(tabNumber);
  };

  return(
    <>
      <AdministrationObjectWrapper
        object={inventoryItemType}
        setObject={setInventoryItemType}
        getObject={getInventoryItemType}
        createObject={createInventoryItemType}
        updateObject={updateInventoryItemType}
        namePlaceholder="Inventory Item Type Name"
        descriptionPlaceholder="Description of Inventory Item Type"
        iconComponent={<InventoryIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          inventoryItemType &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Details" value="1"/>
              {
                inventoryItemType.id !== 0 ?
                  <Tab label="Brands" value="2"/> :
                  <Tooltip title="Save the inventory item type to begin specifying brands">
                    <Tab label="Brands" value="2"/>
                  </Tooltip>
              }
              {
                inventoryItemType.id !== 0 ?
                  <Tab label="Skill Sets" value="3"/> :
                  <Tooltip title="Save the inventory item type to apply it to skillsets">
                    <Tab label="Skill Sets" value="3"/>
                  </Tooltip>
              }
              {
                inventoryItemType.id !== 0 ?
                  <Tab label="Functions" value="4"/> :
                  <Tooltip title="Save the inventory item type to add functionality">
                    <Tab label="Functions" value="4"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          inventoryItemType &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
                <FormGroup sx={{width: "100%", mt: 2}}>
                  <FormControl>
                    <AdminFormLabel id="inventory-item-type-category-radio-label">Item Type Category:</AdminFormLabel>
                    <RadioGroup
                      sx={{ml: 1}}
                      name="categoryId"
                      row
                      aria-labelledby="inventory-item-type-category-radio-label"
                      value={inventoryItemType.categoryId}
                      onChange={handleSetSetting}>
                      {Object.keys(inventoryItemTypeCategories).map((key) => (
                        <FormControlLabel key={key} value={key} control={<Radio />} label={inventoryItemTypeCategories[key]} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <AdminFormLabel id="quantification-type-radio-label">Quantification Type:</AdminFormLabel>
                    <RadioGroup
                      sx={{ml: 1}}
                      row
                      name="quantificationTypeId"
                      aria-labelledby="quantification-type-radio-label"
                      value={inventoryItemType.quantificationTypeId}
                      onChange={handleSetSetting}>
                      {Object.keys(inventoryItemQuantificationCategories).map((key) => (
                        <FormControlLabel key={key} value={key} control={<Radio />} label={inventoryItemQuantificationCategories[key]} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <AdminFormLabel id="acquisition-type-radio-label">Acquisition Type:</AdminFormLabel>
                    <RadioGroup
                      sx={{ml: 1}}
                      row
                      name="acquisitionTypeId"
                      aria-labelledby="acquisition-type-radio-label"
                      value={inventoryItemType.acquisitionTypeId}
                      onChange={handleSetSetting}>
                      {Object.keys(inventoryItemAcquisitionCategories).map((key) => (
                        <FormControlLabel key={key} value={key} control={<Radio />} label={inventoryItemAcquisitionCategories[key]} />
                      ))}
                    </RadioGroup>
                  </FormControl>
                  <FormControl>
                    <AdminFormLabel id="inventory-item-type-maximum-users-label">Maximum Users:</AdminFormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={inventoryItemType.maximumUsers === null}
                          onChange={(event) => handleSetMaximumUsers(event.target.checked ? null : 1)}
                        />
                      }
                      label="Unlimited"
                    />
                    <TextField
                      type="number"
                      value={inventoryItemType.maximumUsers || ''}
                      disabled={inventoryItemType.maximumUsers === null}
                      onChange={(event) => handleSetMaximumUsers(event.target.valueAsNumber)}
                      variant="outlined"
                      sx={{ mt: 2 }}
                    />
                  </FormControl>
                </FormGroup>
              </TabPanel>
          }
          panels={
            inventoryItemType?.id !== 0 &&
              <>
                <TabPanel 
                  value="2"
                  style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
                  <SplitMediaCardArray
                    items={brands}
                    titles={["Brands who supply this item", "All other brands"]}
                    action={handleToggleItemTypeHasBrand}
                    searchboxLabel="Search brands"/>
                </TabPanel>
                <TabPanel 
                  value="3"
                  style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
                  <SplitMediaCardArray
                    items={skillSets}
                    titles={["SkillSets which require this item type", "All other skillsets"]}
                    action={handleToggleSkillSetNeedsItemType}
                    searchboxLabel="Search Inventory Item Types"/>
                </TabPanel>
                <TabPanel 
                  value="4"
                  style={{ padding: 8, display: activeTab === "4" ? "flex" : "none", flexGrow: 1 }}>
                  <CreateAndEditInPlaceArray
                    items={createButton.concat(functions)}
                    editor={handleSetActiveFunction}
                    deleteAction={handleDeleteFunction}/>
                </TabPanel>
              </>
          }
          validate={validate}
          setInvalidFields={setInvalidFields}
          invalidFields={invalidFields}
      />
      {
        activeFunction !== -1 &&
          <FunctionEditor
            closeEditor={handleSetActiveFunction}
            functionId={activeFunction}
            setFunctions={setFunctions}
            inventoryItemTypeId={inventoryItemType.id}/>
      }
    </>
  )
}

export default InventoryItemType;