//This file is used for controlling the global states of canvas objects
import React, { createContext, useContext, useReducer, useMemo } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Application } from "pixi.js";

import { createGradientTexture, createDirectionalSpotlight, createHatching } from "helpers/canvashelpers/textures";

// Canvas context
const canvas = createContext();

// Setting custom name for the context which is visible on react dev tools
canvas.displayName = "CanvasContext";

// React reducer
function reducer(state, action) {
  switch (action.type) {
    case "STATE_LOADED":
      return { ...state, stateLoaded: action.value };

    case "VERTICES":
      return { ...state, vertices: action.value };
    case "UNCONFIGURED_JOBS":
      return { ...state, unconfiguredJobs: action.value };
    case "OPEN_DRAWERS":
      return { ...state, openDrawers: action.value };
    case "PROJECT_TOGGLED":
      return { ...state, projectToggled: action.value };
    case "VERTEX_TAG_COLOURS":
      return { ...state, vertexTagColours: action.value };
    case "METRIC_TABLES":
      return { ...state, metricTables: action.value };
    case "VERTEX_HEAT_COLOURS":
      return { ...state, vertexHeatColours: action.value };
    case "VERTEX_INNER_SIZES":
      return { ...state, vertexInnerSizes: action.value };
    case "RELATE_SIZES_TO_ZERO":
      return { ...state, relateSizesToZero: action.value };
    case "SUM_METRIC_VALUES":
      return { ...state, sumMetricValues: action.value };
    case "EDGES":
      return { ...state, edges: action.value };
    case "TAG_GROUPS":
      return { ...state, tagGroups: action.value };
    case "METRICS":
      return { ...state, metrics: action.value };
    case "ORGANISATION_NODE_ID":
      return { ...state, organisationNodeId: action.value };
    case "USER_ATLAS_PERMISSIONS":
      return { ...state, userAtlasPermissions: action.value };

    case "SCALE":
      return { ...state, scale: action.value };
    case "COORDINATES":
      return { ...state, coordinates: action.value };
    case "OVERRIDE_LEVEL_ID":
      return { ...state, overrideLevelId: action.value };
    case "SELECTED_OBJECT_TYPE":
      return { ...state, selectedObjectType: action.value };
    case "SELECTED_OBJECT":
      return { ...state, selectedObject: action.value };
    case "SELECTED_EXECUTOR_ID":
      return { ...state, selectedExecutorId: action.value };
    case "SELECTED_PROJECT_ID":
      return { ...state, selectedProjectId: action.value };
    case "METRICS_VISIBLE":
      return { ...state, metricsVisible: action.value };
    case "HOVER_ACTIVE":
      return { ...state, hoverActive: action.value };
    case "TAGS_VISIBLE":
      return { ...state, tagsVisible: action.value };
    case "METRICS_VISIBLE":
      return { ...state, metricsVisible: action.value };
    case "HEAT_MAP_VISIBLE":
      return { ...state, heatMapVisible: action.value };

    case "ACTIVE_PROJECT_ID":
      return { ...state, activeProjectId: action.value };
    case "ACTIVE_ASSIGNMENT_ID":
      return { ...state, activeAssignmentId: action.value };

    case "ORGANISATION_TAG_GROUP_ID":
      return { ...state, organisationTagGroupId: action.value };
    case "JOB_TAG_GROUP_ID":
      return { ...state, jobTagGroupId: action.value };
    case "STEP_TAG_GROUP_ID":
      return { ...state, stepTagGroupId: action.value };
    case "CUSTOM_PRODUCT_TAG_GROUP_ID":
      return { ...state, customProductTagGroupId: action.value };
    case "SUBSTEP_TAG_GROUP_ID":
      return { ...state, substepTagGroupId: action.value };
    case "GENERIC_INPUT_TAG_GROUP_ID":
      return { ...state, genericInputTagGroupId: action.value };
    case "ACTION_TAG_GROUP_ID":
      return { ...state, actionTagGroupId: action.value };
    
    case "PROPERTY_PANEL_VISIBLE":
      return { ...state, propertyPanelVisible: action.value };
    case "KEY_PANEL_VISIBLE":
      return { ...state, keyPanelVisible: action.value };
    case "PROJECT_PANEL_VISIBLE":
      return { ...state, projectPanelVisible: action.value };
    case "QUALITY_PANEL_VISIBLE":
      return { ...state, qualityPanelVisible: action.value };
    case "PROJECT_MODE":
      return { ...state, projectMode: action.value };

    case "ACTIVE_PROPERTY_PANEL_TAB":
      return { ...state, activePropertyPanelTab: action.value };
    case "ACTIVE_KEY_PANEL_TAB":
      return { ...state, activeKeyPanelTab: action.value };
    case "ACTIVE_PROJECT_PANEL_TAB":
      return { ...state, activeProjectPanelTab: action.value };
    case "ACTIVE_KEY":
      return { ...state, activeKey: action.value };

    case "NETWORK_STATES":
      return { ...state, networkStates: action.value };

    case "INTERACTION_MODE":
      return { ...state, interactionMode: action.value };
    case "PROPERTIES_MANAGER_WINDOW":
      return { ...state, propertiesManagerWindow: action.value };
    case "ACTIVE_NETWORK_IDS":
      return { ...state, activeNetworkIds: action.value };
    case "ACTIVE_LEVEL":
      return { ...state, activeLevel: action.value };
    case "ACTIVE_TAG":
      return { ...state, activeTag: action.value };
    case "ACTIVE_METRIC":
      return { ...state, activeMetric: action.value };
    case "HIGHLIGHTED_VERTEX_IDS":
      return { ...state, highlightedVertexIds: action.value };

    case "USER_ASSIGNMENT_NODES":
      return { ...state, userAssignmentNodes: action.value };

    case "STATS_WINDOW":
      return { ...state, statsWindow: action.value };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

let app;

function CanvasControllerProvider({ children }) {
  const initialState = {
    stateLoaded: false,

    vertices: undefined,
    unconfiguredJobs: undefined,
    openDrawers: {},
    projectToggled: false,
    vertexTagColours: {},
    metricTables: [],
    vertexHeatColours: {},
    vertexInnerSizes: undefined,
    relateSizesToZero: false,
    sumMetricValues: false,
    edges: undefined,
    tagGroups: undefined,
    metrics: undefined,
    organisationNodeId: undefined,
    userAtlasPermissions: undefined,

    scale: 1,
    coordinates: {x: 0, y: 0},
    overrideLevelId: null,
    selectedObjectType: 'vertex',
    selectedObject: {id: 0, networkId: 0},
    selectedExecutorId: undefined,
    selectedProjectId: undefined,
    tagsVisible: true,
    metricsVisible: false,
    heatMapVisible: false,
    hoverActive: false,
    metricsVisible: false,

    activeProjectId: undefined,
    activeAssignmentId: undefined,

    organisationTagGroupId: undefined,
    jobTagGroupId: undefined,
    stepTagGroupId: undefined,
    customProductTagGroupId: undefined,
    substepTagGroupId: undefined,
    genericInputTagGroupId: undefined,
    actionTagGroupId: undefined,

    propertyPanelVisible: false,
    keyPanelVisible: false,
    projectPanelVisible: false,
    qualityPanelVisible: false,
    projectMode: undefined,

    activePropertyPanelTab: "1",
    activeKeyPanelTab: "1",
    activeProjectPanelTab: "1",
    activeKey: "1",

    networkStates: undefined,

    interactionMode: "select",
    propertiesManagerWindow: undefined,
    activeNetworkIds: [0],
    activeLevel: 1,
    activeTag: undefined,
    activeMetric: undefined,
    highlightedVertexIds: [],

    userAssignmentNodes: undefined,

    statsWindow: undefined
  };

  if(!app){
    app = new Application({
      antialias: true,
      backgroundColor: 0xe0e0e0,
      resizeTo: window,
    });
    document.body.appendChild(app.view);
    document.body.style.overflow = 'hidden';
    app.view.id = "atlascanvas";
    app.stage.sortableChildren = true;
    app.stage.eventMode = "dynamic";

    app.resources = {
      createGradientTexture: createGradientTexture,
      createDirectionalSpotlight: createDirectionalSpotlight,
      createHatching: createHatching
    };
  }
  
  const [controller, dispatch] = useReducer(reducer, initialState);
  
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  
  return <canvas.Provider value={value}>{children}</canvas.Provider>;
}

// React custom hook for using context
function useCanvasController() {
  const canvasContext = useContext(canvas);
  if (!canvasContext)
    throw new Error(
      "useCanvasController should be used inside the CanvasControllerProvider."
    );

  return canvasContext;
}

// Typechecking props for the CanvasControllerProvider
CanvasControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Context module functions
const getApp = () => { return app };
const setApp = (value) => { app = value };

const setStateLoaded = (dispatch, value) => dispatch({ type: "STATE_LOADED", value});

const setVertices = (dispatch, value) => dispatch({ type: "VERTICES", value });
const setUnconfiguredJobs = (dispatch, value) => dispatch({ type: "UNCONFIGURED_JOBS", value });
const setOpenDrawers = (dispatch, value) => dispatch({ type: "OPEN_DRAWERS", value });
const setProjectToggled = (dispatch, value) => dispatch({ type: "PROJECT_TOGGLED", value });
const setVertexTagColours = (dispatch, value) => dispatch({ type: "VERTEX_TAG_COLOURS", value });
const setMetricTables = (dispatch, value) => dispatch({ type: "METRIC_TABLES", value });
const setVertexHeatColours = (dispatch, value) => dispatch({ type: "VERTEX_HEAT_COLOURS", value });
const setVertexInnerSizes = (dispatch, value) => dispatch({ type: "VERTEX_INNER_SIZES", value });
const setRelateSizesToZero = (dispatch, value) => dispatch({ type: "RELATE_SIZES_TO_ZERO", value });
const setSumMetricValues = (dispatch, value) => dispatch({ type: "SUM_METRIC_VALUES", value });
const setEdges = (dispatch, value) => dispatch({ type: "EDGES", value });
const setTagGroups = (dispatch, value) => dispatch({ type: "TAG_GROUPS", value });
const setMetrics = (dispatch, value) => dispatch({ type: "METRICS", value });
const setOrganisationNodeId = (dispatch, value) => dispatch({ type: "ORGANISATION_NODE_ID", value });
const setUserAtlasPermissions = (dispatch, value) => dispatch({ type: "USER_ATLAS_PERMISSIONS", value });

const setScale = (dispatch, value) => dispatch({ type: "SCALE", value });
const setCoordinates = (dispatch, value) => dispatch({ type: "COORDINATES", value });
const setOverrideLevelId = (dispatch, value) => dispatch({ type: "OVERRIDE_LEVEL_ID", value });
const setSelectedObjectType = (dispatch, value) => dispatch({ type: "SELECTED_OBJECT_TYPE", value });
const setSelectedObject = (dispatch, value) => dispatch({ type: "SELECTED_OBJECT", value });
const setSelectedExecutorId = (dispatch, value) => dispatch({ type: "SELECTED_EXECUTOR_ID", value });
const setSelectedProjectId = (dispatch, value) => dispatch({ type: "SELECTED_PROJECT_ID", value });
const setTagsVisible = (dispatch, value) => dispatch({ type: "TAGS_VISIBLE", value });
const setMetricsVisible = (dispatch, value) => dispatch({ type: "METRICS_VISIBLE", value });
const setHeatMapVisible = (dispatch, value) => dispatch({ type: "HEAT_MAP_VISIBLE", value });
const setHoverActive = (dispatch, value) => dispatch({ type: "HOVER_ACTIVE", value });

const setActiveProjectId = (dispatch, value) => dispatch({ type: "ACTIVE_PROJECT_ID", value});
const setActiveAssignmentId = (dispatch, value) => dispatch({ type: "ACTIVE_ASSIGNMENT_ID", value});

const setOrganisationTagGroupId = (dispatch, value) => dispatch({ type: "ORGANISATION_TAG_GROUP_ID", value });
const setJobTagGroupId = (dispatch, value) => dispatch({ type: "JOB_TAG_GROUP_ID", value });
const setStepTagGroupId = (dispatch, value) => dispatch({ type: "STEP_TAG_GROUP_ID", value });
const setCustomProductTagGroupId = (dispatch, value) => dispatch({ type: "CUSTOM_PRODUCT_TAG_GROUP_ID", value });
const setSubstepTagGroupId = (dispatch, value) => dispatch({ type: "SUBSTEP_TAG_GROUP_ID", value });
const setGenericInputTagGroupId = (dispatch, value) => dispatch({ type: "GENERIC_INPUT_TAG_GROUP_ID", value });
const setActionTagGroupId = (dispatch, value) => dispatch({ type: "ACTION_TAG_GROUP_ID", value });

const setPropertyPanelVisible = (dispatch, value) => dispatch({ type: "PROPERTY_PANEL_VISIBLE", value });
const setKeyPanelVisible = (dispatch, value) => dispatch({ type: "KEY_PANEL_VISIBLE", value });
const setProjectPanelVisible = (dispatch, value) => dispatch({ type: "PROJECT_PANEL_VISIBLE", value });
const setQualityPanelVisible = (dispatch, value) => dispatch({ type: "QUALITY_PANEL_VISIBLE", value });
const setProjectMode = (dispatch, value) => dispatch({ type: "PROJECT_MODE", value });

const setActivePropertyPanelTab = (dispatch, value) => dispatch({ type: "ACTIVE_PROPERTY_PANEL_TAB", value });
const setActiveKeyPanelTab = (dispatch, value) => dispatch({ type: "ACTIVE_KEY_PANEL_TAB", value });
const setActiveProjectPanelTab = (dispatch, value) => dispatch({ type: "ACTIVE_PROJECT_PANEL_TAB", value });
const setActiveKey = (dispatch, value) => dispatch({ type: "ACTIVE_KEY", value });

const setNetworkStates = (dispatch, value) => dispatch({ type: "NETWORK_STATES", value });

const setInteractionMode = (dispatch, value) => dispatch({ type: "INTERACTION_MODE", value });
const setPropertiesManagerWindow = (dispatch, value) => dispatch({ type: "PROPERTIES_MANAGER_WINDOW", value });
const setActiveNetworkIds = (dispatch, value) => dispatch({ type: "ACTIVE_NETWORK_IDS", value });
const setActiveLevel = (dispatch, value) => dispatch({ type: "ACTIVE_LEVEL", value });
const setActiveTag = (dispatch, value) => dispatch({ type: "ACTIVE_TAG", value });
const setActiveMetric = (dispatch, value) => dispatch({ type: "ACTIVE_METRIC", value });
const setHighlightedVertexIds = (dispatch, value) => dispatch({ type: "HIGHLIGHTED_VERTEX_IDS", value });

const setUserAssignmentNodes = (dispatch, value) => dispatch({ type: "USER_ASSIGNMENT_NODES", value });

const setStatsWindow = (dispatch, value) => dispatch({ type: "STATS_WINDOW", value });

export {
  CanvasControllerProvider,
  useCanvasController,

  getApp,
  setApp,

  setStateLoaded,

  setVertices,
  setUnconfiguredJobs,
  setOpenDrawers,
  setProjectToggled,
  setVertexTagColours,
  setMetricTables,
  setVertexHeatColours,
  setVertexInnerSizes,
  setRelateSizesToZero,
  setSumMetricValues,
  setEdges,
  setTagGroups,
  setMetrics,
  setOrganisationNodeId,
  setUserAtlasPermissions,
  
  setScale,
  setCoordinates,
  setSelectedObjectType,
  setSelectedObject,
  setSelectedExecutorId,
  setSelectedProjectId,
  setOverrideLevelId,
  setTagsVisible,
  setMetricsVisible,
  setHeatMapVisible,
  setHoverActive,

  setActiveProjectId,
  setActiveAssignmentId,

  setOrganisationTagGroupId,
  setJobTagGroupId,
  setStepTagGroupId,
  setCustomProductTagGroupId,
  setSubstepTagGroupId,
  setGenericInputTagGroupId,
  setActionTagGroupId,

  setPropertyPanelVisible,
  setKeyPanelVisible,
  setProjectPanelVisible,
  setQualityPanelVisible,
  setProjectMode,

  setActivePropertyPanelTab,
  setActiveKeyPanelTab,
  setActiveProjectPanelTab,
  setActiveKey,

  setNetworkStates,

  setInteractionMode,
  setPropertiesManagerWindow,
  setActiveNetworkIds,
  setActiveLevel,
  setActiveTag,
  setActiveMetric,
  setHighlightedVertexIds,

  setUserAssignmentNodes,

  setStatsWindow
};
