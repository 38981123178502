import React, { useState } from "react";

// @mui components
import { FormGroup, FormControlLabel, Checkbox, Tab, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import GroupIcon from '@mui/icons-material/Group';

// Components
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { createUserGroup, updateUserGroup, getUserGroup, toggleUserInGroup } from "apiservices/AuthorisationServices/userGroupService";

const UserGroupView = () => {
  const [userGroup, setUserGroup] = useState();
  
  const handleSetIsGlobal = async (e) => setUserGroup({...userGroup, ...{isGlobal: e.target.checked}});

  const handleToggleUserInGroup = async (e, id) => {
    let _users = await toggleUserInGroup(id, userGroup.id);
    setUserGroup({...userGroup, ...{users: _users}});
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(userGroup.id > 0)
      setActiveTab(tabNumber);
  };

  return(
    <AdministrationObjectWrapper
      object={userGroup}
      setObject={setUserGroup}
      getObject={getUserGroup}
      createObject={createUserGroup}
      updateObject={updateUserGroup}
      namePlaceholder="Group Name"
      descriptionPlaceholder="Description of Group"
      iconComponent={<GroupIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        userGroup &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
            {
              userGroup.id > 0 ?
                <Tab label="Users" value="2"/> :
                <Tooltip title="Save the user group to begin setting access for users">
                  <Tab label="Users" value="2"/>
                </Tooltip>
            }
          </AdminTabList>
      }
      form={
        userGroup &&
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={userGroup.isGlobal} onChange={handleSetIsGlobal}/>} label="Includes all organisation users" />
            </FormGroup>
          </TabPanel>
      }
      panels={
        userGroup?.id > 0 &&
          <TabPanel 
            value="2"
            style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <SplitMediaCardArray
              titles={["Users in group", "Users not in group"]} 
              items={userGroup.users} 
              actions={[handleToggleUserInGroup, handleToggleUserInGroup]} 
              searchboxLabel="Search users"/>
          </TabPanel>
      }
      modal
    />
  )
}

export default UserGroupView;