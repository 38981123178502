import React, { useState, useEffect } from "react";

// @mui components
import { Card } from "@mui/material";

// Components
import Chart from "./chart";
import ChartControls from "./chartControls";

// Canvas context
import { useCanvasController, setStatsWindow } from "context/canvasContext";

import { getNodeChartData, getMetricChartData, getKnowledgeChartData } from "apiservices/AtlasServices/propertiesDataService";

const StatsWindow = ({atlasId}) => {
  const [controller, dispatch] = useCanvasController();
  const { statsWindow, selectedObject } = controller;

  const [chartData, setChartData] = useState([]);
  const [chartTitle, setChartTitle] = useState("");
  
  useEffect(() => {
    const _getSelectionStats = async () => {
      switch(statsWindow){
        case "nestedNodeStats":
          let _nodeData = await getNodeChartData(atlasId, selectedObject.id);
          setChartData(JSON.parse(_nodeData.intervalData));
          setChartTitle(_nodeData.name);
          break;
        case "metricStats":
          let _metricData = await getMetricChartData(atlasId, selectedObject.id);
          setChartData(JSON.parse(_metricData.intervalData));
          break;
        case "knowledgeStats":
          let _knowledgeData = await getKnowledgeChartData(atlasId, selectedObject.id);
          setChartData(JSON.parse(_knowledgeData.intervalData));
          break;
        case "metricKeyStats":
          //show box and whisker charts, for each node, in level (and possibly highlight) nodes with greater range of values
          break;
        default:
          break;
      }
    };
    _getSelectionStats();
  }, [selectedObject]);

  const handleMouseExitStatsWindow = (e) => setStatsWindow(dispatch, undefined);

  return(
    <Card
      onMouseLeave={handleMouseExitStatsWindow}
      sx={{
        left: "10%",
        top: "10%",
        width: "80%",
        height: "80%",
        display: "flex",
        flexDirection: "row",
        flexGrow: 1,
        zIndex: 10,
        position: "absolute",
        backgroundColor: "rgba(189, 189, 189, 0.98)",
        justifyContent: "right",
        p: 1,
        borderRadius: "9px"
      }}
    >
      {/* <ChartControls/> */}
      <Chart data={chartData} title={chartTitle}/>
    </Card>
  )
}

export default StatsWindow;