// @mui components
import { Grid, Box } from "@mui/material";

// Components
import AssociateRequestCard from "components/Cards/AssociateRequestCard";
import UserRequestCard from "components/Cards/UserRequestCard";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

const RequestCardContainer = ({requests, responseAction, children}) => {
  return(
    <Box
      borderRadius="6px"
      p={1.5}
      bgcolor="primary.main"
      style={{backgroundColor: "white"}}
      sx={{display: "flex", flexDirection: "column", flexGrow: 1, overflow: "hidden"}}>
      {children}
      <ScrollbarWrapper>
        <Grid container spacing={2} alignItems="flex-start" justifyContent="flex-start">
          {
            requests.map((request) => {
              return (
                <Grid item xl={3} lg={3} md={4} sm={6} xs={6} style={{height: "12.65rem", alignSelf: "flex-start", justifySelf: "flex-start"}} key={request.id}>
                  {
                    request.type === "associate" ?
                      <AssociateRequestCard request={request} responseAction={responseAction}/>
                      :
                      <UserRequestCard request={request} responseAction={responseAction}/>
                  }
                </Grid>
              )
            })
          }
        </Grid>
      </ScrollbarWrapper>
    </Box>
  )
}

export default RequestCardContainer;