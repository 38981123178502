import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/system";
import { Select, Tab, MenuItem, FormControl, InputLabel, Typography } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import PersonIcon from '@mui/icons-material/Person';

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import ToolkitBuilder from "layouts/inventoryadministrator/userinventory/views/ToolkitBuilder";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { getSkillSetToolkit, getUserToolkit, toggleUserAllocation } from "apiservices/InventoryServices/allocationService";
import { getUserById, getAssignedUserSkillSets } from "apiservices/AuthorisationServices/userAdminService";

const UserInventory = () => {
  const [user, setUser] = useState();
  const [jobs, setJobs] = useState();
  const [skillSetToolkit, setSkillSetToolkit] = useState();
  const [userToolkit, setUserToolkit] = useState();
  const [selectedSkillSetId, setSelectedSkillSetId] = useState();

  useEffect(() => {
    if (user?.id === 0 || !user) return;
    const _getUser = async () => {
        let _jobs = await getAssignedUserSkillSets(user.id);
        setJobs(_jobs);
        _jobs.length > 0 && setSelectedSkillSetId(_jobs[0].id);
    };
    _getUser();
  }, [user]);

  useEffect(() => {
    if (!selectedSkillSetId) return;
    const _getToolkit = async () => {
      let _toolkit = await getSkillSetToolkit(selectedSkillSetId);
      let _userToolkit = await getUserToolkit(user.id);
      setSkillSetToolkit(_toolkit);
      setUserToolkit(_userToolkit);
    };
    _getToolkit();
  }, [selectedSkillSetId]);

  const handleSetSelectedSkillSet = (e) => {
    setSelectedSkillSetId(e.target.value);
  };
  
  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    setActiveTab(tabNumber);
  };

  const handleAllocateTool = async (toolId) => {
    // await toggleUserAllocation(user.id, selectedJobId, toolId);
    // setSelectedJobId(selectedJobId);
  }

  return(
    <AdministrationObjectWrapper
      object={user}
      setObject={setUser}
      getObject={getUserById}
      createObject={() => {}}
      updateObject={() => {}}
      nameField="name"
      descriptionField="email"
      namePlaceholder="Name"
      descriptionPlaceholder="Email"
      iconComponent={<PersonIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        user &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
            <Tab label="Toolkit" value="2"/>
          </AdminTabList>
      }
      form={
        user &&
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            {
              (jobs && jobs.length === 0) &&
                <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>No jobs assigned to this user</Typography>
            }
            {
              (jobs && jobs.length > 0) &&
                <Typography variant="body1" sx={{ mt: 1, mb: 1 }}>User is assigned to job: {jobs.find(x => x.id === selectedSkillSetId).name}</Typography>
            }
            {
              (jobs && jobs.length > 1) &&
                <FormControl fullWidth sx={{ mt: 1}}>
                  <InputLabel htmlFor="job-select">Select Job</InputLabel>
                  <Select
                    sx={{height: "40px"}}
                    id="job-select"
                    value={selectedSkillSetId ?? ""}
                    label="Select Skill Set"
                    onChange={handleSetSelectedSkillSet}
                    >
                    {
                      jobs && jobs.map((job) => {
                        return (
                          <MenuItem value={job.id} key={job.id}>{job.name}</MenuItem>
                        )
                      })
                    }
                  </Select>
                </FormControl>
            }
          </TabPanel>
      }
      panels={
        skillSetToolkit &&
          <TabPanel 
            value="2"
            style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
            <ToolkitBuilder 
              toolkit={skillSetToolkit}
              userToolkit={userToolkit}
              />
          </TabPanel>
      }
    />
  )
}

export default UserInventory;