import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// @mui components
import { Dialog, DialogActions, Button  } from "@mui/material";
import { styled } from '@mui/material/styles';

// Local context
import { 
  useAdminController, 
  setIsDirty, 
  setNavigationPending, 
  setRoleChangePending, 
  setActiveUserRole,
  setMiniSidenav
} from "context";

const WarningDialog = styled(Dialog)(() => ({
  ".MuiDialog-paper": {
    padding: '16px',
  }
}));

const DialogContent = styled('div')(() => ({
  wordWrap: 'break-word',
  textAlign: 'center'
}));

const WarningActions = styled(DialogActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '8px 24px',
  position: 'relative',
}));

const CancelButton = styled(Button)(() => ({
  backgroundColor: '#eee',
  left: '25%',
  transform: 'translateX(-50%)',
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)"
}));

const ConfirmButton = styled(Button)(() => ({
  backgroundColor: '#eee',
  right: '25%',
  transform: 'translateX(50%)',
  boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.2)"
}));

const UnsavedChangesWarning = ({ children }) => {
  const [controller, dispatch] = useAdminController();
  const { navigationPending, roleChangePending, isDirty, activeUserRole } = controller;
  const [showDialog, setShowDialog] = useState(false);
  const navigate = useNavigate();

  const affectChange = () => {
    if(navigationPending){
      navigate(navigationPending);
      setNavigationPending(dispatch, false);
    }
    if(roleChangePending){
      if(activeUserRole === "user"){
        setMiniSidenav(dispatch, false);
      }
      else if(roleChangePending === "user"){
        setMiniSidenav(dispatch, true);
      }
      setActiveUserRole(dispatch, roleChangePending);
      setRoleChangePending(dispatch, false);
    }
  }

  useEffect(() => {
    if (isDirty) {
      setShowDialog(!!navigationPending || !!roleChangePending);
    }
    else {
      affectChange();
      setShowDialog(false);
    }
  }, [navigationPending, roleChangePending]);

  const handleConfirm = () => {
    setIsDirty(dispatch, false);
    affectChange();
  };

  const cancelNavigation = () => {
    setNavigationPending(dispatch, false);
    setRoleChangePending(dispatch, false);
  };

  return (
    <>
      {children}
      <WarningDialog open={showDialog} onClose={cancelNavigation}>
        <DialogContent>
          You have unsaved changes. <br /> Are you sure you want to leave?
        </DialogContent>
        <WarningActions>
          <CancelButton onClick={cancelNavigation}>Cancel</CancelButton>
          <ConfirmButton onClick={handleConfirm}>Confirm</ConfirmButton>
        </WarningActions>
      </WarningDialog>
    </>
  );
}

export default UnsavedChangesWarning;