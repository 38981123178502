import React, { useState, useEffect } from "react";

// @mui components
import { Card, TextField, Typography, FormControlLabel, Checkbox, Stack } from "@mui/material";
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Local context
import { useCanvasController, setRelateSizesToZero } from "context/canvasContext";

const MetricSettings = ({ closeWindow, metric }) => {
  const [canvasController, canvasDispatch] = useCanvasController();
  const { relateSizesToZero } = canvasController;

  // Separate state for start and end dates
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [position, setPosition] = useState({ top: 0, left: 0 });
  const targetElementId = 'settings-window';

  useEffect(() => { 
    const targetElement = document.getElementById(targetElementId);
    if (targetElement) {
      const rect = targetElement.getBoundingClientRect();

      setPosition({ 
        top: rect.top - 50,
        left: rect.left - 10 
      });
    }   
  }, []);
  
  return (
    <Card
      onMouseLeave={closeWindow}
      sx={{
        left: position.left,
        top: position.top,
        width: "auto",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        zIndex: 10,
        position: "fixed",
        backgroundColor: "rgba(189, 189, 189, 0.98)",
        justifyContent: "right",
        p: 1,
        borderRadius: "9px"
      }}>
      <Typography variant="h6" sx={{ textAlign: "center" }}>
        Metric Settings
      </Typography>
      <SettingsCheckbox 
        label="Relate to zero"
        checked={relateSizesToZero} 
        onChange={() => setRelateSizesToZero(canvasDispatch, !relateSizesToZero)}/>
      <SettingsCheckbox
        label="Relate only to items in ancestral tree"/>
      <SettingsCheckbox
        label="Displace and minimise text labels"/>
      <SettingsCheckbox
        label="Show distribution chart"/>
      <TextField
        id="decimal-places"
        label="Decimal Places"
        placeholder="2"
        type="number"
        sx={{ my: 2 }}/>
      
      {/* Start and End Date Pickers */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack direction="row" spacing={2}>
          {/* Start Date Picker */}
          <DatePicker
            label="Start Date"
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
          
          {/* End Date Picker */}
          <DatePicker
            label="End Date"
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      </LocalizationProvider>
    </Card>
  );
};

export default MetricSettings;

const SettingsCheckbox = ({ label, checked, onChange }) => {
  return (
    <FormControlLabel
      value={1}
      control={<Checkbox checked={checked} onChange={onChange} />}
      label={
        <Typography
          fontSize={16}
          sx={{ display: 'inline-block', width: 'auto' }}>
          {label}
        </Typography>
      }
    />
  );
};