import React, { useState, useEffect } from 'react';

import FinderBarLayout from './FinderBarLayout';

const FinderBar = ({ objs, setFilteredObjs, filterAttributes }) => {
  const initialSelectedCategories = createInitialSelectedCategories(filterAttributes);
  const [constraints, setConstraints] = useState({
    search: "",
    orderBy: undefined,
    orderType: undefined,
    orderDirection: 0,
    selectedCategories: initialSelectedCategories
  });
  
  useEffect(() => {
    let filtered = sortObjects(objs, constraints.orderBy, constraints.orderType, constraints.orderDirection);
    
    if (constraints.search !== "") {
      const filterAttribute = constraints.orderType === 'datetime' ? 'name' : (constraints.orderBy ?? 'name');
      filtered = filtered.filter((obj) => {
          // Ensure the attribute exists on the object and is not undefined to avoid errors.
          const attributeValue = obj[filterAttribute] ? obj[filterAttribute].toLowerCase() : '';
          return attributeValue.includes(constraints.search.toLowerCase());
      });
    }

    Object.keys(constraints.selectedCategories).forEach((category) => {
      if (constraints.selectedCategories[category].length > 0) {
        filtered = filtered.filter((obj) => {
          return obj.categoryIds[category]?.some((categoryId) => constraints.selectedCategories[category].includes(categoryId));
        });
      }
    });

    setFilteredObjs(filtered);
  }, [constraints, objs]);
  
  return (
    <FinderBarLayout constraints={constraints} setConstraints={setConstraints} filterAttributes={filterAttributes}/>
  )
}

function sortObjects(list, property, type, order) {
  if(order === 0) return [...list];
    
  return [...list].sort((a, b) => {
    const valueA = type === 'string' ? String(a[property]).toLowerCase() : new Date(a[property]);
    const valueB = type === 'string' ? String(b[property]).toLowerCase() : new Date(b[property]);
    if (valueA < valueB) {
      return order === 1 ? -1 : 1;
    }
    if (valueA > valueB) {
      return order === 1 ? 1 : -1;
    }
      return 0;
  });
}

function createInitialSelectedCategories(attributes) {
  return attributes.reduce((acc, attr) => {
    if (attr.type === 'categorical') {
      acc[attr.property] = [];
    }
    return acc;
  }, {});
}

function getVarType(sample) {
  // Check if constraints is a string formatted as an ISO 8601 date
  if (typeof sample === 'string' && isISO8601Date(sample)) {
    return 'datetime';
  }
  // Check if constraints is a number
  else if (typeof sample === 'number') {
    return 'numeric';
  }
  // Check if constraints is a string (assuming it to be 'alpha' if not a datetime)
  else if (typeof sample === 'string') {
    return 'alpha';
  }
  // Throw an error if the type is not recognized or constraints is undefined
  else {
    throw new Error('Invalid type or undefined constraints');
  }
}
  
// Helper function to check for ISO 8601 date format
function isISO8601Date(str) {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{1,3})?(Z|([+-]\d{2}:\d{2}))?$/.test(str);
}

export default FinderBar;