import React, { useEffect, useState } from "react";

import { updateAdminState, getAdminState } from "apiservices/AuthorisationServices/adminStateService";

import {
  useAdminController,
  setMiniSidenav,
  setMiniViewHeader,
  setDarkMode,
  setActiveUserRole,
  //setLanguage
} from "context";

const AdminStateManager = ({}) => {
  const [controller, dispatch] = useAdminController();
  
  const [hasInitialized, setHasInitialized] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const state = await getAdminState(controller.userId);
        dispatchStateData(state, dispatch);
        setTimeout(() => {
          setHasInitialized(true);
        }, 200);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!hasInitialized) return;
    const updateStateInBackend = async () => {
      try {
        await updateAdminState(
          {
            userId: controller.userId,
            role: controller.activeUserRole,
            miniSideNav: controller.miniSidenav,
            miniViewHeader: controller.miniViewHeader,
            darkMode: controller.darkMode,
            language: ''
          },
        );
      } catch (error) {
        console.error(error);
      }
    };
    updateStateInBackend();
  }, [controller.miniSidenav, controller.miniViewHeader, controller.darkMode, controller.userId, controller.activeUserRole]);

  const dispatchStateData = (state, dispatch) => {
    setActiveUserRole(dispatch, state.role);
    setMiniSidenav(dispatch, state.miniSideNav);
    setMiniViewHeader(dispatch, state.miniViewHeader);
    setDarkMode(dispatch, state.darkMode);
    // setLanguage(dispatch, state.language);
  };

  return <div></div>;
};

export default AdminStateManager;