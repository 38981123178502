// @mui components
import { Box } from '@mui/material';

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Local context
import { useAdminController } from "context";

export default function DashboardLayout({ children }) {
  const [controller, dispatch] = useAdminController();
  const { miniSidenav, activeUserRole } = controller;

  return (
    <Box
      style={{ overflow: 'hidden', height: '100vh' }}
      display="flex" 
      flexDirection="column"
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        position: "relative",
        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(70) : pxToRem(activeUserRole === "user" ? 180 : 224),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard
          })
        }
      })}>
      {children}
    </Box>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
