import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "App";

import React from 'react';

// Context Provider
import { AdminControllerProvider } from "context";

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AdminControllerProvider>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
  </AdminControllerProvider>
);