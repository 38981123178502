import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import bgImage from './Frog.webp';

const organisationsIndustries = [
  {
    name: "Organisations & Industries",
    paragraph1: "For complex supply chains, managing large-scale projects, or coordinating teams across multiple locations, Merit streamlines operations, creates data-driven workflows, and enhances transparency and accountability within your supply chain ecosystem. For smaller, recurring projects with similar features, Merit’s advanced analytics empower you to uncover inefficiencies and drive best practices, enabling continuous improvement and high-definition process refinement.",
    paragraph2: "For industries as diverse as healthcare, agriculture, construction, and logistics, use Merit to operate smoothly and optimise endlessly."
  }
];

export const Industries = () => {
  return (
    <Box
      id="industries"
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '0px', md: '20px' },
        py: { xs: '17px', md: '40px' },
        backgroundColor: '#f2f2f2', 
        scrollMarginTop: { xs: '27px', sm: '10px', md: '-20px' }
      }}
    >
      <Container>
        <Grid container spacing={{ xs: 2, md: 4 }} alignItems="center">
          {/* Text on the left */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: { xs: '11px', md: '30px' },
                borderRadius: '12px',
                boxShadow: '0 6px 12px rgba(0,0,0,0.1)',
              }}
            >
              <Typography 
                fontSize={{ xs: 15, md: 22 }}
                gutterBottom 
                sx={{ fontWeight: 600 }}>
                {organisationsIndustries[0].name}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                paragraph 
                sx={{ fontWeight: 400 }}>
                {organisationsIndustries[0].paragraph1}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                paragraph 
                sx={{ fontWeight: 400 }}>
                {organisationsIndustries[0].paragraph2}
              </Typography>
            </Box>
          </Grid>

          {/* Image on the right */}
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={bgImage}
              alt="Various industries landscape"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '12px',
                boxShadow: '0 6px 12px rgba(0,0,0,0.1)',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
