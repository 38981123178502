import React from 'react';

// prop-types for typechecking props
import PropTypes from "prop-types";

// @mui components
import { Box } from "@mui/material";
import { Breadcrumbs as MuiBreadcrumbs, Icon, Typography } from "@mui/material";

// Local context
import { useAdminController, setNavigationPending } from "context";

const Breadcrumbs = ({ icon, title, route, light=false }) => {
  const [controller, dispatch] = useAdminController();
  const routes = route.slice(0, -1);

  const handleNavigation = (event, route) => {
    setNavigationPending(dispatch, `/${route}`);
  }

  return (
    <Box mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Typography
          component="span"
          variant="body2"
          color={light ? "white" : "dark"}
          opacity={light ? 0.8 : 0.5}
          sx={{ lineHeight: 0, cursor: "pointer"}}
          onClick={(event) => handleNavigation(event, "")} // Navigate to home
        >
          <Icon>{icon}</Icon>
        </Typography>
        {routes.map((el) => (
          <Typography
            component="span"
            variant="button"
            fontWeight="regular"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0, cursor: "pointer"}}
            onClick={(event) => handleNavigation(event, el)} // Handle navigation
            key={el}
          >
            {el}
          </Typography>
        ))}
        <Typography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {title.replace("-", " ")}
        </Typography>
      </MuiBreadcrumbs>
      <Typography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </Typography>
    </Box>
  );
}

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;