import React, { useState } from "react";

// @mui components
import { Box, Card, CardContent, CardActionArea } from "@mui/material";
import { Typography, Tooltip, Icon, IconButton } from "@mui/material";

// @mui icons
import EditIcon from '@mui/icons-material/Edit';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import EqualizerIcon from '@mui/icons-material/Equalizer';

// Components
import { StatsWindowButton } from "components/CanvasComponents/OverlayButtons";
import { DeleteButton } from "components/GlobalComponents/Buttons";
import KnowledgeReviewWindow from "layouts/canvas/overlays/propertypanel/KnowledgeReviewWindow";

const KMCard = ({ name, id, cardColour = "#F5F5F5", colour = "white", editor, icon, description = "", deleteAction }) => {
  let noWrap = icon === undefined ? true : false;

  const [showStatsWindow, setShowStatsWindow] = useState(false);

  const [reviewWindowId, setReviewWindowId] = useState(0);
  const navigateToUrl = () => { 
    window.open(name, '_blank');
    setReviewWindowId(id);
  }
  
  return (
    <Card elevation={1} sx={{ height: 1, bgcolor: cardColour, borderRadius: "3px", display: 'flex' }} id={id}>
      {
        (deleteAction !== undefined && icon === undefined) && (
          <DeleteButton deleteAction={deleteAction} id={id}/>   
        )
      }
        <CardContent
          sx={{ flexGrow: 1, p: '0 !important', textAlign: "center",
            cursor: id === 0 ? 'pointer' : 'default',
            '&:hover': { cursor: id === 0 ? 'pointer' : 'default' } }}
          id={id}
          onClick={id === 0 ? (e) => editor(e, id) : undefined}>
          {
            icon !== undefined ?
              <Icon sx={{fontSize: 115, mt: 1, mb: -3.8}} fontSize="">{icon}</Icon> :
              <Box sx={{height: 0.5, backgroundColor: colour, borderTopRightRadius: "3px", borderTopLeftRadius: "3px" }}/>
          }
          <Box sx={{ height: 0.5 }}>
            <Tooltip title={name}>
              <Typography
                variant="h6"
                component="div"
                mt={1}
                p={1}
                textAlign="center"
                noWrap={noWrap}
                id={id}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '100%' }}>
                {name}
              </Typography>
            </Tooltip>
            <Tooltip title={description}>
              <Typography
                variant="caption"
                component="div"
                mb={1}
                color="text.secondary"
                textAlign="center"
                noWrap
                id={id}
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '100%' }}>
                {description}
              </Typography>
            </Tooltip>
            {
              id !== 0 &&
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ px: 1, bottom: 0 }}>
                  <Box>
                    <IconButton  onClick={(e) => editor(e, id)} sx={{ p: 0 }}>
                      <EditIcon/>
                    </IconButton>
                    <IconButton onClick={navigateToUrl} sx={{ p: 0, ml: 0.4 }}>
                      <ExitToAppIcon/>
                    </IconButton>
                  </Box>
                  <Box>
                    <StatsWindowButton setWindow={setShowStatsWindow} icon={<EqualizerIcon/>}/>
                  </Box>
                </Box>
            }
          </Box>
        </CardContent>
      {
        reviewWindowId !== 0 && (
          <KnowledgeReviewWindow 
            closeReviewWindow={() => setReviewWindowId(0)}
            id={id}
            />
        )
      }
    </Card>
  );
}

export default KMCard;