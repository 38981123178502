import React, { useState, useEffect } from "react";

import './compact-picker.css';

// @mui components
import { Box } from "@mui/material";
import { TextField } from "@mui/material";

// Colour picker
import { CompactPicker } from 'react-color';

// Components
import { WrapperModal } from "components/GlobalComponents/Modals";

// Db context
import { createPropertyAttribute, updatePropertyAttribute } from "apiservices/AuthorisationServices/propertyService";

function TagEditor({ closeEditor, property, setProperty, tagId, organisationId }) {
  const [tag, setTag] = useState({ id: 0, name: '', value: '', organisationId: organisationId });

  // Effect to initialize the tag state when editing
  useEffect(() => {
    if (tagId >= 0) {
      const existingTag = property.propertyAttributes.find(t => t.id === tagId);
      if (existingTag) {
        setTag({ ...existingTag });
      }
    } else {
      // Resetting tag for adding new tag
      setTag({ id: 0, name: '', value: '' });
    }
  }, [tagId, property.propertyAttributes]);
  
  const saveTag = async (tag) => {
    tag.propertyId = property.id;
    let result = tag.id === 0 ?
      await createPropertyAttribute(tag) :
      await updatePropertyAttribute(tag);
 
    setProperty({
      ...property,
      propertyAttributes: result.propertyAttributes,
    });
  };
  
  const handleChangeColour = (color) => setTag({ ...tag, value: color.hex });
  const handleChangeName = (e) => setTag({ ...tag, name: e.target.value });
  const handleChangeOrdinal = (e) => setTag({ ...tag, number: Number(e.target.value) });
 
  // Submit event handler
  const handlePrimaryEvent = (e) => {
    e.preventDefault();
    if (tagId >= 0) {
      // Edit existing tag
      const updatedTags = property.propertyAttributes.map(t => t.id === tagId ? { ...tag } : t);
      setProperty({ ...property, propertyAttributes: updatedTags });
    } else {
      // Add new tag
      setProperty({ ...property, propertyAttributes: [...property.propertyAttributes, { ...tag }] });
    }
    saveTag(tag);
    closeEditor(e, -1);
  };

  const ordinalValue = () => {
    if (tag.number) {
      return tag.number;
    } else {
      const newNumber = property.propertyAttributes.map(t => t.number).reduce((a, b) => Math.max(a, b), 0) + 1;
      setTag({ ...tag, number: newNumber });
      return newNumber;
    }
  };
  
  return (
    <WrapperModal
      open={true}
      handleClose={(e) => closeEditor(e, -1)}
      title="Create New Tag"
      primaryButtonText={tag.id === 0 ? "Add Tag" : "Update Tag"}
      primaryEvent={handlePrimaryEvent}>
      <Box mt={1} display='flex' flexDirection='column'>
        <CompactPicker
          color={tag?.value}
          onChangeComplete={handleChangeColour}
          width="100%" />
        <TextField label="Tag name" value={tag.name} variant="outlined" sx={{ mt: 2 }} onChange={handleChangeName} />
        {
          property.isOrdinal &&
            <TextField type="number" value={ordinalValue()} label="Tag ordinal value" variant="outlined" sx={{ mt: 2 }} onChange={handleChangeOrdinal} />
        }
      </Box>
    </WrapperModal>
  );
}

export default TagEditor;  