import React, { useState, useEffect, useRef } from "react";

// Editors
import ProcessEditorCanvas from "./ProcessEditorCanvas";
import SubProcessEditorCanvas from "./SubProcessEditorCanvas";

// Components
import { ProcessEditorModal } from "components/GlobalComponents/Modals";
import { ProcessEditorWindowFooter } from "components/CanvasComponents/ProcessEditorComponents";
import ProcessAlternator from "layouts/canvas/overlays/propertypanel/nodepanels/ProcessAlternator";

// Db context
import { createProcess, createSubProcess, getProcesses, getAtlasProducts } from "apiservices/AtlasServices/nodeService";
import { getInventoryItemTypes } from "apiservices/InventoryServices/inventoryItemTypeService";

// Canvas context
import { useAdminController } from "context";
import { useCanvasController, setPropertiesManagerWindow, setVertices, setUnconfiguredJobs, setEdges } from "context/canvasContext";

const ProcessEditor = () => {
  const [controller, dispatch] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { organisationId, userId } = controller;
  const { propertiesManagerWindow, selectedObject } = canvasController;
  const stepIdRef = useRef(-2);
  const edgeIdRef = useRef(-1);
  
  const [processes, setProcesses] = useState();
  const [activeProcess, setActiveProcess] = useState(undefined);
  const [selectedProcessElementType, setSelectedProcessElementType] = useState('vertex');
  const [selectedProcessElement, setSelectedProcessElement] = useState();
  const [products, setProducts] = useState();
  const [selectedProduct, setSelectedProduct] = useState();
  useEffect(() => {
    const _getProcessData = async () => {
      let _processes = (await getProcesses(selectedObject.id));
      if(_processes[0].nodes[0].id === 0){
        _processes[0].nodes[0].sentBy = userId;
      }
      let _products;
      if (selectedObject.networkId === 2) {
        _products = await getAtlasProducts(organisationId, selectedObject.authorisationId);
      } else if (selectedObject.networkId === 3) {
        _products = await getInventoryItemTypes(organisationId);
      }
      setProcesses(_processes);
      setActiveProcess(_processes[0]);
      setProducts(_products);
    };
    _getProcessData();
  }, [propertiesManagerWindow]);

  useEffect(() => {
    if(selectedProcessElementType === 'edge'){
      setSelectedProduct(selectedProcessElement?.authorisationId);
    }
  }, [selectedProcessElement]);
  
  const handleSetSelectedProcessElement = (node) => setSelectedProcessElement(node);
  
  const handleCloseEditor = () => {
    setPropertiesManagerWindow(canvasDispatch, undefined);
    setSelectedProcessElement(undefined);
  }

  const handleSubmitProcess = async () => {
    activeProcess.sentBy = userId;
    let atlas = selectedObject.networkId === 2 ?
      await createProcess(activeProcess):
      await createSubProcess(activeProcess);
    handleCloseEditor();
    // below ruins the atlas, if not all products are accounted for with the new (alternative) process
    await setVertices(canvasDispatch, atlas.vertices.filter(x => !(x.context.networkId === 2 && x.isIntegrated === 4)));
    await setUnconfiguredJobs(canvasDispatch, atlas.vertices.filter(x => x.context.networkId === 2 && x.isIntegrated === 4));
    setEdges(canvasDispatch, atlas.edges);
  }

  const handleAddStep = (step) => {
    step.id = stepIdRef.current--;
    step.sentBy = userId;
    step.authorisationId = 0; // this is a placeholder value. Should allow nulls in the db
    setActiveProcess(_process => {
      const updatedNodes = [..._process.nodes];
      updatedNodes.forEach(node => {
        if (node.horizontalIndex >= step.horizontalIndex) {
          node.horizontalIndex += 1;
        }
      });

      updatedNodes.push(step);
      return {..._process, nodes: updatedNodes};
    });
    setSelectedProcessElement(step);
  }

  const handleAddEdge = (edge) => {
    edge.id = edgeIdRef.current--;
    edge.sentBy = userId;
    edge.authorisationId = selectedProduct;
    setActiveProcess(_process => ({..._process, edges: [..._process.edges, edge]}));
    setSelectedProcessElementType('edge');
    setSelectedProcessElement(edge);
  }

  const handleDeleteStep = (nodeId) => {
    const deletedNode = activeProcess.nodes.find(x => x.id === nodeId);
    let remainingNodes = activeProcess.nodes.filter(x => x.id !== nodeId);
    let remainingEdges = activeProcess.edges.filter(x => x.sourceNodeId !== nodeId && x.targetNodeId !== nodeId);

    if (deletedNode) {
      remainingNodes = remainingNodes.map(node => 
        node.horizontalIndex > deletedNode.horizontalIndex ?
        { ...node, horizontalIndex: node.horizontalIndex - 1 } : node
      );
    }

    setActiveProcess(prevProcess => ({ ...prevProcess, nodes: remainingNodes, edges: remainingEdges }));
  }
  
  const handleDeleteEdge = (edgeId) => {
    setActiveProcess(_process => ({..._process, edges: _process.edges.filter(x => x.id !== edgeId)}));
  }

  return(
    products &&
      <ProcessEditorModal
        toggleEditorVisibility={handleCloseEditor}
        title={selectedObject.networkId === 2 ? "Process Editor: " + selectedObject.name : "Sub-process Editor: " + selectedObject.name}
        alternator={<ProcessAlternator
          processes={processes}
          setProcesses={setProcesses}
          process={activeProcess}
          setProcess={setActiveProcess}
          parentNodeId={selectedObject.id}
          networkId={selectedObject.networkId + 1}/>}>
        {selectedObject.networkId === 2 ? 
          <ProcessEditorCanvas
            process={activeProcess}
            addStep={handleAddStep}
            deleteStep={handleDeleteStep}
            addEdge={handleAddEdge}
            deleteEdge={handleDeleteEdge}
            selectedProcessElementType={selectedProcessElementType}
            setSelectedProcessElementType={setSelectedProcessElementType}
            selectedProcessElement={selectedProcessElement}
            setSelectedProcessElement={handleSetSelectedProcessElement}/> 
          :
          <SubProcessEditorCanvas
            subProcess={activeProcess}
            addStep={handleAddStep}
            deleteStep={handleDeleteStep}
            addEdge={handleAddEdge}
            deleteEdge={handleDeleteEdge}
            selectedProcessElementType={selectedProcessElementType}
            setSelectedProcessElementType={setSelectedProcessElementType}
            selectedSubProcessElement={selectedProcessElement}
            setSelectedSubProcessElement={handleSetSelectedProcessElement}/>
        }
        <ProcessEditorWindowFooter
          action={handleSubmitProcess}
          buttonText={selectedObject.networkId === 2 ? "Update Process" : "Update Sub-process"}
          selectedProcessElementType={selectedProcessElementType}
          selectedProcessElement={selectedProcessElement}
          setSelectedProcessElement={setSelectedProcessElement}
          process={activeProcess}
          setProcess={setActiveProcess}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
          products={products}
          level={selectedObject.networkId === 2 ? 3 : 4}/>
      </ProcessEditorModal>
  )
}

export default ProcessEditor;