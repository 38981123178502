// @mui components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";

const squareStyle = {
  borderRadius: "0px",
  margin: 0,
  height: "100%",
  paddingBottom: 0,
  width: 180
}

const roundedStyle = {
  borderRadius: "6px",
  margin: 4,
  height: "98.6%",
  width: 220,
}

export default styled(Drawer)(({ theme, ownerState, activerole }) => {
  const { palette, boxShadows, transitions, breakpoints, functions } = theme;
  const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } = ownerState;

  const _style = activerole === "user" ? squareStyle : roundedStyle;
  const { transparent, white, background } = palette;
  const { xxl } = boxShadows;
  const { pxToRem } = functions;

  let backgroundValue = darkMode
    ? background.sidenav
    : "#424242";

  if (transparentSidenav)
    backgroundValue = transparent.main;
  else if (whiteSidenav)
    backgroundValue = white.main;

  // styles for the sidenav when miniSidenav={false}
  const drawerOpenStyles = () => ({
    ..._style,
    background: backgroundValue,
    transform: "translateX(0)",
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.enteringScreen,
      }),
    },
  });

  // styles for the sidenav when miniSidenav={true}
  const drawerCloseStyles = () => ({
    ..._style,
    background: backgroundValue,
    transform: `translateX(${pxToRem(-320)})`,
    transition: transitions.create("transform", {
      easing: transitions.easing.sharp,
      duration: transitions.duration.shorter,
    }),

    [breakpoints.up("xl")]: {
      boxShadow: transparentSidenav ? "none" : xxl,
      marginBottom: transparentSidenav ? 0 : "inherit",
      width: pxToRem(67),
      overflowX: "hidden",
      transform: "translateX(0)",
      transition: transitions.create(["width", "background-color"], {
        easing: transitions.easing.sharp,
        duration: transitions.duration.shorter,
      }),
    },
  });

  return {
    "& .MuiDrawer-paper": {
      boxShadow: xxl,
      border: "none",
      overflowX: "hidden",

      ...(miniSidenav ? drawerCloseStyles() : drawerOpenStyles()),
    },
  };
});
