export const validateNameAndDescription = (object, validationRules) => {
  const invalidFields = {};
  if (!object.name || object.name.length === 0) {
    invalidFields.name = 'Name is required';
  } else if (object.name.length < validationRules.name.minLength || object.name.length > validationRules.name.maxLength) {
    invalidFields.name = `Name must be between ${validationRules.name.minLength} and ${validationRules.name.maxLength} characters`;
  }
  // if (!object.description || object.description.length === 0) {
  //   invalidFields.description = 'Description is required';
  // } else if (object.description.length < validationRules.description.minLength || object.description.length > validationRules.description.maxLength) {
  //   invalidFields.description = `Description must be between ${validationRules.description.minLength} and ${validationRules.description.maxLength} characters`;
  // }
  return invalidFields;
};

export async function generateThumbnail(input, options = {}) {
  try {
    if (!input) {
      throw new Error('Input is required');
    }

    const {
      boundBox,
      width,
      height,
      quality = 0.8, // Sensible default for PNG
      outputFormat = "jpeg" // Default output format
    } = options;

    // 1. Load the image (handle both File objects and URLs)
    const img = new Image();
    img.crossOrigin = "anonymous"; // Required for loading images from different origins

    const loadPromise = new Promise((resolve, reject) => {
      img.onload = resolve;
      img.onerror = reject;

      if (input instanceof File) {
        img.src = window.URL.createObjectURL(input);
      } else {
        img.src = input; // Assuming it's a URL
      }
    });

    await loadPromise;

    // 2. Handle scaling and dimensions
    let scaleRatio = 1;
    if (boundBox) {
      scaleRatio = Math.min(...boundBox) / Math.max(img.width, img.height);
    }
    const scaledWidth = width || img.width * scaleRatio;
    const scaledHeight = height || img.height * scaleRatio;

    // 3. Create canvas and draw image
    const canvas = document.createElement("canvas");
    canvas.width = scaledWidth;
    canvas.height = scaledHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, scaledWidth, scaledHeight);

    // 4. Generate data URL based on output format
    const mimeType = options.quality ? 'image/jpeg' : `image/${outputFormat}`;
    const thumbnail = canvas.toDataURL(mimeType, quality);

    // Clean up
    canvas.remove();
    img.remove();

    return thumbnail;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0;
}

export function decapitalizeFirstLetter(string) {
  if (string.length === 0)
    return string;
  return string.replace(/^\p{L}/u, char => char.toLowerCase())
}