// @mui components
import { Box } from '@mui/material';
import { FormLabel, Typography } from '@mui/material';
import { TabList } from "@mui/lab";

import { styled } from '@mui/material/styles';

export const AdminPanel = styled(Box)(({ backgroundcolour = "white" }) => ({
  width: "100%",
  borderRadius: "6px",
  backgroundColor: backgroundcolour,
  display: "flex",
  flexDirection: "column",
  flexGrow: 1,
  flex: 1
}));

export const AdminFormLabel = ({ children, error }) => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <FormLabel sx={{fontSize: '18px', color: '#333', fontWeight: 'bold' }}>{children}</FormLabel>
      {error && <Typography color="error" sx={{ml: 2, fontSize: 16}}>{error}</Typography>}
    </Box>
  )
}

export const AdminTabList = styled(TabList)(() => ({
  borderRadius: "6px 6px 0 0",
  color: "#ffffff",
  background: "#faf7f2"
}));

export const tabIndicator = {
  TabIndicatorProps: {
    style: {
      background: "#00b0ff",
      color: "#ffffff"
    }
  }
};

export const HatchedBox = styled(Box)(({
  size = "200px",
  borderRadius = "6px",
  marginpercentage = "5%",
  backgroundcolour = "rgba(204, 204, 204, 0.96)"
}) => ({
  
  width: size,
  height: size,
  borderRadius: borderRadius,
  position: "relative",
  background: `repeating-linear-gradient(
    45deg,
    #000,
    #000 6px,
    #ccc 8px,
    #ccc 14px
  )`,
  overflow: "hidden",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",

  // Semi-transparent overlay in the center
  "::after": {
    content: '""',
    position: "absolute",
    top: marginpercentage,
    left: marginpercentage,
    right: marginpercentage,
    bottom: marginpercentage,
    backgroundColor: backgroundcolour
  },
  
  // Ensure the text is above the overlay
  "> *": {
    position: "relative",
    zIndex: 2
  },
}));
