import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db context
import { getUsers } from "apiservices/AuthorisationServices/userAdminService";

// Local context
import { useAdminController } from "context";

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const UserInventories = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;
  
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    const _getUsers = async () => {
      let _users = await getUsers(organisationId);
      setUsers(_users.items);
      setFilteredUsers(_users.items);
    };
    _getUsers();
  }, []);

  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={filteredUsers} path={"/userInventories/userInventory"}>
        <FinderBar
          objs={users}
          setFilteredObjs={setFilteredUsers}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default UserInventories;