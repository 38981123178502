import React from "react";

import { Navigation } from "./Navigation";
import { Header } from "./Header";
import { Services } from "./Services";
import { Concepts } from "./Concepts";
import { RolesIndividuals } from "./RolesIndividuals.js";
import { Industries } from "./Industries";
import { Contact } from "./Contact";

import SmoothScroll from "smooth-scroll";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

export default function FrontPage () {
  return (
    <React.StrictMode>
      <div style={{ overflow: 'hidden', height: '100vh' }}>
        <link rel="stylesheet" type="text/css" href="css/bootstrap.css"/>
        <Navigation/>
        <div id="scrollportion" style={{ overflow: 'auto', height: '93vh', paddingRight: "1px" }}>
          <Header/>
          <Concepts/>
          <Services/>
          <Industries/>
          <RolesIndividuals/>
          <Contact/>
        </div>
      </div>
    </React.StrictMode>
  );
};
