import { postWrapper, getWrapper } from '../fetchWrapper';

export const getAtlas = async (userId, atlasId, projectMode, activeProjectId) => await postWrapper(`/api/atlas/getatlas`, 
  {userId: userId, atlasId: atlasId, projectMode: projectMode, activeProjectId: activeProjectId}
);

export const getNetworkVertexColours = async (tagGroupId, nodeIds, projectId) => await postWrapper(`/api/atlas/getnetworkvertexcolours/` + projectId, 
  {tagGroupId: tagGroupId, nodeIds: nodeIds}
);

export const getMetricTable = async (metricId) => await getWrapper(`/api/atlas/getmetrictable/` + metricId);
export const getProjectMetricTable = async (metricId, projectId) => await getWrapper(`/api/atlas/getprojectmetrictable/` + metricId + '/' + projectId);