import { Grid } from "@mui/material";

// @mui icons
import { Add, Remove } from '@mui/icons-material';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import SchemaIcon from '@mui/icons-material/Schema';

// Components
import { CanvasControlButton } from "components/CanvasComponents/OverlayButtons";

// Atlas context
import { useCanvasController, getApp, setScale, setCoordinates, setHoverActive } from "context/canvasContext";
import { applyWindowCenteredScaleChange,  centerOnSpecifiedChildren, centerOnCoordinates } from "helpers/canvashelpers/canvasfunctions";

const CanvasControls = () => {
  const [controller, dispatch] = useCanvasController();
  const { selectedObjectType, selectedObject, hoverActive, vertices } = controller;

  const handleScaleChange = async (e, factor) => {
    let scaleChange = factor * 0.06
    applyWindowCenteredScaleChange(e, scaleChange, getApp().stage);
    setScale(dispatch, getApp().stage.scale.x);
  };

  const handleCentreOnSelection = async () => {
    if(selectedObjectType === 'vertex' && selectedObject.id !== 0){
      centerOnSpecifiedChildren(getApp(), getApp().stage.children
        .filter(x => x.canvasObjectType === "vertex" && x.context.id === selectedObject.id));
    }
    else{
      const orgs = vertices.filter(x => x.context.networkId === 1);
      let xMin = orgs.map(x => x.tl[0]).reduce((a, b) => Math.min(a, b));
      let yMin = orgs.map(x => x.tl[1]).reduce((a, b) => Math.min(a, b));
      let xMax = orgs.map(x => x.tl[0]).reduce((a, b) => Math.max(a, b)) + vertices.map(x => x.sizes[0]).reduce((a, b) => Math.max(a, b));
      let yMax = orgs.map(x => x.tl[1]).reduce((a, b) => Math.max(a, b)) + vertices.map(x => x.sizes[1]).reduce((a, b) => Math.max(a, b));
      let xCentre = (xMin + xMax) / 2;
      let yCentre = (yMin + yMax) / 2;
      centerOnCoordinates(getApp(), xCentre, yCentre);
    }
    setCoordinates(dispatch, { x: getApp().stage.x, y: getApp().stage.y });
  }

  const handleToggleHoverActive = () => setHoverActive(dispatch, !hoverActive);

  return (
    <Grid container sx={{mt: 0, position: "absolute", width: "min-content", right: 15, zIndex: 1}} spacing={2} direction="column">
      <CanvasControlButton
        iconSize={45}
        icon={<Add sx={{mb: 5}}/>}
        action={(e) => handleScaleChange(e, 1)}
        tooltip="Zoom in"
        enableRepeat/>
      <CanvasControlButton
        iconSize={45}
        icon={<Remove sx={{mb: 5}}/>}
        action={(e) => handleScaleChange(e, -1)}
        tooltip="Zoom out"
        enableRepeat/>
      <CanvasControlButton
        iconSize={30}
        icon={<CenterFocusStrongIcon sx={{mb: 5, ml: -0.1}}/>}
        action={handleCentreOnSelection}
        tooltip="Centre on selected node"/>
      <CanvasControlButton
        iconSize={28}
        icon={<SchemaIcon sx={{mb: 5}}/>}
        action={handleToggleHoverActive}
        colour={hoverActive ? "#00b0ff" : "#fff"}
        tooltip="Hover over vertex or edge to show its relationships"/>
    </Grid>
  );
};

export default CanvasControls;