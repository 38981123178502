import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getUserGroups, deleteUserGroup } from "apiservices/AuthorisationServices/userGroupService";

// Local context
import { useAdminController } from "context";

const createButton = [{media: ["add"], name: "Create New Group", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const UserGroups = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [userGroups, setUserGroups] = useState([]);
  const [filteredUserGroups, setFilteredUserGroups] = useState([]);
  useEffect(() => {
    const _getUserGroups = async () => {
      let _userGroups = await getUserGroups(organisationId);
      setUserGroups(_userGroups);
      setFilteredUserGroups(_userGroups);
    };
    _getUserGroups();
  }, []);

  const handleDelete = async (e, userGroupId, onDeleteComplete) => {
    let _userGroups = await deleteUserGroup(organisationId, userGroupId); //why should organisationId be required here?
    setUserGroups(_userGroups);
    onDeleteComplete();
  }

  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={createButton.concat(filteredUserGroups)} path={"/userGroups/userGroup"} deleteAction={handleDelete}>
        <FinderBar
          objs={userGroups}
          setFilteredObjs={setFilteredUserGroups}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default UserGroups;