import React, { useState, useEffect } from "react";

// @mui components
import { Grid, Box, Divider } from "@mui/material";

// Components
import AddRemoveItemMediaCard from "components/Cards/AddRemoveItemMediaCard";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";
import SearchOrderFilterPanel from "components/AdministrationComponents/FinderBarMini";

const SplitMediaCardArray = ({ items, action, permissionsPanelToggle }) => {
  const [leftItems, setLeftItems] = useState();
  const [rightItems, setRightItems] = useState();

  useEffect(() => {
    setLeftItems(items.filter((item) => item.isIncluded));
    setRightItems(items.filter((item) => !item.isIncluded));
  }, [items]);

  const [leftFilteredItems, setLeftFilteredItems] = useState(leftItems);
  const [rightFilteredItems, setRightFilteredItems] = useState(rightItems);

  useEffect(() => {
    setLeftFilteredItems(leftItems);
    setRightFilteredItems(rightItems);
  }, [leftItems, rightItems]);

  const handleLeftFilterChange = (filteredData) => setLeftFilteredItems(filteredData);
  const handleRightFilterChange = (filteredData) => setRightFilteredItems(filteredData);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box sx={{ height: 1, display: "flex" }} gap={1}>
        {[leftItems, rightItems].map((items, i) => {
          const isLeftSide = i === 0;
          const filteredItemsList = isLeftSide ? leftFilteredItems : rightFilteredItems;
          const handleFilterChange = isLeftSide ? handleLeftFilterChange : handleRightFilterChange;
          const panelTitle = isLeftSide ? "Inclusions" : "Omissions";
          return (
            <Box
              key={i}
              borderRadius="6px"
              display="flex"
              flexDirection="column"
              flexGrow={1}
              justifyContent="flex-start"
              alignItems="flex-start"
              p={1}
              style={{ backgroundColor: "#faf7f2" }}
              width={0.5}
              overflow={"hidden"}>
              <SearchOrderFilterPanel
                items={items}
                title={panelTitle}
                searchboxLabel="Search here"
                onFilterChange={handleFilterChange}/>
              <Divider sx={{ width: "100%", my: 1 }} />
              <ScrollbarWrapper>
                <Grid container spacing={1} justifyContent="flex-start" alignContent="flex-start" alignItems="flex-start" height={1}>
                  {
                    filteredItemsList?.map((element) => {
                      return (
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12} key={element.id}>
                          <AddRemoveItemMediaCard
                            name={element.name}
                            id={element.id}
                            action={action}
                            hasAccess={element.isIncluded}
                            permissionsPanelToggle={permissionsPanelToggle}
                          />
                        </Grid>
                      );
                    })
                  }
                </Grid>
              </ScrollbarWrapper>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default SplitMediaCardArray;

