import * as PIXI from "pixi.js";

import { warningIcon } from "helpers/canvashelpers/base64icons";

import { levelVisibilitySettings } from "helpers/canvashelpers/constants";

import { getLineDescriptions, calculateStartAngleForLine, getArcCenter, drawEdgeSegment, drawArcForJunction, drawArrowhead } from "components/CanvasComponents/CanvasObjects/edgehelpers";

const BORDER_WIDTH_FACTOR = 0.02;
const BORDER_RADIUS_FACTOR = 0.03;

export const CanvasBox = ({
  app,
  selectedVertexId,
  colour,
  context,
  sizes,
  position,
  borderColour,
  fontSizes,
  isIntegrated,
  scale = 1,
  alpha = 1,
  interactive = "dynamic",
  cursor = "pointer",
  highlighted,
  resources,
  innerBoxProportion,
  zIndexAdjustment = 0,
  unconfiguredChildren,
  openDrawers,
  // displaceLabels,
  metricsVisible,
  setOpenDrawers
}) => {

  const box = new PIXI.Graphics();

  const borderRadius = sizes[0] * BORDER_RADIUS_FACTOR;
  const borderWidth = sizes[0] * (borderColour === "#00b0ff" ? BORDER_WIDTH_FACTOR : BORDER_WIDTH_FACTOR / 2);

  const fadeRange = levelVisibilitySettings.visibility_settings_ranges[context.networkId];
  // Draw border shape
  const borderAlpha = borderColour === "#00b0ff" 
    ? 1 
    : scale <= fadeRange[1] 
      ? alpha * 0.2  // Proportionate to alpha in 0-0.2 during fade-in
      : scale >= fadeRange[2] 
        ? 0.2 + (1 - alpha) * 0.8  // Increase from 0.2 to 1 during fade-out
        : 0.2;

  box.lineStyle(borderWidth, borderColour, borderAlpha);

  // Adjust the size of the border shape to be slightly larger
  box.drawRoundedRect(-borderWidth/2, -borderWidth/2, sizes[0] + borderWidth, sizes[1] + borderWidth, borderRadius + borderWidth/2);

  // Draw fill shape
  if(highlighted){
    box.beginTextureFill({ texture: resources.createDirectionalSpotlight(colour, "#f0f6ff", sizes[0], sizes[1], alpha) });
  }
  else if(innerBoxProportion === null && alpha > 0){
    box.beginTextureFill({ texture: resources.createHatching(sizes[0], colour, alpha, scale) });
  }
  else{
    const outerBoxColour = metricsVisible ? "#ccc" : colour;
    const _alpha = innerBoxProportion < 1 ? 0.2 : alpha;
    box.beginFill(outerBoxColour, _alpha);
  }
  
  box.drawRoundedRect(0, 0, sizes[0], sizes[1], borderRadius);
  box.endFill();

  if(innerBoxProportion){
    addInnerBox(box, innerBoxProportion, sizes, colour, alpha);
  }

  // Set properties
  box.position.set(position[0], position[1]);
  box.zIndex = (interactive === "dynamic" ? 2 : 1) + zIndexAdjustment;
  box.eventMode = "dynamic";
  box.cursor = cursor;
  box.canvasObjectType = "vertex";
  box.sortableChildren = true;
  box.wide = sizes[0];

  if(context){
    box.context = context;
    const fontSize = unconfiguredChildren === true ?
      fontSizes['unconfigured'] :
      fontSizes[context.networkId];

    // Add text
    if(alpha !== 0){
      const nodeName = context.name;
      if(context.execution){
        const taskedTo = `Tasked to: ${Object.values(context.execution.executors).join(', ')}`;
        const targetDate = `Target date: ${context.execution.targetDate ?? 'TBD'}`;

        let top = 1;
        [nodeName, taskedTo, targetDate].forEach((v, i) => {
          const {text, height} = projectBoxText(context.id, v, fontSize, sizes, alpha, scale, top, i);
          top += height + 5;
          box.addChild(text);
        });
      }

      else{
        const text = canvasBoxText(context.id, nodeName, fontSize, sizes, alpha, scale, typeof(innerBoxProportion) === "number");
        box.addChild(text);
      }
    }
    
    if(context.networkId === 1 && isIntegrated !== 1 && alpha !== 1 && unconfiguredChildren.length > 0){
      app.stage.addChild(UnconfiguredNodeDrawer(app, position, sizes, borderRadius, unconfiguredChildren, context.id, selectedVertexId, openDrawers, setOpenDrawers, alpha, fontSizes));
    }
  }

  return box;
}

const addInnerBox = (box, innerBoxProportion, sizes, colour, alpha) => {
  let innerBoxSizes = sizes.map(size => size * innerBoxProportion);
  let innerBoxPosition = [
    (sizes[0] - innerBoxSizes[0]) / 2,
    (sizes[1] - innerBoxSizes[1]) / 2
  ];
  
  let innerBox = new PIXI.Graphics();
  let innerBorderRadius = innerBoxSizes[0] * BORDER_RADIUS_FACTOR;
  
  // Draw inner box fill
  innerBox.beginFill(colour, alpha);
  innerBox.drawRoundedRect(0, 0, innerBoxSizes[0], innerBoxSizes[1], innerBorderRadius);
  innerBox.endFill();
  
  innerBox.position.set(innerBoxPosition[0], innerBoxPosition[1]);
  box.addChild(innerBox);
}

const UnconfiguredNodeDrawer = (app, position, sizes, borderRadius, unconfiguredChildren, id, selectedVertexId, openDrawers, setOpenDrawers, alpha, fontSizes) => {
  let innerBox = new PIXI.Graphics();
  innerBox.orgId = id;
  innerBox.zIndex = 3;
  innerBox.eventMode = "static";

  const drawerState = openDrawers[id] || 'closed';
  const isOpening = drawerState === 'opening';
  const isOpen = drawerState === 'open';
  const isClosing = drawerState === 'closing';

  const addChildren = () => {
    const fixedWidth = 53;
    const fixedHeight = 53;
    const horizontalSpacing = 10;
    const verticalSpacing = 10;
  
    unconfiguredChildren.forEach((child, index) => {
      const columnIndex = index % 2;
      const rowIndex = Math.floor(index / 2);
      const x = 55 + horizontalSpacing + columnIndex * (fixedWidth + horizontalSpacing);
      const y = verticalSpacing + rowIndex * (fixedHeight + verticalSpacing);
  
      let frameColour = selectedVertexId === child.context.id ? "#00b0ff" : child.colour;
  
      app.stage.addChild(CanvasBox({
        colour: "#cccccc",
        context: child.context,
        sizes: [fixedWidth, fixedHeight],
        position: [position[0] + x, position[1] + y],
        borderColour: frameColour,
        fontSizes: fontSizes,
        isIntegrated: 1,
        scale: 3,
        alpha: 1,
        interactive: "dynamic",
        cursor: "pointer",
        zIndexAdjustment: 2,
        unconfiguredChildren: true
      }));
    });
  };

  const drawBox = (boxWidth, boxHeight) => {
    innerBox.clear();
    innerBox.beginFill(0x000000, 0.5 - (alpha * 0.5));
    innerBox.drawRoundedRect(0, 0, boxWidth, boxHeight, borderRadius);
    innerBox.endFill();
    innerBox.hitArea = new PIXI.Rectangle(0, 0, boxWidth, boxHeight);
  };
  
  const initialWidth = isOpen || isOpening ? sizes[0] * 0.72 : sizes[0] * 0.09;
  const initialHeight = isOpen || isOpening ? sizes[1] : sizes[1] * 0.09;
  drawBox(initialWidth, initialHeight);
  

  if (isOpen) {
    addChildren();
  }

  const iconButton = CanvasIconButton(warningIcon, [initialWidth - 16, 1], 0xFFA500, 1 - alpha, 0.3, () => {
    let newOpenDrawers = { ...openDrawers };

    if (drawerState === 'open') {
      // Remove children before starting the closing animation
      let ids = unconfiguredChildren.map((child) => child.context.id);
      let childrenToRemove = app.stage.children.filter((child) => ids.includes(child.orgId));
      childrenToRemove.forEach((child) => app.stage.removeChild(child));
      newOpenDrawers[id] = 'closing';
    } else if (drawerState === 'closed' || drawerState === 'opening') {
      newOpenDrawers[id] = 'opening';
    }

    setOpenDrawers(newOpenDrawers);
  }, 1 - alpha);

  innerBox.position.set(position[0] + sizes[0] - initialWidth, position[1]);
  innerBox.addChild(iconButton);

  if (isOpening || isClosing) {
    animateDrawer(innerBox, iconButton, sizes, position, id, openDrawers, setOpenDrawers);
  }

  return innerBox;
}

const animateDrawer = (innerBox, iconButton, sizes, position, id, openDrawers, setOpenDrawers) => {
  let drawerState = openDrawers[id];
  let targetWidth = drawerState === 'opening' ? sizes[0] * 0.72 : sizes[0] * 0.09;
  let targetHeight = drawerState === 'opening' ? sizes[1] : sizes[1] * 0.09;
  let animationDuration = 500; // in milliseconds
  let animationStartTime = Date.now();
  let startWidth = drawerState === 'opening' ? sizes[0] * 0.09 : sizes[0] * 0.72;
  let startHeight = drawerState === 'opening' ? sizes[1] * 0.09 : sizes[1];

  const animate = () => {
    let currentTime = Date.now();
    let timeElapsed = currentTime - animationStartTime;
    let progress = Math.min(timeElapsed / animationDuration, 1);
    let currentWidth = startWidth + (targetWidth - startWidth) * progress;
    let currentHeight = startHeight + (targetHeight - startHeight) * progress;
    let currentX = sizes[0] - currentWidth;

    innerBox.clear();
    innerBox.beginFill(0x000000, 0.5);
    innerBox.drawRoundedRect(0, 0, currentWidth, currentHeight, 7);
    innerBox.endFill();
    innerBox.hitArea = new PIXI.Rectangle(0, 0, currentWidth, currentHeight);
    innerBox.position.set(position[0] + currentX, position[1]);

    iconButton.position.set(currentWidth - 16, 1);

    if (progress < 1) {
      requestAnimationFrame(animate);
    } else {
      let newOpenDrawers = { ...openDrawers };
      if (drawerState === 'opening') {
        newOpenDrawers[id] = 'open';
      } else if (drawerState === 'closing') {
        newOpenDrawers[id] = 'closed';
      }
      setOpenDrawers(newOpenDrawers);
    }
  };

  animate();
}

const canvasBoxText = (id, value, fontSize, sizes, alpha, scale, displaceLabels=false) => {
  fontSize = displaceLabels ? fontSize * 0.65 : fontSize;
  const wordWrapWidth = sizes[0] * 0.9;
  const text = createCacheText(id, value, fontSize, wordWrapWidth, scale);

  const boundingBox = text.getBounds();
  text.x = displaceLabels ?
    sizes[0]/30 :
    (sizes[0] - boundingBox.width)/2;
  text.y = displaceLabels ?
    sizes[1]/30 :
    (sizes[1] - boundingBox.height)/2;
  text.alpha = alpha === 1 ? 1 : alpha * 0.3;
  return text;
}

const projectBoxText = (id, value, fontSize, sizes, alpha, scale, top, i) => {
  fontSize = fontSize * 0.65;
  const wordWrapWidth = sizes[0] * 0.9;
  const text = createCacheText(`${id}-${value}-${i}`, value, fontSize, wordWrapWidth, scale, 'left');

  const boundingBox = text.getBounds();
  const height = boundingBox.height;
  text.x = sizes[0]/30;
  text.y = top;
  text.alpha = alpha === 1 ? 1 : alpha * 0.3;
  return {text, height};
}

const textCache = {};

const createCacheText = (id, value, fontSize, wordWrapWidth, scale, align='center', fontWeight='normal') => { // Options: 'normal', 'bold', 'bolder', 'lighter', or a number like 100, 200, etc.
  const devicePixelRatio = window.devicePixelRatio || 1;

  const logScale = Math.log2(scale + 1);
  const resolution = devicePixelRatio * logScale * 2;

  const adjustedFontSize = fontSize * scale;

  // Quantize the scale to the nearest 15%
  const quantizedScale = Math.round(scale / 0.15) * 0.15;
  const cacheKey = `${id}-${quantizedScale}-${fontSize}`;

  // Cache expiry: Define a cache lifespan (in milliseconds)
  const cacheLifespan = 60000; // 1 minute

  // Clean up expired cache items
  for (const key in textCache) {
    if (Date.now() - textCache[key].timestamp > cacheLifespan) {
      delete textCache[key];
    }
  }
  if (textCache[cacheKey]) {
    return textCache[cacheKey].text;
  }

  const text = new PIXI.Text(value, {
    fontFamily: "Arial",
    fontSize: adjustedFontSize,
    fontWeight: fontWeight,
    fill: 0x000000,
    align: align,
    wordWrap: true,
    breakWords: true,
    wordWrapWidth: Math.floor(wordWrapWidth * scale),
    resolution: resolution
  });

  text.scale.set(1 / scale);

  textCache[cacheKey] = {
    text: text,
    timestamp: Date.now()
  };

  return text;
}

export const createText = (value, fontSize, wordWrapWidth, scale, rotation = 0, wordWrap = true) => {
  const devicePixelRatio = window.devicePixelRatio || 1;
  const resolution = devicePixelRatio * scale;

  const adjustedFontSize = fontSize * scale;

  const text = new PIXI.Text(value, {
    fontFamily: "Arial",
    fontSize: adjustedFontSize,
    fill: 0x000000,
    align: "center",
    wordWrap: wordWrap,
    wordWrapWidth: wordWrapWidth * scale,
    resolution: resolution
  });

  text.scale.set(1 / scale);
  text.rotation = rotation * (Math.PI / 180);

  return text;
}

export const CanvasEdge = (edge, width, alpha, colour, junctionRadius = 5, arrowheadLength = 10, onClick, hoveredEdges, setHoveredEdges) => {
  let lines = edge.paths;
  if (!lines || lines.length === 0) {
    throw new Error('At least one line is required to draw an atlas edge');
  }

  const path = new PIXI.Graphics();
  path.canvasObjectType = 'edge';
  path.eventMode = 'dynamic';
  path.cursor = 'pointer';
  path.context = edge.context;

  alpha = Math.max(alpha, 0.2);
  path.lineStyle(width, colour, alpha);

  const hitAreaOptions = {
    edge: edge.context,
    hoveredEdges: hoveredEdges,
    setHoveredEdges: setHoveredEdges,
    onClick: onClick
  }

  const descriptions = getLineDescriptions(lines, junctionRadius, arrowheadLength);

  descriptions.forEach((description, index) => {
    // Draw the visible line segment
    const line = lines[index];
    const angle = Math.atan2(line.endY - line.startY, line.endX - line.startX);
    drawEdgeSegment(path, description, width, angle, hitAreaOptions);

    if (lines.length > 1 && index < lines.length - 1) {
      const startAngle = calculateStartAngleForLine(description, lines[index + 1]);
      const arcCenter = getArcCenter(description, lines[index + 1], junctionRadius);

      // Draw the arc for the junction
      drawArcForJunction(path, arcCenter, junctionRadius, startAngle, width, hitAreaOptions);
    }

    if (index === lines.length - 1) {
      // Draw the arrowhead
      drawArrowhead(path, width, line, angle, colour, alpha, arrowheadLength, hitAreaOptions);
    }
  });

  return path;
};

export const MapEdgeText = (value, fontSize, wordWrapWidth, position, scale = 1, alignment = "middle") => {
  const text = createText(value, fontSize, wordWrapWidth, scale);
  text.eventMode = "none";
  text.zIndex = 2;

  let boundingBox = text.getBounds();
  
  // Set x position based on alignment
  if (alignment === "left") {
    text.x = position[0];
  } else if (alignment === "right") {
    text.x = position[0] - boundingBox.width;
  } else { // "middle"
    text.x = position[0] - (boundingBox.width / 2);
  }
  
  // Set y position
  text.y = position[1] - (20 + boundingBox.height);

  return text;
};

export const CanvasIconButton = (icon, position, colour, alpha, scale, action, actionParam) => {
  let iconButton = new PIXI.Sprite(PIXI.Texture.from(icon));
  iconButton.position.set(position[0], position[1]);
  iconButton.tint = colour;
  iconButton.alpha = alpha;
  iconButton.zIndex = 6;
  iconButton.scale.set(scale);
  iconButton.eventMode = "dynamic";
  iconButton.cursor = "pointer";
  iconButton.on("pointerdown", (e) => {
    e.preventDefault()
    e.stopPropagation();
    action(actionParam);
  })
  return iconButton;
}

export const MapBox = ({context, position, edgeColour, edgeThickness}) => {
  const box = new PIXI.Graphics();
  
  const sizes = [200, 200];
  const borderRadius = 4;
  
  const alpha = 0.9; // remove
  box.lineStyle(edgeThickness, edgeColour, 1);
  box.beginFill("#cccccc", alpha);
  box.drawRoundedRect(0, 0, sizes[0], sizes[1], borderRadius);
  box.endFill();

  // Set properties
  box.position.set(position[0], position[1]);
  box.zIndex = 3;
  box.eventMode = "dynamic";
  box.cursor = "pointer";
  box.canvasObjectType = "vertex"; // can this be removed?
  box.sortableChildren = true; // can this be removed?
  box.wide = sizes[0]; // can this be removed?
  box.context = context;

  // Add text
  const wordWrapWidth = sizes[0] * 0.87;
  const text = createText(context.name, 20, wordWrapWidth, 1);

  const boundingBox = text.getBounds();
  text.x = (sizes[0] - boundingBox.width)/2;
  text.y = (sizes[1] - boundingBox.height)/2;
  box.addChild(text);

  return box;
}