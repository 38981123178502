import React from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import bgImage from './Skeptic.webp';

const description = [
  {
    name: "Merit",
    paragraph1: "Merit is a framework that enables organisations to, easily, define product blueprints and map the processes for delivering those product, in 3D. You can define which jobs and inventory are associated with different parts of the process and which individuals perform those roles. When it’s time to deliver a project, simply use your product blueprint, and seamlessly leverage our tools for product definition, project management, inventory management, and knowledge/document management.",
    paragraph2: "As project data accumulates, Merit’s comprehensive business intelligence toolkit enables continuous comparison and optimisation of people, processes, inventory, tools, materials, and more."
  }
];

export const Concepts = () => {
  return (
    <Box
      id="concepts"
      sx={{
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: { xs: '0px', md: '20px' },
        py: { xs: '17px', md: '20px' },
        backgroundColor: '#F1EDE2',
        scrollMarginTop: { xs: '20px', sm: '0px', md: '0px' }
      }}>
      <Container>
        <Grid container spacing={{ xs: 2, md: 4 }} alignItems="center">
          {/* Image on the left */}
          <Grid item xs={12} md={4}>
            <Box
              component="img"
              src={bgImage}
              alt="Suspicious expression"
              sx={{
                maxWidth: '100%',
                height: 'auto',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            />
          </Grid>

          {/* Text on the right with a white background */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                backgroundColor: '#fff',
                padding: { xs: '11px', md: '20px' },
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
              }}
            >
              <Typography 
                fontSize={{ xs: 15, md: 22 }}
                gutterBottom 
                sx={{ fontWeight: 600 }}>
                {description[0].name}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                paragraph 
                sx={{ fontWeight: 400 }}>
                {description[0].paragraph1}
              </Typography>
              <Typography 
                fontSize={{ xs: 13, md: 18 }}
                sx={{ fontWeight: 400 }}>
                {description[0].paragraph2}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
