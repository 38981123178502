import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { Tab, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// Icons
import FolderIcon from '@mui/icons-material/Folder';

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";
import InstanceDetailsEditor from "./InstanceDetailsEditor";
import InstanceVariablesEditor from "./InstanceVariablesEditor";
import InstanceDescriptorEditor from "./InstanceDescriptorEditor";
import InstanceScopesEditor from "./InstanceScopesEditor";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db Context
import { getResult } from "apiservices/ResultServices/resultService";
import { createInstance, updateInstance, getInstance } from "apiservices/ResultServices/instanceService";

// Local context
import { useAdminController } from "context";

const Instance = () => {
  const [controller, dispatch] = useAdminController();

  const [instance, setInstance] = useState();
  const [resultOptions, setResultOptions] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});
  const [selectedInstanceVariableId, setSelectedInstanceVariableId] = useState(undefined);

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    if ((!instance || resultOptions.length !== 0) && instance?.resultId !== 0) return;
    const _getResultOptions = async () => {
      let _result = await getResult(controller.organisationId, instance.resultId);
      setResultOptions(_result.resultOptions);
    };
    _getResultOptions();
  }, [instance]);

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(instance.id > 0 && instance.resultId > 0)
      setActiveTab(tabNumber);
  };
  
  return(
    <AdministrationObjectWrapper
      object={instance}
      setObject={setInstance}
      getObject={getInstance}
      createObject={createInstance}
      updateObject={updateInstance}
      namePlaceholder="Instance Name"
      descriptionPlaceholder="Description of Instance"
      iconComponent={<FolderIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        instance &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
            {
              (instance.id > 0 && instance.resultId > 0) ?
                <Tab label="Variables" value="2"/> :
                <Tooltip title="Save the instance and select a result type to begin defining variables" arrow>
                  <Tab label="Variables" value="2"/>
                </Tooltip>
            }
          </AdminTabList>
      }
      form={
        instance &&
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <Box sx={{ display: "flex", height: 1, flexDirection: 'row', gap: 1, mt: -1 }}>
                <InstanceDetailsEditor instance={instance} setInstance={setInstance}/>
                <InstanceScopesEditor instance={instance} setInstance={setInstance} resultId={instance.resultId}/>
              </Box>
          </TabPanel>
      }
      panels={
        (instance?.id > 0 && instance?.resultId > 0) &&
          <TabPanel 
            value="2"
            style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
            <Box sx={{ display: "flex", height: 1, flexDirection: 'row', gap: 1, width: 1 }}>
              <InstanceVariablesEditor
                resultOptions={resultOptions}
                instance={instance}
                selectedInstanceVariableId={selectedInstanceVariableId}
                setSelectedInstanceVariableId={setSelectedInstanceVariableId}/>
              <InstanceDescriptorEditor selectedInstanceVariableId={selectedInstanceVariableId}/>
            </Box>
          </TabPanel>
      }
      validate={validate}
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}/>
  )
}

export default Instance;