import React, { useState, useEffect } from 'react';

// @mui components
import { Box } from '@mui/material';
import { Typography, TextField, IconButton, Select, MenuItem } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';

// @mui icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

// Components
import TreeItemLabel from './TreeItemLabel';
import ScrollbarWrapper from 'components/GlobalComponents/ScrollbarWrapper';

// Db context
import { getNetworkProducts } from "apiservices/AuthorisationServices/productService";

const ResultOptionsTree = ({ result, setResult, selectedResult, setSelectedResult, organisationId }) => {
  const [showInput, setShowInput] = useState({ id: '', show: false });
  const [newOption, setNewOption] = useState({ name: '', typeId: 1, authorisationId: null });
  const [lastId, setLastId] = useState(-1);
  const [networkProducts, setNetworkProducts] = useState([]);

  useEffect(() => {
    const _getNetworkProducts = async () => {
      try {
        const _networkProducts = await getNetworkProducts(organisationId, newOption.typeId - 1);
        setNetworkProducts(_networkProducts);
      } catch (error) {
        console.error(error);
      }
    };
    if (newOption.typeId === 2 || newOption.typeId === 3) {
      _getNetworkProducts();
    }
  }, [newOption.typeId]);

  const handleCancelClick = () => {
    setShowInput({ id: '', show: false });
    setNewOption({ name: '', typeId: 1, authorisationId: null });
  };

  const handleCreateOption = (parentResultOptionId, typeId) => {
    const newId = lastId - 1;
    setLastId(newId);
    const newVar = {
      id: newId,
      name: newOption.name,
      description: '',
      typeId: typeId,
      parentResultOptionId: parentResultOptionId,
      categories: [],
      associatedNodeIds: [],
      authorisationId: newOption.authorisationId,
    };
    setResult(prevResult => ({
      ...prevResult,
      resultOptions: [...prevResult.resultOptions, newVar]
    }));
    setShowInput({ id: '', show: false });
    setNewOption({ name: '', typeId: 1, authorisationId: null });
  };

  const AddInput = ({ parentOptionId, typeId }) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
        <IconButton onClick={(e) => {
          e.stopPropagation();
          handleCancelClick();
        }} size="small">
          <CancelIcon />
        </IconButton>
        {(typeId === 2 || typeId === 3) ? (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={newOption.authorisationId || ''}
              onChange={(e) => {
                const selectedProduct = networkProducts.find((product) => product.id === e.target.value);
                setNewOption({ ...newOption, name: selectedProduct.name, authorisationId: e.target.value });
              }}
              sx={{ width: 200, height: 30 }}>
              {networkProducts.map((product) => (
                <MenuItem key={product.id} value={product.id}>
                  {product.name}
                </MenuItem>
              ))}
            </Select>
            <IconButton onClick={() => handleCreateOption(parentOptionId, typeId)} size="small">
              <AddIcon />
            </IconButton>
          </Box>
        ) : (
          <TextField
            autoFocus
            variant="standard"
            size="small"
            value={newOption.name}
            onChange={(e) => setNewOption({ ...newOption, name: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleCreateOption(parentOptionId, typeId);
              }
            }}
          />
        )}
      </Box>
    );
  };

  const AddIconComponent = ({ id, typeId, sx }) => {
    const handleAddClick = (id) => {
      setShowInput({ id, show: true });
      setNewOption({ ...newOption, typeId: typeId });
    };
  
    return (
      <IconButton onClick={(e) => {
        e.stopPropagation();
        handleAddClick(id);
      }} size="small" sx={sx}>
        <AddIcon />
      </IconButton>
    );
  };

  const handleSelect = (option) => {
    setSelectedResult(option);
  };

  const buildTree = (parentResultOptionId, typeId) => {
    const filteredOptions = result.resultOptions.filter((option) => option.parentResultOptionId === parentResultOptionId);
  
    return filteredOptions.map((option) => (
      <TreeItem
        key={option.id}
        nodeId={option.id.toString()}
        sx={{
          "& .MuiTreeItem-content": {
            backgroundColor: "transparent !important"
          }
        }}
        label={
          <TreeItemLabel
            option={option}
            showInput={showInput.id === option.id && showInput.show}
            colour={selectedResult && selectedResult.id === option.id ? "#C0C0C0" : "transparent"}
            resultOptions={result.resultOptions}
            setResult={setResult}
            AddInput={AddInput}
            AddIconComponent={AddIconComponent}
            onSelect={() => handleSelect(option)}
            typeId={typeId}
            onAddClick={() => handleAddClick(option.id)}/>
        }>
        {buildTree(option.id, typeId + 1)}
      </TreeItem>
    ));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: "column", backgroundColor: '#F5F5F5', borderRadius: 2, p: 1, flex: 1 }}>
      <ScrollbarWrapper>
        <Box sx={{ display: 'flex', alignItems: 'center', padding: 1 }}>
          <AddIconComponent id={null} typeId={1}/>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            Create Generic Component
          </Typography>
          {
            (showInput.id === null && showInput.show) && 
              <AddInput parentOptionId={null} typeId={1} />
          }
        </Box>
        <TreeView
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
        >
          {buildTree(null, 2)}
        </TreeView>
      </ScrollbarWrapper>
    </Box>
  );
};

export default ResultOptionsTree;