import React, { useState, useRef } from "react";

// @mui components
import { Grid, Box, Card } from "@mui/material";
import { Typography, Tooltip, Divider, IconButton } from "@mui/material";

// @mui icons
import BrushIcon from '@mui/icons-material/Brush';
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CancelIcon from '@mui/icons-material/Cancel';

// Font Awesome icon import
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoePrints } from '@fortawesome/free-solid-svg-icons';

// Contexts
import { useCanvasController, setHighlightedVertexIds } from "context/canvasContext";

// Services
export const networkIcons = {
  1: <BusinessIcon />,
  2: <WorkIcon />,
  3: <FontAwesomeIcon icon={faShoePrints} style={{ fontSize: '14px', paddingBottom: '5px', marginTop: 6 }}/>,
  4: <FingerprintIcon />,
  5: <CameraAltIcon />
}

const ResultOptionsTreeLabel = ({ option, networkNodeCounts, activeResultOption, setInteractionMode, selectedResultOption, setSelectedResultOption, interactionMode }) => {
  const [canvasController, canvasDispatch] = useCanvasController();

  const [highlightTimeout, setHighlightTimeout] = useState(null);
  const isMouseOverRef = useRef(false);

  const handleMouseLeave = () => {
    isMouseOverRef.current = false;
    if (highlightTimeout) {
      clearTimeout(highlightTimeout);
      setHighlightTimeout(null);
    }
    if ((interactionMode !== 'removeResultVariable' && interactionMode !== 'resultVariable')) {
      setHighlightedVertexIds(canvasDispatch, []);
    }
    else{
      if (activeResultOption?.id === option.id){
        const scopeNodeIds = option.scopeNodes.find(x => x.scopeId === parseInt(activeResultOption.scopeId))?.nodeIds ?? [];
        setHighlightedVertexIds(canvasDispatch, scopeNodeIds);
      }
    }
  };

  const handleMouseEnter = () => {
    isMouseOverRef.current = true;
    if (highlightTimeout) {
      clearTimeout(highlightTimeout);
    }
    const newTimeout = setTimeout(() => {
      if (isMouseOverRef.current) {
        const concatenatedNodeIds = option.scopeNodes
          .reduce((acc, scopeNode) => acc.concat(scopeNode.nodeIds), []);
        setHighlightedVertexIds(canvasDispatch, concatenatedNodeIds);
      }
    }, 700);
    setHighlightTimeout(newTimeout);
  };

  let backgroundColour = selectedResultOption?.id === option.id ? "#00b0ff" : "white";

  const handleSetSelectedResultOption = () => {
    if (selectedResultOption?.id === option.id){
      setSelectedResultOption(null);
      setInteractionMode(null, null, 'select');
    }
    else
      setSelectedResultOption(option);
  };

  return(
    <Card
      sx={{ mb: 1, ml: -3, borderRadius: "4px", p: 0.5, pl: 2.5, backgroundColor: backgroundColour }}
      onClick={(e) => { e.stopPropagation(); handleSetSelectedResultOption() }}
      onMouseEnter={() => handleMouseEnter()}
      onMouseLeave={() => handleMouseLeave()}>
      <Grid container flexDirection="column">
        <Box width={1} height= "19px" display= "flex" justifyContent= "space-between">
          <Tooltip title={option.name} placement="right" arrow={false}>
            <Typography
              variant="h1"
              fontSize={18}
              sx={{ ml: 0.5 }}
              noWrap>
              {option.name}
            </Typography>
          </Tooltip>
          <Box display="flex" flexDirection="row" alignItems="center" gap={0.5} height='24px' mt={-0.26}>
          {
            Object.entries(networkNodeCounts).map(([networkId, count]) => {
              return(
                <Box 
                  key={networkId} 
                  display="flex" 
                  flexDirection="row" 
                  alignItems="center"
                  sx={{
                    bgcolor: '#c4c4c4',
                    borderRadius: "4px",
                    maxHeight: "19px",
                    pb: 0.15,
                    pr: 0.3
                  }}>
                  {
                    networkIcons[networkId]
                  }
                  <Typography fontSize={13} mb={-0.8}>{count}</Typography>
                </Box>
              )
            })
          }
          </Box>
        </Box>
        {
          selectedResultOption?.id === option.id &&
            <>
              <Divider sx={{ my: 0.5 }}/>
              {
                option.scopeNodes.map(sn => {
                  return(
                    <Box
                      key={sn.scopeId}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      alignItems="center"
                      width={1}
                      ml={-1.6}
                      pr={0.5}
                      sx={{
                        backgroundColor: (activeResultOption?.id === option.id &&
                                          activeResultOption?.scopeId === sn.scopeId)
                                          ? '#c4c4c4'
                                          : '#00b0ff',
                        borderRadius: '4px',
                        '&:hover': {
                          backgroundColor: (activeResultOption?.id === option.id &&
                                            activeResultOption?.scopeId === sn.scopeId)
                                            ? '#c4c4c4'
                                            : '#007bb2',
                        }
                      }}
                    >
                      <Typography
                        key={sn.scopeId}
                        variant="h1"
                        fontSize={15}
                        sx={{ ml: 0.6 }}
                        noWrap>
                        {sn.scopeName}
                      </Typography>
                      <Box sx={{ mt: -0.5 }}>
                        <IconButton
                          sx={{ p: 0 }}
                          onClick={(e) => { e.stopPropagation(); setInteractionMode(option.id, sn.scopeId, 'add') }}>
                          <BrushIcon
                            sx={{
                              fontSize: 20,
                              backgroundColor: (activeResultOption?.id === option.id
                                             && activeResultOption?.scopeId === sn.scopeId
                                             && interactionMode === 'resultVariable') ? "red" : "black",
                              borderRadius: "10px"
                            }}
                            fontSize=""
                          />
                        </IconButton>
                        <IconButton 
                          sx={{ p: 0 }}
                          onClick={(e) => { e.stopPropagation(); setInteractionMode(option.id, sn.scopeId, 'remove') }}
                          >
                          <CancelIcon
                            sx={{
                              fontSize: 20,
                              backgroundColor: (activeResultOption?.id === option.id
                                             && activeResultOption?.scopeId === sn.scopeId
                                             && interactionMode === 'removeResultVariable') ? "red" : "black",
                              borderRadius: "10px"
                            }}
                            fontSize=""
                          />
                        </IconButton>
                      </Box>
                    </Box>
                  )
                })
              }
            </>
        }
      </Grid>
    </Card>
  )
}

export default ResultOptionsTreeLabel;