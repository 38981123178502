import { useNavigate } from "react-router-dom";

// @mui components
import { Grid } from "@mui/material";

// @mui icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import KeyIcon from "@mui/icons-material/Key";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SearchIcon from "@mui/icons-material/Search";
import FolderIcon from "@mui/icons-material/Folder";

// Components
import { PanelControlButton } from "components/CanvasComponents/OverlayButtons";
import PropertiesPanel from "./propertypanel/PropertiesPanel";
import KeyPanel from "./keypanel/KeyPanel";
import ResultPanel from "./resultOptionsPanel";
import AssignmentPanel from "./assignmentPanel";
import StatsWindow from "./statsWindow";

import { clearStage } from "helpers/canvashelpers/canvasfunctions";

import SkillSetNodeManager from "./propertypanel/nodepanels/SkillSetNodeManager";
import ProcessEditor from "./propertypanel/nodepanels/ProcessEditor";
import KnowledgeManager from "./propertypanel/KnowledgeManager";

// Canvas context
import { useCanvasController, setPropertyPanelVisible, setKeyPanelVisible, setProjectPanelVisible, getApp, setApp,
         setProjectMode, setProjectToggled, setActiveLevel } from "context/canvasContext";

const CanvasPanelController = ({atlasId, userRoles}) => {
  const navigate = useNavigate();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { propertyPanelVisible, keyPanelVisible, projectPanelVisible,
          projectMode, propertiesManagerWindow, statsWindow } = canvasController;

  const handleReturnToDashboard = () => {
    getApp().destroy();
    document.getElementById("atlascanvas").remove();
    setApp(undefined);
    navigate("/dashboard");
  };

  const handleSetPropertyPanelVisible = () => setPropertyPanelVisible(canvasDispatch, !propertyPanelVisible);
  const handleSetKeyPanelVisible = () => setKeyPanelVisible(canvasDispatch, !keyPanelVisible);
  const handleSetProjectPanelVisible = () => {
    if (!projectMode && userRoles.includes("instanceadmin"))
      setProjectPanelVisible(canvasDispatch, !projectPanelVisible);
    if (projectMode)
      setProjectPanelVisible(canvasDispatch, !projectPanelVisible);
  };
  const handleToggleProjectMode = async () => {
    await setProjectMode(canvasDispatch, !projectMode);
    await setActiveLevel(canvasDispatch, 1);
    await setProjectToggled(canvasDispatch, true);
    clearStage(getApp());
  };
  
  let projectModeButtonBgColour = projectMode ? "#00b0ff" : "white";
  let projectPanelButtonBgColour = (!projectMode && !userRoles.includes("instanceadmin")) ? "#C0C0C0" : "white";
  let projectPanelButtonTooltip = (!projectMode && !userRoles.includes("instanceadmin")) ? "Available in project mode" : "Toggle result panel visibility";

  return (
    <>
      <Grid sx={{width: "20%", ml: 1.5, mt: 1.5 }} style={{position: "absolute"}}>
        <Grid container sx={{ width: 1, flexWrap: "nowrap", justifyContent: "space-between" }} direction="row">
          <PanelControlButton buttonIcon={<ArrowBackIcon sx={{ fontSize: 25 }} fontSize=""/>} action={handleReturnToDashboard} tooltip="Exit to dashboard"/>
          <PanelControlButton buttonIcon={<SearchIcon sx={{ fontSize: 25 }} fontSize=""/>} action={handleSetPropertyPanelVisible} tooltip="Toggle property panel"/>
          <PanelControlButton buttonIcon={<KeyIcon sx={{ fontSize: 25 }} fontSize=""/>} action={handleSetKeyPanelVisible} tooltip="Toggle key"/>
          <PanelControlButton buttonIcon={<AccountTreeIcon sx={{ fontSize: 25 }} fontSize=""/>} action={handleSetProjectPanelVisible} tooltip={projectPanelButtonTooltip} bgColour={projectPanelButtonBgColour}/>
          <PanelControlButton buttonIcon={<FolderIcon sx={{ fontSize: 25 }} fontSize=""/>} action={handleToggleProjectMode} tooltip="Toggle project mode" bgColour={projectModeButtonBgColour}/>
        </Grid>
        {
          propertyPanelVisible && (
            <PropertiesPanel atlasId={atlasId}/>
          )
        }
        {
          keyPanelVisible && ( 
            <KeyPanel/>
          )
        }
        {
          (userRoles.includes("instanceadmin") && projectPanelVisible && !projectMode) && (
            <ResultPanel/>
          )
        }
        {
          (projectPanelVisible && projectMode) && (
            <AssignmentPanel/>
          )
        }
      </Grid>
      {
        ["1", "2"].includes(propertiesManagerWindow) && (
          <SkillSetNodeManager/>
        )
      }
      {
        ["3", "4"].includes(propertiesManagerWindow) && (
          <ProcessEditor/>
        )
      }
      {
        propertiesManagerWindow === "kMEditor" && (
          <KnowledgeManager/>
        )
      }
      {
        statsWindow && (
          <StatsWindow atlasId={atlasId}/>
        )
      }
    </>
  );
};

export default CanvasPanelController;