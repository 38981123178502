import React, { useState } from 'react';
import seedrandom from 'seedrandom';

// @mui components
import { Card, Box } from '@mui/material';

// recharts components
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ReferenceLine, ResponsiveContainer } from 'recharts';

// Components
import { StatCard, commonCardStyle, outerBoxStyle, chartMargins, ObjectStatusCardArray, DashboardFilter } from 'components/AdministrationComponents/DashboardComponents';

const categories = [
  { name: 'Complete', color: 'green' },
  { name: 'Late', color: 'red' },
  { name: 'On Target', color: 'black' },
];

const InventoryAdministratorDashboard = () => {
  // Mock data for the last 10 days
  const mockData = [
    { name: '03-04-2023', inventoryItems: 200, ordersPending: 50 },
    { name: '02-04-2023', inventoryItems: 220, ordersPending: 45 },
    { name: '01-04-2023', inventoryItems: 190, ordersPending: 60 },
    { name: '30-03-2023', inventoryItems: 210, ordersPending: 40 },
    { name: '29-03-2023', inventoryItems: 230, ordersPending: 55 },
    { name: '28-03-2023', inventoryItems: 205, ordersPending: 35 },
    { name: '27-03-2023', inventoryItems: 180, ordersPending: 48 },
    { name: '26-03-2023', inventoryItems: 225, ordersPending: 65 },
    { name: '25-03-2023', inventoryItems: 215, ordersPending: 42 },
    { name: '24-03-2023', inventoryItems: 200, ordersPending: 50 },
  ];

  const inventoryItemsCount = 215;
  const ordersPendingCount = 48;
  
  const [selectedStatCard, setSelectedStatCard] = useState('');

  const handleStatCardClick = (title) => {
      setSelectedStatCard(title === selectedStatCard ? '' : title);
  };

  // Generate more mock data for the new component
  const generateMockDataForNewComponent = () => {
    const rng = seedrandom('fixed-seed-for-nent'); // Use a fixed seed here

    const data = Array.from({ length: 10 }, (_, index) => {
      const randomNum = rng(); // Use the seeded random number generator
      let category;
      if (randomNum < 0.6) {
        category = 'On Target';
      } else if (randomNum < 0.9) {
        category = 'Complete';
      } else {
        category = 'Late';
      }

      return {
        id: index,
        value: Math.floor(rng() * 100) + 20, // Use seeded random for value
        label: `Label ${index + 1}`,
        category,
      };
    });

    // Sort the data using the index of the category in the categories array
    return data.sort((a, b) => {
      const categoryAIndex = categories.findIndex((cat) => cat.name === a.category);
      const categoryBIndex = categories.findIndex((cat) => cat.name === b.category);
      return categoryAIndex - categoryBIndex;
    });
  };

  const mockDataForNewComponent = generateMockDataForNewComponent();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
      <DashboardFilter />
      <Box sx={outerBoxStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '50%', gap: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <StatCard
              title="Inventory Items"
              count={inventoryItemsCount}
              diff={15}
              selectedStatCard={selectedStatCard}
              setSelectedStatCard={setSelectedStatCard}
              onClick={() => handleStatCardClick('Inventory Items')}
            />
            <StatCard
              title="Orders Pending"
              count={ordersPendingCount}
              diff={-8}
              selectedStatCard={selectedStatCard}
              setSelectedStatCard={setSelectedStatCard}
              onClick={() => handleStatCardClick('Orders Pending')}
            />
          </Box>

          {/* Chart Card */}
          <Card elevation={2} sx={commonCardStyle}>
            <ResponsiveContainer height="99%">
              <LineChart data={mockData} margin={chartMargins}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="name"
                  tickFormatter={(value) => new Date(value).toLocaleDateString()}
                  angle={30}
                  dy={15}
                  dx={10}
                  style={{ fontSize: '10px' }}
                  textAnchor="end"
                />
                <YAxis
                  label={{
                    value: 'Inventory & Orders',
                    angle: -90,
                    dx: -10,
                    dy: 10,
                    style: { fontSize: 14, fontWeight: 'bold' }
                  }}
                  tick={{ fontSize: 10 }}
                />
                <Tooltip />
                <Legend wrapperStyle={{ bottom: 20, left: -60 }} />
                <Line type="monotone" dataKey="inventoryItems" name="Inventory Items" stroke="#8884d8" />
                <Line type="monotone" dataKey="ordersPending" name="Orders Pending" stroke="#82ca9d" />
                <ReferenceLine y={0} stroke="#000" />
              </LineChart>
            </ResponsiveContainer>
          </Card>
        </Box>
        <ObjectStatusCardArray data={mockDataForNewComponent} keyColours={categories}></ObjectStatusCardArray>
      </Box>
    </Box>
  );
};
export default InventoryAdministratorDashboard;