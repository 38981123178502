import React, {useState, useEffect} from "react";

// @mui components
import { FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';

// @mui icons
import GavelIcon from '@mui/icons-material/Gavel';

// Components
import { ObjectModal } from "components/GlobalComponents/Modals";
import HorizontalEditableMediaCard from "components/Cards/HorizontalEditableMediaCard";
import HorizontalMediaCard from "components/Cards/HorizontalMediaCard";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";
import { FormSaveButton } from "components/GlobalComponents/Buttons";
import { AdminPanel } from "components/AdministrationComponents/StyledAdminComponents";

import { getAtlasAccessPolicy, createAtlasAccessPolicy, updateAtlasAccessPolicy } from "apiservices/AuthorisationServices/accessPolicyService";

import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Local context
import { useAdminController } from "context";

const AccessPolicyEditor = ({setOpen, policyId, setAccessPolicies, atlasId, readonly = false}) => {
  const [controller, dispatch] = useAdminController();
  const [policy, setPolicy] = useState(undefined);
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    const _getAccessPolicy = async () => {
      let _policy = await getAtlasAccessPolicy(atlasId, controller.organisationId, policyId);
      setPolicy(_policy);
    }
    _getAccessPolicy();
  }, []);
  
  const handlePolicyChange = (event) => {
    setPolicy((prevPolicy) => ({ ...prevPolicy, [event.target.name]: event.target.checked }));
  };
  
  const handleCRUDPolicyChange = (propertyName) => (event) => {
    const subPropertyName = event.target.name;
    const checkedStatus = event.target.checked;
  
    setPolicy((prevPolicy) => ({
      ...prevPolicy,
      [propertyName]: prevPolicy[propertyName].map((item) => 
        item.function === subPropertyName 
          ? { ...item, granted: checkedStatus }
          : item
      ),
    }));
  };
  
  const generalProperties = [
    { name: 'isPublic', label: 'Is Public' },
    { name: 'allowInternalInvites', label: 'Allow Internal Invites' },
    { name: 'allowAssociateInvites', label: 'Allow Associate Invites' },
    { name: 'allowProjectCreation', label: 'Allow Project Creation' }
  ];

  const handleSave = async () => {
    policy.organisationId = controller.organisationId;
    policy.sentBy = controller.userId;
    let result = policy.id === 0 ?
      await createAtlasAccessPolicy(policy, atlasId) :
      await updateAtlasAccessPolicy(policy, atlasId);
    setAccessPolicies(result);
    setOpen(undefined);
  }

  return (
    policy &&
      <ObjectModal 
        open={true}
        handleClose={(e) => setOpen(undefined)}>
        {
          policy &&
            (readonly ? (
              <HorizontalMediaCard
                obj={policy}
                color="#FF0000"
                icon={<GavelIcon sx={{ fontSize: 94 }} fontSize=""/>}
              />
            ) : (
              <HorizontalEditableMediaCard
                obj={policy}
                setObj={setPolicy}
                color="#FF0000"
                icon={<GavelIcon sx={{ fontSize: 94 }} fontSize=""/>}
                namePlaceholder="Policy Name"
                descriptionPlaceholder="Policy Description"
                nameField="name"
                descriptionField="description"
                validate={validate}
                setInvalidFields={setInvalidFields}
                invalidFields={invalidFields}
              />
            ))
        }
        <AdminPanel>
          <ScrollbarWrapper style={{ml: 1, mt: 1, flexGrow: 1}}>
            <FormGroup>
              {
                generalProperties.map((property) => (
                  <FormControlLabel
                    key={property.name}
                    control={
                    <Checkbox
                      name={property.name}
                      checked={policy?.[property.name] ?? false}
                      onChange={handlePolicyChange}
                      disabled={readonly}
                    />
                    }
                    label={property.label}
                  />
                ))
              }
            </FormGroup>
            <CheckboxFormGroup
              title="Tag Group Policies"
              object={policy?.tagGroupPolicies}
              onChange={handleCRUDPolicyChange('tagGroupPolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Project Tag Group Policies"
              object={policy?.projectTagGroupPolicies}
              onChange={handleCRUDPolicyChange('projectTagGroupPolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Metric Policies"
              object={policy?.metricPolicies}
              onChange={handleCRUDPolicyChange('metricPolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Project Metric Policies"
              object={policy?.projectMetricPolicies}
              onChange={handleCRUDPolicyChange('projectMetricPolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Skill Set Policies"
              object={policy?.skillSetPolicies}
              onChange={handleCRUDPolicyChange('skillSetPolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Knowledge Policies"
              object={policy?.knowledgePolicies}
              onChange={handleCRUDPolicyChange('knowledgePolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Result Mapping Policies"
              object={policy?.productMappingPolicies}
              onChange={handleCRUDPolicyChange('productMappingPolicies')}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Organisation Node Policies"
              object={policy?.organisationNodePolicies}
              onChange={handleCRUDPolicyChange('organisationNodePolicies')}
              omissions={['Read']}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Job Node Policies"
              object={policy?.jobNodePolicies}
              onChange={handleCRUDPolicyChange('jobNodePolicies')}
              omissions={['Read']}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Step Node Policies"
              object={policy?.stepNodePolicies}
              onChange={handleCRUDPolicyChange('stepNodePolicies')}
              omissions={['Read']}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Substep Node Policies"
              object={policy?.substepNodePolicies}
              onChange={handleCRUDPolicyChange('substepNodePolicies')}
              omissions={['Read']}
              disabled={readonly}
            />
            <CheckboxFormGroup
              title="Action Node Policies"
              object={policy?.actionNodePolicies}
              onChange={handleCRUDPolicyChange('actionNodePolicies')}
              omissions={['Read']}
              disabled={readonly}
            />
          </ScrollbarWrapper>
          <FormSaveButton handleSave={handleSave}/>
        </AdminPanel>
      </ObjectModal>
  );
};

const CheckboxFormGroup = ({ title, object, onChange, omissions = [], disabled }) => {
  // if (!object) return null; // or some other fallback, like a loading indicator

  const crudProperties = ['Create', 'Read', 'Update', 'Delete'];
  const filteredProperties = crudProperties.filter(property => !omissions.includes(property));

  return (
    <>
      <Typography variant="h6">{title}</Typography>
      <FormGroup row>
        {
          object && filteredProperties.map((property) => {
            const propertyObj = object?.find(x => x.function === property);
            const isGranted = propertyObj?.granted ?? false;
            const isDisabled = propertyObj?.editable === false || disabled;

            return (
              <FormControlLabel
                key={property}
                control={
                  <Checkbox
                    name={property}
                    checked={isGranted}
                    onChange={onChange}
                    disabled={isDisabled}
                    style={{
                      backgroundColor: isDisabled ? '#f0f0f0' : 'transparent'
                    }}
                  />
                }
                label={property}
              />
            );
          })
        }
      </FormGroup>
    </>
  );
};

export default AccessPolicyEditor;