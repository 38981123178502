import React, { useState } from "react";

// @mui components
import { IconButton, Button, Fab, Typography } from "@mui/material";
import { styled } from '@mui/system';

// @mui icons
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';

// Components
import { WrapperModal } from "./Modals";

export const CloseWindowButton = ({action, background="#EBEBEB"}) => {
  return (
    <IconButton
      onClick={action}
      sx={{
        marginLeft: "auto",
        background: background,
        borderRadius: "4px",
        height: "25px",
        width: "25px",
        pointerEvents: "auto",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
        "&:hover": {
          backgroundColor: "#EBEBEB",
          boxShadow: "0px 4px 5px 0px rgba(0,0,0,0.2), 0px 1px 10px 0px rgba(0,0,0,0.14), 0px 2px 4px -1px rgba(0,0,0,0.12)"
        }
      }}>
      <CloseIcon sx={{ stroke: "black", strokeWidth: 4, fontSize: 20 }} fontSize=""/>
    </IconButton>
  )
}

export const SubmitButton = ({ action, text, background="#FFFFFF"}) => {
  return (
    <Button
      variant="contained"
      sx={{
        height: "26px",
        minHeight: "26px",
        position: "absolute",
        bottom: 0,
        right: 0,
        mr: 1,
        mb: 1,
        zIndex: 10,
        background: background,
        width: "auto",
        borderRadius: "4px",
        color: "black",
        px: "8px",
        py: "0px",
        textTransform: "none",
        pointerEvents: "auto",
        "&:hover": { backgroundColor: "#EBEBEB" }
      }}
      onClick={action}>
      <Typography variant="h1" fontSize={18} sx={{ pt: 0.35 }}>
        {text}
      </Typography>
    </Button>
  )
}

export const CircularButton = styled(Button)((props) => ({
  background: props.colour,
  color: props.bordercolor,
  borderRadius: '50%',
  width: '26px',
  height: '26px',
  padding: '1px 0 0 0',
  minWidth: '0',
  minHeight: '0',
  
  '&:hover': {
    background: props.colour,
    cursor: 'pointer',
  }
}));

export const SquareButton = styled(Button)((props) => ({
  background: props.colour,
  color: props.bordercolor,
  borderRadius: '10%',
  width: '26px',
  height: '26px',
  padding: '1px 0 0 0',
  minWidth: '0',
  minHeight: '0',
  
  '&:hover': {
    background: props.colour,
    color: "white",
    cursor: 'pointer',
  }
}));

export const DeleteButton = ({deleteAction, id, scale=0.5, margin=0.2}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onDeleteComplete = () => handleClose();
  
  return(
    <>
      <Fab 
        size="small" style={{ transform: `scale(${scale})` }}
        color="primary" 
        sx={{position: "absolute", top: `${margin}rem`, right: `${margin}rem`, boxShadow: 0, zIndex: 1 }}
        onClick={handleOpen}>
        <DeleteForeverIcon sx={{fontSize: 24}} fontSize=""/>
      </Fab>
      <WrapperModal
        open={open} 
        handleClose={handleClose}
        title={"Delete Item"}
        primaryButtonText={"Permanently Delete Item"}
        primaryEvent={(e) => deleteAction(e, id, onDeleteComplete)} 
        id={id}>
        <Typography id="transition-modal-description" sx={{ mt: 2 }}>
          Are you sure you want to delete this item? This action cannot be undone.
        </Typography>
      </WrapperModal>
    </>
  )
}

export const FormSaveButton = ({handleSave, isDirty}) => {
  return(
    <Fab 
      style={{
      position: "absolute", 
      zIndex: 1, 
      right: "1rem",
      fontWeight: "800",
      bottom: "1rem", 
      width: "50px",
      height: "50px",
      borderRadius: "6px",
      background: isDirty ? "#e53935" : "#4caf50",}}
      onClick={handleSave}>
      Save
    </Fab>
  )
}