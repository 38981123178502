// @mui components
import { Box, Card } from "@mui/material";
import { Typography, IconButton } from "@mui/material";

// @mui icons
import DoneIcon from '@mui/icons-material/Done';
import ClearIcon from '@mui/icons-material/Clear';

const AssociateRequestCard = ({request, responseAction}) => {
  return(
    <Card 
      elevation={1} 
      sx={{ height: 1, bgcolor: "#F5F5F5", borderRadius: "4px", p: 1, display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <Box>
        <Typography variant="h6" component="div" textAlign="center">
          Associate Request
        </Typography>
        <Typography variant="h6" component="div">
          Associate Name:
        </Typography>
        <Typography variant="h6" fontWeight={100} component="div">
          {request.requestingOrganisationName}
        </Typography>
        <Typography variant="h6" component="div">
          Associate Domain:
        </Typography>
        <Typography variant="h6" fontWeight={100} component="div">
          {'@' + request.requestingOrganisationDomain}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between">
        <IconButton onClick={() => responseAction(request.id, true)}>
          <DoneIcon style={{ color: 'green' }}/>
        </IconButton>
        <IconButton onClick={() => responseAction(request.id, false)}>
          <ClearIcon style={{ color: 'red' }}/>
        </IconButton>
      </Box>
    </Card>
  )
}

export default AssociateRequestCard;