import { useSearchParams } from "react-router-dom";

import StateManager from "./StateManager";
import Init from "./Init";
import Canvas from "./Canvas";
import CanvasPanelController from "./overlays/CanvasPanelController";
import CanvasControls from "./overlays/CanvasControls";

// Local context
import { useAdminController } from "context";
import { CanvasControllerProvider } from "context/canvasContext";

const Workplace = () => {
  const [controller, dispatch] = useAdminController();
  const { userId, userRoles, organisationId } = controller;
  const [searchParams] = useSearchParams();
  const atlasId = searchParams.get('id');

  return (
    <CanvasControllerProvider>
      <StateManager atlasId={atlasId} userId={userId}/>
      <Init organisationId={organisationId}/>
      <Canvas/>
      <CanvasPanelController atlasId={atlasId} userRoles={userRoles}/>
      <CanvasControls/>
    </CanvasControllerProvider>
  );
};

export default Workplace;
