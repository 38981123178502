import React, { useState, useEffect } from "react";

// @mui components
import { Box } from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";

// Components
import { BasicDatePicker } from "components/GlobalComponents/Inputs";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";

// Local Context
import { useAdminController } from "context";

// Db Context
import { getResults } from "apiservices/ResultServices/resultService";
import { getClients } from "apiservices/ResultServices/clientService";
import { getContracts } from "apiservices/ResultServices/contractService";

const InstanceDetailsEditor = ({ instance, setInstance }) => {
  const [controller, dispatch] = useAdminController();
  const [results, setResults] = useState([]);
  const [clients, setClients] = useState([]);
  const [contracts, setContracts] = useState([]);

  useEffect(() => {
    const fetchClientsAndResults = async () => {
      try {
        const _clients = await getClients(controller.organisationId);
        const _results = await getResults(controller.organisationId);
        setClients(_clients);
        setResults(_results);
      } catch (error) {
        console.error("Failed to fetch clients or results:", error);
      }
    };
    fetchClientsAndResults();
  }, [controller.organisationId]);

  useEffect(() => {
    if (!instance.clientId) return;
    const fetchContracts = async () => {
      try {
        const _contracts = await getContracts(instance.clientId);
        setContracts(_contracts);
      } catch (error) {
        console.error("Failed to fetch contracts:", error);
      }
    };
    fetchContracts();
  }, [instance.clientId]);

  const handleSetResult = (event) => {
    const selectedResult = results.find(result => result.id === event.target.value);
    setInstance({ ...instance, resultId: selectedResult?.id || "" });
  };

  const handleSetClient = (event) => {
    const selectedClient = clients.find(client => client.id === event.target.value);
    setInstance({ ...instance, clientId: selectedClient?.id || "" });
  };

  const handleSetContract = (event) => {
    const selectedContract = contracts.find(contract => contract.id === event.target.value);
    setInstance({ ...instance, contractId: selectedContract?.id || "" });
  };

  return (
    <Box display='flex' flexDirection="column" gap={1}>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          borderRadius: "4px",
          p: 1,
          minWidth: "270px",
          gap: 1
        }}>
        {
          clients.length > 0 && (
            <FormControl fullWidth sx={{ mt: 0.5}}>
              <InputLabel htmlFor="clientId">Client</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={instance.clientId || ""}
                onChange={handleSetClient}
                id="clientId"
                label="Client"
                sx={{ height: '40px' }}>
                {clients.map((client) => (
                  <MenuItem key={client.id} value={client.id}>{client.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
        {
          contracts.length > 0 && (
            <FormControl fullWidth sx={{ mt: 1.5}}>
              <InputLabel htmlFor="contractId">Contract</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={instance.contractId || ""}
                onChange={handleSetContract}
                id="contractId"
                label="Contract"
                sx={{ height: '40px' }}>
                {contracts.map((contract) => (
                  <MenuItem key={contract.id} value={contract.id}>{contract.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
        {
          results.length > 0 && (
            <FormControl fullWidth sx={{ mt: 1.5}}>
              <InputLabel htmlFor="resultId">Result</InputLabel>
              <Select
                fullWidth
                variant="outlined"
                value={instance.resultId || ""}
                onChange={handleSetResult}
                id="resultId"
                label="Result"
                sx={{ height: '40px' }}>
                {results.map((result) => (
                  <MenuItem key={result.id} value={result.id}>{result.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          )
        }
      </Box>
      <Box
        sx={{
          backgroundColor: "#F5F5F5",
          borderRadius: "4px",
          p: 1,
          display: "flex",
          flexDirection: "column",
          minWidth: "270px",
          flexGrow: 1
        }}
      >
        <ScrollbarWrapper>
          <BasicDatePicker
            id="deliveryDate"
            label="Delivery Date"
            obj={instance}
            setObj={setInstance}
            prop="deliveryDate"/>
          {/* <Typography sx={{ mt: 10 }}>Option to use existing project as template</Typography> */}
        </ScrollbarWrapper>
      </Box>
    </Box>
  );
};

export default InstanceDetailsEditor;