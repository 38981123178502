import React, { useState } from "react";

// @mui components
import { Box, Card } from "@mui/material";
import { Backdrop, Fade, Modal, IconButton, Typography } from '@mui/material';

// @mui icons
import { CloseWindowButton } from "components/GlobalComponents/Buttons";

// Components
import AspectRatioIcon from "@mui/icons-material/AspectRatio";

const baseStyle = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  gap: 1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "7px",
  boxShadow: 0,
  p: 1
}

const expandedStyle = {
  ...baseStyle,
  width: "100%",
  height: "100%",
  borderRadius: "0px"
}

const BaseModal = ({
  open,
  handleClose,
  title,
  children,
  style,
  expandable = true,
  additional,
  closeButtonPosition = 'left'
}) => {
  const [expanded, setExpanded] = useState(false);
   
  const expandAction = () => {
    setExpanded(!expanded);
  };
    
  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}>
      <Fade in={open}>
        <Box sx={{ ...baseStyle, ...style, ...(expanded ? expandedStyle : {}) }}>
          <Box display="flex" flexDirection="row" sx={{ justifyContent: "center" }} style={{ pointerEvents: "none" }}>
            {
              (closeButtonPosition === 'left' || closeButtonPosition === 'right') && (
                <Card sx={{ position: "absolute", [closeButtonPosition]: 8, zIndex: 2 }}>
                  <CloseWindowButton action={handleClose} background="#FFFFFF"/>
                </Card>
              )
            }
            {
              title && (
                <Card sx={{ zIndex: 2, maxWidth: '80%' }}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ backgroundColor: "#FFFFFF", minHeight: "25px", px: 1, borderRadius: "4px" }}>
                    <Typography variant="h1" fontSize={18} sx={{ mb: -0.4}}>{title}</Typography>
                  </Box>
                </Card>
              )
            }
            <Box 
              sx={{ 
                display: 'flex',
                flexDirection: 'row',
                position: "absolute",
                right: 3,
                top: 0.6,
                zIndex: 2,
                pointerEvents: "auto",
                alignItems: "center"
              }}>
              {
                additional && (
                  additional
                )
              }
              {
                expandable && (
                  <IconButton
                    onClick={() => expandAction()}>
                    <AspectRatioIcon/>
                  </IconButton>
                )
              }
            </Box>
          </Box>
          {children}
        </Box>
      </Fade>
    </Modal>
  );
}

export default BaseModal;