import React, { useState, useEffect } from "react";

// @mui components
import { Tab, Tooltip, FormGroup, FormControl, FormControlLabel, Checkbox } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import SellIcon from '@mui/icons-material/Sell';

// Components
import { NetworkPicker } from "components/AdministrationComponents/General";
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import TagEditor from "layouts/administrator/properties/views/TagEditor";
import { AdminTabList, AdminFormLabel, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createProperty, updateProperty, deletePropertyAttribute, getProperty } from "apiservices/AuthorisationServices/propertyService";

let createButton = [{icon: "add", name: "Add Tag to Group", id: 0}]

const TagGroupView = () => {
  const [property, setProperty] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    // Add more validation rules specific to MetricView
    if (!object.networks || !Object.values(object.networks).some((value) => value)) {
      invalidFields.networks = 'At least one network must be selected';
    }
    if (!object.isPrimaryApplicable && !object.isProjectApplicable) {
      invalidFields.applicationAtlasTypes = 'At least one application atlas type must be selected';
    }
    
    // add more validation rules as needed
    return invalidFields;
  };

  const handleSetDescriptor = (descriptor, e) => setProperty({...property, ...{[descriptor]: e.target.checked}});

  const handleDeleteTag = async (e, tagId, onDeleteComplete) => {
    let _property = await deletePropertyAttribute(tagId);
    setProperty(_property);
    onDeleteComplete();
  }

  const [activeTag, setActiveTag] = useState(-1);
  const handleSetActiveTag = (e, id) => setActiveTag(id);

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(property.id !== 0)
      setActiveTab(tabNumber);
  };

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if(property?.organisationId === null)
      setDisabled(true);
  }, [property]);

  return (
    <>
      <AdministrationObjectWrapper
        object={property}
        setObject={setProperty}
        getObject={getProperty}
        createObject={createProperty}
        updateObject={updateProperty}
        namePlaceholder="Group Name"
        descriptionPlaceholder="Description of Group"
        iconComponent={<SellIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          property &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Tag Group Details" value="1"/>
              {
                property.id !== 0 ?
                  <Tab label="Tags" value="2"/> :
                  <Tooltip title="Save the tag group to begin adding tags">
                    <Tab label="Tags" value="2"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          property &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <NetworkPicker
                networks={property.networks}
                setNetworks={setProperty}
                error={invalidFields.networks}
                setError={(error) => setInvalidFields({ ...invalidFields, networks: error })}
                disabled={disabled}/>
              <FormGroup>
                <FormControl error={Boolean(invalidFields.applicationAtlasTypes)}>
                  <AdminFormLabel error={invalidFields.applicationAtlasTypes}>Application atlas types:</AdminFormLabel>
                  <FormControlLabel
                    control={<Checkbox
                      checked={property.isPrimaryApplicable}
                      disabled={disabled}
                      onChange={(e) => { handleSetDescriptor("isPrimaryApplicable", e); if (e.target.checked) setInvalidFields({ ...invalidFields, applicationAtlasTypes: null }) }}/>}
                    name="primary"
                    label="Primary"/>
                  <FormControlLabel
                    control={<Checkbox
                      checked={property.isProjectApplicable}
                      disabled={disabled}
                      onChange={(e) => { handleSetDescriptor("isProjectApplicable", e); if (e.target.checked) setInvalidFields({ ...invalidFields, applicationAtlasTypes: null }) }}/>}
                    name="project"
                    label="Project"/>
                  <FormControlLabel
                    control={<Checkbox
                      checked={property.isOrdinal}
                      disabled={disabled}
                      onChange={(e) => { handleSetDescriptor("isOrdinal", e) }}/>}
                    name="ordinal"
                    label="Ordinal"/>
                </FormControl>
              </FormGroup>
            </TabPanel>
        }
        panels={
          property?.id !== 0 &&
            <TabPanel 
              value="2"
              style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
              <CreateAndEditInPlaceArray
                items={property?.organisationId !== null ?
                  createButton.concat(property?.propertyAttributes) :
                  property?.propertyAttributes
                }
                editor={handleSetActiveTag}
                deleteAction={!disabled ? handleDeleteTag : undefined}/>
            </TabPanel>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
        requiresOrgId/>
      {
        (activeTag !== -1 && !disabled) &&
          <TagEditor
            closeEditor={handleSetActiveTag}
            property={property}
            setProperty={setProperty}
            tagId={activeTag}
            organisationId={property.organisationId}/>
      }
    </>
  );
}

export default TagGroupView;