import * as React from 'react';

import { CircularProgress, Grid } from '@mui/material';

const LoadingView = () => {
  return (
    <Grid sx={{ height: 1, width: 1, display: "flex", justifyContent: "center", alignItems: "center", position: "absolute" }}>
      <CircularProgress size={160}/>
    </Grid>
  );
}

export default LoadingView;