import React, { useState } from 'react';

// @mui components
import { Box, IconButton, Typography, TextField, Tooltip } from '@mui/material';

// @mui icons
import DeleteIcon from '@mui/icons-material/Delete';
import CreateIcon from '@mui/icons-material/Create';
import CheckIcon from '@mui/icons-material/Check';

const TreeItemLabel = ({ option, showInput, colour, resultOptions, setResult, AddInput, AddIconComponent, onSelect, typeId, onAddClick }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedOption, setUpdatedOption] = useState({ name: option.name });

  const handleEditClick = () => setIsEditing(true);

  const handleCancelEdit = () => {
    setIsEditing(false);
    setUpdatedOption({ name: option.name });
  };

  const handleDeleteOption = (id) => {
    const descendants = getDescendants(resultOptions, id);
    const newResultOptions = resultOptions.filter((option) => !descendants.includes(option.id) && option.id !== id);
    setResult(prevResult => ({
      ...prevResult,
      resultOptions: newResultOptions
    }));
  };

  const getDescendants = (options, id) => {
    const descendants = [];
    const children = options.filter((option) => option.parentResultOptionId === id);
    children.forEach((child) => {
      descendants.push(child.id);
      descendants.push(...getDescendants(options, child.id));
    });
    return descendants;
  };
  
  const handleSaveClick = () => {
    const newOptions = resultOptions.map((varItem) => {
      if (varItem.id === option.id) {
        return { ...varItem, name: updatedOption.name };
      }
      return varItem;
    });
    setResult(prevResult => ({
      ...prevResult,
      resultOptions: newOptions
    }));
    setIsEditing(false);
  };
  
  return (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      borderTop: '1px solid #ccc',
      minHeight: '40px',
      cursor: 'default',
      backgroundColor: colour
    }}
    onClick={(e) => {
      e.stopPropagation();
      onSelect();
    }}>
      { 
        option.typeId < 4 &&
          <AddIconComponent id={option.id} typeId={typeId} parentOptionId={option.id} onClick={onAddClick}/>
      }
      {
        isEditing ?
          <TextField
            autoFocus
            variant="standard"
            size="small"
            value={updatedOption.name}
            onChange={(e) => setUpdatedOption({ ...updatedOption, name: e.target.value })}/>
          :
          <Typography variant="body2">{option.name}</Typography>
      }
      <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
        {
          (showInput && option.typeId < 4) && 
            <Box sx={{ marginRight: 1 }} key={option.id}>
              <AddInput parentOptionId={option.id} typeId={typeId} />
            </Box>
        }
        {
          isEditing && 
            <IconButton onClick={handleSaveClick} size="small" sx={{ mr: 1 }}>
              <CheckIcon />
            </IconButton>
        }
        {
          (option.typeId === 1 || option.typeId > 3) &&
            <Tooltip title={isEditing ? 'Cancel Edit' : 'Edit'}>
              <IconButton onClick={isEditing ? handleCancelEdit : handleEditClick} size="small">
                <CreateIcon sx={{ color: isEditing ? 'blue' : 'inherit' }} />
              </IconButton>
            </Tooltip>
        }
        <IconButton 
          onClick={(e) => {e.stopPropagation(); handleDeleteOption(option.id);}}
          size="small">
          <DeleteIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TreeItemLabel;