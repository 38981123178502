import React, { useState, useEffect } from "react";

// @mui components
import { FormGroup, FormControlLabel, Checkbox, Tab, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import GroupIcon from '@mui/icons-material/Group';

// Components
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import PermissionsAdjustmentPanel from "components/AdministrationComponents/PermissionsAdjustmentPanel";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db Context
import { createUserGroup, updateUserGroup, getUserGroup, toggleUserInGroup, getUserGroupAtlases, 
  toggleUserGroupAtlasAccess, getUserGroupSkillSets, getUserGroupProperties, toggleUserGroupSkillSet, toggleUserGroupProperty
} from "apiservices/AuthorisationServices/userGroupService";

// Local context
import { useAdminController } from "context";

const UserGroupView = () => {
  const [controller, dispatch] = useAdminController();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [userGroup, setUserGroup] = useState();
  const [atlases, setAtlases] = useState([]);
  const [userGroupSkillSets, setUserGroupSkillSets] = useState([]);
  const [userGroupProperties, setUserGroupProperties] = useState([]);
  useEffect(() => {
    if(!userGroup?.id > 0) return;
    const _getAtlases = async () => {
      let _atlases = await getUserGroupAtlases(userGroup.id, controller.organisationId);
      let _skillSets = await getUserGroupSkillSets(userGroup.id, controller.organisationId);
      let _properties = await getUserGroupProperties(userGroup.id, controller.organisationId);
      setAtlases(_atlases);
      setUserGroupSkillSets(_skillSets);
      setUserGroupProperties(_properties);
    }
    _getAtlases();
  }, [userGroup]);
  
  const handleSetIsGlobal = async (e) => setUserGroup({...userGroup, ...{isGlobal: e.target.checked}});

  const handleToggleUserInGroup = async (e, id) => {
    let _users = await toggleUserInGroup(id, userGroup.id);
    setUserGroup({...userGroup, ...{users: _users}});
  }
  const handleToggleUserGroupAtlasAccess = async (e, id) => {
    let _atlases = await toggleUserGroupAtlasAccess(userGroup.id, id, controller.organisationId);
    setAtlases(_atlases);
  }
  const handleToggleUserGroupSkillSet = async (e, id) => {
    let _skillSets = await toggleUserGroupSkillSet(userGroup.id, id, controller.organisationId);
    setUserGroupSkillSets(_skillSets);
  }
  const handleToggleUserGroupProperty = async (e, id) => {
    let _properties = await toggleUserGroupProperty(id, userGroup.id, controller.organisationId);
    setUserGroupProperties(_properties);
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(userGroup.id > 0)
      setActiveTab(tabNumber);
  };

  const [permissionsPanelAtlasId, setPermissionsPanelAtlasId] = useState(0);
  const handlePermissionsPanelAtlasId = (e, id) => setPermissionsPanelAtlasId(id);

  // we need to add the concept of a group type. Types to include: 'horizontal', 'vertical', 'mixed', 'senior', 'junior', 'admin'

  return(
    <>
      <AdministrationObjectWrapper
        object={userGroup}
        setObject={setUserGroup}
        getObject={getUserGroup}
        createObject={createUserGroup}
        updateObject={updateUserGroup}
        namePlaceholder="Group Name"
        descriptionPlaceholder="Description of Group"
        iconComponent={<GroupIcon sx={{ fontSize: 94 }} fontSize=""/>}
        activeTab={activeTab}
        header={
          userGroup &&
            <AdminTabList onChange={handleTabChange} {...tabIndicator}>
              <Tab label="Details" value="1"/>
              {
                userGroup.id > 0 ?
                  <Tab label="Users" value="2"/> :
                  <Tooltip title="Save the user group to begin setting access for users">
                    <Tab label="Users" value="2"/>
                  </Tooltip>
              }
              {
                userGroup.id > 0 ?
                  <Tab label="Atlases" value="3"/> :
                  <Tooltip title="Save the user group to begin setting access to atlases">
                    <Tab label="Atlases" value="3"/>
                  </Tooltip>
              }
              {
                userGroup.id > 0 ?
                  <Tab label="Skillsets" value="4"/> :
                  <Tooltip title="Save the user group to begin adding skillsets">
                    <Tab label="Skillsets" value="4"/>
                  </Tooltip>
              }
              {
                userGroup.id > 0 ?
                  <Tab label="Tag Groups" value="5"/> :
                  <Tooltip title="Save the user group to begin setting access to tag groups">
                    <Tab label="Tag Groups" value="5"/>
                  </Tooltip>
              }
              {
                userGroup.id > 0 ?
                  <Tab label="Metrics" value="6"/> :
                  <Tooltip title="Save the user group to begin setting access to metrics">
                    <Tab label="Metrics" value="6"/>
                  </Tooltip>
              }
            </AdminTabList>
        }
        form={
          userGroup &&
            <TabPanel 
              value="1"
              style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
              <FormGroup>
                <FormControlLabel 
                  control={<Checkbox checked={userGroup.isGlobal} onChange={handleSetIsGlobal}/>} 
                  label="Includes all organisation users" />
              </FormGroup>
            </TabPanel>
        }
        panels={
          userGroup?.id > 0 &&
            <>
              <TabPanel 
                value="2"
                style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userGroup.users}
                  titles={["Users in group", "Users not in group"]}
                  action={handleToggleUserInGroup}
                  searchboxLabel="Search users"/>
              </TabPanel>
              <TabPanel
                value="3"
                style={{ padding: 8, display: activeTab === "3" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={atlases}
                  titles={["Atlases which group has access to", "Atlases which group does not have access to"]}
                  action={handleToggleUserGroupAtlasAccess}
                  permissionsPanelToggle={handlePermissionsPanelAtlasId}
                  searchboxLabel="Search atlases"/>
              </TabPanel>
              <TabPanel
                value="4"
                style={{ padding: 8, display: activeTab === "4" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userGroupSkillSets}
                  titles={["User group skillsets", "Other skillsets"]}
                  action={handleToggleUserGroupSkillSet}
                  searchboxLabel="Search skillsets"/>
              </TabPanel>
              <TabPanel
                value="5"
                style={{ padding: 8, display: activeTab === "5" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userGroupProperties.filter(x => x.objectTypeId == 1)}
                  titles={["Permitted tag groups", "Other tag groups"]}
                  action={handleToggleUserGroupProperty}
                  searchboxLabel="Search tag groups"/>
              </TabPanel>
              <TabPanel
                value="6"
                style={{ padding: 8, display: activeTab === "6" ? "flex" : "none", flexGrow: 1 }}>
                <SplitMediaCardArray
                  items={userGroupProperties.filter(x => x.objectTypeId == 2)}
                  titles={["Permitted metrics", "Other metrics"]}
                  action={handleToggleUserGroupProperty}
                  searchboxLabel="Search metrics"/>
              </TabPanel>
            </>
        }
        validate={validate}
        setInvalidFields={setInvalidFields}
        invalidFields={invalidFields}
      />
      {
        permissionsPanelAtlasId !== 0 &&
          <PermissionsAdjustmentPanel
            organisationId={controller.organisationId}
            atlasId={permissionsPanelAtlasId}
            entityId={userGroup.id}
            setOpen={handlePermissionsPanelAtlasId}
            userType="group"
          />
      }
    </>
  )
}

export default UserGroupView;