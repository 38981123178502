import React from 'react';

// @mui components
import { Box } from '@mui/material';
import { Icon, Typography } from '@mui/material';

// Components
import BoxAndWhiskerChart from './BoxAndWhisker';

const NodeMetricsMetaVisual = ({ data, icon, metric }) => {
  return (
    <Box>
      <Box display='flex' flexDirection='row' alignItems='center' mb={0.5}>
        <Icon ml={1}>{icon}</Icon>
        <Typography variant="h1" fontSize={15}>{data.nodeCount + " Measurements totaling " + data.sumValue}</Typography>
      </Box>
      <BoxAndWhiskerChart data={data}/>
    </Box>
  );
};

export default NodeMetricsMetaVisual;
