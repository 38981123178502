import React, {useEffect, useState} from 'react';

import { getMetricTableNodeIds, getAggregatedMetricTable, getMetaProfile, getMetricSizes, getHeatMap } from "./metricSizingService";

// Local context
import { useCanvasController, setActiveMetric, setVertexInnerSizes, setVertexHeatColours } from "context/canvasContext";
import { ImagePress } from "helpers/canvashelpers/iconpress";

const Init = ({organisationId}) => {
  const [controller, dispatch] = useCanvasController();
  const { networkStates, activeLevel, projectMode, metrics,vertices, vertexInnerSizes, organisationNodeId, 
          activeMetric, heatMapVisible, selectedExecutorId, selectedProjectId, metricTables, relateSizesToZero } = controller;
            
  // Set the active metric based on the state properties
  useEffect(() => {
    if(!projectMode){
      let metricId = networkStates?.find(x => x.networkId === parseInt(activeLevel))?.metricId;
      setActiveMetric(dispatch, metricId ? metrics[activeLevel].find(x => x.metricId === metricId) : undefined);
    }
    else{
      let metricId = networkStates?.find(x => x.networkId === parseInt(activeLevel))?.projectMetricId;
      setActiveMetric(dispatch, metricId ? metrics[activeLevel].find(x => x.metricId === metricId) : undefined);
    }
  }, [networkStates, activeLevel, projectMode]);

  // Set the vertex inner sizes based on the metric table
  const [metricTable, setMetricTable] = useState([]);
  useEffect(() => {
    if(vertices?.length > 0){
      const _metricTable = metricTables.find(x => x.metricId === activeMetric?.metricId)?.rows;
      setMetricTable(_metricTable);
      if(!_metricTable) return;
      let adjustedMetricTable = _metricTable;
      if(_metricTable[0]?.authorisationId){
        adjustedMetricTable = getMetricTableNodeIds(organisationNodeId, vertices, _metricTable);
      }
      const aggregatedMetricTable = getAggregatedMetricTable(vertices, adjustedMetricTable);
      const metaProfile = getMetaProfile(aggregatedMetricTable);
      const metricSizes = getMetricSizes(aggregatedMetricTable, metaProfile, relateSizesToZero);
      setVertexInnerSizes(dispatch, metricSizes);
    }
  }, [metricTables, relateSizesToZero, activeLevel, activeMetric]);

  // Set the vertex heat colours based on the metric table
  useEffect(() => {
    if (metricTable?.length === 0 || !vertexInnerSizes) return;
  
    const filterMetricTable = () => {
      if (selectedExecutorId) {
        return metricTable.filter(x => x.userId === parseInt(selectedExecutorId));
      } 
      if (selectedProjectId) {
        return metricTable.filter(x => x.projectId === parseInt(selectedProjectId));
      } 
      return metricTable.filter(x => x.organisationId === organisationId);
    };
  
    const filteredMetricTable = filterMetricTable();
    const aggregatedMetricTable = getAggregatedMetricTable(vertices, filteredMetricTable);
    const metaProfile = getMetaProfile(aggregatedMetricTable);
    const metricSizes = getMetricSizes(aggregatedMetricTable, metaProfile, relateSizesToZero);
    setVertexHeatColours(dispatch, getHeatMap(vertexInnerSizes, metricSizes));
    
  }, [selectedExecutorId, selectedProjectId, heatMapVisible, vertexInnerSizes, relateSizesToZero]);
  
  // For turning icons into canvas elements
  // ImagePress();
  
  return (<></>);
};

export default Init;