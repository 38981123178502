import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getResults, deleteResult } from "apiservices/ResultServices/resultService";

// Local context
import { useAdminController } from "context";

let createButton = [{media: ["add"], name: "Create New Result", description: "", id: 0}]

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' },
];

const Results = () => {
  const [controller] = useAdminController();
  const { organisationId } = controller;

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  useEffect(() => {
    const _getResults = async () => {
      let _results = await getResults(organisationId);
      setResults(_results);
    };
    _getResults();
  }, []);

  const handleDeleteResult = async (e, resultId, onDeleteComplete) => {
    let _results = await deleteResult(resultId);
    setResults(_results);
    onDeleteComplete();
  }
  
  return (
    results &&
      <AdministrationViewWrapper>
        <MediaCardArrayContainer items={createButton.concat(filteredResults)} path={"/results/result"}  deleteAction={handleDeleteResult}>
          <FinderBar
            objs={results}
            setFilteredObjs={setFilteredResults}
            filterAttributes={filterAttributes}/>
        </MediaCardArrayContainer>
      </AdministrationViewWrapper>
  );
}

export default Results;