// @mui components
import { Box } from "@mui/system";

// Components
import { EditorModal } from "components/GlobalComponents/Modals";
import { useAdminController, setGuideVisible } from "context";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper";
import { PlatformRoles, PlatformTerminology, AtlasCreation, ProcessMapCreation, ResultVariableMapping, ProjectCreation } from "components/AdministrationComponents/Instructions"

export const Guide = ({ userRole }) => {
  //need to create a hover button which will display images relating each instruction where a visual would be helpful
  const [controller, dispatch] = useAdminController();
  const { guideVisible } = controller;
  
  const handleCloseWindow = () => setGuideVisible(dispatch, false);

  return(
    <EditorModal 
      open={guideVisible}
      title={userRole + " Guide"}
      toggleEditorVisibility={handleCloseWindow}>
      <ScrollbarWrapper style={{ gap: 1 }} >
        <Box sx={{ p: 0.4, pt: 1.3, background: 'white', borderRadius: '6px' }}>
          <FormCollapse title='Merit Roles' marginBottom='0'>
            <PlatformRoles/>
          </FormCollapse>
        </Box>
        <Box sx={{ p: 0.4, pt: 1.3, background: 'white', borderRadius: '6px' }}>
          <FormCollapse title='Merit Terminology' marginBottom='0'>
            <PlatformTerminology/>
          </FormCollapse>
        </Box>
        <Box sx={{ p: 0.4, pt: 1.3, background: 'white', borderRadius: '6px' }}>
          <FormCollapse title='Atlas Creation' marginBottom='0'>
            <AtlasCreation/>
          </FormCollapse>
        </Box>
        <Box sx={{ p: 0.4, pt: 1.3, background: 'white', borderRadius: '6px' }}>
          <FormCollapse title='Process Map Creation' marginBottom='0'>
            <ProcessMapCreation/>
          </FormCollapse>
        </Box>
        <Box sx={{ p: 0.4, pt: 1.3, background: 'white', borderRadius: '6px' }}>
          <FormCollapse title='Result Variable Mapping' marginBottom='0'>
            <ResultVariableMapping/>
          </FormCollapse>
        </Box>
        <Box sx={{ p: 0.4, pt: 1.3, background: 'white', borderRadius: '6px' }}>
          <FormCollapse title='Project Creation' marginBottom='0'>
            <ProjectCreation/>
          </FormCollapse>
        </Box>
      </ScrollbarWrapper>
    </EditorModal>
  )
}