// @mui components
import { Box, FormControl, InputLabel, Select, MenuItem, Typography, IconButton } from "@mui/material";

// @mui icons
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

const OperationCard = ({ id, index, metrics, currentOperation, currentMetricId, setSelectedCalculation }) => {
  const handleMetricChange = (id, metricId) => {
    setSelectedCalculation((prevCalc) => ({
      ...prevCalc,
      operations: prevCalc.operations.map((operation) =>
        operation.id === id
          ? { ...operation, metricId: metricId }
          : operation
      ),
    }));
  };
  
  const handleOperationChange = (id, newOperationId) => {
    setSelectedCalculation((prevCalc) => ({
      ...prevCalc,
      operations: prevCalc.operations.map((operation) =>
        operation.id === id
          ? { ...operation, operationId: newOperationId }
          : operation
      )
    }));
  };
  
  return (
    <FormControl sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
      <InputLabel id={`${id}-metric2-label`} sx={{ top: '1px' }}>Metric {index + 1}</InputLabel>
      <Select
        labelId={`${id}-metric2-label`}
        id={`${id}-metric2-select`}
        value={currentMetricId}
        label="Metric 2"
        onChange={handleMetricChange}
        sx={{ width: "180px", height: "40px" }}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {
          metrics.map((item) => (
            <MenuItem key={item.metricId} value={item.metricId}>
              {item.metricName}
            </MenuItem>
          ))
        }
      </Select>
      <Box sx={{ display: 'flex', ml: 1, alignItems: 'center' }}>
        <IconButton
          onClick={() => handleOperationChange(1)}
          sx={{ color: currentOperation === 1 ? 'blue' : 'inherit', p: 0 }}>
          <AddIcon />
        </IconButton>
        <IconButton
          onClick={() => handleOperationChange(2)}
          sx={{ color: currentOperation === 2 ? 'blue' : 'inherit', p: 0 }}>
          <RemoveIcon />
        </IconButton>
        <IconButton
          onClick={() => handleOperationChange(3)}
          sx={{ color: currentOperation === 3 ? 'blue' : 'inherit', p: 0 }}>
          <CloseIcon />
        </IconButton>
        <IconButton
          onClick={() => handleOperationChange(4)}
          sx={{ color: currentOperation === 4 ? 'blue' : 'inherit', p: 0 }}>
          <Typography variant="h6" sx={{ p: 1 }}>/</Typography>
        </IconButton>
      </Box>
    </FormControl>
  );
};

export default OperationCard;