import React, { useState, useEffect } from "react";

// @mui components
import { FormGroup, Tab, Tooltip } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Components
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { getScope, createScope, updateScope } from "apiservices/ResultServices/scopeService";

const Scope = () => {
  const [scope, setScope] = useState();
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);
  
  return(
    <AdministrationObjectWrapper
      object={scope}
      setObject={setScope}
      getObject={getScope}
      createObject={createScope}
      updateObject={updateScope}
      namePlaceholder="Scope Name"
      descriptionPlaceholder="Description of Scope"
      iconComponent={<ContentPasteSearchIcon sx={{ fontSize: 94 }} fontSize=""/>}
      activeTab={activeTab}
      header={
        scope &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
          </AdminTabList>
      }
      validate={validate}
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}
    />
  )
}

export default Scope;