import { useEffect, useState } from "react";

// react-router components
import { Routes, Route, useSearchParams, useLocation, Navigate } from "react-router-dom";

// @mui components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Helpers
import AdminStateManager from "helpers/jsxpanelhelpers/adminStateManager";

// Components
import Sidenav from "components/Sidenav";
import Configurator from "components/Configurator";
import theme from "assets/theme";
import themeDark from "assets/theme-dark";
import LoadingView from "layouts/LoadingScreen";
import RouteGuard from "components/GlobalComponents/RouteGuard";
import { Guide } from "components/AdministrationComponents/Guide";

// Routes
import { routes, defaultRoutes } from "routes";

// Local context
import {
  useAdminController,
  setUserEmail,
  setUserRoles,
  setActiveUserRole,
  setUserId,
  setOrganisationId,
  setLogoutURL
} from "context";
import { getSessionDetails, fetchUserLogin, login } from "apiservices/AuthorisationServices/oAuthService";

export default function App() {
  const [controller, dispatch] = useAdminController();
  const { sidenavColor, darkMode, userRoles, activeUserRole } = controller;
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setUserDetails = async () => {
      const response = await fetchUserLogin();
      if (response.isLoggedIn === true && userRoles.includes("frontpage")) {
        const authorisationModel = await login();
        const sessionDetails = await getSessionDetails();
        const data = await sessionDetails.json();

        setUserEmail(dispatch, authorisationModel.userEmail);
        setUserId(dispatch, authorisationModel.userId);
        setOrganisationId(dispatch, authorisationModel.organisationId);
        setUserRoles(dispatch, authorisationModel.userRoles);
        setActiveUserRole(dispatch, authorisationModel.activeRole);
        setLogoutURL(dispatch, data.find((claim) => claim.type === "bff:logout_url")?.value);
      }
      setLoading(false);
    };
    setUserDetails();
  }, []);
  
  useEffect(() => {
    const currentRole = searchParams.get('role') || 'user';
  
    if (currentRole !== activeUserRole) {
      searchParams.set('role', activeUserRole);
      setSearchParams(searchParams, { replace: true });
    }
  }, [activeUserRole]);

  let activeRoutes = routes.filter(x => x.userRoles.includes(activeUserRole));
  if(userRoles.includes("admin") && activeUserRole === "user"){
    activeRoutes = activeRoutes.filter(x => x.route !== "/requests");
  }
  
  let defaultRoute = defaultRoutes(activeUserRole);
  
  if (loading) {
    return <LoadingView/>;
  }

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <AdminStateManager/>
      <CssBaseline/>
      {
        (activeUserRole !== "frontpage" && pathname !== "/usercanvas") &&
          <Sidenav
            color={sidenavColor}
            routes={activeRoutes.filter(x => x.icon !== null)}
            activeUserRole={activeUserRole}/>
      }
      <Configurator/>
      <Guide userRole={activeUserRole}/>
      <Routes>
        {
          activeRoutes.map(route =>
            <Route path={route.route} element={route.element} key={route.key}/>
          )
        }
        {
          location.pathname === "/" || !activeRoutes.some(route => route.route === location.pathname) ?
            <Route path="*" element={<Navigate replace to={defaultRoute} />} />
            : null
        }
      </Routes>
    </ThemeProvider>
  );
}