export async function getSessionDetails(){
    const response = await fetch("bff/user", {
        headers: { "X-CSRF": 3, },
    });
    return response;
}

export async function fetchUserLogin() {
    try {
        const response = await getSessionDetails()
        if (response.ok && response.status === 200) {
            const data = await response.json();
            return {
                isLoggedIn: data.find(x => x.type === "bff:logout_url")?.value !== "undefined" ? true : false,
                logOutUrl: data.find(x => x.type === "bff:logout_url")?.value
            };
        }
        else
            return { 
                isLoggedIn: false,
                logOutUrl: ""
            };
    }
    catch (e) {
        return false;
    }
}

export async function login() {
  try {
    let response = await fetch("/api/authorisation/", 
                               {method: "GET",
                                headers: {"Content-Type": "application/json",
                                          "X-CSRF": 3
                                         }
                               }
    );
    let data = await response.json();
    return data;
    }
    catch (e) {
        return false;
    }
}
