import React, { useState, useEffect } from "react";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import FinderBar from "components/AdministrationComponents/FinderBar";

// Db Context
import { getAtlasReferences, deleteAtlas } from "apiservices/AuthorisationServices/atlasAdminService";

// Local context
import { useAdminController } from "context";

const CREATE_ATLAS_BUTTON = [{
  media: ["add"],
  name: "Create New Atlas",
  description: "",
  id: 0,
}];

const filterAttributes = [
  { property: 'name', name: "Name", type: 'string', icon: 'alpha' },
  { property: 'creationDate', name: "Creation Date", type: 'datetime', icon: 'datetime' },
  { property: 'description', name: "Description", type: 'string', icon: 'description' }
];

const Atlases = () => {
  const [controller, dispatch] = useAdminController();

  const [atlases, setAtlases] = useState([]);
  const [filteredAtlases, setFilteredAtlases] = useState([]);
  useEffect(() => {
    const _getAtlasReferences = async () => {
      let _atlases = await getAtlasReferences(controller.organisationId);
      setAtlases(_atlases);
      setFilteredAtlases(_atlases);
    };
    _getAtlasReferences();
  }, []);

  const handleDeleteAtlas = async (e, atlasId, onDeleteComplete) => {
    let _atlases = await deleteAtlas(atlasId);
    setAtlases(_atlases);
    onDeleteComplete();
  }
  
  return (
    <AdministrationViewWrapper>
      <MediaCardArrayContainer items={CREATE_ATLAS_BUTTON.concat(filteredAtlases)} path={"/atlases/atlas"} deleteAction={handleDeleteAtlas}>
        <FinderBar
          objs={atlases}
          setFilteredObjs={setFilteredAtlases}
          filterAttributes={filterAttributes}/>
      </MediaCardArrayContainer>
    </AdministrationViewWrapper>
  );
}

export default Atlases;