import React, { useState, useEffect } from "react";

// @mui components
import { FormControl, Select, InputLabel, MenuItem } from "@mui/material";

// Components
import { EditorModal } from "components/GlobalComponents/Modals";

// Db context
import { createNode, updateNode, getNode } from "apiservices/AtlasServices/nodeService";
import { getNodeSkillSets } from "apiservices/AuthorisationServices/skillSetService";

// Local context
import { useAdminController } from "context";

// Canvas context
import { useCanvasController, setVertices, setUnconfiguredJobs, setEdges } from "context/canvasContext";

const SkillSetNodeEditor = ({networkId, nodeId, setEditorVisible, nodes, setNodes}) => {
  const [controller, dispatch] = useAdminController();
  const [canvasController, canvasDispatch] = useCanvasController();
  const { organisationId, userId } = controller;
  const { selectedObject } = canvasController;
  
  const [node, setNode] = useState();
  const [skillSets, setSkillSets] = useState();

  useEffect(() => {
    const _getData = async () => {
      let _node = await getNode(nodeId);
      _node.parentNodeId = selectedObject.id !== 0 ? selectedObject.id : null;
      _node.networkId = networkId;
      setNode(_node);
      let _skillSets = await getNodeSkillSets(organisationId, networkId);
      setSkillSets(_skillSets);
    };
    _getData();
  }, []);

  const handleSaveNode = async () => {
    node.sentBy = userId;
    let result = node.id === null ?
      await createNode(node):
      await updateNode(node);
    
    await setVertices(canvasDispatch, result.vertices.filter(x => !(x.context.networkId === 2 && x.isIntegrated === 4)));
    await setUnconfiguredJobs(canvasDispatch, result.vertices.filter(x => x.context.networkId === 2 && x.isIntegrated === 4));
    await setEdges(canvasDispatch, result.edges);
    let parentNodeId = selectedObject.id !== 0 ? selectedObject.id : null;
    setNodes(result.vertices.filter(x => x.context.networkId === networkId && x.context.parentNodeId === parentNodeId).map(x => x.context));
    setEditorVisible(undefined);
    // setObject(result);
    // setInitialObject(result);
    // setIsDirty(dispatch, false);
  }
  
  const handleSetContractor = (e) => setNode({...node, ...{sourceNodeId: e.target.value}});
  const handleSetSkillSet = (e) => {
    setNode({...node, ...{authorisationId: e.target.value, name: skillSets[e.target.value]}});
  };

  const handleSetEditorVisibility = (e) => setEditorVisible(undefined);

  const title = networkId === 1 ? "Create Organisation Node" : "Create Job Node";

  return(
    (node && skillSets) &&
      <EditorModal
        open={true}
        toggleEditorVisibility={handleSetEditorVisibility}
        title={title} 
        createButtonText="Submit Node" 
        submitEvent={handleSaveNode}
        width="25%"
        height="30%">
        <FormControl fullWidth sx={{ mt: 1}}>
          <InputLabel htmlFor="skill-set-select">Select Skill Set</InputLabel>
          <Select
            sx={{height: "40px"}}
            id="skill-set-select"
            value={node?.authorisationId ?? ""}
            label="Select Skill Set"
            onChange={handleSetSkillSet}>
            {
              skillSets && Object.entries(skillSets)
                .filter(([id, name]) => {
                  const authorisationIds = nodes.map(x => String(x.authorisationId));
                  return (!authorisationIds.includes(String(id)) || String(id) === String(node.authorisationId));
                })
                .map(([id, name]) => {
                  return (
                    <MenuItem value={id} key={id}>{name}</MenuItem>
                  );
                })
            }
          </Select>
        </FormControl>
        {
          (networkId === 1 && nodes.length > 0) && (
            <FormControl fullWidth sx={{ mt: 1}}>
              <InputLabel htmlFor="contractor-select">Select contracting organisation</InputLabel>
              <Select
                sx={{height: "40px"}}
                id="contractor-select"
                value={node.sourceNodeId ?? ""}
                label="Select contracting organisation"
                onChange={(e) => handleSetContractor(e)}>
                {
                  nodes.filter(x => x.id !== node.id).map((_node) => {
                    return (
                      <MenuItem value={_node.id} key={_node.id}>{_node.name}</MenuItem>
                    )
                  })
                }
              </Select>
            </FormControl>
          )
        }
      </EditorModal>
  )
}

export default SkillSetNodeEditor;