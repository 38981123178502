import React, { useState } from 'react';

// Import your envelope image
import envelopeImage from './Envelope.webp';

// @mui components
import { Box, Paper, TextField, Button, Typography, Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

// Services
import { sendEnquiryEmail } from 'apiservices/EmailService';

export const Contact = () => {
  const [email, setEmail] = useState({
    name: '',
    from: '',
    subject: 'enquiry',
    body: ''
  });

  const [isEmailSent, setIsEmailSent] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleChange = (e) => setEmail((email) => ({ ...email, [e.target.name]: e.target.value }));

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    setEmailSending(true);
    setEmailError(false);
    try {
      const response = await sendEnquiryEmail(email);
      if (response) {
        setIsEmailSent(true);
      }
    } catch (error) {
      setEmailError(true);
    } finally {
      setEmailSending(false);
    }
  };

  return (
    <Box
      width={1}
      minHeight="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      id="contact"
      sx={{
        scrollMarginTop: { xs: '-3px', md: '5px' },
        padding: { xs: '0px', md: '20px' },
        py: { xs: '10px', md: '20px' }
      }}
    >
      <Paper 
        elevation={3} 
        sx={{ 
          margin: { xs: 2, md: 4 }, 
          padding: { xs: 1, md: 2 }, 
          width: '100%', 
          maxWidth: { xs: '100%', md: 800 } 
        }}
      >
        <Grid container spacing={{ xs: 2, md: 2 }} alignItems="center">
          {/* Text on the left */}
          <Grid item xs={12} md={6}>
            <Typography 
              fontSize={{ xs: 15, md: 22 }}
              gutterBottom 
              sx={{ fontWeight: 600 }}
            >
              Get In Touch
            </Typography>
            <Typography 
              fontSize={{ xs: 13, md: 18 }}
              paragraph 
              sx={{ fontWeight: 400 }}
            >
              Please fill out the form below to send us an email and we will get back to you as soon as possible.
            </Typography>
            <form onSubmit={handleSubmitEmail} noValidate style={{ paddingTop: 0 }}>
              <Box display='flex' flexDirection='column' gap={2}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  required
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      height: '40px',
                      fontSize: { xs: 13, md: 18 }
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: 13, md: 18 },
                      top: '50%',
                      transform: 'translate(10px, -50%)',
                      transition: 'top 0.2s ease-out, transform 0.2s ease-out',
                      '&.MuiInputLabel-shrink': {
                        top: 0,
                        transform: 'translate(14px, -40%) scale(0.9)'
                      }
                    }
                  }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="from"
                  type="email"
                  required
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      height: '40px',
                      fontSize: { xs: 13, md: 18 }
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: 13, md: 18 },
                      top: '50%',
                      transform: 'translate(10px, -50%)',
                      transition: 'top 0.2s ease-out, transform 0.2s ease-out',
                      '&.MuiInputLabel-shrink': {
                        top: 0,
                        transform: 'translate(14px, -40%) scale(0.9)'
                      }
                    }
                  }}
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="body"
                  multiline
                  rows={6}
                  required
                  onChange={handleChange}
                  InputProps={{
                    sx: {
                      fontSize: { xs: 13, md: 18 }
                    }
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: { xs: 13, md: 18 },
                      top: '20px',
                      transform: 'translate(10px, -50%)',
                      transition: 'top 0.2s ease-out, transform 0.2s ease-out',
                      '&.MuiInputLabel-shrink': {
                        top: 0,
                        transform: 'translate(14px, -40%) scale(0.9)'
                      }
                    }
                  }}
                />
                <Box 
                  display="flex" 
                  justifyContent={{ xs: 'center', md: 'space-between' }}
                  alignItems="center"
                >
                  <Button
                    variant="contained"
                    type="submit"
                    sx={{
                      backgroundColor: 'rgba(0, 0, 0, 0.06)',
                      color: '#000',
                      fontSize: { xs: '1rem', md: '1.25rem' },
                      fontWeight: 'bold',
                      padding: '10px 10px',
                      letterSpacing: '0.05rem',
                      width: '132px',
                      borderRadius: '3px',
                      '&:hover': {
                        backgroundColor: 'rgba(211, 211, 211, 1)'
                      }
                    }}
                    disabled={emailSending}
                  >
                    {emailSending ? <CircularProgress size={24} /> : 'Send Message'}
                  </Button>
                  {isEmailSent && (
                    <Typography variant="body1" sx={{ color: 'green', marginLeft: 2 }}>
                      Your message has been sent!
                    </Typography>
                  )}
                  {emailError && (
                    <Typography variant="body1" sx={{ color: 'red', marginLeft: 2 }}>
                      Error sending email. Please try again.
                    </Typography>
                  )}
                </Box>
              </Box>
            </form>
          </Grid>

          {/* Image on the right */}
          <Grid item xs={12} md={6} display='flex' justifyContent='center' alignItems='center'>
            <Box
              component="img"
              src={envelopeImage}
              alt="Envelope"
              sx={{
                maxWidth: { xs: '30%', md: '80%' },
                height: 'auto'
              }}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};
