import React, { useEffect, useState } from 'react';

// @mui components
import { Box, Typography, FormControl, FormControlLabel, Checkbox } from '@mui/material';

// @mui icons
import CategoryIcon from '@mui/icons-material/Category';
import NumbersIcon from '@mui/icons-material/Numbers';

// Components
import ScrollbarWrapper from 'components/GlobalComponents/ScrollbarWrapper';

// Db context
import { getScopes } from 'apiservices/ResultServices/scopeService';
import { toggleApplyScopeToResultOption, toggleApplyDescriptorToResultOption, toggleDescriptorRequired, getResultOption } from 'apiservices/ResultServices/resultService';
import { getDescriptors } from 'apiservices/ResultServices/descriptorService';

const DescriptorManager = ({ selectedResult, setSelectedResult, organisationId }) => {
  const [scopes, setScopes] = useState([]);
  const [descriptors, setDescriptors] = useState([]);

  useEffect(() => {
    if(!organisationId) return;
    const _getDescriptors = async () => {
      const _descriptors = await getDescriptors(organisationId, 0);
      const _scopes = await getScopes(organisationId);
      setScopes(_scopes);
      setDescriptors(_descriptors);
    };
    _getDescriptors();
  }, []);

  useEffect(() => {
    const _getResultOption = async () => {
      if (selectedResult && selectedResult.id) {
        const _resultOption = await getResultOption(selectedResult.id);
        setSelectedResult(_resultOption);
      }
    };
    _getResultOption();
  }, [selectedResult?.id]);

  const handleToggleScope = async (e, scopeId) => {
    let resultOption = await toggleApplyScopeToResultOption(selectedResult.id, scopeId);
    setSelectedResult(resultOption);
  };

  const handleToggleDescriptor = async (e, descriptorId) => {
    let resultOption = await toggleApplyDescriptorToResultOption(selectedResult.id, descriptorId);
    setSelectedResult(resultOption);
  }

  const handleToggleDescriptorRequired = async (e, descriptorId) => {
    let resultOption = await toggleDescriptorRequired(selectedResult.id, descriptorId);
    setSelectedResult(resultOption);
  }

  return (
    selectedResult?.id > 0 ?
      <ScrollbarWrapper>
        {
          descriptors.map((descriptor) => {
            const selectedDescriptor = selectedResult?.descriptors?.find((d) => d.resultOptionDescriptorId === descriptor.id); 
            const isChecked = selectedDescriptor ? true : false;
            const isRequired = selectedDescriptor?.required ? true : false;
            return (
              <Box 
                key={descriptor.id} 
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#fff',
                  border: '1px solid #ccc',
                  borderRadius: 2,
                  px: 1,
                  justifyContent: 'space-between',
                  mb: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  {descriptor.typeId === 1 ? <CategoryIcon /> : <NumbersIcon />}
                  <Typography sx={{ ml: 1 }} fontWeight={500}>{descriptor.name}</Typography>
                </Box>
                <Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={(e) => handleToggleDescriptor(e, descriptor.id)}
                        size='large'
                      />
                    }
                    label='Valid'
                  />
                  {
                    isChecked &&
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isRequired}
                            onChange={(e) => handleToggleDescriptorRequired(e, descriptor.id)}
                            size='large'
                          />
                        }
                        label='Required'/>
                  }
                </Box>
              </Box>
            )
          })
        }
        <FormControl sx={{ width: 1, backgroundColor: '#fff', border: '1px solid #ccc', borderRadius: 2 }}>
          <Typography sx={{ ml: 1, mt: 1 }} fontWeight={500}>Relevant Scopes</Typography>
          {
            scopes.map((scope) => {
              const isScopeChecked = selectedResult?.scopes?.includes(scope.id) || false;
              return (
                <FormControlLabel
                  key={scope.id}
                  control={
                    <Checkbox
                      sx={{ ml: 1.3 }}
                      checked={isScopeChecked}
                      onChange={(e) => handleToggleScope(e, scope.id)}
                      size='large'
                    />
                  }
                  label={scope.name}
                />
              )
            })
          }
        </FormControl>
      </ScrollbarWrapper>
      :
      <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
        Select a saved result to view descriptors
      </Typography>
  );
};

export default DescriptorManager;