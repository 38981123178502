import React, { useEffect, useState } from "react";

// @mui components
import { Box } from "@mui/material";
import { Typography, Divider, IconButton, FormControl, FormControlLabel, Radio, Select, MenuItem, Tooltip } from "@mui/material";

// @mui icons
import BusinessIcon from '@mui/icons-material/Business';
import WorkIcon from '@mui/icons-material/Work';
import StraightenIcon from '@mui/icons-material/Straighten';
import SellIcon from '@mui/icons-material/Sell';
import PermDeviceInformationIcon from '@mui/icons-material/PermDeviceInformation';

// Components
import { EditorModal } from "components/GlobalComponents/Modals";
import ScrollbarWrapper from "components/GlobalComponents/ScrollbarWrapper"
import { StatsWindowButton } from "components/CanvasComponents/OverlayButtons";
import ExperienceTable from "./ExperienceTable";
import { AssignmentDatePicker } from "components/GlobalComponents/Inputs";

import { getAssignedProject, setProjectAssignments } from "apiservices/AtlasServices/assignmentService"

// Canvas context
import { useAdminController } from "context";

const AssignmentEditor = ({projectId, setEditorVisibility, userRole}) => {
  const [controller, dispatch] = useAdminController();
  const { userId } = controller;

  const [assignedProject, setAssignedProject] = useState(null);
  const [filter, setFilter] = useState({1: true, 2: true});
  const [propertyTypeId, setPropertyTypeId] = useState(1);
  const [properties, setProperties] = useState([]);

  const _userId = userRole === "coordinator" ? 0 : userId;

  useEffect(() => {
    const fetchAssignedProject = async () => {
      try {
        const response = await getAssignedProject(projectId, _userId);
        setAssignedProject(response);
        setProperties(response.properties.filter(x => x.propertyTypeId === propertyTypeId));
      } catch (error) {
        console.error(error);
      }
    };
    fetchAssignedProject();
  }, [projectId]);

  const handleCloseWindow = () => {
    setEditorVisibility(false);
  };
  
  useEffect(() => {
    if(properties.length === 0) return;
    setProperties(assignedProject.properties.filter(x => x.propertyTypeId === propertyTypeId));
  }, [propertyTypeId]);

  const handleSaveAssignments = async () => {
    try {
      await setProjectAssignments(assignedProject, _userId);
      handleCloseWindow();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    assignedProject &&
      <EditorModal
        toggleEditorVisibility={handleCloseWindow}
        title={"Project Assigment Editor" + " - " + assignedProject.name}
        createButtonText="Submit Assignments"
        submitEvent={handleSaveAssignments}
        style="managerModalStyle"
      >
        <ScrollbarWrapper vertical={false}>
          <Box width={1}>
          <Box sx={{ display: 'flex' }}>
            <AssignmentEditorSettings filter={filter} setFilter={setFilter} propertyTypeId={propertyTypeId} setPropertyTypeId={setPropertyTypeId} />
            <Box sx={{ display: 'flex' }}>
              {properties?.map((property) => (
                <AssignmentEditorHeader
                  key={property.id}
                  title={property.name}
                  propertyId={property.id}
                  assignedProject={assignedProject}
                  setAssignedProject={setAssignedProject}/>
              ))}
            </Box>
          </Box>
          <Divider sx={{
            opacity: 0.9,
            width: '100%',
            my: 0,
            mt: 0.5,
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            position: 'relative',
            height: '1px'
          }}/>
            {
              assignedProject?.assignments?.filter(x => filter[x.networkId]).map((assignment, index) => (
                <AssignmentEditorRow
                  key={index}
                  assignment={assignment}
                  properties={properties}
                  assignedProject={assignedProject}
                  setAssignedProject={setAssignedProject}
                  index={index}
                />
              ))
            }
          <Divider orientation="vertical" sx={{
            position: 'absolute',
            opacity: 1,
            height: '100%',
            left: 556,
            top: 0,
            bottom: 0,
            margin: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.12)',
            width: '1px'
          }}/>
          </Box>
        </ScrollbarWrapper>
      </EditorModal>
  );
}

const StyledIconButton = ({ active, onClick, Icon }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        backgroundColor: active ? '#40c4ff' : '#787878',
        padding: 0.5,
        border: '1px solid transparent',
        '&:hover': {
          border: '1px solid black',
          backgroundColor: active ? '#40c4ff' : '#787878',
        },
      }}
    >
      <Icon style={{ color: active ? 'black' : 'white' }} fontSize="small" />
    </IconButton>
  );
};

const AssignmentEditorSettings = ({filter, setFilter, propertyTypeId, setPropertyTypeId}) => {

  const handleSetFilter = (networkId) => {
    const updatedFilter = { ...filter };
    if (networkId) {
      updatedFilter[networkId] = !updatedFilter[networkId];
    } else {
      updatedFilter[1] = true;
      updatedFilter[2] = true;
    }
    setFilter(updatedFilter);
  }

  return (
    <Box sx={{ width: 550, p: 2, pb: 0, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
        <StyledIconButton
          active={propertyTypeId === 1}
          onClick={() => setPropertyTypeId(1)}
          Icon={SellIcon}
        />
        <StyledIconButton
          active={propertyTypeId === 2}
          onClick={() => setPropertyTypeId(2)}
          Icon={StraightenIcon}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: 1 }}>
        <StyledIconButton
          active={filter.business}
          onClick={() => handleSetFilter(1)}
          Icon={BusinessIcon}
          selected
        />
        <StyledIconButton
          active={filter.work}
          onClick={() => handleSetFilter(2)}
          Icon={WorkIcon}
        />
      </Box>
    </Box>
  );
};

const AssignmentEditorHeader = ({ title, propertyId, assignedProject, setAssignedProject }) => {
  // Extract the property values for each assignment
  const propertyValues = assignedProject.assignments.map(x => x.propertyDefinitions[propertyId]);

  // Check if all values are the same
  const firstValue = propertyValues[0];
  const allSame = propertyValues.every(val => val === firstValue);

  // Determine the top-level value
  const topValue = allSame ? String(firstValue) : '';

  const handleTopSelection = (selectedValue) => {
    const updatedAssignments = assignedProject.assignments.map((assignment) => {
      const updatedPropertyDefinitions = { ...assignment.propertyDefinitions };
  
      // Update the propertyDefinitions or add the key-value pair if it doesn't exist
      updatedPropertyDefinitions[propertyId] = parseInt(selectedValue);
  
      return {
        ...assignment,
        propertyDefinitions: updatedPropertyDefinitions,
      };
    });
  
    // Update the assignedProject with the updated assignments
    setAssignedProject({ ...assignedProject, assignments: updatedAssignments });
  };

  return (
    <Box sx={{ width: 180, p: 2, pb: 0, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Tooltip title={title} placement="top">
        <Typography
          sx={{
            width: '100%',
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}>
          {title}
        </Typography>
      </Tooltip>
      <Box display="flex" flexDirection="row" ml={2.2}>
        {[
          { value: '1', label: 'Job' },
          { value: '2', label: 'Step' },
          { value: '3', label: 'Substep' }
        ].map((option, index) => (
          <Box key={index} display="flex" mr={1}>
            <FormControlLabel
              value={option.value}
              control={
                <Radio 
                  sx={{
                    mb: -1,
                    '& .MuiSvgIcon-root': {
                      color: 'gray',
                      border: `1px solid black`
                    }
                  }}
                  checked={String(topValue) === String(option.value)}
                  onChange={() => handleTopSelection(option.value)}/>
              }
              label={option.label}
              labelPlacement="top"
              sx={{ margin: 0 }}/>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const AssignmentEditorRow = ({ assignment, properties, assignedProject, setAssignedProject, index }) => {
  const handleSetExecutor = (value) => {
    const updatedAssignments = [...assignedProject.assignments];
    updatedAssignments[index].executorId = value;
    setAssignedProject({ ...assignedProject, assignments: updatedAssignments });
  };

  const handleSetPropertyDefinition = (propertyId, value) => {
    const updatedAssignments = [...assignedProject.assignments];
    updatedAssignments[index].propertyDefinitions = {
      ...updatedAssignments[index].propertyDefinitions,
      [propertyId]: value
    };
    setAssignedProject({ ...assignedProject, assignments: updatedAssignments });
  };

  // Handle case where executorId is not set
  const executorId = assignment.executorId || 
  (assignment.evaluatedExecutors.qualifiedExecutors.length > 0
    ? (handleSetExecutor(assignment.evaluatedExecutors.qualifiedExecutors[0].id), 
       assignment.evaluatedExecutors.qualifiedExecutors[0].id)
    : '');

  const [experienceVisible, setExperienceVisible] = useState(false);
  return (
    <Box sx={{ display: 'flex', width: '100%', p: 0, my: 0.5 }}>
      <Box sx={{ width: 550, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {assignment.networkId === 1 ? <BusinessIcon sx={{ ml: 2 }} /> : <WorkIcon sx={{ ml: 2 }} />}
          <Tooltip title={assignment.nodeName} placement="top">
            <Typography
              sx={{
                width: '100%',
                maxWidth: '180px',
                textAlign: 'center',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ml: 1
              }}>
              {assignment.nodeName}
            </Typography>
          </Tooltip>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControl>
            <Select
              value={executorId}
              onChange={(event) => handleSetExecutor(event.target.value)}
              sx={{ 
                width: 150, 
                height: 40,
                color: 'black',
                borderColor: 'darkgray',
                '& .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'darkgray'
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'black'
                }
              }}>
              {
                assignment.evaluatedExecutors.qualifiedExecutors.map((exec) => {
                  return (
                    <MenuItem key={exec.id} value={exec.id}>
                      {exec.name}
                    </MenuItem>
                  );
                })
              }
            </Select>
          </FormControl>
          <StatsWindowButton 
            setWindow={setExperienceVisible} 
            icon={<PermDeviceInformationIcon sx={{fontSize: 30}} fontSize=""/>} 
            id='experience-window' 
            delay={1200}/>
          <AssignmentDatePicker
            assignment={assignment}
            setObj={setAssignedProject}/>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {
          properties.map((property) => (
            <Box key={property.id} sx={{ width: 180, px: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <PropertyRadioGroup
                options={[
                  { value: 1, label: '' },
                  { value: 2, label: '' },
                  { value: 3, label: '' }
                ]}
                selectedValue={assignment.propertyDefinitions[property.id]}
                onChange={(value) => handleSetPropertyDefinition(property.id, value)}/>
            </Box>
          ))
        }
      </Box>
      {
        experienceVisible &&
          <ExperienceTable
            closeWindow={() => setExperienceVisible(false)}
            properties={assignment.evaluatedExecutors.properties}
            attributes={assignment.evaluatedExecutors.attributes}
            executors={assignment.evaluatedExecutors.qualifiedExecutors}
            data={assignment.evaluatedExecutors.executorExperienceData}/>
      }
    </Box>
  );
};

const PropertyRadioGroup = ({ options, selectedValue, onChange }) => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      {options.map((option, index) => (
        <Box key={index} display="flex" flexDirection="column" alignItems="center" mr={1}>
          <FormControlLabel
            value={option.value}
            control={
              <Radio sx={{
                '& .MuiSvgIcon-root': {
                  color: 'gray',
                  border: `1px solid black`
                }
              }}
              checked={selectedValue === option.value}
              onChange={() => onChange(option.value)}/>
            }
            // label={option.label} //remove if it doesn't affect behavior
            // labelPlacement="top" //remove if it doesn't affect behavior
            sx={{ margin: 0 }}/>
        </Box>
      ))}
    </Box>
  );
};

export default AssignmentEditor;