import React, { useEffect, useState } from "react";

// @mui components
import { Grid } from "@mui/material";
import { Fab, Button, Tooltip, Icon } from "@mui/material";

export const PanelControlButton = ({buttonIcon, action, tooltip, bgColour="white"}) => {
  const fabStyle = {
    backgroundColor: bgColour, 
  };
  return (
    <Grid item>
      <Tooltip title={tooltip} placement="bottom" arrow={false}>
        <Fab 
          size="small"
          color="white"
          sx={{ borderRadius: "7px", boxShadow: 0 }}
          style={fabStyle}
          onClick={action}>
          {buttonIcon}
        </Fab>
      </Tooltip>
    </Grid>
  )
}

export const NetworkButton = ({name, buttonIcon, action, overrideLevel, buttonLevel, activeLevel, iconSize=32}) => {
  let borderColour = activeLevel === buttonLevel ? "black" : "white";
  borderColour = overrideLevel === buttonLevel ? "#FF0000" : borderColour;
  let bgColour = activeLevel === buttonLevel ? "#00b0ff" : "#787878";
  return (
    <button
      id={name}
      style={{
        borderWidth: "0px",
        borderRadius: "5px",
        color: borderColour,
        width: "40px",
        height: "40px",
        background: bgColour,
        cursor: "pointer"
      }}
      onClick={action}>
      <Icon sx={{ fontSize: iconSize }} fontSize="">{buttonIcon}</Icon>
    </button>
  )
}

export const CanvasControlButton = ({ iconSize, icon, action, tooltip, enableRepeat, colour='#fff' }) => {
  const [pressTimer, setPressTimer] = useState(null);
  const [initialTimeout, setInitialTimeout] = useState(null);

  // Start repeating the action every 0.02 seconds
  const startRepeat = () => setPressTimer(setInterval(action, 20));

  // Start the initial delay of 0.35 seconds
  const handleMouseDown = () => setInitialTimeout(setTimeout(startRepeat, 350));

  const handleStopAction = (log) => {
    // Clear the timers and reset
    clearTimeout(initialTimeout);
    clearInterval(pressTimer);
    setPressTimer(null);
    setInitialTimeout(null);
  };

  useEffect(() => {
    // Clean up timers
    return () => {
      clearTimeout(initialTimeout);
      clearInterval(pressTimer);
    };
  }, [initialTimeout, pressTimer]);

  return (
    <Grid item>
      <Tooltip title={tooltip} placement="left" arrow={false}>
        <Fab
          size="small"
          sx={{ borderRadius: "7px", boxShadow: 0, backgroundColor: colour }}
          onMouseDown={enableRepeat ? handleMouseDown : null}
          onMouseUp={enableRepeat ? () => handleStopAction("Mouse up") : null}
          onMouseLeave={enableRepeat ? () => handleStopAction("Mouse leave") : null}
          onClick={(event) => {
            // Prevent onClick if the button was held down
            if (!pressTimer && !initialTimeout) {
              action(event);
            }
          }}>
          <Icon sx={{ fontSize: iconSize }} fontSize="">{icon}</Icon>
        </Fab>
      </Tooltip>
    </Grid>
  );	
};

export const StatsWindowButton = ({ setWindow, icon, dispatch, id, delay=700 }) => {
  let currentElement = null;

  document.addEventListener('mouseover', function(event) {
    currentElement = event.target;
  });
  
  const handleMouseEnterDataButton = (e) => {
    setTimeout(function() {
      if (e.target.contains(currentElement)) {
        if(dispatch) {
          setWindow(dispatch, e.target.id);
        } else {
          setWindow(true);
        }
      }
    }, delay);
    document.removeEventListener('mouseover', handleMouseEnterDataButton);
  };

  return (
    <Button
      id={id}
      sx={{ 
        p: 0, 
        minWidth: "14px", 
        pointerEvents: 'auto', 
        color: "green", 
        backgroundColor: "white"
      }} 
      onMouseEnter={handleMouseEnterDataButton}
    >
      {icon}
    </Button>
  );
}
