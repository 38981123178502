// @mui components
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

export default function EdgeMetricPanel({networkId}) {
  return(
    <Box height="100px" display="flex" alignItems="center" justifyContent="center">
      <Typography align="center" variant="h1" fontSize={17} sx={{ mb: 1 }}>
        Coming soon...
      </Typography>
    </Box>
  )
}