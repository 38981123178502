// @mui components
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

// Rechart components
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Label, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { networkColours, networkNames } from "helpers/canvashelpers/constants";

// Canvas context
import { decapitalizeFirstLetter } from "helpers/jsxpanelhelpers/helpers";

const Chart = ({ data, title }) => {
  return (
    <Box sx={{ width: 1, height: 0.92 }}>
      <Typography variant="h5" component="h2" style={{ textAlign: "center" }} sx={{mb: 2}}>{title}</Typography>
      <ResponsiveContainer width="100%" height="100%" padding={0}>
        <LineChart data={data} margin={{ right: 30, bottom: 10 }} overflow="visible">
          <XAxis
            dataKey="label"
            angle={-45}
            height={70}
            width={600}
            style={{ fontSize: "0.6rem", fontFamily: "Times New Roman" }}
            tick={{ dy: 25, dx: -27 }}
            stroke="black"
            strokeWidth={1.3}>
            <Label
              position="insideBottom"
              offset={-11}
              value="Date"
              zIndex={100}
              style={{ fontSize: "80%", fill: "black", padding: "100px" }}/>
          </XAxis>
          <YAxis
            domain={[0, dataMax => Math.ceil(dataMax * 1.05)]}
            style={{ fontSize: "0.6rem", fontFamily: "Times New Roman" }}
            stroke="black"
            strokeWidth={1.3}>
            <Label
              position="insideLeft"
              angle={-90}
              offset={35}
              dy={22}
              value="Count"
              style={{ fontSize: "80%", fill: "black", padding: "100px" }}/>
          </YAxis>
          <Tooltip/>
          <CartesianGrid stroke="#eee" strokeWidth={0.8}/>
            {
            Object.values(networkNames).map((id) => {
                const decapitalizedId = decapitalizeFirstLetter(id);
                return(
                <Line
                    type="monotone"
                    dataKey={decapitalizedId}
                    // stroke={"#" + networkColours[id].slice(2)}
                    strokeWidth={2}
                    key={id}/>
                )
            })
            }
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default Chart;