const CountryAddressFormats = {
    'us': {
      countryname: 'United States',
      fields: [
        { name: 'street1', label: 'Address Line 1' },
        { name: 'street2', label: 'Address Line 2' },
        {
          name: 'city',
          label: 'City',
          required: true,
          pattern: /^[a-zA-Z ]{1,25}$/,
          errorMessage: 'City should have at least one letter and a maximum of 25 letters'
        },
        { name: 'state', label: 'State' },
        {
          name: 'zip',
          label: 'ZIP Code',
          required: true,
          pattern: /^\d{5}(?:[-\s]\d{4})?$/,
          errorMessage: 'Please enter a valid US ZIP code'
        },
      ]
    },
    'uk': {
      countryname: 'United Kingdom',
      fields: [
        { name: 'street1', label: 'Address Line 1' },
        { name: 'street2', label: 'Address Line 2' },
        { name: 'town', label: 'Town/City' },
        { name: 'county', label: 'County' },
        {
          name: 'postcode',
          label: 'Postcode',
          required: true,
          pattern: /^(?=(?:[^A-Za-z]*[A-Za-z]){2,4})(?=(?:\D*\d){2,3})[A-Za-z\d ]{4,7}$/,
          // ([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})
          errorMessage: 'Please enter a valid UK postcode'
        },
      ]
    },
    // ...other countries
  };
  
  export default CountryAddressFormats;