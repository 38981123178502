import { CanvasBox, CanvasEdge, createText } from "components/CanvasComponents/CanvasObjects/canvascomponents";

export function getVertex(
  app,
  vertex,
  colour,
  innerSize,
  selectedVertexId,
  cursor,
  alpha,
  activeNetwork,
  fontSizes,
  viewport,
  highlighted,
  unconfiguredChildren,
  openDrawers,
  metricsVisible,
  setOpenDrawers
) {
  const networkId = vertex.context.networkId;

  const xLoc = vertex.tl[0];
  const yLoc = vertex.tl[1];
  const tL = [xLoc * app.renderer.resolution, yLoc * app.renderer.resolution];

  const bottomRightX = tL[0] + vertex.sizes[0];
  const bottomRightY = tL[1] + vertex.sizes[1];

  // Check if the vertex is within the viewport
  const isWithinViewport = tL[0] < viewport.x + viewport.width && bottomRightX > viewport.x &&
                           tL[1] < viewport.y + viewport.height && bottomRightY > viewport.y;

  if (isWithinViewport){
    const paneColour = colour ?? "#cccccc";
    const unselectedFrameColour = (alpha < 1 && vertex.colour !== "#cccccc") ? vertex.colour : undefined;
    let frameColour = selectedVertexId === vertex.context.id ? "#00b0ff" : unselectedFrameColour;
    frameColour = vertex.hovered ? "#FF5F1F" : frameColour;
    const isActive = networkId === parseInt(activeNetwork);

    const interactiveMode = isActive ? "dynamic" : "none";
    const cursorStyle = isActive ? cursor : "default";

    app.stage.addChild(CanvasBox({
      app: app,
      selectedVertexId: selectedVertexId,
      colour: paneColour,
      alpha: alpha,
      context: vertex.context,
      sizes: vertex.sizes,
      position: tL,
      borderColour: frameColour,
      scale: app.stage.scale.x,
      interactive: interactiveMode,
      cursor: cursorStyle,
      fontSizes: fontSizes,
      isIntegrated: vertex.isIntegrated,
      highlighted: highlighted,
      resources: app.resources,
      innerBoxProportion: innerSize,
      unconfiguredChildren: unconfiguredChildren,
      openDrawers: openDrawers,
      metricsVisible: metricsVisible,
      setOpenDrawers: setOpenDrawers
    }));
  }
}

export function getEdge(app, edge, selectedEdgeId, hoveredEdges, setHoveredEdges, onClick, selectedEdgeAncestors) {
  let sampleVertex = app.stage.children.find(x => x.canvasObjectType === "vertex" 
                                               && (x.context?.id === edge.context.sourceNodeId || 
                                                   x.context?.id === edge.context.targetNodeId));

  if (sampleVertex) {
    const isSelected = selectedEdgeId === edge.context.id || selectedEdgeAncestors.includes(edge.context.id);
    let colour;
    if (edge.hovered) {
      colour = "#FF5F1F";
    } else if (isSelected) {
      colour = "#00b0ff";
    } else {
      colour = edge.colour;
    }
    const alpha = isSelected ? 1 : 0.25;
    const { borderWidth, cornerRadius, arrowLength } = getEdgeDimensionParams(sampleVertex);
    app.stage.addChild(
      CanvasEdge(
        edge,
        borderWidth,
        alpha,
        colour,
        cornerRadius,
        arrowLength,
        () => onClick(edge.context),
        hoveredEdges,
        setHoveredEdges
      )
    );
    if(edge.context.networkId === 3 && edge.context.typeId === 1){
      getProductText(edge, app, sampleVertex, borderWidth, arrowLength);
    }
  }
}

function getProductText(edge, app, sampleVertex, borderWidth, arrowLength) {
  const rotation = edge.isIncoming ? -90 : 90;
  const text = createText(edge.context.name, sampleVertex.wide * 0.03, 100, app.stage.scale.x, rotation, false)
  
  text.x = edge.isIncoming ?
    edge.paths.at(-1).endX + borderWidth:
    edge.paths[0].startX - borderWidth;
  text.y = edge.isIncoming ?
    edge.paths.at(-1).endY - (arrowLength + borderWidth):
    edge.paths[0].startY + borderWidth * 2;
  app.stage.addChild(text);
}

function getEdgeDimensionParams(sampleVertex) {
  if(sampleVertex.context.networkId !== 3){
    return {
      borderWidth: sampleVertex.wide * 0.014,
      cornerRadius: sampleVertex.wide * 0.02,
      arrowLength: sampleVertex.wide * 0.04,
    }
  }
  return {
    borderWidth: sampleVertex.wide * 0.016,
    cornerRadius: sampleVertex.wide * 0.04,
    arrowLength: sampleVertex.wide * 0.06,
  };
}