import React, { useState, useEffect } from "react";

// @mui components
import { FormControl, FormControlLabel } from "@mui/material";
import { Tab, RadioGroup, Radio, Tooltip, Checkbox } from "@mui/material";
import { TabPanel } from "@mui/lab";

// @mui icons
import WorkIcon from '@mui/icons-material/Work';

// Components
import SplitMediaCardArray from "components/CardArrayContainers/SplitMediaCardArray";
import AdministrationObjectWrapper from "components/AdministrationComponents/AdministrationObjectWrapper";
import FormCollapse from "components/AdministrationComponents/FormCollapse";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Services
import { validateNameAndDescription } from "helpers/jsxpanelhelpers/helpers";
import { descriptorValidationRules } from "helpers/jsxpanelhelpers/constants";

// Db context
import { createSkillSet, updateSkillSet, getSkillSet, getSkillSetProducts, toggleSkillSetProducesProduct, toggleSkillSetRole } from "apiservices/AuthorisationServices/skillSetService";

// Local context
import { useAdminController } from "context";

const SkillSetView = () => {
  const [controller, dispatch] = useAdminController();
  const [skillSet, setSkillSet] = useState();
  const [skillSetProducts, setSkillSetProducts] = useState([]);
  const [invalidFields, setInvalidFields] = useState({});

  const validate = (object) => {
    const invalidFields = validateNameAndDescription(object, descriptorValidationRules);
    
    // add more validation rules as needed
    return invalidFields;
  };

  useEffect(() => {
    if(skillSet?.id === 0 || !skillSet) return
    const _getSkillSet = async () => {
      let _products = await getSkillSetProducts(skillSet.id);
      setSkillSetProducts(_products);   
    };
    _getSkillSet();
  }, [skillSet]);

  const handleNetworkChange = (e) => setSkillSet({...skillSet, ...{networkId: e.target.value}});

  const handleToggleSkillSetProducesProduct = async (e, id) => {
    let _products = await toggleSkillSetProducesProduct(id, skillSet.id);
    setSkillSetProducts(_products);
  }

  const handleToggleSkillSetRole = async (e, id) => {
    let _skillSetRoles = await toggleSkillSetRole(id, skillSet.id);
    setSkillSet(prevSkillSet => ({
      ...prevSkillSet,
      skillSetRoles: prevSkillSet.skillSetRoles.map(role => ({
          ...role,
          isSelected: _skillSetRoles.includes(role.roleId)
      }))
    }));
  }
  
  const [activeTab, setActiveTag] = useState("1");
  const handleTabChange = (e, tabNumber) => {
    if(skillSet.id !== 0)
      setActiveTag(tabNumber);
  };

  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (skillSet?.organisationId !== controller.organisationId) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [skillSet]);

  return (
    <AdministrationObjectWrapper
      object={skillSet}
      setObject={setSkillSet}
      getObject={getSkillSet}
      createObject={createSkillSet}
      updateObject={updateSkillSet}
      namePlaceholder="Skill Set Name"
      descriptionPlaceholder="Description of Skill Set"
      iconComponent={<WorkIcon sx={{ fontSize: 94 }} fontSize="" />}
      activeTab={activeTab}
      header={
        skillSet &&
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Details" value="1"/>
            {
              skillSet.id !== 0 ?
                <Tab label="Products" value="2"/> :
                <Tooltip title="Save the skillset to begin detailing its products">
                  <Tab label="Products" value="2"/>
                </Tooltip>
            }
          </AdminTabList>
      }
      form={
        skillSet &&
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <FormCollapse title="Skill Set Network" open>
              <FormControl>
                <RadioGroup
                  aria-labelledby="skill-set-network"
                  value={skillSet.networkId}
                  onChange={handleNetworkChange}>
                  <FormControlLabel value={2} control={<Radio disabled={disabled}/>} label="Job skill set" />
                  <FormControlLabel value={1} control={<Radio disabled={disabled}/>} label="Organisation skill set" />
                </RadioGroup>
              </FormControl>
            </FormCollapse>
            <FormCollapse title="Roles" open>
              <FormControl component="fieldset">
                {
                  skillSet.networkId === 2 && skillSet.skillSetRoles.map((role, index) => (
                    <FormControlLabel
                      key={index}
                      control={<Checkbox
                        checked={role.isSelected}
                        onChange={(e) => handleToggleSkillSetRole(e, role.roleId)}
                        disabled={disabled}/>
                      }
                      name={role.name}
                      label={role.name}/>
                  ))
                }
              </FormControl>
              {/* E.g. an estimator would be a project administrator and should have access to instance admin views. */}
              {/* E.g. a Buyer would be an Inventory Administrator and should have access to inventory admin views. */}
              {/* E.g. a Design Coordinator would be a coordinator and should be able to set assignments for Design Technicians and Buyers. */}
            </FormCollapse>
            {/* Add a list of those entities who have this skillset, on the rhs, similar to how skillsets are seen in products */}
          </TabPanel>
      }
      panels={
        skillSet?.id !== 0 &&
          <TabPanel 
            value="2"
            style={{ padding: 8, display: activeTab === "2" ? "flex" : "none", flexGrow: 1 }}>
            <SplitMediaCardArray
              items={skillSetProducts}
              titles={["Products Produced by Skill Set", "Products not Produced by Skill Set"]}
              action={handleToggleSkillSetProducesProduct}
              searchboxLabel="Search products"/>
          </TabPanel>
      }
      validate={validate}
      setInvalidFields={setInvalidFields}
      invalidFields={invalidFields}
      requiresOrgId
    />
  );
}

export default SkillSetView;