import * as PIXI from "pixi.js";

export function convertHexToRGBA(hex, alpha) {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

const DEFAULT_OPACITY = 1;
const DEFAULT_GRADIENT_STOP = 0.7;
const DEFAULT_LIGHT_SOURCE_X_RATIO = 3 / 4;
const DEFAULT_LIGHT_SOURCE_Y_RATIO = 1 / 4;
const DEFAULT_LIGHT_SPREAD_X_RATIO = 1 / 2;
const DEFAULT_LIGHT_SPREAD_Y_RATIO = 1 / 2;

function createCanvas(width, height) {
  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  return { canvas, ctx };
}

function createTextureFromCanvas(canvas, opacity = DEFAULT_OPACITY, resolutionMultiplier = 1) {
  const texture = PIXI.Texture.from(canvas);
  texture.baseTexture.setResolution(resolutionMultiplier);
  texture.baseTexture.alphaMode = PIXI.ALPHA_MODES.UNPACK;
  texture.baseTexture.alpha = opacity;
  return texture;
}

export function createGradientTexture(startColor, endColor, width, height, opacity = DEFAULT_OPACITY) {
  const { canvas, ctx } = createCanvas(width, height);

  const gradient = ctx.createLinearGradient(0, 0, width, height);
  gradient.addColorStop(0, startColor);
  gradient.addColorStop(DEFAULT_GRADIENT_STOP, endColor);
  gradient.addColorStop(1, startColor);

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width, height);

  return createTextureFromCanvas(canvas, opacity);
}

export function createDirectionalSpotlight(startColor, endColor, width, height, opacity = DEFAULT_OPACITY) {
  const { canvas, ctx } = createCanvas(width, height);

  const lightSourceX = width * DEFAULT_LIGHT_SOURCE_X_RATIO;
  const lightSourceY = height * DEFAULT_LIGHT_SOURCE_Y_RATIO;
  const lightSpreadX = width * DEFAULT_LIGHT_SPREAD_X_RATIO;
  const lightSpreadY = height * DEFAULT_LIGHT_SPREAD_Y_RATIO;
  const outerRadius = Math.min(width, height) / 2;

  const gradient = ctx.createRadialGradient(
    lightSourceX, lightSourceY, 0,
    lightSpreadX, lightSpreadY, outerRadius
  );
  gradient.addColorStop(0, convertHexToRGBA(endColor, (opacity + 1) / 2));
  gradient.addColorStop(1, convertHexToRGBA(startColor, opacity));

  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, width, height);

  return createTextureFromCanvas(canvas, opacity);
}

const textureCache = new Map();

function generateCacheKey(width, backgroundColour, scale) {
  const normalizedScale = Math.round(scale / 0.2) * 0.2;
  return `${width}-${backgroundColour}-${normalizedScale}`;
}

export function createHatching(width, backgroundColour, opacity = DEFAULT_OPACITY, resolutionMultiplier = 2) {
  const cacheKey = generateCacheKey(width, backgroundColour, resolutionMultiplier);

  if (textureCache.has(cacheKey)) {
    return textureCache.get(cacheKey);
  }

  const highResWidth = width * resolutionMultiplier;
  const borderThickness = (width / 50) * resolutionMultiplier;

  const { canvas, ctx } = createCanvas(highResWidth, highResWidth);

  ctx.fillStyle = backgroundColour;
  ctx.fillRect(0, 0, highResWidth, highResWidth);

  ctx.globalAlpha = opacity;
  ctx.strokeStyle = "#000";
  ctx.lineWidth = (width / 24) * resolutionMultiplier;
  ctx.lineCap = 'butt'; // or 'square'

  const extendedWidth = highResWidth * 2;
  const startPointOffset = highResWidth / 8; // Offset starting point to extend above canvas

  for (let x = -extendedWidth; x < extendedWidth * 2; x += (width / 8) * resolutionMultiplier) {
    ctx.beginPath();
    ctx.moveTo(x, -startPointOffset); // Start drawing above the canvas
    ctx.lineTo(x + extendedWidth, extendedWidth - startPointOffset); // Draw beyond the canvas top
    ctx.stroke();
  }

  ctx.fillStyle = backgroundColour;
  ctx.globalAlpha = 0.96;
  ctx.fillRect(borderThickness, borderThickness, highResWidth - 2 * borderThickness, highResWidth - 2 * borderThickness);

  const texture = createTextureFromCanvas(canvas, opacity, resolutionMultiplier);

  textureCache.set(cacheKey, texture);

  return texture;
}