import * as PIXI from "pixi.js";

export function getLineDescriptions(lines, junctionRadius, arrowheadLength) {
  return lines.map((line, index, array) => {
    const isFirstLine = index === 0;
    const isLastLine = index === array.length - 1;
  
    const startPoint = isFirstLine ? { x: line.startX, y: line.startY } : getStartPoint(line, junctionRadius);
    const endPoint = getEndPoint(line, junctionRadius, arrowheadLength, isLastLine);
  
    return { startX: startPoint.x, startY: startPoint.y, endX: endPoint.x, endY: endPoint.y };
  });
}
  
function getStartPoint(line, radius) {
  if (line.startX === line.endX) { // vertical line
    return { x: line.startX, y: line.startY + (line.endY > line.startY ? radius : -radius) };
  } else { // horizontal line
    return { x: line.startX + (line.endX > line.startX ? radius : -radius), y: line.startY };
  }
}
  
function getEndPoint(line, radius, arrowheadLength, isLastLine) {
  if (isLastLine) {
    if (line.startX === line.endX) { // vertical line
      return { x: line.endX, y: line.endY - (line.endY > line.startY ? arrowheadLength : -arrowheadLength) };
    } else { // horizontal line
      return { x: line.endX - (line.endX > line.startX ? arrowheadLength : -arrowheadLength), y: line.endY };
    }
  } else {
    if (line.startX === line.endX) { // vertical line
      return { x: line.endX, y: line.endY - (line.endY > line.startY ? radius : -radius) };
    } else { // horizontal line
      return { x: line.endX - (line.endX > line.startX ? radius : -radius), y: line.endY };
    }
  }
}
  
export function calculateStartAngleForLine(currentLine, nextLine) {
  // Determine the direction of the current line
  const currentLineDirectionX = currentLine.endX > currentLine.startX ? 1 : -1;
  const currentLineDirectionY = currentLine.endY > currentLine.startY ? 1 : -1;
  
  // Determine the direction of the next line
  const nextLineDirectionX = nextLine.endX > nextLine.startX ? 1 : -1;
  const nextLineDirectionY = nextLine.endY > nextLine.startY ? 1 : -1;
  
  // Calculate the start angle based on the directions of the lines
  if (currentLine.startX === currentLine.endX) {
    // Vertical line
    if (nextLineDirectionX > 0) {
      return currentLineDirectionY > 0 ? Math.PI / 2 : Math.PI;
    } else {
      return currentLineDirectionY > 0 ? 0 : 1.5 * Math.PI;
    }
  } else if (currentLine.startY === currentLine.endY) {
    // Horizontal line
    if (nextLineDirectionY > 0) {
      return currentLineDirectionX > 0 ? 1.5 * Math.PI : Math.PI;
    } else {
      return currentLineDirectionX > 0 ? 0 : Math.PI / 2;
    }
  }
}
  
export function getArcCenter(line, nextLine, radius) {
  let arcCenterX = line.endX;
  let arcCenterY = line.endY;
  
  // Determine the direction of the current line
  const currentLineDirectionX = line.endX > line.startX ? 1 : -1;
  const currentLineDirectionY = line.endY > line.startY ? 1 : -1;

  // Determine the direction of the next line
  const nextLineDirectionX = nextLine.endX > nextLine.startX ? 1 : -1;
  const nextLineDirectionY = nextLine.endY > nextLine.startY ? 1 : -1;

  // Adjust the arc center based on the directions of the lines
  if (line.startX === line.endX) {
    // Vertical line
    arcCenterX += nextLineDirectionX * radius;
    arcCenterY += currentLineDirectionY * radius;
  } else if (line.startY === line.endY) {
    // Horizontal line
    arcCenterX += currentLineDirectionX * radius;
    arcCenterY += nextLineDirectionY * radius;
  }
  
  // Adjust to the center of the quadrant
  if (line.startX === line.endX) {
    arcCenterY -= currentLineDirectionY * radius;
  } else if (line.startY === line.endY) {
    arcCenterX -= currentLineDirectionX * radius;
  }
  
  return { arcCenterX, arcCenterY };
}

export function drawEdgeSegment(graphic, description, width, angle, hitAreaOptions) {
  // Draw the line segment
  graphic.moveTo(description.startX, description.startY);
  graphic.lineTo(description.endX, description.endY);

  // The rectangle width is 2x the line width
  const rectangleWidth = width * 2;
  const halfRectWidth = rectangleWidth / 2;

  // Precompute sine and cosine for the angle
  const sinAngle = Math.sin(angle);
  const cosAngle = Math.cos(angle);

  // Calculate the four corners of the rectangle around the line
  const point1 = [
    description.startX - halfRectWidth * sinAngle,
    description.startY + halfRectWidth * cosAngle
  ];
  const point2 = [
    description.startX + halfRectWidth * sinAngle,
    description.startY - halfRectWidth * cosAngle
  ];
  const point3 = [
    description.endX + halfRectWidth * sinAngle,
    description.endY - halfRectWidth * cosAngle
  ];
  const point4 = [
    description.endX - halfRectWidth * sinAngle,
    description.endY + halfRectWidth * cosAngle
  ];

  // Define the points of the rectangle (hit area)
  const hitAreaDescription = [...point1, ...point2, ...point3, ...point4];
  addHitArea(graphic, hitAreaDescription, hitAreaOptions);
}

export function drawArcForJunction(graphic, arcCenter, radius, startAngle, width, hitAreaOptions) {
  // Calculate start and end coordinates of the arc
  const startX = arcCenter.arcCenterX + radius * Math.cos(startAngle);
  const startY = arcCenter.arcCenterY + radius * Math.sin(startAngle);
  const endAngle = startAngle + (Math.PI / 2);

  // Draw the arc
  graphic.moveTo(startX, startY);
  graphic.arc(arcCenter.arcCenterX, arcCenter.arcCenterY, radius, startAngle, endAngle);

  // We create a polygon that starts at the beginning of the arc and covers the bounding area
  const cosStart = Math.cos(startAngle);
  const sinStart = Math.sin(startAngle);
  const cosEnd = Math.cos(endAngle);
  const sinEnd = Math.sin(endAngle);

  const innerRadius = radius - width * 0.7;
  const outerRadius = radius + width * 1.5;

  const hitAreaDescription = [ 
    // Inner points (closer to the center of the round)
    arcCenter.arcCenterX + innerRadius * cosStart,
    arcCenter.arcCenterY + innerRadius * sinStart,
    arcCenter.arcCenterX + innerRadius * cosEnd,
    arcCenter.arcCenterY + innerRadius * sinEnd,

    // Outer points (extended outward to cover the outer hit area)
    arcCenter.arcCenterX + outerRadius * cosEnd, 
    arcCenter.arcCenterY + outerRadius * sinEnd,
    arcCenter.arcCenterX + outerRadius * cosStart, 
    arcCenter.arcCenterY + outerRadius * sinStart
  ];

  addHitArea(graphic, hitAreaDescription, hitAreaOptions);
}

export function drawArrowhead(graphic, width, line, angle, color, alpha, arrowheadLength, hitAreaOptions) {
  const arrowheadX = line.endX - (width * 0.3) * Math.cos(angle);
  const arrowheadY = line.endY - (width * 0.3) * Math.sin(angle);

  const angleAdjustment = Math.PI / 6;
  const point1 = [
    arrowheadX - arrowheadLength * Math.cos(angle - angleAdjustment),
    arrowheadY - arrowheadLength * Math.sin(angle - angleAdjustment)
  ];
  const point2 = [
    arrowheadX - arrowheadLength * Math.cos(angle + angleAdjustment),
    arrowheadY - arrowheadLength * Math.sin(angle + angleAdjustment)
  ];

  // Draw the arrowhead
  graphic.beginFill(color, alpha);
  graphic.lineStyle(0);
  graphic.drawPolygon([arrowheadX, arrowheadY, point1[0], point1[1], point2[0], point2[1]]);
  graphic.closePath();
  graphic.endFill();

  // Define the hit area polygon
  const hitAreaDescription = [arrowheadX, arrowheadY, point1[0], point1[1], point2[0], point2[1]];
  addHitArea(graphic, hitAreaDescription, hitAreaOptions);
}

function addHitArea(graphic, hitAreaDescription, hitAreaOptions){
  // Create the hit area graphics and attach event listeners
  const hitAreaGraphics = new PIXI.Graphics();
  hitAreaGraphics.eventMode = 'dynamic';
  hitAreaGraphics.hitArea = new PIXI.Polygon(hitAreaDescription);
  hitAreaGraphics.cursor = 'pointer';

  // Event listeners for mouseover and mouseout
  if (hitAreaOptions.hoveredEdges) {
    hitAreaGraphics.on('mouseover', (e) => {
      if (!hitAreaOptions.hoveredEdges.includes(hitAreaOptions.edge)) {
        e.preventDefault();
        // Add the edge to hoveredEdges if it's not already present
        hitAreaOptions.setHoveredEdges([...hitAreaOptions.hoveredEdges, hitAreaOptions.edge]);
      }
    });

    hitAreaGraphics.on('mouseout', (e) => {
      e.preventDefault();
      // Remove the edge from hoveredEdges if it's there
      hitAreaOptions.setHoveredEdges(hitAreaOptions.hoveredEdges.filter(hoveredEdge => hoveredEdge !== hitAreaOptions.edge));
    });
  }

  hitAreaGraphics.on('mousedown', (e) => {
    e.preventDefault();
    hitAreaOptions.onClick();
  });
  // hitAreaGraphics.beginFill(0xff0000, 0.3); // Red with 30% opacity
  // hitAreaGraphics.drawPolygon(hitAreaDescription);
  // hitAreaGraphics.endFill();

  // Add the hit area graphics as a child of the main graphic
  graphic.addChild(hitAreaGraphics);
}