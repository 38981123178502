import { logger } from './logWrapper';

const csrfTokenCache = {
  token: null,
  expiresAt: null,
};

const getCsrfToken = async () => {
  if (csrfTokenCache.token && csrfTokenCache.expiresAt > Date.now()) {
    return csrfTokenCache.token;
  }

  try {
    const response = await fetch(`/api/authorisation/getcsrf`);
    const data = await response.json();
    csrfTokenCache.token = data;
    csrfTokenCache.expiresAt = Date.now() + 300000;
    return csrfTokenCache.token;
  } catch (error) {
    throw new Error('Failed to fetch CSRF token');
  }
};

const makeRequest = async (url, options) => {
  if (!url) {
    throw new Error('URL is required');
  }

  try {
    const csrfToken = await getCsrfToken();
    const headers = {
      'X-CSRF': csrfToken,
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      throw new Error(response.statusText);
    }

    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return response.json();
    }

    return response.text();
  } catch (error) {
    logger(url, 'error', error);
    throw error;
  }
};

export const postWrapper = async (url, body) => {
  return makeRequest(url, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });
};

export const getWrapper = async (url) => {
  return makeRequest(url, {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
    },
  });
}