// @mui components
import { Box } from "@mui/material";
import { Button, Typography } from '@mui/material';

// Components
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import KMCardContainer from "components/CardArrayContainers/KMCardContainer";
import { SubmitButton } from "components/GlobalComponents/Buttons";
import BaseModal from "./BaseModal";

export const ManagerModal = ({ handleClose, title, data, handleOpenEditor, deleteAction, open=true, cardTitle="name" }) => {
  return(
    <BaseModal open={open} handleClose={handleClose} title={title} style={{ width: "80%", height: "80%", bgcolor: "rgba(235, 235, 235, 0.9)" }}>
      <CreateAndEditInPlaceArray 
        items={data}
        title={cardTitle}
        editor={handleOpenEditor}
        cardColour="#FFFFFF"
        borderRadius="0px"
        padding={0} 
        margins={1.5}
        deleteAction={deleteAction}/>
    </BaseModal>
  )
}

export const KnowledgeManagerModal = ({ handleClose, title, data, handleOpenEditor, deleteAction, open=true, cardTitle="name" }) => {
  return(
    <BaseModal open={open} handleClose={handleClose} title={title} style={{ width: "80%", height: "80%", bgcolor: "rgba(235, 235, 235, 0.9)" }}>
      <KMCardContainer
        items={data}
        title={cardTitle}
        editor={handleOpenEditor}
        cardColour="#FFFFFF"
        borderRadius="0px"
        padding={0} 
        margins={1.5}
        deleteAction={deleteAction}/>
    </BaseModal>
  )
}

export const ProcessEditorModal = ({ toggleEditorVisibility, title, alternator, children }) => {
  return(
    <BaseModal
      open={true}
      handleClose={toggleEditorVisibility}
      title={title}
      style={{ width: "80%", height: "80%", bgcolor: "rgba(235, 235, 235, 0.9)" }}
      additional={alternator}>
      <Box
        sx={{
          height: 1,
          width: 1,
          ml: -1,
          mt: -1,
          display: "flex",
          position: "absolute",
          justifyContent: "space-between",
          alignItems: "flex-end",
          borderRadius: "7px"
        }}
        style={{ backgroundColor: "rgb(224, 224, 224)" }}
        id="mattsbox">
        {children}
      </Box>
    </BaseModal>
  );
};

export const EditorModal = ({ toggleEditorVisibility, createButtonText, title, children, submitEvent, open=true, width="80%", height="80%" }) => {
  return(
    <BaseModal open={open} handleClose={toggleEditorVisibility} title={title} style={{width: width, height: height, bgcolor: "rgba(235, 235, 235, 0.97)"}}>
      {children}
      {
        submitEvent && (
          <SubmitButton action={submitEvent} text={createButtonText}/>
        )
      }
    </BaseModal>
  )
}

export const DeleteModal = ({ id, handleClose, deleteAction }) => {
  return(
    <BaseModal open={id !== 0} handleClose={handleClose} title="Delete Item" style={{width: "40%", height: "40%", bgcolor: "rgba(255, 255, 255)"}}>
      <Typography id="transition-modal-title" variant="h6" component="h2">
        This action cannot be undone
      </Typography>
      <Typography id="transition-modal-description" sx={{ mt: 2 }}>
        Are you sure you want to delete this item?
      </Typography>
      <Button onClick={(e) => deleteAction(e, id)}>Delete Item</Button>
    </BaseModal>
  )
}

export const WrapperModal = ({ open, handleClose, children, title, primaryEvent, primaryButtonText }) => {
  return(
    <BaseModal
      open={open}
      handleClose={handleClose}
      title={title}
      children={
        <>
          {children}
          {
            primaryEvent !== undefined &&
              <SubmitButton action={primaryEvent} text={primaryButtonText} background="#EBEBEB"/>
          }
        </>
      }
      style={{width: "40%", height: "40%", bgcolor: "rgba(235, 235, 235, 0.9)"}}
      expandable={false}
      closeButtonPosition='right'
    />
  )
}

export const ObjectModal = ({ open, handleClose, children }) => {
  return(
    <BaseModal
      open={open}
      handleClose={handleClose}
      children={children}
      expandable={false}
      closeButtonPosition='right' 
      style={{width: "90%", height: "90%", bgcolor: "rgba(235, 235, 235, 0.9)"}}
    />
  )
}