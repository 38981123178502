import React, { useState } from 'react';

// @mui components
import { Box } from "@mui/material";
import { TextField, Checkbox, Tooltip, IconButton, Typography, Icon } from "@mui/material";

// @mui icons
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';

import { createInstanceVariable, deleteInstanceVariable } from "apiservices/ResultServices/instanceService";

const InstanceVariableEditorCard = ({
  colour,
  resultOption,
  instanceVariable,
  setSelectedInstanceVariableId,
  instanceVariables,
  setInstanceVariables,
  instanceId,
  parentInstanceVariableId
}) => {
  
  const name = instanceVariable ? instanceVariable.name : resultOption.name;

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleUpdateVariableName = (e) => {
    e.stopPropagation();
    setInstanceVariables((prevVariables) =>
      prevVariables.map((variable) => 
        variable.id === instanceVariable.id
          ? { ...variable, name: e.target.value }
          : variable
      )
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleEditClick();
    }
  };

  const handleDelete = async (e) => {
    e.stopPropagation();
    setSelectedInstanceVariableId(undefined);
    const descendants = getDescendants(instanceVariable.id);
    const descendantIds = descendants.map((descendant) => descendant.id);

    [instanceVariable.id, ...descendantIds].forEach(async (id) => {
      await deleteInstanceVariable(id);
    });
    
    setInstanceVariables((prevVariables) =>
      prevVariables.filter((variable) =>
        variable.id !== instanceVariable.id && !descendantIds.includes(variable.id)
      )
    );
  };
  
  const addInstanceVariable = async (resultOptionId, name, parentInstanceVariableId = null) => {
    const _instanceVariable = await createInstanceVariable(instanceId, {
      name: name,
      parentInstanceVariableId,
      resultOptionId: resultOptionId,
    });
    setInstanceVariables((prevVariables) => [...prevVariables, _instanceVariable]);
  };

  const handleDuplicateVariable = (instanceVariable, resultOptionId, deepCopy) => {
    addInstanceVariable(resultOptionId, createVariableName(instanceVariable.name), instanceVariable.parentInstanceVariableId);
    
    if (deepCopy) {
      const descendants = getDescendants(instanceVariable.id);
      descendants.forEach((descendant) => {
        addInstanceVariable(descendant.resultOptionId, createVariableName(descendant.name), descendant.parentInstanceVariableId);
      });
    }
  };

  const createVariableName = (variableName) => {
    const baseName = variableName.replace(/: Type \d+$/, '');
    const existingVariables = instanceVariables.filter((variable) => variable.name.startsWith(baseName));
    const suffixes = existingVariables.map((variable) => {
      const match = variable.name.match(/: Type (\d+)$/);
      return match ? parseInt(match[1]) : 0;
    });
    const highestSuffix = Math.max(...suffixes, 0);
    return `${baseName}: Type ${highestSuffix + 1}`;
  };

  const getDescendants = (projectVariableId) => {
    const descendants = [];
    const children = instanceVariables.filter((variable) => variable.parentInstanceVariableId === projectVariableId);
    children.forEach((child) => {
      descendants.push(child);
      descendants.push(...getDescendants(child.id));
    });
    return descendants;
  };

  const handleToggleCheck = async (e) => {
    const id = instanceVariable?.id;
  
    // Determine if we should delete or create the instance variable
    const _instanceVariable = id
      ? await deleteInstanceVariable(id)
      : await createInstanceVariable(instanceId, {
          name: resultOption.name,
          parentInstanceVariableId,
          resultOptionId: resultOption.id,
        });
  
    setInstanceVariables((prevVariables) =>
      id && _instanceVariable === true
        ? prevVariables.filter((v) => v.id !== id) // Remove if delete was successful
        : [...prevVariables, _instanceVariable]   // Add if a new variable was created
    );
  };

  const handleSelectVariable = () => {
    let id = instanceVariable && instanceVariable.id;
    setSelectedInstanceVariableId(id);
  };

  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'space-between', background: colour, pr: 1 }}
      onClick={handleSelectVariable}>
      <Box sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden' }}>
        <Checkbox checked={instanceVariable !== undefined} onChange={handleToggleCheck} />
        {
          !isEditing ? (
            <Typography
              variant="body1"
              sx={{ textOverflow: 'ellipsis' }}
              noWrap>
              {name}
            </Typography>
          ) : (
            <TextField
              autoFocus
              variant="standard"
              size="small"
              value={name}
              onChange={handleUpdateVariableName}
              onKeyDown={handleKeyDown}/>
          )
        }
      </Box>
      {
        (instanceVariable && resultOption.typeId > 1) &&
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Tooltip title="Rename" placement="right" arrow={false}>
              <IconButton
                size="small" 
                sx={{ backgroundColor: 'grey', borderRadius: '50%', padding: "3px" }}
                onClick={() => handleEditClick()}>
                <Icon sx={{ fontSize: 22 }} fontSize="">create</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Duplicate as per the default settings" placement="right" arrow={false}>
              <IconButton
                size="small" 
                sx={{ backgroundColor: 'grey', borderRadius: '50%', padding: "1px" }}
                  onClick={(e) => handleDuplicateVariable(e, false)}>
                <Icon sx={{ fontSize: 26 }} fontSize="">add</Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Duplicate as per the below settings" placement="right" arrow={false}>
              <IconButton
                size="small" 
                sx={{ backgroundColor: 'grey', borderRadius: '50%' }}
                onClick={(e) => handleDuplicateVariable(e, true)}>
                <ContentCopyIcon/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete variable and its children" placement="right" arrow={false}>
              <IconButton
                size="small" 
                sx={{ backgroundColor: 'grey', borderRadius: '50%' }}
                onClick={handleDelete}>
                <DeleteIcon/>
              </IconButton>
            </Tooltip>
          </Box>
      }
    </Box>
  );
}

export default InstanceVariableEditorCard;