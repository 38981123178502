import { postWrapper, getWrapper } from '../fetchWrapper';

export const createInstance = async (instance) => await postWrapper('/api/instance/createinstance', instance);
export const updateInstance = async (instance) => await postWrapper('/api/instance/updateinstance', instance);

export const deleteInstance = async (instanceId) => await getWrapper(`/api/instance/deleteinstance/` + instanceId);
export const getInstance = async (instanceId) => await getWrapper(`/api/instance/getinstance/` + instanceId);
export const getInstances = async (organisationId) => await getWrapper(`/api/instance/getinstances/` + organisationId);

export const createInstanceVariable = async (instanceId, instanceVariable) => await postWrapper(`/api/instance/createinstancevariable/` + instanceId, instanceVariable);
export const deleteInstanceVariable = async (instanceVariableId) => await getWrapper(`/api/instance/deleteinstancevariable/` + instanceVariableId);
export const getInstanceVariable = async (instanceVariableId) => await getWrapper(`/api/instance/getinstancevariable/` + instanceVariableId);
export const getInstanceVariables = async (instanceId) => await getWrapper(`/api/instance/getinstancevariables/` + instanceId);

export const updateDescriptorTag = async (instanceVariableId, descriptorId, tagId) => await getWrapper(`/api/instance/updatedescriptortag/` + instanceVariableId + `/` + descriptorId + `/` + tagId);
export const toggleDescriptorTag = async (instanceVariableTags) => await postWrapper(`/api/instance/toggledescriptortag`, instanceVariableTags);
export const updateDescriptorValue = async (projectVariableId, descriptorId, value) => await getWrapper(`/api/instance/updatedescriptorvalue/` + projectVariableId + `/` + descriptorId + `/` + value);

export const getProjectBaseData = async (atlasId, projectId) => await getWrapper(`/api/instance/getprojectbasedata/` + atlasId + `/` + projectId);