import React, { useState, useEffect } from 'react';

// @mui components
import { Box } from '@mui/material';
import { TreeView, TreeItem } from '@mui/lab';

// @mui icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

// Components
import InstanceVariableEditorCard from "./InstanceVariableEditorCard";

import { getInstanceVariables } from 'apiservices/ResultServices/instanceService';

const InstanceVariablesEditor = ({ resultOptions, instance, selectedInstanceVariableId, setSelectedInstanceVariableId }) => {
  const [instanceVariables, setInstanceVariables] = useState([]);
  useEffect(() => {
    const fetchInstanceVariables = async () => {
      const instanceVariables = await getInstanceVariables(instance.id);
      setInstanceVariables(instanceVariables);
    };
    fetchInstanceVariables();
  }, [instance.id]);

  const buildTreeItems = (resultOptions, parentResultOptionId = null, parentVariableId = null) => {
    return resultOptions
      .filter(v => v.parentResultOptionId === parentResultOptionId)
      .flatMap(resultOption => {
        const _instanceVariables = instanceVariables.filter(pv => pv.resultOptionId === resultOption.id && pv.parentInstanceVariableId === parentVariableId);
        const variables = _instanceVariables.length > 0 ? _instanceVariables : [{ id: resultOption.id, name: resultOption.name }];
  
        return variables.map((variable, index) => {
          const childTreeItems = buildTreeItems(resultOptions, resultOption.id, variable.id);
  
          return (
            <CustomTreeItem
              key={`${resultOption.id}-${variable.id}`}
              nodeId={`${resultOption.id}-${variable.id}`}
              label={
                <InstanceVariableEditorCard
                  colour={selectedInstanceVariableId === variable.id ? "#C0C0C0" : "transparent"}
                  resultOption={resultOption}
                  instanceVariable={_instanceVariables[index]}
                  setSelectedInstanceVariableId={setSelectedInstanceVariableId}
                  instanceVariables={_instanceVariables}
                  setInstanceVariables={setInstanceVariables}
                  instanceId={instance.id}
                  parentInstanceVariableId={parentVariableId}/>
              }>
              {childTreeItems}
            </CustomTreeItem>
          );
        });
      });
  };  

  return (
    <Box
      sx={{ backgroundColor: "#F5F5F5", borderRadius: "4px", width: '50%', display: "flex" }}>
      <TreeView
        sx={{ flexGrow: 1, width: '100%' }}
        onNodeToggle={(event, nodeIds) => {
          event.stopPropagation();
        }}
        defaultCollapseIcon={<ExpandMoreIcon/>} 
        defaultExpandIcon={<ChevronRightIcon/>}>
        {buildTreeItems(resultOptions)}
      </TreeView>
    </Box>
  );
};

export default InstanceVariablesEditor;


function CustomTreeItem({ nodeId, label, children }) {
  return (
    <TreeItem 
      nodeId={nodeId} 
      label={
        <span
          onClick={(event) => { event.stopPropagation(); }}>
          {label}
        </span>
      }
      sx={{
        "& .MuiTreeItem-content": {
          backgroundColor: "transparent !important"
        }
      }}>
      {children}
    </TreeItem>
  );
}