import React, { useState, useEffect } from "react";

// react-router-dom components
import { useSearchParams } from "react-router-dom";

// @mui components
import { TabPanel, TabContext } from "@mui/lab";
import { Tab } from "@mui/material";

// @mui icons
import LanguageIcon from '@mui/icons-material/Language';
import GroupIcon from '@mui/icons-material/Group';
import HandshakeIcon from '@mui/icons-material/Handshake';
import WorkIcon from '@mui/icons-material/Work';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import StraightenIcon from '@mui/icons-material/Straighten';

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import CreateAndEditInPlaceArray from "components/CardArrayContainers/CreateAndEditInPlaceArray";
import RequestEditor from "./RequestEditor";

import UserGroupView from "./ug";
import { ObjectModal } from "components/GlobalComponents/Modals";
import { AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { getUserRequests, getUserPermits } from "apiservices/AuthorisationServices/requestService";

// Local context
import { useAdminController } from "context";

const RequestsPage = () => {
  const [controller, dispatch] = useAdminController();
  const { userId } = controller;
  const [searchParams, setSearchParams] = useSearchParams();

  const [requests, setRequests] = useState([]);
  const [permits, setPermits] = useState([]);
  useEffect(() => {
    const _getRequests = async () => {
      let _requests = await getUserRequests(userId);
      let _permits = await getUserPermits(userId);
      setRequests(_requests);
      setPermits(_permits);
    };
    _getRequests();
  }, []);

  const [editor, setEditor] = useState(0);
  const [builder, setBuilder] = useState(undefined);
  const openEditor = (e, id) => {
    if(!permits.includes(id))
      setEditor(id);
    else{
      setSearchParams({ id: '0' });
      setBuilder("create");
    }
  }
  const closeEditor = () => setEditor(0);
  const closeBuilder = () => setBuilder(undefined);

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);
  return (
    // Object requests
    // Access requests
    // Inventory requests

    // Icons and text should be green if the user has permission to create the item type
    <AdministrationViewWrapper ml={0} mr={0} mt={0} mb={0} gap={0}>
      <TabContext value={activeTab}>
        <AdminTabList onChange={handleTabChange} sx={{ borderRadius: "0px" }} {...tabIndicator}>
          <Tab label="Create request" value="1"/>
          <Tab label="View requests" value="2"/>
        </AdminTabList>
        <TabPanel 
          value="1"
          style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
          <CreateAndEditInPlaceArray
            items={getItemButtons(permits)}
            colour={"white"}
            editor={openEditor}
            borderRadius={"0px"}/>
        </TabPanel>
        <TabPanel 
          value="2"
          style={{ padding: 0, display: activeTab === "2" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
          <CreateAndEditInPlaceArray
            items={requests}
            colour={"white"}
            editor={openEditor}
            borderRadius={"0px"}/>
        </TabPanel>
      </TabContext>
      {
        editor !== 0 &&
          <RequestEditor
            closeEditor={closeEditor}
            setRequests={setRequests}
            requestId={editor}
            tabId={activeTab}/>
      }
      {
        builder &&
          <ObjectModal open handleClose={closeBuilder} >
            <UserGroupView/>
          </ObjectModal>
      }
    </AdministrationViewWrapper>
  );
}

function getItemButtons(permits) {
  const buttonConfigs = [
    { id: 1, createName: "Create Atlas", requestName: "Request Atlas", icon: <LanguageIcon/> },
    { id: 2, createName: "Create User Group", requestName: "Request User Group", icon: <GroupIcon/> },
    { id: 3, createName: "Create Associate", requestName: "Request Associate", icon: <HandshakeIcon/> },
    { id: 4, createName: "Create Tag Group", requestName: "Request Tag Group", icon: <LocalOfferIcon/> },
    { id: 5, createName: "Create Metric", requestName: "Request Metric", icon: <StraightenIcon/> }
  ];

  let buttons = buttonConfigs.map(config => {
    const name = permits.includes(config.id) ? config.createName : config.requestName;
    return { icon: config.icon, name: name, id: config.id };
  });

  return buttons;
}

export default RequestsPage;