export const descriptorValidationRules = {
  name: {
    minLength: 3,
    maxLength: 100,
  },
  description: {
    minLength: 0,
    maxLength: 500,
  },
};

export const userValidationRules = {
  name: {
    minLength: 3,
    maxLength: 100,
  },
  description: {
    minLength: 10,
    maxLength: 500,
  },
};