import React, { useState, useEffect } from "react";

// @mui components
import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";

// Components
import { AdministrationViewWrapper } from "components/AdministrationComponents/General";
import MediaCardArrayContainer from "components/CardArrayContainers/MediaCardArrayContainer";
import { AdminPanel, AdminTabList, tabIndicator } from "components/AdministrationComponents/StyledAdminComponents";

// Db Context
import { getDescriptors, deleteDescriptor } from "apiservices/ResultServices/descriptorService";

// Local context
import { useAdminController } from "context";

const createDescriptorTagGroupButton = [{media: ["add"], name: "Create New Descriptor Tag Group", id: 0}]
const createNumericDescriptorButton = [{media: ["add"], name: "Create New Numeric Descriptor", id: 0}]

const Descriptors = () => {
  const [controller, dispatch] = useAdminController();
  const { organisationId } = controller;

  const [descriptorTagGroups, setDescriptorTagGroups] = useState([]);
  const [numericDescriptors, setNumericDescriptors] = useState([]);
  useEffect(() => {
    const _getDescriptors = async () => {
      let _descriptors = await getDescriptors(organisationId, 0);
      setDescriptorTagGroups(_descriptors.filter(x => x.typeId === 1));
      setNumericDescriptors(_descriptors.filter(x => x.typeId === 2));
    };
    _getDescriptors();
  }, []);

  const handleDeleteDescriptor = async (e, descriptorId) => {
    let _descriptors = await deleteDescriptor(descriptorId);
    setDescriptorTagGroups(_descriptors.filter(x => x.typeId === 1));
    setNumericDescriptors(_descriptors.filter(x => x.typeId === 2));
  }

  const [activeTab, setActiveTab] = useState("1");
  const handleTabChange = (e, tabNumber) => setActiveTab(tabNumber);
  
  return (
    <AdministrationViewWrapper>
      <AdminPanel>
        <TabContext value={activeTab}>
          <AdminTabList onChange={handleTabChange} {...tabIndicator}>
            <Tab label="Descriptor Tag Groups" value="1"/>
            <Tab label="Numeric Descriptors" value="2"/>
          </AdminTabList>
          <TabPanel 
            value="1"
            style={{ padding: 0, display: activeTab === "1" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <MediaCardArrayContainer 
              items={createDescriptorTagGroupButton.concat(descriptorTagGroups)} 
              path={"/descriptors/descriptorTagGroup"} 
              deleteAction={handleDeleteDescriptor}/>
          </TabPanel>
          <TabPanel 
            value="2"
            style={{ padding: 0, display: activeTab === "2" ? "flex" : "none", flexDirection: "column", flexGrow: 1 }}>
            <MediaCardArrayContainer 
              items={createNumericDescriptorButton.concat(numericDescriptors)} 
              path={"/descriptors/numericDescriptor"} 
              deleteAction={handleDeleteDescriptor}/>
          </TabPanel>
        </TabContext>
      </AdminPanel>
    </AdministrationViewWrapper>
  );
}

export default Descriptors;